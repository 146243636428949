import { useTheme } from "styled-components"
import { WarningIcon } from "../../primitives/media/Icons"
import { DesignLimitErrorDetailsWrapper, ErrorModalColumnTitle, ErrorModalContent, ErrorModalWrapper, ErrorWarningMsg } from "../../primitives/styles/Common/CommonStyle"
import { RippleWarningModalHeader, RippleInfoDetail } from "../../primitives/styles/Ripples/RipplesStyle"
import Phrase from "../Phrase/Phrase"

const CookiesAreDisabled = () => {
  const theme: any = useTheme();
    return <ErrorModalWrapper>
    <ErrorModalContent style={{width: '80vw'}}>
      <RippleWarningModalHeader style={{padding: 0}}>
        <ErrorModalColumnTitle style={{fontSize: '25px', fontWeight: 'bold'}}>
          <WarningIcon />
          <Phrase
            translationKey={'6ec623b0-9ca7-4c5a-9eae-3e00b5eb910e'}
            defaultText={'Cookie Blocking Detected'}
          />
        </ErrorModalColumnTitle>
      </RippleWarningModalHeader>
      <DesignLimitErrorDetailsWrapper style={{paddingTop: 0, paddingBottom: `calc(45px * ${theme.ratio})`}}>
        <RippleInfoDetail>
          <ErrorWarningMsg>
            <Phrase
              translationKey={'22aff626-5c4e-4170-8d97-0071ec360ae6'}
              defaultText={'To access Ripples mobile portal, please enable cookies in your browser settings.'}
            />
          </ErrorWarningMsg>
        </RippleInfoDetail>
      </DesignLimitErrorDetailsWrapper>
    </ErrorModalContent>
  </ErrorModalWrapper>
}

export default CookiesAreDisabled;