import styled from 'styled-components';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const SubTitle = styled.div`
font-family: Brandon Grotesque;
font-size: 23px;
font-weight: 390;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
color: white;
white-space: pre-line;
`

export const OnboardingContainer = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
align-items: center;
width: -webkit-fit-content;
width: -moz-fit-content;
width: 90%;
height: calc(${(props) => props.theme.deviceHeight}px - 80.16px * ${(props) => props.theme.heightRatio});
margin-top: calc( 80.16px * ${(props) => props.theme.heightRatio});
`

export const LocationImage = styled.img`
width: 120px;
height: 120px;
`

export const Option = styled.div`
font-family: Brandon Grotesque;
font-size: 22px;
font-weight: 390;
line-height: 25px;
letter-spacing: 0.02em;
text-align: left;
color: #FFFFFF;
text-decoration: underline;
width: 80%;
text-align: center;
margin-bottom: 20px;
margin-top: 10px;
`

export const NoteContainer = styled.div`
display: flex;
justify-content: space-around;
white-space: pre-line;
color: #FFFFFFB2;
line-height: 23px;
`;

export const NoteDivider = styled.div`
width: 5px;
border-left: 1px solid;
margin 0 15px;
height: 45px;
`;

export const LocationNameContainer = styled.div`
display: flex;
color: white;
align-self: flex-start;
font-size: 20px;
height: 20%;
margin-top: 5%;
`;

export const FloatingLocationButtonContainer = styled.div`
position: fixed;
bottom: 0px;
width: 100vw;
height: calc( 100px * ${(props) => props.theme.heightRatio});
background-image: linear-gradient(rgba(24, 25, 29, 0), rgba(24, 25, 29, 1));
display: flex;
justify-content: center;
align-items: center;
`

export const createFloatingButtonStyles = (
    ratio: number,
    heightRatio: number,
  ) => {
    return makeStyles((theme: Theme) =>
      createStyles({
        root: {
          '&.MuiButton-root:hover': {
            backgroundColor: '#E86161 !important'
          },
          '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#D9D9D9',
            color: 'white'
          },
          width: `calc(212px * ${ratio})`,
          height: `calc(48px * ${ratio})`,
          borderRadius: `calc(25px * ${ratio})`,
          paddingTop: 13,
          paddingBottom: 13,
          fontFamily: 'Brandon Grotesque',
          fontWeight: 600,
          alignSelf: 'center',
          fontSize: `calc(16px * ${ratio})`,
          color: '#FFFFFF',
          backgroundColor: '#E86161',
        }
      })
    );
  };