import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { global } from '../Global/global';
export const FloatingButton = styled.button.attrs(
  (props: { isTouched: boolean }) => ({
    isTouched: props.isTouched,
  })
)`
  background: ${(props) =>
    props.isTouched ? props.theme.palette.white : props.theme.palette.red};
  border-radius: calc(25px * ${(props) => props.theme.ratio});
  border: none;
  padding: 13px 38px;
  position: fixed;
  left: calc(50vw - 106px * ${(props) => props.theme.ratio});
  bottom: 47px;
  min-width: calc(212px * ${(props) => props.theme.ratio});
  min-height: calc(48px * ${(props) => props.theme.ratio});
  :disabled {
    background: ${(props) => props.theme.palette.grey};
  }
`;

export const ErrorModalColumnTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: bold;
  font-size: calc(30px * ${(props) => props.theme.ratio});
  width: 100%;
  line-height: 120.56%;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: calc(5px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const FloatingButtonText = styled.span.attrs(
  (props: { isTouched: boolean }) => ({
    isTouched: props.isTouched,
  })
)`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) =>
    props.isTouched ? props.theme.palette.red : props.theme.palette.white};
  text-transform: uppercase;
  letter-spacing: 0.08em;
  text-align: center;
  font-weight: bold;
  min-width: calc(136px * ${(props) => props.theme.ratio});
  min-height: calc(22px * ${(props) => props.theme.ratio});
  font-size: calc(16px * ${(props) => props.theme.ratio});
`;

export const CheckboxLabel = styled.div`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  font-style: normal;
  font-weight: 500;
  font-size: calc(18px * ${(props) => props.theme.ratio});
  text-transform: capitalize;
  margin-left: calc(7px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const Divider = styled.span`
  width: 100%;
  height: 0px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
`;

export const SpacedDivider = styled.span`
  width: 100%;
  height: 0px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  margin: 20px 0;
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(20px * ${(props) => props.theme.ratio});
  padding: calc(15px * ${(props) => props.theme.ratio});
  width: calc(31px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(20px * ${(props) => props.theme.ratio});
  padding: calc(15px * ${(props) => props.theme.ratio});
  width: calc(31px * ${(props) => props.theme.ratio});
  z-index: 3;
  position: relative;
  ${global.userSelectNone}
`;

export const BackArrow = styled.div`
  min-width: calc(12px * ${(props) => props.theme.ratio});
  min-height: calc(12px * ${(props) => props.theme.ratio});
  border-left: calc(2px * ${(props) => props.theme.ratio}) solid white;
  border-top: calc(2px * ${(props) => props.theme.ratio}) solid white;
  transform: rotate(-45deg);
  ${global.userSelectNone}
`;

export const ForwardArrow = styled.div`
  min-width: calc(12px * ${(props) => props.theme.ratio});
  min-height: calc(12px * ${(props) => props.theme.ratio});
  border-left: calc(2px * ${(props) => props.theme.ratio}) solid white;
  border-top: calc(2px * ${(props) => props.theme.ratio}) solid white;
  transform: rotate(135deg);
  ${global.userSelectNone}
`;

export const BackArrowTail = styled.div`
  min-width: calc(21px * ${(props) => props.theme.ratio});
  height: 0px;
  background: ${(props) => props.theme.palette.white};
  border: calc(1px * ${(props) => props.theme.ratio}) solid white;
  margin-left: calc(-15px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const AutocompleteWrapper = styled.div`
  position: relative;
  max-height: calc(42px * ${(props) => props.theme.ratio});
  min-height: calc(42px * ${(props) => props.theme.ratio});
  max-width: calc(311px * ${(props) => props.theme.ratio});
  min-width: calc(311px * ${(props) => props.theme.ratio});
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid grey;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  ${global.userSelectNone}
`;

export const AutocompleteInput = styled.input`
  width: 85%;
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  background: rgba(0, 0, 0, 0);
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 390;
  overflow: hidden;
  padding: 0px;
  height: calc(40px * ${(props) => props.theme.ratio});
  font-size: calc(21px * ${(props) => props.theme.ratio});
  :disabled {
    background: rgba(0,0,0,0);
    border: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const AutocompleteSearchDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: -1px;
  overflow-y: auto;
  top: calc(42.5px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.palette.black};
  max-width: calc(311px * ${(props) => props.theme.ratio});
  min-width: calc(311px * ${(props) => props.theme.ratio});
  max-height: calc(35px * 5 * ${(props) => props.theme.ratio});
  border-left: 1px solid ${(props) => props.theme.palette.border};
  border-right: 1px solid ${(props) => props.theme.palette.border};
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  z-index: 12;
  &::-webkit-scrollbar {
    width: 14px !important;
    display: block;
  }
  &::-webkit-scrollbar-track {
    width: 3px;
    background: ${(props) => props.theme.palette.black};
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: 15px;
    background-clip: padding-box;
    background-color: ${(props) => props.theme.palette.grey};
  }
  ${global.userSelectNone}
`;
export const AutocompleteSelectOption = styled.span`
  width: fit-content;
  margin-left: 15%;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  background: ${(props) => props.theme.palette.black};
  font-size: calc(21px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const NoDataMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: calc(312px * ${(props) => props.theme.ratio});
  max-width: calc(400px * ${(props) => props.theme.ratio});
  min-height: calc(${(props) => props.theme.deviceHeight}px * 0.35);
  max-height: calc(${(props) => props.theme.deviceHeight}px * 0.35);
  margin-bottom: 50px;
  ${global.userSelectNone}
`;


export const MessageText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.color || props.theme.palette.white};
  font-weight: 330;
  line-height: calc(27px * ${(props) => props.theme.ratio});
  font-size: calc(25px * ${(props) => props.theme.ratio});
  min-width: calc(248px * ${(props) => props.theme.ratio});
  max-width: calc(248px * ${(props) => props.theme.ratio});
  text-align: center;
`;


export const MessageTextWide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.color || props.theme.palette.white};
  font-weight: 330;
  line-height: calc(27px * ${(props) => props.theme.ratio});
  font-size: calc(25px * ${(props) => props.theme.ratio});
  min-width: calc(248px * ${(props) => props.theme.ratio});
  max-width: calc(290px * ${(props) => props.theme.ratio});
  text-align: center;
`;

export const NoDataText = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.color || props.theme.palette.white};
  font-weight: 330;
  line-height: calc(27px * ${(props) => props.theme.ratio});
  font-size: calc(25px * ${(props) => props.theme.ratio});
  min-width: calc(248px * ${(props) => props.theme.ratio});
  max-width: calc(248px * ${(props) => props.theme.ratio});
  text-align: center;
`;

export const NoDataSubText = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  margin-top: calc(20px * ${(props) => props.theme.ratio});
  color: ${(props) => props.color || 'rgba(255, 255, 255, 0.51)'};
  font-weight: 300;
  line-height: calc(36px * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});
  min-width: calc(246px * ${(props) => props.theme.ratio});
  max-width: calc(246px * ${(props) => props.theme.ratio});
  text-align: center;
`;
export const NoDataSubTextLink = styled(NavLink)`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  margin-top: calc(20px * ${(props) => props.theme.ratio});
  color: rgba(255, 255, 255, 0.51);
  font-weight: 300;
  line-height: calc(36px * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});
  min-width: calc(246px * ${(props) => props.theme.ratio});
  max-width: calc(246px * ${(props) => props.theme.ratio});
  text-align: center;
  text-decoration: none;
`;

export const ErrorModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100% - (20px * ${(props) => props.theme.ratio}));
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.transparent.black};
  ${ global.userSelectNone }
`;

export const ErrorModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center
  flex: 1;
  width: 95vw;
  background-color: #e1e1e1;
  border-radius: calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio}) 0 0;
  ${ global.userSelectNone }
`;

export const DesignLimitErrorDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(29px * ${(props) => props.theme.ratio});
  padding-bottom: calc(35px * ${(props) => props.theme.ratio});
  padding-left: calc(26px * ${(props) => props.theme.ratio});
  padding-right: calc(26px * ${(props) => props.theme.ratio});
  background: #e1e1e1;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  // min-height: calc(263px * ${(props) => props.theme.ratio});
  // max-height: calc(263px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const ErrorWarningMsg = styled.span`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: normal;
  padding-top: calc(18px * ${(props) => props.theme.ratio});
  font-size: calc(18px * ${(props) => props.theme.ratio});
  line-height: calc(24px * ${(props) => props.theme.ratio});
  width: 100%;
  // text-transform: capitalize;
`;


export const FloatingCircularButtonWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(48px * ${(props) => props.theme.ratio});
  height: calc(48px * ${(props) => props.theme.ratio});
  bottom: calc(30px * ${(props) => props.theme.ratio});
  right: calc(40px * ${(props) => props.theme.ratio});
`;


export const LoaderGeneralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40%;
  flex: 1;
  width: 100vw;
  z-index: 999;
`;