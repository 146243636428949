import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../primitives/types/Location'

interface LocationState {
  selectedLocationId: string;
  hasPrivateLocations: boolean;
  selectedLocationName: string;
  locations: Location[] | null;
  selectedLocationShortId: string | null;
}

export const initState: LocationState = {
  locations: null,
  selectedLocationId: "",
  selectedLocationName: "",
  hasPrivateLocations: false,
  selectedLocationShortId: null
};

const locationSlice = createSlice({
  name: 'location',
  initialState: initState,
  reducers: {
    SetLocations( state, action: PayloadAction<Location[]>) {
      const locations = action.payload;
      const hasPrivateLocations = locations.some( loc => loc.isPrivate);
      state.locations = locations;
      state.hasPrivateLocations = hasPrivateLocations;
    },

    SetSelectedLocation( state, action: PayloadAction<Location> ) {
      state.selectedLocationId = action.payload.id;
      state.selectedLocationName = action.payload.name;
      state.selectedLocationShortId = action.payload.shortId

    },

    ResetLocationState(state) {
      state.selectedLocationId = initState.selectedLocationId;
      if(state.locations && state.locations.length && state.locations.length === 1) {
        state.selectedLocationId = state.locations[0].id;
        state.selectedLocationName = state.locations[0].name;
      }
    },

    SetInitLocationState(state) {
      state.locations = null;
      state.selectedLocationId = "";
      state.selectedLocationName = "";
    },

  },
});

export const { 
  SetLocations, 
  ResetLocationState, 
  SetSelectedLocation, 
  SetInitLocationState 
} = locationSlice.actions;

export const SelectLocations = (state: any): Location[] => state.location.locations;

export const SelectLocationId = (state: any): string => state.location.selectedLocationId;

export const SelectLocationName = (state: any): string => state.location.selectedLocationName;

export const SelectHasPrivateLocations = (state: any): boolean => state.location.hasPrivateLocations;

export const SelectLocationShortId = (state: any): string | null => state.location.selectedLocationShortId;

export default locationSlice.reducer;
