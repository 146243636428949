import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ViewModeState {
  width: number;
  ratio: number;
  height: number;
  heightRatio: number;
  initialWidth: number;
  initialHeight: number;
  device: 'mobile' | 'tablet';
  deviceKeyboardIsOpen: boolean;
}

const MIN_TABLET_WIDTH = 480;
const BASE_WIDTH = 375;
const BASE_HEIGHT = 665;

export const viewModeState: ViewModeState = {
  ratio: 1,
  width: 375,
  height: 812,
  heightRatio: 1,
  initialWidth: 0,
  device: 'mobile',
  initialHeight: 0,
  deviceKeyboardIsOpen: false
};

const viewModeSlice = createSlice({
  name: 'viewMode',
  initialState: viewModeState,
  reducers: {
    setCurrentViewMode(
      state,
      action: PayloadAction<{ height: number; width: number }>
    ) {
      const { height, width } = action.payload;
      state.width = width;
      state.height = height;
      if (width >= MIN_TABLET_WIDTH) {
        state.ratio = (width / BASE_WIDTH) * 0.7;
      }
      else {
        state.ratio = width / BASE_WIDTH;
      }

      state.heightRatio = height / BASE_HEIGHT;

      if( state.initialHeight > height ){
        state.deviceKeyboardIsOpen = true;
      } else {
        state.deviceKeyboardIsOpen = false;
      }
    },

    SetInitialScreenHeight( state, action: PayloadAction<number>) {
      state.initialHeight = action.payload;
    },

    SetInitialScreenWidth( state, action: PayloadAction<number>) {
      state.initialWidth = action.payload;
    },

  },
});

export const { 
  setCurrentViewMode,
  SetInitialScreenWidth,
  SetInitialScreenHeight 
} = viewModeSlice.actions;

export const SelectRatio = (state: any): number => state.viewMode.ratio;
export const SelectHeightRatio = (state: any): number => state.viewMode.heightRatio
export const SelectIsIFrame = (state: any): boolean => state.viewMode.isIFrame;
export const SelectDeviceHeight = (state: any): number => state.viewMode.height;
export const SelectInitialScreenWidth = (state: any): number => state.viewMode.initialWidth;
export const SelectDeviceInitialHeight = (state: any): number => state.viewMode.initialHeight;
export const SelectDeviceKeyboardIsOpen = (state: any): boolean => state.viewMode.deviceKeyboardIsOpen;
export default viewModeSlice.reducer;
