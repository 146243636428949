
import Phrase from '../Phrase/Phrase';

import { useEffect } from 'react';
import { CloseIcon } from '../../primitives/media/Icons';
import { WarningIcon } from '../../primitives/media/Icons';
import { CloseWrapper } from '../../primitives/styles/Common/CommonStyle';
import {
  RippleInfoDetail,
  RippleInfoModalClose,
  RippleWarningModalHeader,
} from '../../primitives/styles/Ripples/RipplesStyle';

import {
  ErrorWarningMsg,
  ErrorModalWrapper,
  ErrorModalContent,
  ErrorModalColumnTitle,
  DesignLimitErrorDetailsWrapper,
} from '../../primitives/styles/Common/CommonStyle'

interface ErrorProps {
    error?: string;
    errorTitle?: string;
    onClose: () => void;
}

const ConnectionError: React.FC<ErrorProps> = ({onClose, error, errorTitle}) => {
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });

  const message = error ? error : '77d3b437-b8fd-4822-af57-7d854c698930';
  const title = errorTitle ? errorTitle : 'cf15d6d1-3060-477f-8e9e-e0ef73aa7f23';

  return (
    <ErrorModalWrapper>
      <ErrorModalContent>
        <RippleWarningModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={onClose}>
              <CloseIcon />
            </CloseWrapper>
          </RippleInfoModalClose>
          <ErrorModalColumnTitle>
            <WarningIcon />
            <Phrase
              translationKey={title}
              defaultText={'Connection error'}
            />
          </ErrorModalColumnTitle>
        </RippleWarningModalHeader>
        <DesignLimitErrorDetailsWrapper>
          <RippleInfoDetail>
            <ErrorWarningMsg>
              <Phrase
                translationKey={'7f24a9b0-94e3-4b56-b7c2-099a4f304e80'}
                defaultText={'Ooops!'}
              />
            </ErrorWarningMsg>
            <ErrorWarningMsg>
              <b>
                <Phrase
                  translationKey={message}
                  defaultText={'Please try again later'}
                />
              </b>
              {/* {error && error} */}
            </ErrorWarningMsg>
          </RippleInfoDetail>
        </DesignLimitErrorDetailsWrapper>
      </ErrorModalContent>
    </ErrorModalWrapper>
  );
};

export default ConnectionError;
