import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { RippleTabLabel } from '../../../primitives/styles/Ripples/RipplesStyle'

interface RippleOwnDetailsProps {
  tabs: string[];
  value: number;
  switchTab: (newValue: number) => void;
}

const TabsMenu: React.FC<RippleOwnDetailsProps> = ({ tabs, value, switchTab }) => {

  const handleChange = (event: any, newValue: number) => {
    switchTab(newValue);
  };

  const restProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="standard"
      style={{justifyContent:'space-between'}}
      TabIndicatorProps={{
        style: {
          backgroundColor: '#E86161',
        },
      }}
    >
      {tabs.map((tab: string, i: number) => (
        <Tab label={<RippleTabLabel>{tab}</RippleTabLabel>} key={tab} {...restProps(i)} />
      ))}
    </Tabs>
  );
};

export default TabsMenu;
