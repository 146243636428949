import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { global } from '../Global/global';

export const RippleAccordion = styled(Accordion)`
  && {
    min-width: calc(220px * ${(props) => props.theme.ratio});
    max-width: calc(220px * ${(props) => props.theme.ratio});
    background: #e1e1e1;
    margin-bottom: 9px;
    ${ global.userSelectNone }
  }
`;

export const RippleAccordionDetails = styled(AccordionDetails)`
  && {
    background: ${(props) => props.theme.palette.white};
    padding-bottom: 10px;
    ${ global.userSelectNone }
  }
`;

export const RipplesList = styled.div`
  position: relative;
  justify-content: flex-start;
  display: flex;
  width: calc(113px * 3 * ${(props) => props.theme.ratio});
  max-height: calc(300px * ${(props) => props.theme.ratio});
  padding-bottom: 100%;
  overflow-y: scroll;
  flex-wrap: wrap;
  ${ global.userSelectNone }
`;

export const ScrollableRipplesList = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(113px * 3 * ${(props) => props.theme.ratio});
  max-height: calc(410px * ${(props) => props.theme.ratio});
  padding-bottom: 100%;
  flex-wrap: wrap;
  background: #e1e1e1;
  overflow-y: scroll;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
  calc(5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(50px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const ScrollableRipplesListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-height: calc(490px * ${(props) => props.theme.ratio});
  min-height: calc(490px * ${(props) => props.theme.ratio});
  flex-wrap: wrap;
  background: #e1e1e1;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
  calc(5px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const EmptyRipplesList = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  width: calc(113px * 3 * ${(props) => props.theme.ratio});
  min-height: calc(${(props) => props.theme.deviceHeight}px * 0.5);
  flex-wrap: wrap;
  ${ global.userSelectNone }
`;

export const RippleWrapper = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(113px * ${(props) => props.theme.ratio});
  min-height: calc(113px * ${(props) => props.theme.ratio});
  height: fit-content;
  ${ global.userSelectNone }
`;

export const InlineRippleWrapper = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(113px * ${(props) => props.theme.ratio});
  min-height: calc(113px * ${(props) => props.theme.ratio});
  height: fit-content;
  ${ global.userSelectNone }
`;

export const RipplesLoadingPlaceholder = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: calc(60px * 3 * ${(props) => props.theme.ratio});
  width: calc(113px * 3 * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});
  flex-wrap: nowrap;
  color: white;
  padding: 15px;
  ${ global.userSelectNone }
`;

export const RippleImage = styled.img`
  height: auto;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #f1f1f1;
  min-width: calc(97px * ${(props) => props.theme.ratio});
  max-width: calc(97px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const RippleImageIntersectionTarget = styled.span`
${ global.userSelectNone }
`;

export const RippleImagePlaceholder = styled.img`
  min-height: calc(97px * ${(props) => props.theme.ratio});
  height: auto;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  min-width: calc(97px * ${(props) => props.theme.ratio});
  max-width: calc(97px * ${(props) => props.theme.ratio});
  background: lightgrey;
  ${ global.userSelectNone }
`;

export const RippleSelected = styled.div`
  position: absolute;
  z-index: 2;
  background: rgba(240, 97, 98, 0.3);
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.palette.red};
  min-height: calc(97px * ${(props) => props.theme.ratio});
  min-width: calc(97px * ${(props) => props.theme.ratio});
  max-height: calc(97px * ${(props) => props.theme.ratio});
  max-width: calc(97px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: calc(20px * ${(props) => props.theme.ratio});
  max-width: calc(113px * 3 * ${(props) => props.theme.ratio} * 0.88);
  min-height: fit-content;
`;

export const DesignLibraryTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(20px * ${(props) => props.theme.ratio});
  line-height: calc(31px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: raw;
  align-items: center;
  margin-top: calc(20px * ${(props) => props.theme.ratio});
  color: ${(props) => props.theme.palette.white};
  justify-content: space-between;
  ${ global.userSelectNone }
`;

export const SelectedTagsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-top: calc(20px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const Tag = styled.span`
  display: flex;
  white-space: nowrap;
  flex-direction: raw;
  justify-content: space-between;
  margin-right: calc(8px * ${(props) => props.theme.ratio});
  padding: calc(8px * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});
  background: #383c46;
  border-radius: 6px;
  width: fit-content;
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  ${ global.userSelectNone }
`;

export const RippleModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100% - (20px * ${(props) => props.theme.ratio}));
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.transparent.black};
  ${ global.userSelectNone }
`;

export const RippleInfoModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 95vw;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const RippleInfoModalHeader = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.theme.palette.red};
  color: ${(props) => props.theme.palette.white};
  border-radius: calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio}) 0 0;
  padding: calc(15px * ${(props) => props.theme.ratio});
  min-height: calc(128px * ${(props) => props.theme.ratio});
  max-height: calc(128px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const RippleInfoModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${ global.userSelectNone }
`;

export const RippleInfoModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: bold;
  font-size: calc(30px * ${(props) => props.theme.ratio});
  min-width: calc(130px * ${(props) => props.theme.ratio});
  line-height: 120.56%;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: calc(5px * ${(props) => props.theme.ratio});
  ${ global.userSelectNone }
`;

export const RippleInfoModalDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: calc(86.5px * ${(props) => props.theme.ratio});
  padding-left: calc(5px * ${(props) => props.theme.ratio});
  padding-right: calc(5px * ${(props) => props.theme.ratio});
  background: #e1e1e1;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  overflow-y: auto;
  ${global.userSelectNone}
`;

export const RippleInfoModalImage = styled.img`
  border-radius: 50%;
  position: absolute;
  background: lightgrey;
  width: calc(145.71px * ${(props) => props.theme.ratio});
  height: calc(145.71px * ${(props) => props.theme.ratio});
  left: calc((95vw - 145.71px * ${(props) => props.theme.ratio}) / 2);
  top: calc(85.15px * ${(props) => props.theme.ratio});
  z-index: 10;
  ${ global.userSelectNone }
`;

export const RippleInfoModalDetailsSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: calc(10.5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10.5px * ${(props) => props.theme.ratio});
  padding-left: 0;
  padding-right: 0;
  // border-top: 1px solid ${(props) => props.theme.palette.white};
  min-height: calc(60px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleTabPanel = styled.div`
  width: 95%;
  padding-top: calc(20px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleTabLabel = styled.span`
  font-size: calc(11px * ${(props) => props.theme.ratio});
  font-weight: bold;
`;

export const RippleInfoDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  ${global.userSelectNone}
`;

export const RippleInfoDetailKey = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: bold;
  font-size: calc(15px * ${(props) => props.theme.ratio});
  line-height: calc(19.74px * ${(props) => props.theme.ratio});
  padding-top: calc(7.5px * ${(props) => props.theme.ratio});
  text-transform: capitalize;
`;

export const RippleInfoDetailValue = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: normal;
  font-size: calc(20px * ${(props) => props.theme.ratio});
  line-height: calc(19.74px * ${(props) => props.theme.ratio});
  text-transform: capitalize;
  margin-top: calc(20px * ${(props) => props.theme.ratio});
  padding-bottom: calc(5px * ${(props) => props.theme.ratio});
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const RippleInfoDetailValueScroll = styled.span`
  display: flex;
  flex-direction: column;
  max-height: calc(45px * 7 * ${(props) => props.theme.ratio});
  overflow-y: auto;
  padding-left: calc(20px * ${(props) => props.theme.ratio});
  padding-right: calc(20px * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});

`;

export const RippleInfoTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: calc(20px * ${(props) => props.theme.ratio});
  padding-right: calc(20px * ${(props) => props.theme.ratio});
  min-height: calc(46px * 2 * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});
  max-height: calc(${(props) => props.theme.deviceHeight}px * 0.48);
  max-width: 80vw;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 14px !important;
    display: block;
  }
  &::-webkit-scrollbar-track {
    width: 3px;
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: 15px;
    background-clip: padding-box;
    background-color: #e1e1e1;
  }
  ${global.userSelectNone}
`;

export const RippleInfoTag = styled.div`
  border: 1px solid #4f4f4f;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-size: calc(16px * ${(props) => props.theme.ratio});
  border-radius: calc(5px * ${(props) => props.theme.ratio});
  margin-right: calc(5px * ${(props) => props.theme.ratio});
  margin-top: calc(5px * ${(props) => props.theme.ratio});
  padding-top: calc(8px * ${(props) => props.theme.ratio});
  padding-bottom: calc(6px * ${(props) => props.theme.ratio});
  padding-left: calc(17px * ${(props) => props.theme.ratio});
  padding-right: calc(17px * ${(props) => props.theme.ratio});
  min-height: calc(30px * ${(props) => props.theme.ratio});
  max-height: calc(30px * ${(props) => props.theme.ratio});
  white-space: nowrap;
  ${global.userSelectNone}
`;

export const RippleInfoModalLoaderWrapper = styled.div`
  border-radius: 50%;
  position: absolute;
  background: lightgrey;
  width: calc(145.71px * ${(props) => props.theme.ratio});
  height: calc(145.71px * ${(props) => props.theme.ratio});
  left: calc((95vw - 145.71px * ${(props) => props.theme.ratio}) / 2);
  top: calc(85.15px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleInfoLoader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: calc(145.71px * ${(props) => props.theme.ratio});
  height: calc(145.71px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleWalkThroughModalWrapper = styled.div`
  position: fixed;
  overflow-y: hidden;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  ${global.userSelectNone}
`;

export const RippleWalkThroughModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${global.userSelectNone}
`;

export const RippleWalkThroughModalCircle = styled.div`
  z-index: 5;
  position: absolute;
  top: calc(216px * ${(props) => props.theme.ratio} / 2);
  left: calc(50% - (113px * ${(props) => props.theme.ratio} / 2));
  height: auto;
  border-radius: 1px;
  min-width: calc(113px * ${(props) => props.theme.ratio});
  max-width: calc(113px * ${(props) => props.theme.ratio});
  min-height: calc(113px * ${(props) => props.theme.ratio});
  max-height: calc(113px * ${(props) => props.theme.ratio});
  -webkit-box-shadow: 5px 5px 0px 1000px rgba(0, 0, 0, 0.6);
  box-shadow: 5px 5px 0px 1000px rgba(0, 0, 0, 0.6);
  ${global.userSelectNone}
`;

export const RippleWalkThroughModalText = styled.span`
  position: absolute;
  width: calc(230px * ${(props) => props.theme.ratio});
  height: calc(116px * ${(props) => props.theme.ratio});
  left: calc(35% - (227px * ${(props) => props.theme.ratio} / 2));
  top: calc(116px * ${(props) => props.theme.ratio} / 2);
  font-family: Brandon Grotesque;
  font-style: normal;
  font-weight: bold;
  font-size: calc(20px * ${(props) => props.theme.ratio});
  line-height: calc(24px * ${(props) => props.theme.ratio});
  letter-spacing: 0.02em;
  z-index: 5;
  border-radius: 20%;
  color: ${(props) => props.theme.palette.white};
  text-shadow: 1px 0px 20px black;
  white-space: pre-wrap;
`;

export const TextShadow = styled.span`
  position: absolute;
  min-width: 1px;
  min-height: 1px;
  border-radius: 50%;
  -webkit-box-shadow: 7px 5px 50px calc(80px * ${(props) => props.theme.ratio})
    rgba(0, 0, 0, 0.5);
  box-shadow: 7px 5px 50px calc(80px * ${(props) => props.theme.ratio})
    rgba(0, 0, 0, 0.5);
  left: calc(15%);
  top: calc(116px * ${(props) => props.theme.ratio} / 2);
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  z-index: 5;
  left: calc(25%);
  top: calc(206px * ${(props) => props.theme.ratio} / 2);;
  width: calc(35px * ${(props) => props.theme.ratio});
  height: calc(56px * ${(props) => props.theme.ratio});
  transform: rotateX(180deg) rotateY(10deg);
  ${global.userSelectNone}
`;

export const RippleWarningModalHeader = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background: ${(props) => props.theme.palette.red};
  color: ${(props) => props.theme.palette.white};
  border-radius: calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio}) 0 0;
  padding: calc(15px * ${(props) => props.theme.ratio});
  min-height: calc(95px * ${(props) => props.theme.ratio});
  max-height: calc(95px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleWarningModalDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(29px * ${(props) => props.theme.ratio});
  padding-bottom: calc(35px * ${(props) => props.theme.ratio});
  padding-left: calc(26px * ${(props) => props.theme.ratio});
  padding-right: calc(26px * ${(props) => props.theme.ratio});
  background: #e1e1e1;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  min-height: calc(263px * ${(props) => props.theme.ratio});
  max-height: calc(263px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleWarningMsg = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: normal;
  padding-top: calc(18px * ${(props) => props.theme.ratio});
  font-size: calc(18px * ${(props) => props.theme.ratio});
  line-height: calc(24px * ${(props) => props.theme.ratio});
  // text-transform: capitalize;
`;

export const RippleWarningButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${global.userSelectNone}
`;
