import styled from 'styled-components';

export const FrameEditorWrap = styled.div`
  background: transparent;
  position: relative;
  margin-bottom: 15px;
  margin-top: 10px;
`;

export const CanvasWrap = styled.div`
  width: auto;
  border: 2px #ccc solid;
  overflow: hidden;
  margin: 0px auto 10px auto;
  border-radius: 1000px;
  background-color: #fff;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%);
  background-size: 24px 24px; /* Must be a square */
  background-position: 0 0, 12px 0, 12px -12px, 0px 12px; /* Must be half of one side of the square */
`;

export const TapToEdit = styled.div`
  position: absolute;
  bottom: -15px;
  left: 0px;
  color: #fff;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  background-image: url('/frames/arrow.png');
  background-repeat: no-repeat;
  background-position: 10px 0px;
  padding-top: 40px;
`;


export const SelectFrameTitle = styled.div`
  color: #E86161;
  font-family: 'Brandon Grotesque';
  font-size: 22px;
  margin: 15px 0 5px 0;

`;




