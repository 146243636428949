import Title from '../../../../components/Title/Title'

import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle, Option } from '../../../../primitives/styles/Onboarding/Onboarding';
import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Phrase from '../../../../components/Phrase/Phrase';
import LocationButton from '../../components/LocationButton';
import RunningRipples from './components/RunningRipples';

interface BeforeContinuingProps {
  onNext: () => void;
  onMoveOn: () => void;
  theme?: any;
  isContentFlow: boolean;
}

const BeforeContinuing: React.FC<BeforeContinuingProps> = ({
  onNext,
  onMoveOn,
  isContentFlow
}) => {
  return (
    <Container>
      <BreadcrumbsHeader currentStep={0} totalSteps={0} shouldRenderBack={false} shouldRenderClose={false} shouldRenderRippesIcon={true}/>
      <OnboardingContainer>
      <Title type="main" text={isContentFlow ? TITLES.CHNAGE_PREFERENCES : TITLES.BEFORE_CONTINUING} defaultText={isContentFlow ? 'CHANGE YOUR\nPREFERENCES' : 'BEFORE\nCONTINUING…'} size={'mid_large'} style={{height: isContentFlow ? '21%' : '15%', marginTop: '5%'}}/>
      <SubTitle style={{textAlign: 'center', minHeight: '25%', height: 'fit-content'}}>
        <Phrase defaultText={'Share details about your place, and we\'ll make sure your Ripple Maker gets awesome customized designs'} translationKey='9b6ded7a-59fe-4d36-bdca-a84b9ab287a0'/>
      </SubTitle>
      <RunningRipples/>
      <LocationButton onClick={onNext}>
        <Phrase defaultText={'LET’S DO IT!'} translationKey={'193bc081-21a3-4ab4-8312-55c4890cf502'}/>
      </LocationButton>
      {!isContentFlow && <Option onClick={onMoveOn}>
        <Phrase translationKey={'9d4d4fae-d94c-4844-8fbe-4b2647635540'} defaultText={'Not now'}/>
      </Option>}
      </OnboardingContainer>
    </Container>
  );
};

export default BeforeContinuing;