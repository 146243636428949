import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'

import { useSelector } from 'react-redux';
import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import Note from '../SetLocation/components/Note';
import fontSize from '../../../../constants/FontSize';
import { SelectTranslations } from '../../../../store/translationSlice';
import LocationTextField from './components/CreateLocationTextField';
import { LocationContentContainer } from '../../../../primitives/styles/Onboarding/Location';
import { FormikProps } from 'formik';
import LocationButton from '../../components/LocationButton';
import { useEffect, useState } from 'react';

interface SetLocationNameProps {
  currentStep: number;
  totalSteps: number;
  theme: any;
  form?: FormikProps<any>
  onNext: () => void;
  onBack: () => void;
  onClose: () => void
}

const SetLocationName: React.FC<SetLocationNameProps> = ({
  currentStep,
  totalSteps,
  theme,
  form,
  onNext,
  onBack,
  onClose
}) => {
  const { ratio, deviceHeight } = theme;
  const translations = useSelector(SelectTranslations);
  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(deviceHeight)
  }, [])

  const nickNameLabeltext: string = translations['61eb9fc2-04ea-471f-90e5-86d300846ed9'] || 'Location Nickname';
  const requiredText  = translations['a615fa81-f3f3-41d4-be4d-1684cff14737'] || 'This field is required';

  const titlesStyle = {
      whiteSpace: 'pre-line', textAlign: 'left', textShadow: 'none', height: `calc(${height}px * 0.18`, marginTop: `calc(${height}px * 0.02`, width: '100%'
  };

  const subTitleStyle: Object = {
      textAlign: 'left',
      width: '90%',
      height: '20%'
  }

  return (
    <Container style={{overflowY: 'auto'}}>
      <BreadcrumbsHeader onBack={onBack} currentStep={currentStep} totalSteps={totalSteps} flow='createLocation' onClose={onClose}/>
      <OnboardingContainer>
      <Title type="flow" text={TITLES.SELECT_LOCATION_NICKNAME} defaultText={'Set a location nickname'} size={fontSize.MID_LARGE} style={titlesStyle} />
      <LocationContentContainer style={{height: `calc(${height}px * 0.70`, minHeight: `calc(${height}px * 0.70`, width: '100%', overflowY: 'scroll'}}>
      <SubTitle style={subTitleStyle}>
        <Phrase defaultText={'Choose a nickname in a structure that clearly represents the location'} translationKey={'f999642f-f037-481b-b7b1-4ce3ea37e595'}/>
      </SubTitle>
      <LocationTextField 
      id={'name'} 
      name={'name'} 
      onBlur={form?.handleBlur} 
      label={nickNameLabeltext} 
      value={form?.values.name} 
      onChange={form?.handleChange} 
      style={{ width: '100%', height: '20%', marginTop: '20px'}}
      error={!!form?.errors.name && !!form.touched.name}
      errorText={requiredText}
      />
        <Note 
        defaultText={'For example:\n’Cafe Noir - Front desk’\n(Company name - machine\'s location)'}
         translationKey={'9be0bfea-6cdc-40cc-92cf-ac812b753eff'}
         style={{width: '75%', height: '40%'}}
         />
      <LocationButton onClick={onNext} disabled={!form?.values.name} style={{width: `calc(300px * ${ratio})`}}>
      <Phrase defaultText={'NEXT'} translationKey={'b15c3820-3db5-4c29-8aea-fd6e94e22cc1'}/>
      </LocationButton>
      </LocationContentContainer>
      </OnboardingContainer>
    </Container>
  );
};

export default SetLocationName;