import Phrase from "../../../components/Phrase/Phrase";
import { FontSelectorPseudoSelect, TextControlWrapper } from "../../../primitives/styles/Frames/FrameControlsStyle";
import SelectFontModal from "./SelectFontModal";
import { useState } from "react";

interface TextControlProps {
    selectedFont: string;
    updateSelectedFont: (font: string) => void;
}

const fonts = [
    'Amatic',
    'Arial',
    'Cormorant',
    'Eczar',
    'Open Sans',
    'Pacifico',
    'Playfair',
    'Poppins',
    'Raleway',
    'Saira',
];


const FontSelector: React.FC<TextControlProps> = ({selectedFont, updateSelectedFont}) => {
    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    }

    const selectFont = (font: string) => {
        updateSelectedFont(font);
        closeModal();
    }



    return (
        <TextControlWrapper>
            <Phrase translationKey="cc28ee62-77a5-4189-bf16-8f4013f9d6d2" defaultText="Select Font" />
            {selectedFont && (
                <FontSelectorPseudoSelect 
                    style={{fontFamily: selectedFont}}
                    onClick={()=> {setShowModal(true)}}
                >
                    {selectedFont}
                </FontSelectorPseudoSelect>
            )}
            {showModal && (
                <SelectFontModal fonts={fonts} selectFont={selectFont} closeSelectFontModal={closeModal} />
            )}
            
        </TextControlWrapper>
    );
}

export default FontSelector;
