import ManageChannel from './ManageChannel';
import NoDataMessage from '../Common/NoDataMessage';
import FloatingCircularButton from '../Common/FloatingCircularButton';

import { useEffect } from 'react';
import { useScrollDetector } from '../../providers/ScrollDetector';
import { Channel as ChannelType } from '../../primitives/types/Channel';
import { ManageChannelsList as Wrapper } from '../../primitives/styles/Channel/ChannelStyle';
import Phrase from '../Phrase/Phrase';

interface ManageChannelsListProps {
  organizationId: string;
  onAddAction: () => void;
  onRemoveAction: () => void;
  selectedChannels: String[];
  actionType: 'add' | 'remove' | 'markMobile';
  channels: Array<ChannelType>;
  isAddChannelSelected: boolean;
  handleClickOnChannel: (id: string) => void;
  onLongPress: (channel: ChannelType) => void;
  isMobile?: boolean
  onMarkMobile: (channelId: string) => void;
}

const ManageChannelsList: React.FC<ManageChannelsListProps> = ({
  channels,
  actionType,
  onAddAction,
  onLongPress,
  onRemoveAction,
  organizationId,
  selectedChannels,
  isAddChannelSelected,
  isMobile = false,
  handleClickOnChannel,
  onMarkMobile,
}) => {

  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  }, []);

  const withScroll = useScrollDetector()

  const handleChannelLongPress = (channel: ChannelType) => {
    withScroll(onLongPress, channel)
  }

  if (!channels.length) {
    return (
      <Wrapper>
        <NoDataMessage
          useIcon={false}
          isSubtextLink={false}
          text={'74b9e109-4d95-46be-9927-850ff3e23d49'}
          defaultText={'No channels found'}
          subtext={'cfe034b6-f6ad-4fb9-8ec6-2f7d1c0f81ec'}
          defaultSubtext={isAddChannelSelected || isMobile ? '' : 'Click ‘+’ button to add channels'}
        />
        {!isAddChannelSelected && !isMobile && (
          <FloatingCircularButton
            actionType={actionType}
            onClick={actionType === 'add' ? onAddAction : onRemoveAction}
          />
        )}
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {isMobile && <span style={{ color: 'lightgray', margin: '10px 0 15px 0', width: '100%' }}>
        <Phrase translationKey='892a8711-956e-41e9-8179-b7187dbb177b' defaultText='Select the channels that you want to see in mobile'/>
      </span>}
      {Array.isArray(channels) &&
        channels.map((channel: ChannelType, i: number) => (
          <ManageChannel
            channel={channel}
            key={`${i}-${channel.id}`}
            onLongPress={handleChannelLongPress}
            handleClickOnChannel={handleClickOnChannel}
            isSelected={selectedChannels.includes(channel.id)}
            isShared={channel.organizationId !== organizationId}
            isMobile={isMobile}
            onMarkMobile={onMarkMobile}
          />
        ))}
      {
        !isAddChannelSelected && !isMobile && (
          <FloatingCircularButton
            actionType={actionType}
            onClick={actionType === 'add' ? onAddAction : onRemoveAction}
          />
        )
      }
    </Wrapper>
  );
};

export default ManageChannelsList;
