import axios from 'axios';
import getConfigByEnv from '../../utils/getConfigByEnv';

const createOrgForUser = async (data: any) => {
  const url = `${
    getConfigByEnv().IDENTITY.REST_API_URL
  }/users/createOrgForUser`;

  return axios.post(url, data);
};

export default createOrgForUser;