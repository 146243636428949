import styled from 'styled-components';
import { global } from '../Global/global';

export const PublishSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  margin-top: calc(${(props) => props.theme.deviceHeight}px * 0.01);
  padding-left: 10px;
  padding-bottom: ${(props) =>
    props.theme.deviceHeight <= 500 ? `${props.theme.deviceHeight * 0.2}px` : '0px'};
  ${global.userSelectNone}
`;

export const PublishDisableAll = styled.div`
  min-height: ${(props) => props.theme.deviceHeight}px;
  min-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 444;
  ${global.userSelectNone}
`;

export const SelectionGroupType = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(270px * ${(props) => props.theme.ratio});
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const SelectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //margin-left: calc(27px * ${(props) => props.theme.ratio});
  min-width: calc(270px * ${(props) => props.theme.ratio});
  max-width: calc(270px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const TimeSelection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: calc(300px * ${(props) => props.theme.ratio});
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const TimeLimitTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  font-size: calc(18px * ${(props) => props.theme.ratio});
`;

export const FrameOrderTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  font-size: calc(18px * ${(props) => props.theme.ratio});
  margin-bottom: 10px;
`;

export const TimeRadioSelectorGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(300px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const FirstLastRadioSelectorGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(300px * ${(props) => props.theme.ratio});
  padding-top: calc(15px * ${(props) => props.theme.ratio});
  padding-bottom: calc(15px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const TimeRadioSelectorGroupDisabled = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: calc(320px * ${(props) => props.theme.ratio});
  min-height: calc(50px * ${(props) => props.theme.ratio});
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  background: rgba(255, 255, 255, 0.3);
  z-index: 14;
  ${global.userSelectNone}
`;

export const TimeRadioSelectorOption = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  ${global.userSelectNone}
`;

export const TimeLimitRadioSelectorButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(24px * ${(props) => props.theme.ratio});
  min-height: calc(24px * ${(props) => props.theme.ratio});
  border: 1px solid ${(props) => props.theme.palette.white};
  border-radius: 50%;
  ${global.userSelectNone}
`;

export const TimeLimitRadioSelectorIndicator = styled.div`
  min-width: calc(16px * ${(props) => props.theme.ratio});
  min-height: calc(16px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.palette.red};
  border-radius: 50%;
  ${global.userSelectNone}
`;

export const TimeLimitRadioSelectorLabel = styled.div`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-left: calc(25px * ${(props) => props.theme.ratio});
  font-size: calc(18px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const TimeLimitSelectionIcon = styled.div`
  max-height: calc(17px * ${(props) => props.theme.ratio});
  max-width: calc(17px * ${(props) => props.theme.ratio});
  margin-left: calc(5px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const SelectionIcon = styled.span`
  max-height: fit-content;
  min-width: calc(40px * ${(props) => props.theme.ratio});
  max-width: calc(40px * ${(props) => props.theme.ratio});
`;

export const MobileChannelsTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  font-size: calc(18px * ${(props) => props.theme.ratio});
  word-spacing: 3px;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
`;