import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'
import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import fontSize from '../../../../constants/FontSize';
import { LocationContentContainer } from '../../../../primitives/styles/Onboarding/Location';
import { Answer, ContentSurveyStep } from '../../../../primitives/types/ContentSurevyStep';
import LocationButton from '../../components/LocationButton';
import { SkipButton } from '../../../../primitives/styles/Onboarding/DesignPreferences';
import CultureOptions from './components/CultureOptions';

interface CulturalInfluenceProps {
  currentStep: number;
  totalSteps: number;
  question?: ContentSurveyStep;
  onSelect: (place: Answer, isSelected: boolean) => void;
  onNext: () => void;
  onClose: () => void;
  onBack: () => void;
  theme?: any
}

const CulturalInfluence: React.FC<CulturalInfluenceProps> = ({
  currentStep,
  totalSteps,
  question,
  onSelect,
  onNext,
  onClose,
  theme = {},
  onBack,
}) => {
  const { heightRatio } = theme;

  const titlesStyle = {
    whiteSpace: 'pre-line', textAlign: 'left', marginTop: `calc( 10px * ${heightRatio})`, textShadow: 'none' 
};

const subTitleStyle: Object = {
    textAlign: 'left',
    width: '100%',
    marginTop: `calc( 5px * ${heightRatio})`
}

const selectedCulturalInfluence = question?.selectedAnswer || []

  return (
    <Container>
      <BreadcrumbsHeader currentStep={currentStep} totalSteps={totalSteps} onClose={onClose} onBack={onBack} flow={'aboutYourPlace'}/>
      <OnboardingContainer style={{overflowY: 'auto', width: '95%'}}>
        {question &&
      <LocationContentContainer style={{width: '100%', alignItems: 'center', minHeight: '85%', height: 'fit-content'}}>
        <LocationContentContainer style={{width: '90%'}}>
      <Title type="flow" text={TITLES.CULTURAL_INFLUENCE} defaultText={'Cultural influence'} size={fontSize.MID_LARGE} style={titlesStyle} />
      <SubTitle style={subTitleStyle}>
        <Phrase defaultText={question.subTitle.defaultText} translationKey={question.subTitle.translationKey}/>
      </SubTitle>
        </LocationContentContainer>
      <CultureOptions cultureOptions={question.answers} onClick={onSelect} selectedCultureOptions={selectedCulturalInfluence}/>
      </LocationContentContainer>
      }
      {selectedCulturalInfluence.length > 0 ? <LocationButton onClick={onNext} theme={theme} style={{marginTop: `calc( 20px * ${heightRatio})`, marginBottom: '15px'}}>
          <Phrase defaultText={`Select (${selectedCulturalInfluence.length})`} translationKey='3369276b-1c04-4fa6-b75b-0edf74159513' vars={{ number: selectedCulturalInfluence.length.toString() }}/>
      </LocationButton>
      : 
      <SkipButton onClick={onNext}>
        <Phrase defaultText={'Skip'} translationKey={'9154ebcb-155e-4a54-83f3-33b45e2cbb22'}/>
      </SkipButton>
      }
      </OnboardingContainer>
    </Container>
  );
};

export default CulturalInfluence;