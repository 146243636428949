import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

const getInheritedTagsByOrgId = (orgId: string, isFrame: boolean = false) => {
  const url = `${getConfigByEnv().PORTAL.REST_API_URL}/tags/${
    isFrame ? 'frame/' : ''
  }inherited/${orgId}`;

  return axios.get(url).then((data) => data);
};

export default getInheritedTagsByOrgId;
