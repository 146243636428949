import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import SearchIcon from '@material-ui/icons/Search';

import { useState, useEffect, useRef } from 'react';
import { withTheme } from 'styled-components';

import {
  AutocompleteInput as SearchInput,
  AutocompleteWrapper as SearchWrapper,
} from '../../primitives/styles/Common/CommonStyle';

import {
  SearchLabel,
  SelectModalSearchWrapper,
} from '../../primitives/styles/SelectModal/SelectModal';

interface SearchOptionsProps {
  theme: any;
  handleKeyboardIsOpen: (isOpen: boolean) => void;
  handleSearch: (input: string) => void;
}

const SearchOptions: React.FC<SearchOptionsProps> = ({
  theme,
  handleKeyboardIsOpen,
  handleSearch,
}) => {
  const [showLabel, setShowLabel] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputFocus = () => {
    setShowLabel(false);
    handleKeyboardIsOpen(true)
  };

  const onInputBlur = () => {
    setShowLabel(true);
    setTimeout(() => handleKeyboardIsOpen(false), 150);
  };

  const onLabelClick = () => {
    inputRef.current?.focus();
  };

  const handleInputChange = (e: any) => {
    const input = e.target.value;
    setInputValue(input);
  };

  useEffect(() => {
    handleSearch(inputValue);
    // return () => clearTimeout()
    //eslint-disable-next-line
  },[inputValue])

  return (
    <SelectModalSearchWrapper>
      <SearchWrapper
        style={{
          minWidth: '100%',
          maxWidth: '100%',
          borderColor: 'rgba(0, 0, 0, 0.1)',
        }}
      >
        <SearchIcon
          color="primary"
          style={{
            paddingLeft: `calc(4px * ${theme.ratio})`,
            height: `calc(34px * ${theme.ratio})`,
            width: '15%',
            fill: 'rgba(0, 0, 0, 0.3)',
          }}
        />
        <SearchInput
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          defaultValue={inputValue}
          onChange={(e: any) => handleInputChange(e)}
          ref={inputRef}
          style={{
            color: 'rgba(0, 0, 0, 0.7)',
          }}
        />
        {showLabel && !inputValue && (
          <SearchLabel onClick={onLabelClick}>
            <Phrase 
              translationKey={'e685c3d6-56de-4b61-b0f9-ece1080185ad'}
              defaultText={'Search'}
            />
          </SearchLabel>
        )}
      </SearchWrapper>
    </SelectModalSearchWrapper>
  );
};

export default withTheme(SearchOptions);
