import Loading from '../../../components/Loading/Loader';

import { useEffect, useState } from 'react';
import { Container } from '../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import { gqlClient } from '../../../utils/gqlClient';
import { GET_LOCATION_DETAILS } from '../../../api/graphQl/query/getLoginDetails';
import { get } from 'lodash';
import { Location as LocationType } from '../../../primitives/types/Location';
import LocationContainer from './SetLocation/LocationIsSetContainer';
import SetRippleMakerLocation from './SetLocation/SetRippleMakerLocation';

interface LocationContainerProps {
  onLocationConfirm: () => void;
  onCreateLocation: () => void;
  onSelectLocation: ({locationId, organizationId, serial}: {locationId?: string, organizationId?: string, serial?: string}) => void;
  onBack: () => void;
  locationId?: string | null;
  organizationId?: string;
  locationName?: string;
  theme: any;
  serial?: string
  supportDetails?: {
    phone?: string;
    email?: string;
    url?: string;
    label?: string;
  }
}

const LOCATIONS_PATH = 'viewer.organizationScope.locations.edges';

const Location: React.FC<LocationContainerProps> = ({
  locationId,
  locationName,
  organizationId,
  onLocationConfirm,
  onCreateLocation,
  onBack,
  theme,
  serial,
  onSelectLocation,
  supportDetails
}) => {
  const [locations, setLocations] = useState<LocationType[]>([])
  const [isLoading, setIsLoading] = useState(true);

  const getInitialLocationDetails = (selectedOrgId: string) =>
    gqlClient.query(GET_LOCATION_DETAILS, { orgId: selectedOrgId });

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true)
      getInitialLocationDetails(organizationId)
        .then(({ data }) => get(data, LOCATIONS_PATH))
        .then((locations) => handleReceivedLocations(locations))
        .catch((err: Error) => {
          console.log({err})
        }).finally(() => {
          setIsLoading(false)
        });
    }
  }, [organizationId]);

  const handleReceivedLocations = (rawLocations: any) => {
    const locationsRes: LocationType[] = rawLocations.map(
      ({ node }: { node: any }) => ({ ...node })
    );
    if (organizationId && !locationId && locationsRes.length === 0) {
      onCreateLocation();
    }
    setLocations(locationsRes);
    setIsLoading(false)
  };

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (locationId && locationName) {
    return <LocationContainer serial={serial} theme={theme} onOk={onLocationConfirm} locationName={locationName} onBack={onBack} supportDetails={supportDetails}/>
  }

  else {
    return <SetRippleMakerLocation serial={serial} onCreateLocation={onCreateLocation} theme={theme} organizationId={organizationId} locations={locations} onBack={onBack} onNext={onLocationConfirm} onSelectLocation={onSelectLocation}/>
  }
};

export default Location;