import Phrase from '../../../../../components/Phrase/Phrase';
import { PlaceImage, PlaceNameContainer, PlaceTypeContainer, PlaceTypeNameContainer, PlaceTypeSeperator } from '../../../../../primitives/styles/Onboarding/Places';
import { Answer } from '../../../../../primitives/types/ContentSurevyStep';

interface PlaceTypeProps {
  place?: Answer,
}

const PlaceType: React.FC<PlaceTypeProps> = ({
  place,
}) => {

  return (
    <PlaceTypeContainer>
        {
        place && <>
        <PlaceImage src={place.icon} alt=''/>
        <PlaceTypeNameContainer>
            <PlaceTypeSeperator/>
            <PlaceNameContainer>
            <Phrase translationKey={place.title.translationKey} defaultText={place.title.defaultText}/>
            </PlaceNameContainer>
            <PlaceTypeSeperator/>
        </PlaceTypeNameContainer>
        </> 

        }
    </PlaceTypeContainer>
  );
};

export default PlaceType;