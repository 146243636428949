import styled from 'styled-components';
import { global } from '../Global/global'
export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(${(props) => props.theme.deviceHeight}px * 0.9);
  width: 100vw;
  background: ${(props) => props.theme.palette.black};
  margin-top: calc(${(props) => props.theme.deviceHeight}px * 0.01);
  top: 0;
  z-index: 12;
  ${global.userSelectNone}
`;

export const DatePickerHeader = styled.div`
  position: static;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(51px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.palette.red};
  ${global.userSelectNone}
`;

export const DatePickerHeaderContent = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(20px * ${(props) => props.theme.ratio});
  padding-right: calc(20px * ${(props) => props.theme.ratio});
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const DatePickerHeaderTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  font-size: calc(21px * ${(props) => props.theme.ratio});
`;

export const DatePickerSideIcon = styled.div`
  width: calc(21px * ${(props) => props.theme.ratio});
  height: calc(21px * ${(props) => props.theme.ratio});
  z-index: 12;
  ${global.userSelectNone}
`;

export const TimePickerSection = styled.div`
  width: 80vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${global.userSelectNone}
`;

export const DateSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 33vw;
  ${global.userSelectNone}
`;

export const DateSelectorLabel = styled.span.attrs(
  (props: { isActive: boolean }) => ({
    isActive: props.isActive,
  })
)`
  font-size: calc(16px * ${(props) => props.theme.ratio});
  color: ${(props) =>
    props.isActive ? props.theme.palette.red : props.theme.palette.white};
  line-height: calc(18px * ${(props) => props.theme.ratio});
  letter-spacing: 0.02em;
  text-transform: capitalize;
`;

export const DateSelectorValue = styled.span`
  font-size: calc(22px * ${(props) => props.theme.ratio});
  color: ${(props) => props.theme.palette.white};
  line-height: calc(25px * ${(props) => props.theme.ratio});
  letter-spacing: 0.02em;
  text-transform: capitalize;
  padding-top: calc(5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(5px * ${(props) => props.theme.ratio});
`;

export const DateSelectorUnderline = styled.span.attrs(
  (props: { isActive: boolean }) => ({
    isActive: props.isActive,
  })
)`
  font-size: calc(16px * ${(props) => props.theme.ratio});
  background: ${(props) =>
    props.isActive ? props.theme.palette.red : props.theme.palette.grey};
  height: calc(1px * ${(props) => props.theme.ratio});
`;

export const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
  top: calc(290px * ${(props) => props.theme.ratio});
  height: calc(250px * ${(props) => props.theme.ratio});
  width: 80vw;
  padding-left: calc(31px * ${(props) => props.theme.ratio});
  padding-right: calc(31px * ${(props) => props.theme.ratio});
  margin-top: 20px;
  ${global.userSelectNone}
`;

export const CalendarBody = styled.div`
  position: relative;
  overflow-y: hidden;
  display: flex;
  justify-content: space-around;
  min-height: 250px;
  max-height: 250px;
  width: calc(100vw - 2 * 31px * ${(props) => props.theme.ratio});
  background: linear-gradient(0deg, #383c46, #383c46);
  border-radius: 15px;
  ${global.userSelectNone}
`;

export const CalendarMonths = styled.ul`
  list-style-type: none;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  padding: 0;
  padding-top: calc(180px / 2);
  padding-bottom: calc(180px / 2);
  width: fit-content;
`;

export const CalendarDays = styled.ul`
  list-style-type: none;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  padding: 0;
  padding-top: calc(180px / 2);
  padding-bottom: calc(180px / 2);
  width: fit-content;
`;

export const CalendarYears = styled.ul`
  text-align: center;
  list-style-type: none;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  padding: 0;
  padding-top: calc(180px / 2);
  padding-bottom: calc(180px / 2);
  width: fit-content;
`;

export const CalendarValueItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(343px / 7);
  max-height: calc(343px / 7);
  width: 80px;
  padding: 0;
  color: white;
`;

export const CalendarValueContent = styled.span.attrs(
  (props: { isSelected: boolean; isFade: boolean }) => ({
    isSelected: props.isSelected,
    isFade: props.isFade,
  })
)`
  font-weight: ${(props) => (props.isFade ? '100' : '400')};
  font-weight: ${(props) => (props.isSelected ? '900' : '400')};
  font-size: ${(props) =>
    props.isFade
      ? 'calc(18px *' + props.theme.ratio + ')'
      : props.isSelected
      ? 'calc(24px *' + props.theme.ratio + ')'
      : 'calc(22px *' + props.theme.ratio + ')'};

  border-bottom: ${(props) =>
    props.isSelected ? '2px solid #E86161' : 'none'};
`;

export const TopShades = styled.div`
  position: absolute;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(56, 60, 70, 0.8) 57.29%,
    rgba(56, 60, 70, 0) 89.58%
  );
  ${global.userSelectNone}
`;

export const BottomShades = styled.div`
  position: absolute;
  height: 50px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 12;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(56, 60, 70, 0.8) 57.29%,
    rgba(56, 60, 70, 0) 89.58%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
  ${global.userSelectNone}
`;

export const TriangleIndicator = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  bottom: calc(-56px * ${(props) => props.theme.ratio});
  left: calc(50% - 32px);
  border-style: solid;
  border-width: 0 calc(32.5px * ${(props) => props.theme.ratio})
    calc(50px * ${(props) => props.theme.ratio})
    calc(32.5px * ${(props) => props.theme.ratio});
  border-color: transparent transparent #383c46 transparent;
  border-radius: 2px;
  border-style: inset;
  -webkit-transform: rotate(360deg);
  ${global.userSelectNone}
`;

export const TimePickerHeaderSection = styled.div`
display: flex;
flex-direction: column;
`

export const DatesRow = styled.div`
display: flex;
justify-content: space-between;
`