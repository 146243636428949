import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import Loading from '../../../../components/Loading/Loader';
import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'

import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import fontSize from '../../../../constants/FontSize';
import Address from './components/Address';
import { LocationContentContainer } from '../../../../primitives/styles/Onboarding/Location';
import LocationButton from '../../components/LocationButton';
import { getCountries } from '../../../../services/locationsService';
import getAddressByCoordinates from '../../../../api/rest/getAddressByCoordinates';

interface SetLocationAddressProps {
  onCreateLocation: () => void;
  currentStep: number;
  totalSteps: number;
  theme: any;
  form?: FormikProps<any>
  onBack: () => void;
  setLongitude: (value: number) => void;
  setLatitude: (value: number) => void;
  onClose: () => void
}

const LocationAddress: React.FC<SetLocationAddressProps> = ({
  currentStep,
  totalSteps,
  theme,
  form,
  onCreateLocation,
  onBack,
  setLongitude,
  setLatitude,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [height, setHeight] = useState();
  
  const { ratio, heightRatio, deviceHeight } = theme;

  useEffect(() => {
    setHeight(deviceHeight)
  }, [])

  useEffect(() => {
    getCountries()
    .then(({ data }) => {
      setCountries(data.viewer.countries)
      setStates(data.viewer.states)
    })
    .catch(err => {
      console.log({err})
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (navigator.geolocation) {
      setIsLoading(true)
      navigator.geolocation.getCurrentPosition(successRetrieivingLocation, errorRetrieivingLocation)
    }
  }, [])

  const successRetrieivingLocation = async (position: GeolocationPosition) => {
    setLongitude(position.coords.longitude)
    setLatitude(position.coords.latitude)

    try {
      const result = await getAddressByCoordinates({longitude: position.coords.longitude, latitude: position.coords.latitude})
      const locationDetails = result.data.data;
      locationDetails.country && form?.setFieldValue('country', locationDetails.country)
      locationDetails.state && form?.setFieldValue('state', locationDetails.state)
      locationDetails.city && form?.setFieldValue('city', locationDetails.city)
      locationDetails.zipCode && form?.setFieldValue('postCode', locationDetails.zipCode)
      locationDetails.streetAddress && form?.setFieldValue('streetAddress', locationDetails.streetAddress)
    } catch (error) {
      console.log({error})
    }


    setIsLoading(false)
  }

  const errorRetrieivingLocation = (error: any) => {
    setIsLoading(false)
  }

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }


  const titlesStyle = {
    whiteSpace: 'pre-line', textAlign: 'left', marginTop: `calc( 10px * ${heightRatio})`, textShadow: 'none' 
};

const subTitleStyle: Object = {
    marginTop: `calc( 10px * ${heightRatio})`,
    textAlign: 'left',
    height: `calc( 0.2 * ${height}px)`,
    width: '90%'
}

  return (
    <Container style={{overflowY: 'auto'}}>
      <BreadcrumbsHeader currentStep={currentStep} totalSteps={totalSteps} flow='createLocation' onBack={onBack} onClose={onClose}/>
      <OnboardingContainer>
      {(countries && states) && <LocationContentContainer style={{height: `calc( 0.8 * ${height}px)`}}>
      <Title type="flow" text={TITLES.FILL_IN_LOCATION} defaultText={'Fill in location address'} size={fontSize.MID_LARGE} style={titlesStyle} />
      <SubTitle style={subTitleStyle}>
        {form?.values.name}
      </SubTitle>
      <Address form={form} countries={countries} states={states}/>
      <LocationButton onClick={onCreateLocation} disabled={false} style={{width: `calc(300px * ${ratio})`}}>
      <Phrase defaultText={'CREATE LOCATION'} translationKey={'4c2e8106-8793-41d5-b8a9-8ef86cae8c12'}/>
      </LocationButton>
      </LocationContentContainer>}
      </OnboardingContainer>
    </Container>
  );
};

export default LocationAddress;