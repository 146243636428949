const HELP = '22e6505d-9d1d-49db-a057-38709474632e'; // what can you do here
const DELETE = 'dc700803-c519-44f9-b3fb-78cb253e5958'; // select design to delete
const SELECT_FRAME = 'ddf75c2a-78e9-4fdc-9352-be90103e0270'; //Publish frame
const SELECT_DESIGN = '77aa1230-60f2-4d38-ac70-24c9a0010b4f'; //Select design
const DEVICE_CONTENT = 'a6f04a30-4c69-48d4-8c29-889c918515b7'; // Manage channels
const SELECT_LOCATION = '8f1e8b35-ac69-4002-ba7b-25bed0ee345d'; //Select location
const PUBLISH_CHANNEL = ''; //Publish channel to Ripple Maker TBD
const SELECT_CHANNELS = '96f218e3-ab9c-40b2-9a69-f0d2fd8db1b7'; // Select channels
const LANDSCAPE_ERROR = '6a7fc5e4-a448-4394-8358-4d7dfd7aa787'; //mobile portal works in portrait mode only
const ASSIGN_TO_CHANNEL = '8c7f4a3b-2c90-4d05-8302-9463393eaa2d'; //Assign to channel
const CREATE_FRAME = '48d53a01-84c5-4686-a01f-a8a1558135d7'; // create frame
const SET_LOCATION = 'bec12a45-f55c-4595-99ea-97a3cf4eb4eb'; // Set Location
const LOCATION = '957c9fa3-a276-4980-9755-2bb01e3963fc'; // Location
const SET_RIPPLE_MAKER_LOCATION = 'c8777e4c-c933-42b9-b491-2206757bb3c4'; // Set your Ripple Maker's location
const SELECT_LOCATION_NICKNAME = '07a21fc3-7ad2-41a0-bcc3-f3d994b17d53';
const FILL_IN_LOCATION = 'edab068d-be1c-4be2-8a09-30da8bf32695';
const MOVE_ON = 'd1986184-0a1c-4f49-bd86-ed5f1c9a9d4d' // Let’s move on
const BEFORE_CONTINUING = '3113ff43-1cc2-4f25-b584-77a9b88a2b9c' // BEFORE CONTINUING…
const YOUR_PLACE = 'f9708162-2326-4464-9bde-cc730fa8e614' // Your place type
const DESIGN_PREFERENCES = '33e9df39-6596-4dfc-8ad5-32132ac6059a' // Design preferences
const CULTURAL_INFLUENCE = '5d40735e-73f3-4dc5-8664-036524bbe5cb' // Cultural influence
const CHNAGE_PREFERENCES = 'cef3d7be-1cf2-47c7-ac1e-478ca14eeee4' // Change your location preferences
const MANAGE_CHANNELS = '2d9a7a7a-4d8f-4f79-bc1e-fc5d6a915690' // Manage channels
const ASSIGN_FRAME = '771fdabb-f1c0-4650-9603-41afe1bc0e34' // Assign Frame
const CREATE_ORGANIZATION = 'ff994431-aafd-4b69-a39c-59656f75c331' // Create organization

const TITLES = {
    HELP,
    DELETE,
    SELECT_FRAME,
    SELECT_DESIGN,
    DEVICE_CONTENT,
    LANDSCAPE_ERROR,
    SELECT_LOCATION,
    SELECT_CHANNELS,
    PUBLISH_CHANNEL,
    ASSIGN_TO_CHANNEL,
    CREATE_FRAME,
    SET_LOCATION,
    LOCATION,
    SET_RIPPLE_MAKER_LOCATION,
    SELECT_LOCATION_NICKNAME,
    FILL_IN_LOCATION,
    MOVE_ON,
    BEFORE_CONTINUING,
    YOUR_PLACE,
    DESIGN_PREFERENCES,
    CULTURAL_INFLUENCE,
    CHNAGE_PREFERENCES,
    MANAGE_CHANNELS,
    ASSIGN_FRAME,
    CREATE_ORGANIZATION,
}

export default TITLES;