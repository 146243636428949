import { TemplateSelectorWrapper } from "../../primitives/styles/Frames/FrameControlsStyle";
import { FrameTemplate } from "../../primitives/types/FrameTemplate";
import { useState, useEffect } from 'react';



interface FramesTemplateSelectorProps {
  templates: FrameTemplate[];
  onSelectTemplate: (template: FrameTemplate) => void;
}


const FramesTemplateSelector: React.FC<FramesTemplateSelectorProps> = ({templates, onSelectTemplate}) => {

  const [selectedTemplateId, setSelectedTemplateId] = useState<String | undefined>(undefined);

  // initialize the template selector
  useEffect(() => {
   if (templates.length && !selectedTemplateId) {
    setSelectedTemplateId(templates[0]._id);
    onSelectTemplate(templates[0]);
   }
  }, [templates, selectedTemplateId, setSelectedTemplateId, onSelectTemplate]);

  const selectTemplate = (template: FrameTemplate) => {
    setSelectedTemplateId(template._id);
    onSelectTemplate(template);
  }


  return (
    <TemplateSelectorWrapper>
      {templates.map((template: FrameTemplate ) => {

        let className = "template"
        if (template._id === selectedTemplateId) {
         className = "template selected";
        }

        return (
          <div 
            key={template._id} 
            className={className}
            onClick={() => {selectTemplate(template)}}
            >
            <img src={template.previewImage} alt="" />
          </div>         
        );
      })}
    </TemplateSelectorWrapper>
  );
};

export default FramesTemplateSelector;