import * as React from 'react';
import { Container } from '../../primitives/styles/Wizard/WizardStyle';
import Phrase from '../Phrase/Phrase';
import styled from 'styled-components';


const OrWrapper = styled.div`
    text-align: center;
    flex-grow: 1;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: relative;
        top: 10px;
        width: 80px;
        left: calc(50% - 40px);
        background: red;
        border-top: 1px #828282 solid;
        z-index: 5;
    }
    span {
        background-color: #131418;
        z-index: 200;
        position: relative; 
        padding: 0 5px;
    }
`;

const NoOrgError: React.FC = () => {
    const goToStore = () => {
        window.location.href = 'https://store.drinkripples.com';
    }

    const containerStyle: React.CSSProperties = {
        marginTop: "120px", 
        color: "#828282",
    }

    const buttonStyle: React.CSSProperties = {
        backgroundColor: "#F16063",
        border: 'none',
        color: '#ffffff',
        display: 'block',
        flexGrow: 1,
        fontSize: '20px',
        padding: '10px 0',
        borderRadius: '30px',
        marginTop: '20px',
    }

    return (
        <Container style={containerStyle}>
            <h2 style={{textTransform: 'uppercase', color: "#ffffff"}}>
                <Phrase translationKey="4c193514-523a-4dad-832f-199a12088060" defaultText='Welcome to Ripples Cloud'/>
                <br />
                <Phrase translationKey="f585b6c7-03bc-462f-a277-e64af0096181" defaultText='Let the Magic Begin!'/>
              </h2>
              <p style={{color: "#E86161"}}>
                <Phrase translationKey="c40327e2-9b82-4e7f-a90f-75213a53243f" defaultText='Your user is not assigned to any organization' />
              </p>
              <p>
                <Phrase translationKey="2c9eb4ef-3f07-4bee-ac77-242eff5b2777" defaultText="Contact your organization's admin and ask to be added to your organization" />
              </p>
              <OrWrapper>
                <span><Phrase translationKey="c99b8bf8-a46d-450e-b28f-6589766dc211" defaultText='Or' /></span>
              </OrWrapper>
              <p>
                <Phrase translationKey="88fa31e5-db59-409e-9cf6-357d8bf537fc" defaultText="If you don't have a Ripple Maker, Go to the store" />
              </p>

                <button onClick={goToStore} style={buttonStyle}>
                    <Phrase translationKey="5f4354fe-fc22-4265-bdde-36255f178b0f" defaultText='Shop Now' />
                </button>

        </Container>
    )
}


export default NoOrgError;