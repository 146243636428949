import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import ButtonElement from '@material-ui/core/Button';

// import { useState } from 'react';
import { withTheme } from 'styled-components';
import { createButtonStyles } from './styles';

interface PublishProps {
  theme: any
  buttonText: string;
  isDisabled: boolean;
  onClick: () => void;
  buttonDefaultText: string;
  variant: 'contained' | 'outlined';
}
const Button: React.FC<PublishProps> = ({
  theme,
  onClick,
  variant,
  isDisabled,
  buttonText,
  buttonDefaultText
}) => {
  // const [isButtonTouched, setIsButtonTouched] = useState(false);

  // const handleOnButtonTouchEnd = () => setIsButtonTouched(false);

  // const handleOnButtonTouchStart = () => {
  //   setIsButtonTouched(true)
  // };

  const { ratio } = theme

  const useStyles = createButtonStyles(false, ratio)
  const classes = useStyles();

  return (
    <ButtonElement
      classes={{
        root: classes.root,
        contained: classes.contained,
        outlined: classes.outlined,
      }}
      disableFocusRipple
      disableRipple
      disabled={isDisabled}
      variant={variant}
      onClick={onClick}
    >
      <Phrase
        translationKey={buttonText}
        defaultText={buttonDefaultText}
      />
    </ButtonElement>
  );
};

export default withTheme(Button);
