import SearchIcon from '@material-ui/icons/Search';

import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { SelectTranslations } from '../../store/translationSlice';
import {
  AutocompleteWrapper,
  AutocompleteInput,
  AutocompleteSelectOption,
  AutocompleteSearchDropdown,
} from '../../primitives/styles/Common/CommonStyle';

interface AutocompleteSearchProps {
  id: string;
  theme: any;
  label: string;
  options: any[];
  disabled?: boolean;
  defaultLabel: string;
  onChange?: () => void;
  displayedProp: string;
  handleSelectOption: (option: any | null) => void;
  handleSetKeyboardOpen: (isOpen: boolean) => void;
}

const AutocompleteSearch: React.FC<AutocompleteSearchProps> = ({
  id,
  theme,
  label,
  options,
  disabled,
  onChange,
  defaultLabel,
  displayedProp,
  handleSelectOption,
  handleSetKeyboardOpen
}) => {

  const translations = useSelector(SelectTranslations);
  const initialOptionsState: any[] = [];
  const [inputValue, setInputValue] = useState('');
  const [renderedOptions, setRenderedOptions] = useState(initialOptionsState);

  useEffect(() => {
    return () => clearTimeout(undefined)
  })
  const onInputChange = (e: any) => {
    const value = e.target.value;
    if (!value) {
      setInputValue('');
      setRenderedOptions(initialOptionsState);
      return;
    }
    setInputValue(value);
    const filteredOptions = options.filter((option) => {
      const searchTerm = value.trim().toLowerCase()
      if( !searchTerm ) {
        return false;
      }
      else {
        return option[displayedProp].toLowerCase().includes(searchTerm);
      }
    });
    setRenderedOptions(filteredOptions);
  };

  const onOptionSelected = (option: any) => {
    handleSelectOption(option);
    setInputValue('');
    setRenderedOptions(initialOptionsState);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      const option = {
        [displayedProp]: inputValue,
      };
      handleSelectOption(option);
      setInputValue('');
      setRenderedOptions(initialOptionsState);
    }
  };

  const handleInputFocus = () => {
    handleSetKeyboardOpen(true)
  }

  const handleInputBlur = () => {
    setTimeout(() => handleSetKeyboardOpen(false), 100 )
  }

  const getLabel = () => {
    return translations && translations[label] ? translations[label] : defaultLabel
  }
  return (
    <AutocompleteWrapper id={id}>
      <SearchIcon
        color="primary"
        style={{
          height: `calc(34px * ${theme.ratio})`,
          width: '15%',
          fill: 'grey',
        }}
      />
      <AutocompleteInput
        value={inputValue}
        disabled={disabled}
        onBlur={handleInputBlur}
        placeholder={getLabel()}
        onFocus={handleInputFocus}
        onKeyPress={handleKeyPress}
        onChange={onChange ? onChange : (e) => onInputChange(e)}
      />
      {!isEmpty(renderedOptions) && (
        <AutocompleteSearchDropdown>
          {renderedOptions.map((option) => (
            <AutocompleteSelectOption
              key={uniqueId()}
              onClick={() => onOptionSelected(option)}
            >
              {option[displayedProp]}
            </AutocompleteSelectOption>
          ))}
        </AutocompleteSearchDropdown>
      )}
    </AutocompleteWrapper>
  );
};

export default withTheme(AutocompleteSearch);
