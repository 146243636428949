import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'
import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import fontSize from '../../../../constants/FontSize';
import { LocationContentContainer } from '../../../../primitives/styles/Onboarding/Location';
import PlacesOptions from './components/PlaceOptions';
import { Answer, ContentSurveyStep } from '../../../../primitives/types/ContentSurevyStep';

const JAPANEESE_CODE = 'ja'

interface BusinessTypeProps {
  currentStep: number;
  totalSteps: number;
  question?: ContentSurveyStep;
  onSelect: (place: Answer) => void;
  onClose: () => void;
  theme?: any
}

const BusinessType: React.FC<BusinessTypeProps> = ({
  currentStep,
  totalSteps,
  question,
  onSelect,
  onClose,
  theme = {}
}) => {
  const { heightRatio, ratio } = theme;

  const isJapaneese = navigator.language === JAPANEESE_CODE

  const titlesStyle = {
    whiteSpace: 'pre-line', textAlign: 'left', marginTop: `calc( 10px * ${heightRatio})`, textShadow: 'none', fontSize: isJapaneese ? `calc(25px * ${ratio})` : undefined, width: '100%'
};

const subTitleStyle: Object = {
    textAlign: 'left',
    width: '100%',
    marginTop: `calc( 5px * ${heightRatio})`,
    fontSize: isJapaneese ? '15px' : undefined
}

  return (
    <Container>
      <BreadcrumbsHeader currentStep={currentStep} totalSteps={totalSteps} onClose={onClose} shouldRenderBack={false} flow={'aboutYourPlace'}/>
      <OnboardingContainer style={{overflow: 'auto'}}>
        {question &&
      <LocationContentContainer>
      <Title type="flow" text={TITLES.YOUR_PLACE} defaultText={'Type of your Business'} size={fontSize.MEDIUM} style={titlesStyle} />
      <SubTitle style={subTitleStyle}>
        <Phrase defaultText={question.subTitle.defaultText} translationKey={question.subTitle.translationKey}/>
      </SubTitle>
      <PlacesOptions onClick={onSelect} places={question.answers}/>
      </LocationContentContainer>
}
      </OnboardingContainer>
    </Container>
  );
};

export default BusinessType;