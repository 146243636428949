
import Cookies from "js-cookie";

export const setCookie = (name: string, value: any, expires: number) => {
  const b64Value = window.btoa(JSON.stringify(value));
  Cookies.set(name, b64Value, {expires})
}

export const getCookie = (name: string) => {
  const cookie = Cookies.get(name)
  if (cookie) {
    return JSON.parse(window.atob(cookie));
  } else {
    return {};
  }
}
