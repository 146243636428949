import { RippleLogoIcon } from '../../primitives/media/Icons';
import { LoadingWrapper, LoaderLayersWrapper, Logo, FirstLayerRing, SecondLayerRing, ThirdLayerRing } from '../../primitives/styles/Loading/NewLoadingStyle';
const NewLoading = ():JSX.Element => {

    return (
        <LoadingWrapper>
            <LoaderLayersWrapper>
            <ThirdLayerRing/>
            <SecondLayerRing/>
            <FirstLayerRing/>
            <Logo>
                <RippleLogoIcon/>
            </Logo>
            </LoaderLayersWrapper>
        </LoadingWrapper>
    )
};

export default NewLoading ;