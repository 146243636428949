import * as React from 'react';

// import { useState } from 'react';
import { withTheme } from 'styled-components';
import { AddButton, DeleteButton } from '../../primitives/media/Icons';
import { FloatingCircularButtonWrapper } from '../../primitives/styles/Common/CommonStyle';

interface FloatingCircularButtonProps {
  theme: any;
  isDisabled?: boolean;
  onClick?: () => void;
  actionType: 'add' | 'remove' | 'markMobile';
  position?: 'bottomleft' | 'bottomright';
}
const FloatingCircularButton: React.FC<FloatingCircularButtonProps> = ({
  theme,
  onClick,
  actionType,
}) => {
  return (
    <FloatingCircularButtonWrapper
      id={'floating-button'}
      onClick={onClick}
    >
      {actionType === 'add' ? <AddButton /> : <DeleteButton />}
    </FloatingCircularButtonWrapper>
  );
};

export default withTheme(FloatingCircularButton);
