export const GET_LOGIN_DETAILS = `
  query {
    viewer {
      isLoggedIn
      me {
        id
        name
        email
        defaultScope
        permissions
        orgId
        organizations {
          id
          organizationId
          name
          serviceLevel: activeServiceLevel
        }
      }
    }
  }
`;

export const GET_LOGIN_DETAILS_WITH_ROLES = `
  query($scope: ScopeType!) {
    viewer {
      theScope: scope(scope: $scope) {
        scopeRoles {
          name,
          order
        }
      }
    }
  }
`;

export const GET_INITIAL_LOCATION_DETAILS = `
  query(
    $orgId: String!
  ) {
    viewer {
      organizationScope(id: $orgId) {
        locations(archived: false) {
          edges {
            node {
              id
              name
              displayedName
              state
              country
              state
              city
              shortId
              address
              isPrivate
              rippleMakers{
                appVariant
                serial
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_DETAILS = `
  query(
    $orgId: String!
  ) {
    viewer {
      organizationScope(id: $orgId) {
        locations {
          edges {
            node {
              id
              name
              displayedName
              state
              country
              state
              city
              shortId
              address
              isPrivate
              rippleMakers{
                appVariant
                serial
              }
            }
          }
        }
      }
    }
  }
`;
