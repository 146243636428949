import styled from 'styled-components';
import background from '../../media/Background/background.png';

import { devices } from '../Devices/devices';
import { global } from '../Global/global';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.black};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: 50% calc(-60px * ${(props) => props.theme.ratio});
  background-size: calc(374px * ${(props) => props.theme.ratio})
    calc(666px * ${(props) => props.theme.ratio});
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(${(props) => props.theme.deviceHeight}px * 0.4);
  height: fit-content;
  width: 100vw;
  padding-top: calc(140px * ${(props) => props.theme.ratio});
  padding-bottom: calc(80px * ${(props) => props.theme.ratio});
  @media ${devices.tablet.portrait} {
    background-size: calc(374px * ${(props) => props.theme.ratio} / 0.7)
      calc(666px * ${(props) => props.theme.ratio} / 0.7);
  }
  ${global.userSelectNone}
`;

export const SubWizardWrapper = styled.div`
  background: ${(props) => props.theme.palette.black};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: 50% calc(350px * ${(props) => props.theme.ratio});
  background-size: calc(374px * ${(props) => props.theme.ratio})
    calc(666px * ${(props) => props.theme.ratio});
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(${(props) => props.theme.deviceHeight}px * 0.4);
  height: calc(${(props) => props.theme.deviceHeight}px - (140px * ${(props) => props.theme.ratio}));;
  width: 100vw;
  padding-top: calc(140px * ${(props) => props.theme.ratio});
  @media ${devices.tablet.portrait} {
    background-size: calc(374px * ${(props) => props.theme.ratio} / 0.7)
      calc(666px * ${(props) => props.theme.ratio} / 0.7);
  }
  ${global.userSelectNone}
`;

export const TitleWrapper = styled.div`
align-self: center;
display: flex;
justify-content: center;
margin-bottom: calc(100px * ${(props) => props.theme.ratio});
width: 100%;
${global.userSelectNone}
`;

export const StoreButton = styled.div`
flex-grow: 1
padding: 5px 0px;
height: fit-content`