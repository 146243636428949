import Loading from '../../components/Loading/Loader';
import FloatingButton from '../../components/Common/FloatingButton';
import ConnectionError from '../../components/Errors/ConnectionError';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';
import { uniqueId } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { SelectOrgId } from '../../store/userSlice';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { SelectCanAssign } from '../../store/userSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SelectTotalSteps, SetStepForward } from '../../store/flowSlice';
import { FramesContainerSpacer, FramesContainerWrapper as Wrapper } from '../../primitives/styles/Frames/FrameControlsStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import { getFrameTemplates } from '../../api/rest/frames';
import { FrameTemplate } from '../../primitives/types/FrameTemplate';
import FramesTemplateSelector from './FrameTemplateSelector';
import FrameEditor from './FrameEditor';
import { Ripple } from '../../primitives/types/Ripples';
import { SetRipples } from '../../store/ripplesSlice';
import { SelectFrameTitle } from '../../primitives/styles/Frames/FrameEditorStyle';
import Phrase from '../../components/Phrase/Phrase';
import NoFrameTemplatesError from '../../components/Errors/NoFrameTemplatesError';
import { useHistory } from 'react-router';
import { SelectPathHistory, SetStepBackward } from '../../store/flowSlice';
import { SelectTranslations } from '../../store/translationSlice';

interface RipplesContainerProps {
  path: string;
  flowName: FlowName;
  currentStep: number;
  nextPath: () => void;
}
const FramesContainer: React.FC<RipplesContainerProps> = ({
  path,
  flowName,
  nextPath,
  currentStep,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(SelectOrgId);
  const canAssign = useSelector(SelectCanAssign);
  const totalSteps = useSelector(SelectTotalSteps);

  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<FrameTemplate | undefined>(undefined);
  const [connectionError, setConnectionError] = useState(false);
  const [textInputCache, setTextInputCache] = useState<{[key: string]: string}>({});

  const history = useHistory();
  const pathHistory = useSelector(SelectPathHistory);

  const translations = useSelector(SelectTranslations);


  const handleBack = () => {
    const [path] = pathHistory.slice(-1);
    if (typeof path === 'string') {
      history.push(path);
      dispatch(SetStepBackward(currentStep));
    }
  };

  const canvasRef = useRef<any>(null);

  const setOriginalText = (templates: FrameTemplate[]) => {
    templates.forEach(template => {
      template.editables.forEach(editable => {
        if (editable.text) {
          if (editable.text.translationKey) {
            editable.text.text = translations[editable.text.translationKey] || editable.text.text;
          }
          editable.text.originalText = editable.text.text;
        }
      })
    });
    return templates;
  }

  const getAndSetFrameTemplates = async () => {
    try {
      const templates = await getFrameTemplates(orgId);
      const templatesWithOriginalText = setOriginalText(templates);
      setTemplates(templatesWithOriginalText);
    } catch (err) {
      setConnectionError(true);
    }
    setIsLoading(false);
  }
  
  const onSelectTemplate = (template: FrameTemplate) => {
    
    const updatedCache = {...textInputCache};

    // set the cache based on the current template
    if (selectedTemplate) {
      selectedTemplate.editables.forEach(e => {
        if (e.text) {
          updatedCache[e.title.text] = e.text.text; 
        }
      });
      setTextInputCache(updatedCache);
    }

    // set the text(s) of the selected template based on cache
    template.editables.forEach(e => {
      if (updatedCache[e.title.text] && e.text) {
        e.text.text = updatedCache[e.title.text];
      }
    });

    // set new template
    setSelectedTemplate(template);
  }


  const editorPadding = 150;
  const [editorWidth, setEditorWidth] = useState(window.innerWidth - editorPadding);

  const handleWindowResize = () => {
    setEditorWidth(window.innerWidth - editorPadding);
  };

  useEffect(() => {
    // initialize the ripple container
    if(canAssign){
      setIsLoading(true);
      getAndSetFrameTemplates();
    }
    else {
      setIsLoading(false)
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line
  }, []);



  const uploadFrame = async () => {
    const imageData = await canvasRef.current.toDataURL();

    const id = uniqueId()
    fetch(imageData)
    .then((res: any) => res.blob())
    .then( (blob: Blob) => {
        let url = URL.createObjectURL(blob);
        const ripples: Ripple[] = [ { key: 0, image:url, id} ] // TODO: isFrame: true
        const payload = { hasImages: true, ripples };
        dispatch(SetRipples(payload));
        dispatch(SetStepForward({ currentStep, path }));
        nextPath()
    })    
  }

  if (isLoading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }

  if (connectionError) {
    return (
      <ConnectionError onClose={handleBack} />
    )
  }

  if (!isLoading && !templates.length) {
    return (<NoFrameTemplatesError onClose={handleBack} />)
  }

  return (
    <>
      <Container id={'ripples-container'} isModalOpen={false}>

        <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow='createFrame' />
        <Wrapper>
          
          {selectedTemplate && (
            <FrameEditor frame={selectedTemplate} width={editorWidth} canvasRef={canvasRef}></FrameEditor>
          )}
          
          <FramesContainerSpacer></FramesContainerSpacer>

          <SelectFrameTitle>
            <Phrase
              translationKey="6208f98e-5f5d-4eab-9871-07786fd714fe"
              defaultText="Select frame template"
            />
          </SelectFrameTitle>
          <FramesTemplateSelector templates={templates} onSelectTemplate={onSelectTemplate}></FramesTemplateSelector>

          <FramesContainerSpacer></FramesContainerSpacer>

        </Wrapper>
        <FloatingButton
              onClick={uploadFrame}
              isDisabled={false}
              variant={'contained'}
              position={'sticky'}
              buttonText={'b15c3820-3db5-4c29-8aea-fd6e94e22cc1'} // Next
              buttonDefaultText={'NEXT'}
            />
      </Container>
    </>
  );
};

export default withAuthenticationRequired(FramesContainer, {
  onRedirecting: () => <Loading />,
});
