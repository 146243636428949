import GA from 'react-ga';

export const initializeGA = () => {
    const TRACKING_ID = "UA-69119935-16";
    const config = {
        debug: process.env.REACT_APP_ENV !== 'production'
    }
    GA.initialize(TRACKING_ID, config);
    GA.pageview(window.location.pathname + window.location.search);
}

export default GA;