import styled from 'styled-components';
import { global } from '../Global/global';
export const ContentWrapper = styled.div`
  position: relative;
  padding-top: calc(20px * ${(props) => props.theme.ratio});
  padding-bottom: calc(100px * ${(props) => props.theme.ratio});
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const QRcodeWrapper = styled.div`
  position: relative;
  padding: 5px 5px 1px 5px;
  background: ${(props) => props.theme.palette.white};
  margin-top: calc(30px * ${(props) => props.theme.ratio});
`;

export const QRcodeImage = styled.img`
  padding: 5px;
  background: ${(props) => props.theme.palette.white};
  margin-top: calc(50px * ${(props) => props.theme.ratio});
  height: calc(150px * ${(props) => props.theme.ratio});
  width: calc(150px * ${(props) => props.theme.ratio});
  -webkit-touch-callout: all !important;
  -webkit-user-select: all !important;
  -khtml-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
  z-index: 100;
`;

export const LocationName = styled.span`
  min-width: 90vw;
  max-width: 90vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: calc(55px * ${(props) => props.theme.ratio});
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(31px * ${(props) => props.theme.ratio});
  text-align: center;
  letter-spacing: 0.01em;
  overflow: hidden;
  padding: 0px 10px;
  color: ${(props) => props.theme.palette.white};
  ${global.userSelectNone}
  user-select: none;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  align-self: center;
  text-align: center;
  bottom: -10px;
  width: fit-content;
  // padding-bottom: calc(22px * ${(props) => props.theme.ratio});
  height: fit-content;//calc(0px * ${(props) => props.theme.ratio});
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(22px * ${(props) => props.theme.ratio});
  line-height: calc(25px * ${(props) => props.theme.ratio});
  letter-spacing: 0.02em;
  text-decoration-line: none;
  color: ${(props) => props.theme.palette.white};
  ${global.userSelectNone}
  user-select: none;
`;

// export const CopyURL = styled.span.attrs((props: { isLink: boolean }) => ({
//   isLink: props.isLink,
// }))`
//   position: absolute;
//   align-self: center;
//   text-align: center;
//   bottom: 0;
//   width: fit-content;
//   padding-bottom: calc(22px * ${(props) => props.theme.ratio});
//   height: calc(25px * ${(props) => props.theme.ratio});
//   font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
//   font-style: normal;
//   font-weight: 390;
//   font-size: calc(22px * ${(props) => props.theme.ratio});
//   line-height: calc(25px * ${(props) => props.theme.ratio});
//   letter-spacing: 0.02em;
//   text-decoration-line: ${(props) => (props.isLink ? 'underline' : 'none')};
//   color: ${(props) => props.theme.palette.white};
//   ${ global.userSelectNone }
//   user-select: none;
// `;

export const CopyURL = styled.span.attrs((props: { isLink: boolean }) => ({
  isLink: props.isLink,
}))`
  align-self: center;
  text-align: center;
  width: fit-content;
  min-width: 90vw;
  padding-bottom: calc(22px * ${(props) => props.theme.ratio});
  margin-top: 80px;
  height: calc(26px * ${(props) => props.theme.ratio});
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(22px * ${(props) => props.theme.ratio});
  line-height: calc(25px * ${(props) => props.theme.ratio});
  letter-spacing: 0.02em;
  text-decoration-line: ${(props) => (props.isLink ? 'underline' : 'none')};
  color: ${(props) => props.theme.palette.white};
  ${global.userSelectNone}
  user-select: none;
`;

export const WebAppUrl = styled.span.attrs((props: { isLink: boolean }) => ({
  isLink: props.isLink,
}))`
  align-self: center;
  text-align: center;
  width: fit-content;
  padding-bottom: calc(22px * ${(props) => props.theme.ratio});
  height: calc(26px * ${(props) => props.theme.ratio});
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(22px * ${(props) => props.theme.ratio});
  line-height: calc(25px * ${(props) => props.theme.ratio});
  letter-spacing: 0.02em;
  text-decoration-line: ${(props) => (props.isLink ? 'underline' : 'none')};
  color: ${(props) => props.theme.palette.white};
  ${global.userSelectNone}
  user-select: none;
`;
