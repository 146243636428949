import { createFloatingButtonStyles } from '../../../primitives/styles/Onboarding/Onboarding';
import Button from '@material-ui/core/Button';

interface LocationButtonProps {
  theme?: any;
  children?: React.ReactNode
  style?: Object;
  containerStyle?: Object;
  onClick?: () => void
  disabled?: boolean
}

const LocationButton: React.FC<LocationButtonProps> = ({
  theme = {},
  style = {},
  children,
  onClick,
  disabled = false,
  containerStyle = {}
}) => {
  const { heightRatio = 1, ratio = 1 } = theme;

  const useStyles = createFloatingButtonStyles(
    ratio,
    heightRatio
  );
  const classes = useStyles();

  return (
    <div style={{display: 'flex', justifyContent: 'center', ...containerStyle}}>
    <Button disabled={disabled} classes={classes} style={style} onClick={onClick}>
        {children}
    </Button>
    </div>
  );
};

export default LocationButton;