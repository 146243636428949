import { gqlClient } from '../utils/gqlClient';
import { CREATE_LOCATION, GET_COUNTRIES } from '../api/graphQl/mutation'

export type CreateLocationParams = {
    name?: string;
    state?: string;
    country?: string;
    postCode?: string;
    streetAddress?: string;
    city?: string;
    organization?: string
    longitude?: number
    latitude?: number
}

export const createLocation = ({name, country, state, city, postCode, streetAddress, organization, longitude = 0, latitude = 0}: CreateLocationParams) => {
    return gqlClient.mutate(
        CREATE_LOCATION, {
            name, country, state, city, zipCode: postCode, address: streetAddress, organization, longitude, latitude, isWebappEnabled: true
        },
        'createRippleMakerLocation'
    )
}

export const getCountries = () => {
    return gqlClient.query(
        GET_COUNTRIES, {
        }
    )
}