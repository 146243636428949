import styled from 'styled-components';
import { global } from '../Global/global';

export const WebappEmbed = styled.iframe`
  height: ${(props) => props.theme.deviceHeight}px;
  width: 100vw;
  border: none;
  -webkit-transform:scale(0.85);
  -moz-transform-scale(0.85);
  margin-top: calc(${(props) => props.theme.deviceHeight}px * -0.04);
  ${global.userSelectNone}
`;

export const WebappEmbedLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.theme.deviceHeight}px;
  min-width: 100vw;
  padding-top: calc(50px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.palette.black};
  z-index: 1;
`
