
import Phrase from '../Phrase/Phrase';

import { useEffect } from 'react';
import { CloseIcon } from '../../primitives/media/Icons';
import { WarningIcon } from '../../primitives/media/Icons';
import { CloseWrapper } from '../../primitives/styles/Common/CommonStyle';
import {
  RippleInfoDetail,
  RippleInfoModalClose,
  RippleWarningModalHeader,
} from '../../primitives/styles/Ripples/RipplesStyle';

import {
  ErrorWarningMsg,
  ErrorModalWrapper,
  ErrorModalContent,
  ErrorModalColumnTitle,
  DesignLimitErrorDetailsWrapper,
} from '../../primitives/styles/Common/CommonStyle'

interface ErrorProps {
    onClose: () => void;
}

const DesignLimitError: React.FC<ErrorProps> = ({onClose}) => {
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });

  return (
    <ErrorModalWrapper>
      <ErrorModalContent>
        <RippleWarningModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={onClose}>
              <CloseIcon />
            </CloseWrapper>
          </RippleInfoModalClose>
          <ErrorModalColumnTitle>
            <WarningIcon />
            <Phrase
              translationKey={'8489e112-f273-456e-9364-7da245b21a82'}
              defaultText={'Upload Error'}
            />
          </ErrorModalColumnTitle>
        </RippleWarningModalHeader>
        <DesignLimitErrorDetailsWrapper>
          <RippleInfoDetail>
            <ErrorWarningMsg>
              <Phrase
                translationKey={'4d63f2df-cf82-4c31-a948-f48fe0439477'}
                defaultText={'You can upload max 10 designs each time'}
              />
            </ErrorWarningMsg>
            <ErrorWarningMsg>
              <b>
                <Phrase
                  translationKey={'19ee4b22-9666-4a0c-a76c-6c7e98b5d2a1'}
                  defaultText={'Please try again'}
                />
              </b>
            </ErrorWarningMsg>
          </RippleInfoDetail>
        </DesignLimitErrorDetailsWrapper>
      </ErrorModalContent>
    </ErrorModalWrapper>
  );
};

export default DesignLimitError;
