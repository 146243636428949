import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Channel as ChannelType, } from '../primitives/types/Channel';

export interface SelectedChannel {
  id: string;
  name: string;
}


export const initState: SelectedChannel = {
  id: '',
  name: '',

};

const channelSlice = createSlice({
  name: 'channel',
  initialState: initState,
  reducers: {
    SetChannel(state, action: PayloadAction<{ channelsMetaData: any, selectedChannel: ChannelType }>) {
      const { selectedChannel } = action.payload;

      const id: string = selectedChannel.id;
      const name: string = selectedChannel.name;

      state.id = id;
      state.name = name;
    },

    ResetChannelState(state) {
      state.id = initState.id;
      state.name = initState.name;
    },
  },
});

export const { SetChannel, ResetChannelState } = channelSlice.actions;

export const SelectChannel = (state: any): SelectedChannel => state.channel;

export default channelSlice.reducer;
