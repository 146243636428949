import React from 'react';
import { useSelector } from 'react-redux';
import { SelectTranslations } from '../../store/translationSlice';

interface PhraseProps {
    translationKey: string;
    defaultText: string;
    vars?: {[key: string]: string }
}
const Phrase : React.FC<PhraseProps> = ({ translationKey, defaultText, vars }) => {

    const translations = useSelector(SelectTranslations);

    const getPhrase = () => {
        let translatedText: string = !translations || !translations[translationKey] ? defaultText : translations[translationKey.trim()];
        if(vars) {
            Object.keys(vars).forEach(key => {
                translatedText = translatedText.replace(`{{${key}}}`, vars[key])
            })
        }
        return translatedText.split('\\n');
    }

    const lines: string[] = getPhrase();

    return (
        <>
            {
                lines.length > 1 ? lines.map((line:string, i:number) => (
                    <React.Fragment key={i}>
                        {line}
                        <br/>
                    </React.Fragment>
                )) : lines[0]
            }
        </>
    )
}

export default Phrase;


    // const isUUID = (str: string) => {
    //     try {
    //         let split = str.split('-');
    //         if(str.length === 36
    //         && split[0].length === 8 
    //         && split[1].length === 4
    //         && split[2].length === 4
    //         && split[3].length === 4
    //         && split[4].length === 12
    //         )
    //         { 
    //             return true; 
    //         } 
    //         else 
    //         { 
    //             return false; 
    //         }
    //     } catch (e) {
    //         return false;
    //     }
        
    // }

    // const getPhrase = () => {
    //     const phrase = translationKey ? t(translationKey, vars) : defaultText;        
    //     if(isUUID(phrase)) {
    //         return defaultText;
    //     } else {
    //         return phrase;
    //     }
    // }