//בס"ד

import Calendar from './Calendar';
import Phrase from '../Phrase/Phrase';
import DateSelector from './DateSelector';
import FloatingButton from '../Common/FloatingButton';

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { SelectTranslations } from '../../store/translationSlice';
import {
  DatePickerWrapper,
  TimePickerSection,
  TimePickerHeaderSection,
  DatesRow,
} from '../../primitives/styles/DatePicker/DatePickerStyles';
import { MobileChannelsTitle, TimeLimitRadioSelectorButton, TimeLimitRadioSelectorIndicator, TimeLimitRadioSelectorLabel, TimeRadioSelectorGroup, TimeRadioSelectorOption } from '../../primitives/styles/Publish/Publish';

interface DatePickerProps {
  to: string;
  from: string;
  timeLimit: boolean;
  isPublishOnProgress: boolean
  onPublish: ({ from, to }?: { from: string, to: string }) => void;
  setTo: (date: string) => void;
  setFrom: (date: string) => void;
  handleChangeTimeLimit: (value: boolean) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  to,
  from,
  timeLimit,
  isPublishOnProgress,
  setTo,
  setFrom,
  onPublish,
  handleChangeTimeLimit,
}) => {
  const months: string[] = [
    'c69f2d05-8971-4bd0-933c-09101cbe4b0a',
    '26f04f41-84be-40ac-8494-51b1710743ce',
    '5cd89a6a-a3ac-4130-b1b7-4ff88b6715bd',
    'f28a9470-8b64-4bd4-92a4-05b41510f2c6',
    '8d565587-35c8-4e43-84e3-623549758052',
    '62a4aa80-4dda-4efa-8738-30b46a6f1307',
    '378fa96c-508d-4185-8538-25d190268860',
    '392ab426-8630-46d0-9c58-0c72aee590ca',
    '6e87521f-69d3-4376-b563-27b4f3fbf364',
    'a20361f3-1a6e-4e68-96fb-a8ed539f2c79',
    '609f68ad-7bae-4640-9f3b-a552e0f41fd5',
    'c47e6cb0-9cfb-4f35-b199-b8d3cb5cf01a',
  ];

  const translations = useSelector(SelectTranslations)

  const NOW = translations['0ad35730-8e7d-46bb-bd48-2cf92ac07836'] || 'Now'

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const initDayFrom = from ? new Date(from).getDate() : currentDay;
  const initMonthFrom = from ? new Date(from).getMonth() : currentMonth;
  const initYearFrom = from ? new Date(from).getFullYear() : currentYear;

  const initDayTo = to ? new Date(to).getDate() : currentDay;
  const initMonthTo = to ? new Date(to).getMonth() : currentMonth;
  const initYearTo = to ? new Date(to).getFullYear() : currentYear;

  const initYears: number[] = [];
  for (let i = 0; i < 5; i++) {
    initYears.push(currentYear + i);
  }

  const [toTime, setToTime] = useState(to);
  const [fromTime, setFromTime] = useState(from);

  const [validYearsTo, setValidYearsTo] = useState(initYears);
  const [validYearsFrom, setValidYearsFrom] = useState(initYears);

  const [openToCalendar, setOpenToCalendar] = useState(true);
  const [openFromCalendar, setOpenFromCalendar] = useState(false);

  const [selectedDayFrom, setSelectedDayFrom] = useState(initDayFrom);
  const [selectedYearFrom, setSelectedYearFrom] = useState(initYearFrom);
  const [selectedMonthFrom, setSelectedMonthFrom] = useState(initMonthFrom);
  const [daysInSelectedMonthFrom, setDaysInSelectedMonthFrom] = useState(0);

  const [selectedDayTo, setSelectedDayTo] = useState(initDayTo);
  const [selectedYearTo, setSelectedYearTo] = useState(initYearTo);
  const [selectedMonthTo, setSelectedMonthTo] = useState(initMonthTo);
  const [daysInSelectedMonthTo, setDaysInSelectedMonthTo] = useState(0);

  const getNowFormatted = () => {
    let d = new Date(Date.now()).getDate();
    let m = new Date(Date.now()).getMonth();
    let yyyy = new Date(Date.now()).getFullYear();

    const mm = ++m <= 9 ? `0${m}` : `${m}`;
    const dd = d <= 9 ? `0${d}` : `${d}`;
    const yy = yyyy.toString().substring(2, 4);
    return `${mm}/${dd}/${yy}`;
  };

  const onSetSelectionClicked = () => {
    if (!timeLimit) {
      onPublish();
      return
    }
    const now = getNowFormatted();
    toTime === NOW ? setTo(now) : setTo(toTime);
    fromTime === NOW ? setFrom(now) : setFrom(fromTime);
    onPublish({ from: fromTime === NOW ? now : fromTime, to: toTime === NOW ? now : toTime });
  };

  const selectMonthFrom = (month: number) => {
    setSelectedMonthFrom(month);
  };

  const selectDayFrom = (day: number) => {
    setSelectedDayFrom(day);
  };

  const selectYearFrom = (year: number) => {
    setSelectedYearFrom(year);
  };

  const selectMonthTo = (month: number) => {
    setSelectedMonthTo(month);
  };

  const selectDayTo = (day: number) => {
    setSelectedDayTo(day);
  };

  const selectYearTo = (year: number) => {
    setSelectedYearTo(year);
  };

  const handleSetTime = (m: number, d: number, yyyy: number, cb: Function) => {
    const date = new Date();
    const mm = ++m <= 9 ? `0${m}` : `${m}`;
    const dd = d <= 9 ? `0${d}` : `${d}`;
    const yy = yyyy.toString().substring(2, 4);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currentTime = `${month + 1}/${day}/${year}`;
    const time = `${mm}/${dd}/${yy}`;
    if (new Date(currentTime).getTime() === new Date(time).getTime()) {
      cb(NOW);
      return;
    }
    cb(time);
  };

  const handleOpenFromCalendar = () => {
    setOpenToCalendar(false);
    setOpenFromCalendar(true);
  };

  const handleOpenToCalendar = () => {
    setOpenFromCalendar(false);
    setOpenToCalendar(true);
  };

  useEffect(() => {
    const years: number[] = [];
    for (let i = 0; i < 5; i++) {
      years.push(currentYear + i);
    }
    setValidYearsTo(years);
    setValidYearsFrom(years);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const years: number[] = [];
    for (let i = 0; i < 5; i++) {
      years.push(selectedYearFrom + i);
    }
    setValidYearsTo(years);
    //eslint-disable-next-line
  }, [selectedYearFrom]);

  useEffect(() => {
    const daysInMonth = new Date(
      selectedYearFrom,
      selectedMonthFrom + 1,
      0
    ).getDate();

    setDaysInSelectedMonthFrom(daysInMonth);
  }, [selectedMonthFrom, selectedYearFrom]);

  useEffect(() => {
    const daysInMonth = new Date(
      selectedYearTo,
      selectedMonthTo + 1,
      0
    ).getDate();
    setDaysInSelectedMonthTo(daysInMonth);
  }, [selectedMonthTo, selectedYearTo]);

  // set the displayed from time
  useEffect(() => {
    handleSetTime(
      selectedMonthFrom,
      selectedDayFrom,
      selectedYearFrom,
      setFromTime
    );
    // eslint-disable-next-line
  }, [selectedMonthFrom, selectedDayFrom, selectedYearFrom]);

  // set the displayed to time
  useEffect(() => {
    handleSetTime(selectedMonthTo, selectedDayTo, selectedYearTo, setToTime);
    // eslint-disable-next-line
  }, [selectedMonthTo, selectedDayTo, selectedYearTo]);

  return (
    <>
      <DatePickerWrapper>
        <TimePickerSection>
          <TimePickerHeaderSection>
            <MobileChannelsTitle>
              <Phrase
                defaultText='Set design duration'
                translationKey='c07ba6ff-6787-421b-94bb-9f37222dd9ed'
              />
            </MobileChannelsTitle>
            <TimeRadioSelectorGroup>
              <TimeRadioSelectorOption onClick={() => handleChangeTimeLimit(false)}>
                <TimeLimitRadioSelectorButton>
                  {!timeLimit && <TimeLimitRadioSelectorIndicator />}
                </TimeLimitRadioSelectorButton>
                <TimeLimitRadioSelectorLabel>
                  <Phrase
                    translationKey={'a611755e-b6e1-4e3a-b0bd-13ecbdc20c12'}
                    defaultText={'Unspecified'}
                  />
                </TimeLimitRadioSelectorLabel>
              </TimeRadioSelectorOption>
              <TimeRadioSelectorOption onClick={() => handleChangeTimeLimit(true)}>
                <TimeLimitRadioSelectorButton>
                  {timeLimit && <TimeLimitRadioSelectorIndicator />}
                </TimeLimitRadioSelectorButton>
                <TimeLimitRadioSelectorLabel>
                  <Phrase
                    translationKey={'afd5ee4d-3337-4bab-bbff-ed43997447dd'}
                    defaultText={'Set a schedule'}
                  />
                </TimeLimitRadioSelectorLabel>
              </TimeRadioSelectorOption>
            </TimeRadioSelectorGroup>
            {timeLimit && <DatesRow>
              <DateSelector
                openCalendar={handleOpenFromCalendar}
                isOpen={openFromCalendar}
                label={'0a2a2ad9-4e4e-46e9-8042-a094f9f0682a'}
                defaultLabel={'From'}
                value={fromTime}
              />
              <DateSelector
                openCalendar={handleOpenToCalendar}
                isOpen={openToCalendar}
                label={'1f686b93-a641-4602-9c1e-877bdf341397'}
                defaultLabel={'To'}
                value={toTime}
              />

            </DatesRow>}
          </TimePickerHeaderSection>
        </TimePickerSection>
        {openFromCalendar && daysInSelectedMonthFrom && timeLimit && (
          <Calendar
            fromDate={''}
            months={months}
            validYears={validYearsFrom}
            selectDay={selectDayFrom}
            selectYear={selectYearFrom}
            selectMonth={selectMonthFrom}
            selectedDay={selectedDayFrom}
            selectedYear={selectedYearFrom}
            selectedMonth={selectedMonthFrom}
            daysInMonth={daysInSelectedMonthFrom}
            toDate={`${selectedMonthTo + 1}/${selectedDayTo}/${selectedYearTo}`}
          />
        )}
        {openToCalendar && daysInSelectedMonthTo && timeLimit && (
          <Calendar
            toDate={''}
            months={months}
            validYears={validYearsTo}
            selectDay={selectDayTo}
            selectYear={selectYearTo}
            selectMonth={selectMonthTo}
            selectedDay={selectedDayTo}
            selectedYear={selectedYearTo}
            selectedMonth={selectedMonthTo}
            daysInMonth={daysInSelectedMonthTo}
            fromDate={`${selectedMonthFrom + 1
              }/${selectedDayFrom}/${selectedYearFrom}`}
          />
        )}
      </DatePickerWrapper>
      <FloatingButton
        isDisabled={false}
        inProgress={isPublishOnProgress}
        position={'fixed'}
        variant={'contained'}
        onClick={onSetSelectionClicked}
        buttonText={'aaa7bd0f-1f5e-4b4f-a125-cd599a91f46d'}
        buttonDefaultText={'PUBLISH'}
      />
    </>
  );
};

export default DatePicker;
