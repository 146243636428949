import LandScapeErrorPage from '../components/LandScapeError/LandScapeErrorPage';
import Loading from '../components/Loading/Loader';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { 
  setCurrentViewMode, 
  SetInitialScreenWidth,
  SetInitialScreenHeight,
} from '../store/viewModeSlice';

declare global {
  interface Window { viewMode: any; }
}

window.viewMode = window.viewMode || {};

const OrientationWrapper = ({ children }: { children: JSX.Element }) => {

  const dispatch = useDispatch();
  const [isPortrait, setIsPortrait] = useState('portrait');

  // prevent stretching
  useEffect(() => {

    document.addEventListener('gesturestart', function(e: any) {
      e.preventDefault();
    });

    document.addEventListener('gesturechange', function(e: any) {
        e.preventDefault();
    });

    document.addEventListener('gestureend', function(e: any) {
        e.preventDefault();
    });

    return () => {
      document.removeEventListener('gesturestart', function(e: any) {
        e.preventDefault();
      });
  
      document.removeEventListener('gesturechange', function(e: any) {
          e.preventDefault();
      });
  
      document.removeEventListener('gestureend', function(e: any) {
          e.preventDefault();
      });
    }

  },[])


  useEffect(() => {
    handleViewModeChange();
    window.addEventListener(
      'resize', handleViewModeChange
    );

    return () => {
      window.removeEventListener(
        'resize', handleViewModeChange
      );
    };
  //eslint-disable-next-line
  },[])


  const setInitialHeightAndWidth = (height: number, width: number) => {
      const { initHeight, initWidth } = window.viewMode;

      // if already initialized, return
      if( initHeight && initWidth ) {
        return
      };

      // if initial mode is landscape
      if( width > height ) {
        window.viewMode.initMode = "landscape";
      } 
      
      else {
        window.viewMode.initMode = "portrait";
      }

      window.viewMode.initHeight = height;
      dispatch(SetInitialScreenHeight(height))
      window.viewMode.initWidth = width;
      dispatch(SetInitialScreenWidth(width))
  }

  const dispatchScreenMode = (height: number, width: number) => {
    dispatch(setCurrentViewMode({ height, width }));
  };


  const handleViewModeChange = () => {
    const wrapper = document.getElementById('orientationWrapper')
    if(wrapper) {
      const width = wrapper.clientWidth;
      const height = wrapper.clientHeight;
      setInitialHeightAndWidth(height, width);
      const { initHeight, initWidth, initMode } = window.viewMode;

      // initial load in landscape mode
      if( initMode === "landscape" ) {
        if( initWidth === width && initHeight === height ) {
          setIsPortrait('landscape')
        }
        else if( initWidth === height && initHeight === width) {
          setIsPortrait("portrait");
        }
        return;
      }

      // initial load in portrait mode
      if( initMode === "portrait" ) {
        if(initHeight === width && initWidth === height) {
          setIsPortrait('landscape')
        } 
        else {
          setIsPortrait('portrait')
        }
      }


      dispatchScreenMode(height, width)
    }
  };

  if(!isPortrait) {
    return (<Loading/>)
  }

  return (
    <div style={{width: '100%', height: '100%'}} id={'orientationWrapper'} >
      {isPortrait === 'portrait' ? children : (<LandScapeErrorPage />)}
    </div>
    ) ;
};

export default OrientationWrapper;
