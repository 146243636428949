import * as React from 'react';
import { enforcement } from '@drinkripples/utils/utils-frontend';
import Wizard from '../../components/Wizard/Wizard';

import { generateAppRoutes } from '../../constants/Routes';
import { Container as WizardWrapper } from '../../primitives/styles/Wizard/WizardStyle';
import {
  CreateIcon,
  RemoveIcon,
  FramesIcon,
  QrCodeIcon,
  PublishIcon,
  ChannelsIcon
} from '../../primitives/media/Icons';

const { SERVICE_LEVEL_ENHANCED } = enforcement.config

interface WizardInterface {
  name: string;
  title: string;
  Icon: React.FC<any>;
  defaultTitle: string;
  disabled?: boolean;
}

interface Props {
  orgServiceLevel: number
}

const routes = generateAppRoutes()
const WizardContainer: React.FC<Props> = ({ orgServiceLevel }) => {

  const wizards: Array<WizardInterface> = [
    { name: routes.CREATE, title: 'f9cfcbe3-26a2-4a16-97b3-503aea2d9465', defaultTitle: 'CREATE', Icon: CreateIcon },
    { name: routes.DELETE, title: '219a55a8-6201-4fda-81ed-df7215848308', defaultTitle: 'DELETE', Icon: RemoveIcon },
    { name: routes.PUBLISH, title: 'aaa7bd0f-1f5e-4b4f-a125-cd599a91f46d', defaultTitle: 'PUBLISH', Icon: PublishIcon },
    { name: routes.FRAME, title: '1452763c-175c-431c-a358-e8baa694eb02', defaultTitle: 'FRAMES', Icon: FramesIcon, disabled: orgServiceLevel < SERVICE_LEVEL_ENHANCED},
    { name: routes.CHANNELS, title: 'd986c8d4-5225-4e14-adce-faf28de1c165', defaultTitle: 'CHANNELS', Icon: ChannelsIcon },
    { name: routes.QR_CODE, title: '41789581-651d-49e7-8766-4945378801b0', defaultTitle: 'QR CODE', Icon: QrCodeIcon },
  ];

  const handleStoreClick = () => {
    const storeUrl = 'https://store.drinkripples.com';

    window.open(storeUrl)
  }

  return (
    <WizardWrapper>
      {wizards.map((wiz, index) => (
        <Wizard
          key={`${wiz.title}-${index}`}
          name={wiz.name}
          title={wiz.title}
          Icon={wiz.Icon}
          defaultTitle={wiz.defaultTitle}
          disabled={wiz.disabled}
        />
      ))}
      <Wizard defaultTitle='Ripples Store' title='10b90111-ebc0-433c-b2ef-0ba430708bac' name='store' onClick={handleStoreClick} style={{ flexGrow: 1, height: 'fit-content', padding: '10px 0' }} titleStyle={{ marginTop: '3px' }} />
    </WizardWrapper>
  );
};

export default WizardContainer;
