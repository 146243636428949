import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

const shipMachine = async ({ locationId, serial, organizationId, selectedOrganization, partnerOrganizationId }: { locationId?: string | null, serial?: string, organizationId?: string, selectedOrganization?: string, partnerOrganizationId?: string }) => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/makers/machines/${serial}/orphanAndReship`;
    
      return axios.put(url, { locationId, organizationId, selectedOrganization, partnerOrganizationId }).then(data => data);
}

export default shipMachine;
