import Phrase from "../../../components/Phrase/Phrase";
import { TextControlWrapper } from "../../../primitives/styles/Frames/FrameControlsStyle";
import { FrameEditable } from "../../../primitives/types/FrameTemplate";

interface ImageControlProps {
    index: number;
    data: FrameEditable;
    updateControl: (index: number, control: FrameEditable) => void;
}

const ImageControl: React.FC<ImageControlProps> = ({index, data, updateControl}) => {
    

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = (event.target as HTMLInputElement).files;
        if (files?.length) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                const newData = {...data}
                newData.imageUrl = reader.result as string;
                updateControl(index, newData);
            };
            

            if (file) {
            reader.readAsDataURL(file);
        }
        }
    };

    const fileInputId = 'fileInput_' + data.title.text.replace(/[^\w-]+/g, '_');

    let imageInputDefaultText = "Add Image";
    let imageInputTranslationKey = "233a5391-2ce8-4df3-89cf-7e405fccf75a";
    if (data.imageUrl) {
        imageInputDefaultText = "Replace Image";
        imageInputTranslationKey = "cda5d819-d056-47d2-afce-e69d028a0cd0";  
    }

    return (
        <TextControlWrapper>
            <Phrase translationKey={data.title.translationKey as string} defaultText={data.title.text} />
            {data.imageUrl && (
                <img src={data.imageUrl} className="selectedImage" alt="" />
            )}
            <label htmlFor={fileInputId} className="fileInputLabel">
            <Phrase
                translationKey={imageInputTranslationKey}
                defaultText={imageInputDefaultText}
            />
            </label>
            <input 
                type="file" id={fileInputId} className="fileInputElement" onChange={handleImageChange} accept="image/*"
            ></input>
        </TextControlWrapper>
    );
}

export default ImageControl;
