import {
    makeStyles,
    Theme,
    createStyles,
  } from '@material-ui/core/styles';
 
  
const drawerWidth = '83%';
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      // marginRight: theme.spacing(2),
      width: '8vw',
      height: 'auto',
      marginLeft: '1vw'
    },
    menuList:{
      position:'relative',
      paddingLeft: '45.5px !important',
      paddingRight: '48.5px !important',
      paddingTop: '42px !important'
    },
    hide: {
      display: 'none !important',
    },
    closeIcon: {
      color: '#FFFFFF',
      width: '8vw',
      height: 'auto',
    },
    drawer: {
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaperOpen: {
      width: drawerWidth,
      border: 'none !important',
      boxShadow:'17vw 0px 0px 0px rgba(0,0,0,0.5) !important',
    },
    drawerPaperClose: {
      width: drawerWidth,
      border: 'none',
    },
    drawerHelp: {
      width: '100%',
      flexShrink: 0
    },
    drawerHelpPaper: {
      width: '100%',
      border: 'none !important',
    },
    menuButtonHelp: {
      width: '8vw',
      height: 'auto',
      fontSize: '8vw',
      margin: 0
    },
    helpMenuListItem:{
      display:'flex',
      marginTop: 10
    },
  })
);