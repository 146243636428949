import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import CheckboxButton from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withTheme } from 'styled-components';
import { createCheckboxStyles } from './styles'
import { CheckboxLabel } from '../../primitives/styles/Common/CommonStyle';

interface CheckboxProps {
  theme: any;
  label: string;
  isChecked: boolean;
  isDisabled: boolean;
  defaultLabel: string;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  style?: Object;
  size?: 'large' | 'medium' | 'small';
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  theme,
  onChange,
  isChecked,
  isDisabled,
  defaultLabel,
  style = {},
  size
}) => {
  const useStyles = createCheckboxStyles(isDisabled, theme.ratio, size)
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        classes={{ root: classes.formLabelRoot }}
        disabled={isDisabled}
        style={style}
        control={
          <CheckboxButton
            classes={{ root: classes.root, checked: classes.checked }}
            checked={isChecked}
            onChange={() => onChange((prevState) => !prevState)}
          />
        }
        label={
          <CheckboxLabel>
            <Phrase 
              translationKey={label}
              defaultText={defaultLabel}
            />
          </CheckboxLabel>
        }
      />
    </>
  );
};

export default withTheme(Checkbox);
