import * as React from 'react';
import { CloseIcon } from '../../../../../primitives/media/Icons';
import Phrase from '../../../../../components/Phrase/Phrase';
import {
  SelectModalClose,
  SelectModalTitle,
  SelectModalHeader,
  SelectModalContent,
  SelectModalWrapper,
} from '../../../../../primitives/styles/SelectModal/SelectModal';
import { Channel, ChannelIcon, ChannelsContianer, UnderlineText, ContactSupportContent, SubTitle, SerialNumber } from '../../../../../primitives/styles/Onboarding/ContactSupport';
import WhatsappSrc from '../../../../../primitives/media/Onboarding/whatsappImage.png';
import PhoneSrc from '../../../../../primitives/media/Onboarding/phoneImage.png';
import MailSrc from '../../../../../primitives/media/Onboarding/mailImage.png';
import InfoSrc from '../../../../../primitives/media/Onboarding/info.png';
import _ from 'lodash';

interface ContactSupportProps {
  closeSupportModal: () => void;
  serial?: string
  supportDetails?: {
    phone?: string;
    email?: string;
    url?: string;
    hours?: string;
  }
}

const ContactSupport: React.FC<ContactSupportProps> = ({
  closeSupportModal: closeContactSupportModal,
  serial,
  supportDetails = {}
}) => {
  const {
    email,
    phone,
    url,
    hours,
  } = supportDetails;

  const isCustom: string | boolean = _.get(supportDetails, 'orgId', false);

  return (
    <SelectModalWrapper>
      <SelectModalContent>
        <SelectModalHeader>
          <SelectModalClose
            onClick={closeContactSupportModal}
            style={{ padding: 0, position: 'absolute', top: '7px', right: '7px' }}
          >
            <CloseIcon />
          </SelectModalClose>
          <SelectModalTitle>
            <Phrase
              translationKey={'67c172b9-6281-49d7-94ae-012a77fe1c65'}
              defaultText={'Contact support'}
            />
          </SelectModalTitle>
        </SelectModalHeader>
        <ContactSupportContent>
          <SubTitle>
            <Phrase translationKey={'d3e60a07-197d-451a-b8ce-55621265b2e6'} defaultText={'Please contact us to correct your Ripple Maker\'s erroneous location entry in our systems'} />
          </SubTitle>
          <ChannelsContianer>
            {(url || !isCustom) && <Channel>
              <ChannelIcon src={WhatsappSrc} alt={''} />
              <UnderlineText>
                <Phrase
                  translationKey={'9ececd59-b60e-4344-b1fa-79bd60efd4b0'}
                  defaultText={'Chat with us'}
                />
              </UnderlineText>
            </Channel>}
            <Channel>
              <ChannelIcon src={PhoneSrc} alt={''} />
              <UnderlineText>
                {phone}
              </UnderlineText>
            </Channel>
            <Channel>
              <ChannelIcon src={MailSrc} alt={''} />
              <UnderlineText>
                {email}
              </UnderlineText>
            </Channel>
            {hours && <Channel>
              <ChannelIcon src={InfoSrc} alt={''} />
              <span>
                {hours}
              </span>
            </Channel>}
          </ChannelsContianer>
          <SerialNumber>
            <Phrase defaultText={'Ripple Maker Serial number:'} translationKey={'304438d0-9300-45bf-b2c1-ce90763a88c6'} />
            {`\n${serial}`}
          </SerialNumber>
        </ContactSupportContent>
      </SelectModalContent>
    </SelectModalWrapper>
  );
};

export default ContactSupport;
