import * as React from 'react';
import Location from '../Location/Location';
import NoDataMessage from '../Common/NoDataMessage';
import { isEmpty } from 'lodash';
import { Location as LocationType } from '../../primitives/types/Location';
import { LocationsList as Wrapper } from '../../primitives/styles/Location/Location';

interface LocationsListProps {
  locations: LocationType[];
  handleOpenModal: (location: LocationType) => void;
  handleSelectLocation: (location: LocationType) => void;
}

const LocationsList: React.FC<LocationsListProps> = ({
  locations,
  handleOpenModal,
  handleSelectLocation,
}) => {

  return (
    <Wrapper>
      {isEmpty(locations) ? (
        <NoDataMessage
        useIcon={false}
        isSubtextLink={true}
        defaultText={'No active locations found'}
        text={'19e39749-2e01-4b62-9d58-057371800d98'} // No active locations found
        />
      ) : (
        locations.map((location: LocationType) => (
          <Location
            key={location.id}
            location={location}
            handleOpenModal={handleOpenModal}
            handleSelectLocation={handleSelectLocation}
          />
        ))
      )}
    </Wrapper>
  );
};

export default LocationsList;
