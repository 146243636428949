import { TextPath } from 'react-konva';
import { FrameEditable } from '../../../primitives/types/FrameTemplate';

const svgArcPath = (r: number, start: number, delta: number ) => {
    /* 
    returns a SVG path element that represents a circle.
    r → radius of circle
    start → start angle, in degrees.
    delta → angle to sweep, in degrees.
    */
  
    // center position of circle
    const cx = 850;
    const cy = 850;

    const f_vec_add = (([a1, a2]: number[], [b1, b2]: number[]) => [Math.round(a1 + b1), Math.round(a2 + b2)]);
    start = start * (Math.PI/180);
    delta = delta * (Math.PI/180);

    delta = delta % (2*Math.PI);
    const [sX, sY] = ( f_vec_add ( [r * Math.cos(start), r * Math.sin(start)] , [cx,cy] ) );
    const [eX, eY] = ( f_vec_add ( [r * Math.cos(start+delta), r * Math.sin(start+delta)], [cx,cy] ) );
    const fA = ( (  delta > Math.PI ) ? 1 : 0 );
    const fS = ( (  delta > 0 ) ? 1 : 0 );

    const result = ["M", sX, sY, "A", r , r , 0, fA, fS, eX, eY].join(" ");

    return result;
};

interface RadialTextProps {
    data: FrameEditable;
}

const RadialText: React.FC<RadialTextProps> = ({data}) => {

    if (!data || !data.radius || !data.start || !data.delta) return (<></>);

    
    const arcPath = svgArcPath(data.radius, data.start, data.delta);

    const text = data.text ? data.text.text : "";
    
    return (
        <TextPath 
            text={text} 
            align="center"
            x={0}
            y={0}
            fill={data.color}
            fontSize={data.fontSize} 
            textBaseline="middle"
            fontFamily={data.font}
            data={arcPath}
        />
    );
}

export default RadialText;
