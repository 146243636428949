import { Stage, Layer, Image as KonvaImage} from 'react-konva';
import useImage from 'use-image';
import './FrameEditor.css';
import RadialText from './editables/RadialText';
import Text from './editables/Text';
import Image from './editables/Image';
import { RefObject, useEffect, useState } from 'react';
import Controls from './controls/Controls';
import { FrameTemplate, FrameEditable } from '../../primitives/types/FrameTemplate';
import { CanvasWrap, FrameEditorWrap, TapToEdit } from '../../primitives/styles/Frames/FrameEditorStyle';
import Phrase from '../../components/Phrase/Phrase';
import Konva from 'konva';


interface FrameEditorProps {
    frame: FrameTemplate;
    width: number;
    canvasRef: RefObject<any>;
}

Konva.pixelRatio = 1;

const FrameEditor: React.FC<FrameEditorProps>  = ({frame, width, canvasRef}) =>{
  
  // defaults
  if (!width) width=500;
  let imageUrl = './empty.png';

  const [editables, setEditables] = useState<FrameEditable[]>([]);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    setEditables(frame.editables) 
  }, [frame]);
 
  // canvas resizing
  const actualSize = 1700;
  const canvasScale = width / actualSize;
  const canvasSize = actualSize * canvasScale;


  if (!!frame) {
    imageUrl = frame.image;
  } 

  const [baseImage] = useImage(imageUrl, 'anonymous');
  
  
  if (!frame) {
    return (<></>) 
  }


  const toggleControls = () => {
    setShowControls(!showControls);
  }

  const hideControls = () => {
    setShowControls(false);
  }


  return (
    <>
        <FrameEditorWrap>
          <CanvasWrap style={{height: canvasSize, width: canvasSize}}>
              <Stage 
                width={1700} 
                height={1700} 
                className='main-canvas' 
                style={{transform: `scale(${canvasScale})`}} 
                ref={canvasRef} 
                onTap={toggleControls} 
                onClick={toggleControls}
              >
                <Layer>
                  <KonvaImage image={baseImage}></KonvaImage>
                  {editables.map((e, i) => {
                      switch (e.type) {
                      case 'radialText':
                          return (<RadialText data={e} key={`editable_${i}`}></RadialText>)
                      case 'text':
                          return (<Text data={e} key={`editable_${i}`}></Text>)
                      case 'image': 
                          return (<Image data={e} key={`editable_${i}`}></Image>)
                      default:
                          return (<></>)
                      }           
                  })}
                  </Layer>
              </Stage>
          </CanvasWrap>
          <TapToEdit>
            <Phrase
              translationKey="5c23fe73-3fec-4350-8ae8-96c67bb6da15"
              defaultText="tap to edit"
            />
          </TapToEdit>
        </FrameEditorWrap>

        {showControls && (
            <Controls editables={editables} setEditables={setEditables} closePopup={hideControls}></Controls>
        )}
    </>
  );
}

export default FrameEditor;
