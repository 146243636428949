import styled from 'styled-components';
import { devices } from '../Devices/devices';
import { global } from '../Global/global'

export const ChannelsList = styled.div`
  position: relative;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: contain;
  max-height: calc(300px * ${(props) => props.theme.ratio});
  padding-bottom: 100%;
  overflow-y: scroll;
  ${global.userSelectNone}
`;

export const ManageChannelsList = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: space-between;
  align-items: space-between;
  width: calc(150px * 2.25 * ${(props) => props.theme.ratio});
  max-height: calc(300px * ${(props) => props.theme.ratio});
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: 100%;
  overflow-y: scroll;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  overflow-x: hidden;
  ${global.userSelectNone}
`;

export const ManageChannelDetails = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(12px * ${(props) => props.theme.ratio});
  width: calc(120px * ${(props) => props.theme.ratio});
  height: calc(130px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}

`;

export const ManageChannelWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
  width: calc(150px * ${(props) => props.theme.ratio});
  height: calc(150px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}

`;

export const ManageChannelSharedIconWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(8px * ${(props) => props.theme.ratio});
  max-width: calc(18px * ${(props) => props.theme.ratio});
  max-height: calc(18px * ${(props) => props.theme.ratio});
  top: 0;
  left: 0;
  ${global.userSelectNone}
`;

export const ManageChannelMobileCheckbox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: -5px;
  ${global.userSelectNone}
`;

export const Channel = styled.div`
  display: flex;
  padding-bottom: 22px;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  margin-top: 22px;
  align-items: center;
  min-width: calc(285px * ${(props) => props.theme.ratio});
  min-height: calc(59px * ${(props) => props.theme.ratio});
  max-width: calc(285px * ${(props) => props.theme.ratio});
  max-height: calc(59px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
  // @media ${devices.mobile.portrait} {
  //   min-width: 285px;
  //   min-height: 59px;
  //   max-width: 285px;
  //   max-height: 59px;
  // }
  // @media ${devices.tablet.portrait} {
  //   min-width: calc(285px * ${(props) => props.theme.ratio});
  //   min-height: calc(59px * ${(props) => props.theme.ratio});
  //   max-width: calc(285px * ${(props) => props.theme.ratio});
  //   max-height: calc(59px * ${(props) => props.theme.ratio});
  // }
`;

export const ChannelInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(37px * ${(props) => props.theme.ratio});
  margin-left: calc(14.89px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
  // @media ${devices.mobile.portrait} {
  //   min-height: 37px;
  //   margin-left: 14.89px;
  // }
  // @media ${devices.tablet.portrait} {
  //   min-height: calc(37px * ${(props) => props.theme.ratio});
  //   margin-left: calc(14.89px * ${(props) => props.theme.ratio});
  // }
`;

export const ChannelName = styled.span.attrs(
  (props: { isTouched: boolean }) => ({
    isTouched: props.isTouched,
  })
)`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-weight: ${(props) => (props.isTouched ? 'bolder' : '390')};
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  font-size: calc(21px * ${(props) => props.theme.ratio});
  max-width: calc(228px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const Designs = styled.span`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-size: calc(15px * ${(props) => props.theme.ratio});
  font-weight: 390;
  font-style: normal;
  ${global.userSelectNone}
`;

export const ManageChannelName = styled.span`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-weight: bold;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  font-size: calc(15px * ${(props) => props.theme.ratio});
  max-width: calc(120px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;


