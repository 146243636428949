import styled from 'styled-components';
import { global } from '../Global/global';

export const ThumbnailSupperWrapper = styled.div`
width: fit-content;
min-height: calc(35px * ${(props) => props.theme.ratio});
display: flex;
flex-direction: column;
justify-content: space-between;
margin-bottom: calc(15px * ${(props) => props.theme.ratio});
`;
export const ThumbnailWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: raw;
  justify-content: flex-start;
  // margin-bottom: calc(15px * ${(props) => props.theme.ratio});
  padding-left:  calc(5px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;
export const Thumbnail = styled.img`
  filter: grayscale(100%);
  background: ${(props) => props.theme.palette.white};
  border: 2px solid #18191d;
  box-sizing: border-box;
  border-radius: 50%;
  margin-left: -5px;
  width: calc(28.13px * ${(props) => props.theme.ratio});
  height: calc(28.13px * ${(props) => props.theme.ratio});
`;

export const ThumbnailChannel = styled.img`
  filter: grayscale(100%);
  border: 2px solid #18191d;
  box-sizing: border-box;
  margin-left: -5px;
  width: calc(28.13px * ${(props) => props.theme.ratio});
  height: calc(25.13px * ${(props) => props.theme.ratio});
`;

export const ThumbnailLabel = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  color: ${(props) => props.theme.palette.white};
  border-radius: 30.7292px;
  width: fit-content;
  height: calc(32px * ${(props) => props.theme.ratio});
  font-size: calc(18px * ${(props) => props.theme.ratio});
  font-weight: calc(390px * ${(props) => props.theme.ratio});
  margin-left: calc(6px * ${(props) => props.theme.ratio});
`;

export const ThumbnailErrorLabel = styled.span`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  color: ${(props) => props.theme.palette.red};
  border-radius: 30.7292px;
  width: 80vw;
  height: calc(32px * ${(props) => props.theme.ratio});
  font-size: calc(12px * ${(props) => props.theme.ratio});
  font-weight: calc(390px * ${(props) => props.theme.ratio});
  // margin-left: calc(6px * ${(props) => props.theme.ratio});
`;

export const ThumbnailOverflow = styled.span`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-size: calc(16px * ${(props) => props.theme.ratio});
  padding-top: calc(7px * ${(props) => props.theme.ratio});
  font-weight: calc(390px * ${(props) => props.theme.ratio});
`;
