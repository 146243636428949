import React, { useEffect } from 'react';
import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loader';
import NoDataMessage from '../../components/Common/NoDataMessage';
import ConnectionError from '../../components/Errors/ConnectionError';
import ThumbnailWidget from '../../components/ThumbNails/ThumbnailWidget';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';

import { useState } from 'react';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { SelectRipples } from '../../store/ripplesSlice';
import { SelectLocationId } from '../../store/locationSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SelectOrgId, SelectCanAssign } from '../../store/userSlice';
import { SelectTotalSteps } from '../../store/flowSlice';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import Phrase from '../../components/Phrase/Phrase';
import { MobileChannelsTitle, SelectionGroup, SelectionGroupType, SelectionIcon, TimeLimitRadioSelectorButton, TimeLimitRadioSelectorIndicator, TimeLimitRadioSelectorLabel, TimeRadioSelectorGroup, TimeRadioSelectorOption } from '../../primitives/styles/Publish/Publish';
import { CHANNELS_ORDER } from '../Channel/PublishButtons';
import { MobileIcon } from '../../primitives/media/Icons';
import Checkbox from '../../components/Common/Checkbox';
import { Divider } from '@material-ui/core';
import FloatingButton from '../../components/Common/FloatingButton';
import { createAndPublishFrames, publishFramesToSelectedApps } from '../../services/frames.service';
import { SetNotificationName } from '../../store/notificationsSlice';
import getCampaignByLocationId from '../../api/rest/getCampaignByLocationId';
import CampaignRunningAlert from '../../components/CampaignRunningAlert/CampaignRunningAlert';

interface AssignFrameProps {
    nextPath: () => void;
    currentStep: number;
    flowName: FlowName;
    path: string;
}

const AssignFrames: React.FC<AssignFrameProps> = ({
    currentStep,
    nextPath,
    flowName,
    path,
}) => {
    const dispatch = useDispatch();
    const ripples = useSelector(SelectRipples);
    const locationId: string = useSelector(SelectLocationId);
    const canAssign: boolean = useSelector(SelectCanAssign);
    const organization: string = useSelector(SelectOrgId);

    const isCreateFrame = flowName === 'createFrame';

    const [isLoading, setIsLoading] = useState(false);
    const [channelsOrder, setChannelsOrder] = useState(CHANNELS_ORDER.FIRST);
    const [shouldAssignToAppFrame, setShouldAssignToAppFrame] = useState(isCreateFrame)
    const [publishError, setPublishError] = useState(false as any);
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const handleCampaign = (campaign: any) => {
        if (!campaign || !campaign._doc) return setIsAlertOpen(false);

        setIsAlertOpen(campaign._doc.overwriteContent)
    }

    useEffect(() => {
        if (locationId && organization) {
            getCampaignByLocationId(locationId, organization)
                .then(({ data }) => data.data)
                .then(campaign => handleCampaign(campaign))
                .catch(() => {
                    setPublishError(true);
                });
        }
    }, [locationId, organization])

    const handleAlertClick = () => {
        setIsAlertOpen(false)
    };

    const totalSteps = useSelector(SelectTotalSteps);

    const handlePublishError = (error?: { errorTitle: string, errorContent: string }) => {
        if (error) {
            setPublishError(error)
        } else {
            setPublishError(true);
        }
        setIsLoading(false);
    };

    const handleCreateAndPublishFrames = async (
    ) => {
        try {
            if (!navigator.onLine) {
                handlePublishError();
                return;
            }
            setIsLoading(true);
            await createAndPublishFrames({
                organization,
                frames: ripples,
                locationId,
                channelsOrder,
                shouldPublish: shouldAssignToAppFrame
            })

            dispatch(SetNotificationName({
                clientWarningMessage: '',
                notificationName: flowName,
            }));
            nextPath();
        } catch (err) {
            handlePublishError();
        }
    };

    const handlePublishFrames = async () => {
        try {
            if (!navigator.onLine) {
                handlePublishError();
                return;
            }
            setIsLoading(true);
            await publishFramesToSelectedApps({
                organization,
                frames: ripples.map(({ id }) => id),
                locationId,
                channelsOrder
            })

            dispatch(SetNotificationName({
                clientWarningMessage: '',
                notificationName: flowName,
            }));
            nextPath();
        } catch (err) {
            handlePublishError();
        }
    }

    const onConnectionErrorClose = () => {
        setPublishError(false);
    };

    if (!canAssign) {
        return (
            <Container>
                <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow={flowName as string} />
                <NoDataMessage
                    useIcon={false}
                    isSubtextLink={true}
                    defaultText={'You don’t have permission to perform this action'}
                    text={'08988c8f-8f4b-4413-8a48-e3496f4a4b40'}
                // You don’t have permission to perform this action
                />
            </Container>
        );
    }

    if (isLoading) {
        return (
            <Container>
                <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow={flowName as string} />
                <Loading />
            </Container>
        );
    }

    return (
        <Container>
            <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow={flowName as string} />
            {isAlertOpen && <CampaignRunningAlert onClick={handleAlertClick} />}
            <Wrapper style={{ width: '85%' }}>
                <ThumbnailWidget ripples={ripples} />
                <Title
                    type="flow"
                    size={fontSize.MID_LARGE}
                    text={titles.ASSIGN_TO_CHANNEL}
                    defaultText={'Assign Frame'}
                />
                {isCreateFrame && <React.Fragment>
                    <MobileChannelsTitle>
                        <Phrase
                            translationKey={'05ebd027-8074-4aad-84d3-3f1005711130'}
                            defaultText={'Frame will be uploaded to ‘My Frames’ and to the frames in the WebApp'}
                        />
                    </MobileChannelsTitle>
                    <SelectionGroupType>
                        <SelectionGroup>
                            <Checkbox
                                isDisabled={false}
                                isChecked={shouldAssignToAppFrame}
                                onChange={() => setShouldAssignToAppFrame(shouldAssignToAppFrame => !shouldAssignToAppFrame)}
                                defaultLabel={'Assign to WebApp Frames'}
                                label={'68259675-eedc-4d2b-8248-29769871d837'}
                                size="medium"
                            />
                        </SelectionGroup>
                    </SelectionGroupType>
                </React.Fragment>}
                {
                    (!isCreateFrame || shouldAssignToAppFrame) && <React.Fragment>
                        {shouldAssignToAppFrame && <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.12)', margin: '10px 0' }} />}
                        <MobileChannelsTitle>
                            <Phrase
                                defaultText='Organize Frames in the WebApp'
                                translationKey='cb18b429-0f75-4e1e-b16f-997bfa0dc0a5'
                            />
                        </MobileChannelsTitle>
                        <TimeRadioSelectorGroup>
                            <TimeRadioSelectorOption onClick={() => setChannelsOrder(CHANNELS_ORDER.FIRST)}>
                                <TimeLimitRadioSelectorButton>
                                    {channelsOrder === CHANNELS_ORDER.FIRST && <TimeLimitRadioSelectorIndicator />}
                                </TimeLimitRadioSelectorButton>
                                <TimeLimitRadioSelectorLabel>
                                    <Phrase
                                        translationKey={'4a20e92a-d124-4479-8a38-73ecd4e9dff6'}
                                        defaultText={'Set as First Frame'}
                                    />
                                </TimeLimitRadioSelectorLabel>
                            </TimeRadioSelectorOption>
                            <TimeRadioSelectorOption onClick={() => setChannelsOrder(CHANNELS_ORDER.LAST)}>
                                <TimeLimitRadioSelectorButton>
                                    {channelsOrder === CHANNELS_ORDER.LAST && <TimeLimitRadioSelectorIndicator />}
                                </TimeLimitRadioSelectorButton>
                                <TimeLimitRadioSelectorLabel>
                                    <Phrase
                                        translationKey={'8b0f0185-85c3-4e61-b9ac-6157dee0b9b6'}
                                        defaultText={'Set as Last Frame'}
                                    />
                                </TimeLimitRadioSelectorLabel>
                            </TimeRadioSelectorOption>
                        </TimeRadioSelectorGroup>
                    </React.Fragment>
                }
                <FloatingButton
                    isDisabled={false}
                    inProgress={isLoading}
                    position={'fixed'}
                    variant={'contained'}
                    onClick={isCreateFrame ? handleCreateAndPublishFrames : handlePublishFrames}
                    buttonText={'aaa7bd0f-1f5e-4b4f-a125-cd599a91f46d'}
                    buttonDefaultText={'PUBLISH'}
                />
            </Wrapper>
            {(publishError && typeof publishError === 'boolean') && <ConnectionError onClose={onConnectionErrorClose} />}
            {(publishError && typeof publishError === 'object') && (
                <ConnectionError
                    onClose={onConnectionErrorClose}
                    error={publishError.errorContent}
                    errorTitle={publishError.errorTitle}
                />
            )}

        </Container>
    );
};

export default withAuthenticationRequired(AssignFrames, {
    onRedirecting: () => <Loading />,
});
