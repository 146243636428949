import * as React from 'react';
import RippleMakers from './RippleMakers';

import { sleep } from '../../utils/sleep';
import { useState, useEffect } from 'react';
import { Location as Wrapper, LocationName, LocationAddress } from '../../primitives/styles/Location/Location';
import { Location as LocationType } from '../../primitives/types/Location';

interface LocationProps {
  location: LocationType
  handleOpenModal: (location: LocationType) => void;
  handleSelectLocation: (location: LocationType) => void
}

const Location: React.FC<LocationProps> = ({ location, handleOpenModal, handleSelectLocation }) => {

  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if(isTouched) {
      sleep(100).then(() => handleSelectLocation(location))
    }
  },[isTouched, handleSelectLocation, location])

  const handleOnClick = (e: any) => {
    setIsTouched(true);
  }
  return (
    <>
      <Wrapper
        id={location.id}
        isTouched={isTouched}
        onClick={handleOnClick}
      >
        <LocationName id={location.name} isTouched={isTouched}> 
          {location.name} 
        </LocationName>
        <LocationAddress>
            {`
            ${location.country}, 
            ${location.address}, 
            ${location.city}
            `}
        </LocationAddress>
        <RippleMakers  
          id={`${location.id}-rippleMakers`}  
          rippleMakers={location.rippleMakers} 
          handleOpenModal={() => handleOpenModal(location)}
        />
      </Wrapper>
    </>
  );
};

export default Location;
