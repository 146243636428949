import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NotificationName = 'upload' | 'create' | 'publish' | 'delete' | 'qr' | 'frames' | 'publishFrames' | 'createFrame' | 'qrcode' | 'channels' | 'publishChannels' | 'unpublishChannels' | 'location' | 'createLocation' | 'aboutYourPlace' | 'mobileChannels' | 'createOrg' | null;
interface NotificationsState {
  name: NotificationName;
  clientWarningMessage: string | undefined;
}

export const initState: NotificationsState = {
  name: null,
  clientWarningMessage: ''
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initState,
  reducers: {
    SetNotificationName(state, action: PayloadAction<{ notificationName: NotificationName, clientWarningMessage?: string }>) {
      const { notificationName, clientWarningMessage } = action.payload;
      state.name = notificationName;
      state.clientWarningMessage = clientWarningMessage;
    },

    ResetNotificationsState(state) {
      state.name = initState.name;
      state.clientWarningMessage = initState.clientWarningMessage;
    },
  },
});

export const {
  SetNotificationName,
  ResetNotificationsState,
} = notificationsSlice.actions;

export const SelectNotification = (state: any): { name: string, clientWarningMessage: string | undefined} =>
  state.notifications;

export default notificationsSlice.reducer;
