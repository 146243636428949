import styled from 'styled-components';
import { global } from '../Global/global';

export const LandscapeError = styled.div`
  background: ${(props) => props.theme.palette.red};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.theme.deviceHeight}px;
  width: 100vw;
  ${global.userSelectNone}
  `