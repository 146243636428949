import { Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useState, useRef, useEffect } from 'react';
import { FrameEditable } from '../../../primitives/types/FrameTemplate';
import Konva from 'konva';

const getScaledDimensions = (originalWidth: number, originalHeight: number, maxWidth: number, maxHeight: number): number[] => {
    const aspectRatio = originalWidth / originalHeight;
  
    if (originalWidth <= maxWidth && originalHeight <= maxHeight) {
      return [originalWidth, originalHeight];
    }
  
    let newWidth = maxWidth;
    let newHeight = newWidth / aspectRatio;
  
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }
  
    return [newWidth, newHeight];
  }


const getImageDimensions = (dataUrl: string): Promise<{width: number, height: number}> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
  
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
  
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
  
      img.src = dataUrl;
    });
  };  
  
interface ImageLayerProps {
  data: FrameEditable;
}  

const ImageLayer: React.FC<ImageLayerProps> = ({data}) => {

    const [imageUrl, setImageUrl] = useState('/frames/default-image.png');
    const [imageHeight, setImageHeight] = useState(224);
    const [imageWidth, setImageWidth] = useState(692);

    if (!!data.imageUrl && data.imageUrl !== imageUrl) {
        setImageUrl(data.imageUrl);
        getImageDimensions(data.imageUrl).then(dimensions => {
            setImageWidth(dimensions.width);
            setImageHeight(dimensions.height);
        });
    } 
    
    const [imageElem] = useImage(imageUrl);
    const imageRef = useRef<Konva.Image | null>(null);


    useEffect(() => {
      if (imageElem && imageRef.current) {
        imageRef.current.cache();
      }
    }, [imageElem]);



    if (!data.maxWidth || !data.maxHeight || !data.x || !data.y) return (<></>);


    const [scaledWidth, scaledHeight] = getScaledDimensions(imageWidth, imageHeight, data.maxWidth, data.maxHeight);

    
    const posX = data.x - (scaledWidth / 2);
    const posY = data.y - (scaledHeight / 2);
    
    return (
        <KonvaImage 
            ref={imageRef}
            image={imageElem}
            x={posX}
            y={posY}
            width={scaledWidth}
            height={scaledHeight}
            filters={[Konva.Filters.Grayscale]}
        />
    );
}

export default ImageLayer;
