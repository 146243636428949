import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import WrappedApp from './AppWrapper';

ReactDOM.render(
    <WrappedApp/>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
reportWebVitals();
