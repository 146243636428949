export const CREATE_CHANNEL = `
  mutation createChannel($name: String!, $icon: String!, $organization: String!) {
    createChannel(input: { clientMutationId: "1", name: $name, icon: $icon, organization: $organization, isShareable: true }) {
      clientMutationId
      channel {
        id
        name
        icon
      }
    }
  }
`;

