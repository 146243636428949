import Title from '../../../../components/Title/Title'

import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, LocationImage, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import RippleMakerSrc from '../../../../primitives/media/Onboarding/RippleMaker.png'
import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Phrase from '../../../../components/Phrase/Phrase';

interface LetsMoveOnProps {
  theme?: any;
  isContentFlow: boolean;
}

const LetsMoveOn: React.FC<LetsMoveOnProps> = ({
  theme = {},
  isContentFlow
}) => {
  const { ratio = 1 } = theme;

  return (
    <Container>
      <BreadcrumbsHeader currentStep={0} totalSteps={0} shouldRenderRippesIcon={true} shouldRenderBack={false} shouldRenderClose={false}/>
      <OnboardingContainer>
      <Title type="main" text={TITLES.MOVE_ON} defaultText={'Let’s move on'} size={'extra_large'} />
      <SubTitle style={{textAlign: 'center'}}>
        <Phrase defaultText={isContentFlow ? 'Return to your Ripple Maker to view your updated content': 'Continue the installation process on the Ripple Maker\'s screen'} translationKey={isContentFlow ? '5007bc6b-f5f2-4181-b108-f93e361a8a63' : 'd8e341c9-c2dc-488c-97cd-e7418e75ceab'}/>
      </SubTitle>
      <LocationImage src={RippleMakerSrc} alt='' style={{marginTop: `calc( 100px * ${ratio})`, width: `calc( 300px * ${ratio})`, height: `calc( 200px * ${ratio})`}}/>
      </OnboardingContainer>
    </Container>
  );
};

export default LetsMoveOn;