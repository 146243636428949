import Ripple from './Ripple';
import Loading from '../Loading/Loader';
import NoDataMessage from '../Common/NoDataMessage';
import CircularProgress from '@material-ui/core/CircularProgress';

import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { RippleByTag } from '../../primitives/types/Ripples';
import { useScrollDetector } from '../../providers/ScrollDetector';
import {
  EmptyRipplesList,
  RipplesList as Wrapper,
  RipplesLoadingPlaceholder,
} from '../../primitives/styles/Ripples/RipplesStyle';


interface RippleProps {
  tags: string[]
  selectAll: boolean;
  isLoading: boolean;
  canAssign: boolean;
  loadingMore?: boolean;
  ripples: RippleByTag[];
  ripplesTotalCount: number;
  selectedRipplesList: RippleByTag[];
  loadMore: (cursor: string) => void;
  onLongPress: (rippleId: string) => void;
  addToSelectedRipplesList: (ripple: RippleByTag) => void;
  removeFromSelectedRipplesList: (ripple: RippleByTag) => void;
}

const RipplesList: React.FC<RippleProps> = ({
  removeFromSelectedRipplesList,
  addToSelectedRipplesList,
  selectedRipplesList,
  ripplesTotalCount,
  onLongPress,
  loadingMore,
  isLoading,
  selectAll,
  canAssign,
  loadMore,
  ripples,
  tags
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  /*
    useInView returns a ref that is passed to the element we want to 
    track, and an inView:boolean  state that is set to true whenever 
    our element is visible.
  */
  useEffect(() => {
    if (inView && ripplesTotalCount > ripples.length) {
      const { cursor } = ripples[ripples.length - 1];
      loadMore(cursor);
    }
    //eslint-disable-next-line
  }, [inView]);

  const withScroll = useScrollDetector();

  const handleLongPress = (rippleId: string) => {
    withScroll(onLongPress, rippleId);
  }

  if (canAssign && isLoading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }



  if (!canAssign) {
    return (
      <EmptyRipplesList>
        <NoDataMessage
          useIcon={true}
          isSubtextLink={false}
          defaultText={'You don’t have permission to perform this action'}
          text={"08988c8f-8f4b-4413-8a48-e3496f4a4b40"} 
        />
      </EmptyRipplesList>
    );
  }

  if (isEmpty(ripples) && isEmpty(tags)) {
    return (
      <EmptyRipplesList>
        <NoDataMessage
          useIcon={true}
          isSubtextLink={false}
          defaultText={'You have 0 designs in your content library'}
          text={'f4c142e3-bf3c-4a43-b736-0a3edb27bccd'}
        />
      </EmptyRipplesList>
    );
  } else if (isEmpty(ripples)) {
    return (
      <EmptyRipplesList>
        <NoDataMessage
          useIcon={true}
          isSubtextLink={false}
          defaultSubtext={'Try deleting or selecting other tags'}
          defaultText={'No designs match the selected tags'}
          subtext={'2e2c9d78-2afd-404a-978e-a5ce8cdcc3dc'}
          text={'af9c2016-068c-4b53-940e-4a0ce5ec4ee2'}
        />
      </EmptyRipplesList>
    );
  } else {
    return (
      <>
        <Wrapper>
          {ripples.map((ripple, index) => {
            if (index === ripples.length - 1) {
              return (
                <Ripple
                  rippleRef={ref}
                  ripple={ripple}
                  key={ripple.cursor}
                  selectAll={selectAll}
                  onLongPress={handleLongPress}
                  selectedRipplesList={selectedRipplesList}
                  addToSelectedRipplesList={addToSelectedRipplesList}
                  removeFromSelectedRipplesList={removeFromSelectedRipplesList}
                />
              );
            } else {
              return (
                <Ripple
                  ripple={ripple}
                  key={ripple.cursor}
                  selectAll={selectAll}
                  onLongPress={handleLongPress}
                  selectedRipplesList={selectedRipplesList}
                  addToSelectedRipplesList={addToSelectedRipplesList}
                  removeFromSelectedRipplesList={removeFromSelectedRipplesList}
                />
              );
            }
          })}
          {loadingMore && (
            <RipplesLoadingPlaceholder>
              <CircularProgress
                style={{ color: 'white', animationDuration: '0.5s' }}
              />
            </RipplesLoadingPlaceholder>
          )}
          {/* {ripplesTotalCount === ripples.length && (
            <RipplesLoadingPlaceholder>
              No more data to preview
            </RipplesLoadingPlaceholder>
          )} */} {/* this one requires a proper design */}
        </Wrapper>
      </>
    );
  }
};

export default RipplesList;
