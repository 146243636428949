import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loader';
import Checkbox from '../../components/Common/Checkbox';
import getTagsByOrgId from '../../api/rest/getTagsByOrgId';
import RipplesList from '../../components/Ripple/RipplesList';
import SelectedTags from '../../components/Ripple/SelectedTagsList';
import FloatingButton from '../../components/Common/FloatingButton';
import ConnectionError from '../../components/Errors/ConnectionError';
import AutocompleteSearch from '../../components/Common/AutocompleteNew';
import WarningModal from '../../components/Ripple/WarningModal/WarningModal';
import RippleInfoModal from '../../components/Ripple/InfoModal/RippleInfoModal';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';
import RippleWalkThroughModal from '../../components/Ripple/RippleWalkThroughModal';

import { get, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { gqlClient } from '../../utils/gqlClient';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { getCookie, setCookie } from '../../utils/cookies';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { GET_CHANNELS } from '../../api/graphQl/query/getChannels';
import { SetNotificationName } from '../../store/notificationsSlice';
import { GET_RIPPLE_INFO } from '../../api/graphQl/query/getRippleInfo';
import { RippleInfo, RippleByTag } from '../../primitives/types/Ripples';
import {
  SelectOrgId,
  SelectOrgName,
  SelectCanAssign,
} from '../../store/userSlice';
import { FIND_RIPPLES_BY_TAGS } from '../../api/graphQl/query/findRipplesByTags';
import { INVALIDATE_RIPPLES } from '../../api/graphQl/mutation/invalidateRipples';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import {
  HeadingWrapper,
  DetailsWrapper,
  // DesignLibraryTitle,
} from '../../primitives/styles/Ripples/RipplesStyle';

const COOKIE_NAME = 'isRemoveRipplesPageVisited';
const RIPPLES_INFO_PATH = 'viewer.organizationScope.ripple';
const RIPPLES_DATA_PATH = 'viewer.organizationScope.ripples';
const RIPPLES_PATH = 'viewer.organizationScope.ripples.edges';
const CHANNELS_PATH = 'viewer.organizationScope.channels.edges';
const LOCATIONS_PATH = 'viewer.organizationScope.locations.edges';

interface RemoveRipplesContainerProps {
  path: string;
  flowName: FlowName;
  currentStep: number;
  nextPath: () => void;
}
const RemoveRipplesContainer: React.FC<RemoveRipplesContainerProps> = ({
  flowName,
  nextPath,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(SelectOrgId);
  const orgName = useSelector(SelectOrgName);
  const canAssign: boolean = useSelector(SelectCanAssign);
  // const keyboardIsOpen = useSelector(SelectDeviceKeyboardIsOpen);

  const locationId = '';
  const getAllLocations: boolean = true;
  const isMobileApp: boolean = canAssign;
  const isRippleMakerApp: boolean = canAssign;
  /*
    since these params are passed as props to child components
    which expect specific types, we cannot set them to null.
    therefore we initialize the state as an array of the desired type  
  */
  /*******************************************************************/
  const stringArray: string[] = [];
  const initRippleState: RippleByTag[] = [];
  const initRippleInfoState: RippleInfo[] = [];
  /******************************************************************/

  /* 
    detect if keyboard is open on mobile device to prevent the floating button
    from being pushed up, as QA consider it a BUG!!!
  */

  const [tags, setTags] = useState(stringArray);
  const [isLoading, setIsLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [orgTags, setOrgTags] = useState(stringArray);
  const [loadingMore, setLoadingMore] = useState(false);
  const [ripples, setRipples] = useState(initRippleState);
  const [lastPageCursor, setLastPageCursor] = useState('');
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const [ripplesTotalCount, setRipplesTotalCount] = useState(0);
  const [connectionError, setConnectionError] = useState(false);
  const [rippleInfo, setRippleInfo] = useState(initRippleInfoState);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openRippleInfoModal, setOpenRippleInfoModal] = useState(false);
  const [openWalkThroughModal, setOpenWalkThroughModal] = useState(false);
  const [selectedRipplesList, setSelectedRipplesList] = useState(
    initRippleState
  );

  const handleCloseConnectionError = () => {
    setConnectionError(false);
  };

  const onConnectionError = () => {
    setConnectionError(true);
    setIsLoading(false)
  }

  const handleSetKeyboardOpen = (isOpen: boolean) => {
    setKeyboardIsOpen(isOpen);
  };

  /* 
    remove ripples query
  */
  const removeRipples = (ids: string[]) =>
    gqlClient.mutate(INVALIDATE_RIPPLES, { organization: orgId, ids });

  /* 
    open the pre delete warning modal
  */
  const onRemoveButtonPressed = () => {
    setOpenWarningModal(true);
  };

  /* 
    safely close the pre delete warning modal with no actions
  */
  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  /* 
    the onSubmit function that is passed to the warning modal.
    executed when the user press 'YES'
  */
  const submitRemoveRipplesSelection = () => {
    setInProgress(true)
    const ids: string[] = selectedRipplesList.map((rpl, index) => {
      return rpl.node.id;
    });
    removeRipples(ids)
      .then(({ data }) => data) // do something with data ...
      .then(() => {
        dispatch(SetNotificationName({notificationName: flowName}));
        nextPath();
      })
      .catch(onConnectionError);
  };

  /*
   the gets the info for the selected (long press) ripple 
   from the shared ripples library
  */
  const getRippleInfo = (rippleId: string) => {
    return gqlClient
      .query(GET_RIPPLE_INFO, {
        orgId,
        rippleId,
        canViewOnlyOwn: false,
      })
      .catch(onConnectionError);
  };

  /* 
    the get channels query. we need the derived data 
    to extract the channels and locations names where the ripple is published
  */
  const getChannels = () =>
    gqlClient
      .mutate(GET_CHANNELS, {
        orgId,
        canAssign,
        locationId,
        isMobileApp,
        getAllLocations,
        isRippleMakerApp,
      })
      .catch(onConnectionError);

  /* 
    we pass here the data derived from the query above and the selected rippleId (long press)
    and iterate through the channels and locations to get the required data
  */
  const filterChannelsAndLocationsByRippleId = (
    data: any,
    rippleId: string
  ) => {
    const channels = get(data, CHANNELS_PATH).map((ch: any) => ch.node);
    const locations = get(data, LOCATIONS_PATH).map((loc: any) => loc.node);
    const channelsWithRipple = channels.filter((ch: any) =>
      ch.ripplesOrder.includes(rippleId)
    );
    const locationsWithRipple = locations.reduce((acc: string[], curr: any) => {
      channelsWithRipple.forEach((ch: any) => {
        if (
          curr.apps.channels.some(({channel}: any) => channel.id === ch.id)
        ) {
          acc.push(curr.name);
        }
      });
      return acc;
    }, []);
    return {
      channels: channelsWithRipple.map((ch: any) => ch.name),
      locations: locationsWithRipple,
    };
  };
  /*
    this function is fired once a long press on ripple is detected
    it will first open the modal and then populate it with data
    meanwhile, a nice loader is rendered 
  */
  const handleDisplayRippleInfo = (rippleId: string) => {
    setOpenRippleInfoModal(true);
    getChannels()
      .then(({ data }) => filterChannelsAndLocationsByRippleId(data, rippleId))
      .then(({ channels, locations }) => {
        getRippleInfo(rippleId)
          .then(({ data }) => get(data, RIPPLES_INFO_PATH))
          .then((rippleInfo: RippleInfo) => {
            rippleInfo.channels = channels;
            rippleInfo.locations = locations;
            setRippleInfo([rippleInfo]);
          })
          .catch(onConnectionError);
      })
      .catch(onConnectionError);
  };

  // the ripple info modal close handler
  const handleCloseRippleInfoModal = () => {
    setOpenRippleInfoModal(false);
    setRippleInfo([]);
  };

  // add ripple to list when selected
  const addToSelectedRipplesList = (ripple: RippleByTag) => {
    setSelectedRipplesList((selectedRipples) => [...selectedRipples, ripple]);
  };

  // remove the unselected ripple from the selected ripples list
  const removeFromSelectedRipplesList = (ripple: RippleByTag) => {
    const newRipplesState = selectedRipplesList.filter(
      (r) => r.node.id !== ripple.node.id
    );
    setSelectedRipplesList(newRipplesState);
  };

  // returns a promise to get the ripples from shared library
  const getRipplesByTags = (variables: any) => {
    return gqlClient.query(FIND_RIPPLES_BY_TAGS, variables);
  };

  // the implementation of the get ripple promise with updated variables
  const getAndSetRipples = () => {
    const variables = {
      tags: tags,
      orgId: orgId,
      pageSize: 60,
      lastPageCursor: '',
      canViewOnlyOwn: false,
    };
    getRipplesByTags(variables)
      .then(({ data }) => get(data, RIPPLES_DATA_PATH))
      .then((ripplesData) => {
        const ripples: RippleByTag[] = ripplesData.edges;
        const totalCount: number = ripplesData.totalCount;
        setRipples(ripples);
        setRipplesTotalCount(totalCount);
        setIsLoading(false);
      })
      .catch(onConnectionError);
  };

  // load more ripples when the target ripple is visible
  const loadMore = (cursor: string) => {
    setLastPageCursor(cursor);
  };

  // adds the selected tag to the list.
  const handleSelectTags = (
    selectedTag: { tag: string; count: number } | null
  ) => {
    if (selectedTag) {     
      const { tag } = selectedTag;
      if (tags.indexOf(tag) === -1) {
        setTags((prevTags) => [...prevTags, tag]);
      }
    }
  };

  // removes specific tag from the list on click
  const handleRemoveTag = (selectedTag: string) => {
    const newState = tags.filter((tag) => tag !== selectedTag);
    setTags(newState);
  };

  /*
    the walk through modal will open if the user did not visit this page
    for at least 60 days. Once the 'GOT IT' button is pressed, we set
    the cookie for this page and close the modal
  */
  const closeWalkThroughModal = () => {
    const cookie = { [COOKIE_NAME]: true };
    const validThrough = 60; /*days*/
    setCookie(COOKIE_NAME, cookie, validThrough);
    setOpenWalkThroughModal(false);
  };

  /*
    on page load we first check if the page has been visited
    if not, we first render the walk Through modal.
    otherwise, we "refresh" the cookie to be valid for 60 days
  */
  useEffect(() => {
    const cookie = getCookie(COOKIE_NAME);
    const hasBeenVisited = cookie[COOKIE_NAME];
    if (!hasBeenVisited) {
      setOpenWalkThroughModal(true);
    } else {
      //extend the cookie
      const validThrough = 60; /*days*/
      setCookie(COOKIE_NAME, cookie, validThrough);
    }
  }, []);

  // initialize the ripple container
  useEffect(() => {
    if (canAssign) {
      setIsLoading(true);
      getAndSetRipples();
      getTagsByOrgId(orgId)
        .then(({ data }) => setOrgTags(data))
        .catch(onConnectionError);
    }
    // eslint-disable-next-line
  }, []);

  // refetch ripples if tags added or removed
  useEffect(() => {
    if (canAssign) {
      setIsLoading(true);
      getAndSetRipples();
    }
    // eslint-disable-next-line
  }, [tags]);

  /* 
    when the lastPageCursor is changed (the last ripple from the list is reached by scrolling down) 
    we run the getRipplesByTags query to fetch the next page
  */
  useEffect(() => {
    if (lastPageCursor) {
      const variables = {
        tags,
        orgId,
        pageSize: 60,
        lastPageCursor,
        canViewOnlyOwn: false,
      };
      setLoadingMore(true);
      getRipplesByTags(variables)
        .then(({ data }) => get(data, RIPPLES_PATH))
        .then((ripples: RippleByTag[]) =>
          setRipples((prev) => {
            return prev.concat(ripples);
          })
        )
        .then(() => {
          setLoadingMore(false);
        })
        .catch(onConnectionError);
    }
    // eslint-disable-next-line
  }, [lastPageCursor]);

  // handles the select / unselect all
  useEffect(() => {
    if (selectAll) {
      setSelectedRipplesList(ripples);
    } else {
      setSelectedRipplesList(initRippleState);
    }
    // eslint-disable-next-line
  }, [selectAll]);

  /* 
    if loading more ripples while 'Select all' is checked, make sure to add
    all ripples to the selected ripples list
  */
  useEffect(() => {
    if (selectAll) {
      setSelectedRipplesList(ripples);
    }
    // eslint-disable-next-line
  }, [ripples]);

  const showFloatingButton = !isEmpty(selectedRipplesList) && !keyboardIsOpen;
  const isModalOpen =
    openWalkThroughModal || openRippleInfoModal || openWarningModal;

  return (
    <>
      <Container isModalOpen={isModalOpen}>
        <BreadcrumbsHeader totalSteps={0} currentStep={0} />
        <Wrapper>
          <HeadingWrapper>
            <Title 
              type="flow" 
              size={fontSize.MID_LARGE} 
              text={titles.DELETE} 
              defaultText={'Select design to delete'} 
            />
            <AutocompleteSearch
              id="org-tags"
              options={orgTags}
              displayedProp={'tag'}
              handleSelectOption={handleSelectTags}
              handleSetKeyboardOpen={handleSetKeyboardOpen}
              label="ddcbdca4-f329-4a98-9491-1f75017319c8" // Search by tags
              defaultLabel={'Search by tags'}
            />
            {!isEmpty(tags) && (
              <SelectedTags tags={tags} handleRemoveTag={handleRemoveTag} />
            )}
            <DetailsWrapper>
              {/* <DesignLibraryTitle>{orgName}</DesignLibraryTitle> */}
              <Checkbox
                onChange={setSelectAll}
                isChecked={selectAll}
                isDisabled={false}
                label={'8c180522-c43e-4204-bbd1-755e4b6aa22b'} // Select All
                defaultLabel={'Select all'}
              />
            </DetailsWrapper>
          </HeadingWrapper>
          <RipplesList
            tags={tags}
            ripples={ripples}
            loadMore={loadMore}
            canAssign={canAssign}
            isLoading={isLoading}
            selectAll={selectAll}
            loadingMore={loadingMore}
            ripplesTotalCount={ripplesTotalCount}
            selectedRipplesList={selectedRipplesList}
            onLongPress={handleDisplayRippleInfo}
            addToSelectedRipplesList={addToSelectedRipplesList}
            removeFromSelectedRipplesList={removeFromSelectedRipplesList}
          />
          {showFloatingButton && (
            <FloatingButton
              onClick={onRemoveButtonPressed}
              isDisabled={false}
              variant={'contained'}
              position={'fixed'}
              buttonText={'219a55a8-6201-4fda-81ed-df7215848308'} // delete
              buttonDefaultText={'DELETE'}
            />
          )}
          {openRippleInfoModal && (
            <RippleInfoModal
              isOwnRipple={true}
              rippleInfo={rippleInfo}
              closeModal={handleCloseRippleInfoModal}
            />
          )}
          {openWalkThroughModal && (
            <RippleWalkThroughModal
              detailsTitle={orgName}
              modalTitle={titles.DELETE}
              modalDefaultTitle={'Select design to delete'}
              closeModal={closeWalkThroughModal}
            />
          )}
          {openWarningModal && (
            <WarningModal
              inProgress={inProgress}
              count={selectedRipplesList.length}
              closeModal={handleCloseWarningModal}
              onSubmit={submitRemoveRipplesSelection}
            />
          )}
          {connectionError && (
            <ConnectionError onClose={handleCloseConnectionError} />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default withAuthenticationRequired(RemoveRipplesContainer, {
  onRedirecting: () => <Loading />,
});
