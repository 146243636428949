import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SelectedManageChannels {
  channels: string[]
}


export const initState: SelectedManageChannels = {
    channels: []

};

const manageChannelSlice = createSlice({
  name: 'manageChannels',
  initialState: initState,
  reducers: {
    SetManageChannels( state, action: PayloadAction<{ channels: string[] }> ) {
      state.channels = action.payload.channels
    },

    ResetManageChannelState(state) {
      state.channels = initState.channels
    },
  },
});

export const { SetManageChannels, ResetManageChannelState } = manageChannelSlice.actions;

export const SelectManageChannels = (state: any): string[] => state.manageChannels.channels;

export default manageChannelSlice.reducer;
