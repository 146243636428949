export const UNPUBLISH_CHANNELS = `
  mutation unpublishChannels(
    $organization: String!
    $locationIds: [String]!
    $channelIds: [String]!
  ) {
    unpublishChannels(
      input: {
        clientMutationId: "1"
        organization: $organization
        rippleMakerLocations: $locationIds
        ids: $channelIds
      }
    ) {
      clientWarningMessage
    }
}
`;