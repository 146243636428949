
import Phrase from '../Phrase/Phrase';

import { useEffect } from 'react';
import { WarningIcon } from '../../primitives/media/Icons';
import {
  RippleInfoDetail,
  RippleWarningModalHeader,
} from '../../primitives/styles/Ripples/RipplesStyle';

import {
  ErrorWarningMsg,
  ErrorModalWrapper,
  ErrorModalContent,
  ErrorModalColumnTitle,
  DesignLimitErrorDetailsWrapper,
} from '../../primitives/styles/Common/CommonStyle'
import LocationButton from '../../containers/Onboarding/components/LocationButton';

interface ErrorProps {
    orgName?: string
    onClose: () => void;
    isContentFlow: boolean;
    theme?: any
}

const OnboardingError: React.FC<ErrorProps> = ({onClose, orgName = '', theme, isContentFlow}) => {
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });

  return (
    <ErrorModalWrapper>
      <ErrorModalContent>
        <RippleWarningModalHeader>
          <ErrorModalColumnTitle>
            <WarningIcon />
            {isContentFlow ? 
            <Phrase
            translationKey={'df15e368-77f4-49b5-bb41-c88a24943dbf'}
            defaultText={'No permissions'}
          /> :
            <Phrase
              translationKey={'9d953769-950c-4061-b646-ba6a045d1690'}
              defaultText={'Onboarding failed'}
            />}
          </ErrorModalColumnTitle>
        </RippleWarningModalHeader>
        <DesignLimitErrorDetailsWrapper>
          <RippleInfoDetail>
            <ErrorWarningMsg style={{textAlign: 'left'}}>
              <Phrase
                translationKey={'ff994431-aafd-4b69-a39c-59656f75c32f'}
                defaultText={`Only a ${orgName} administrator can complete this step.`}
                vars={{ ORG_NAME: orgName }}
              />
            </ErrorWarningMsg>
            <ErrorWarningMsg style={{textAlign: 'left'}}>
                <Phrase
                  translationKey={'bcbdacdd-db0c-4910-a544-03fdf97df3e8'}
                  defaultText={'Please contact your organization administrator or support'}
                />
            </ErrorWarningMsg>
          </RippleInfoDetail>
        </DesignLimitErrorDetailsWrapper>
        <LocationButton onClick={onClose} style={{marginTop: `calc(15px * ${theme.ratio})`, marginBottom: `calc(15px * ${theme.ratio})`}}>
            <Phrase defaultText='GOT IT' translationKey='14c7cd6c-8397-4f6d-9176-21f425e23833'/>
        </LocationButton>
      </ErrorModalContent>
    </ErrorModalWrapper>
  );
};

export default OnboardingError;
