import QRcode from '../../components/QRcode/QRcode';
import CopyURL from '../../components/QRcode/CopyURL';
import Loading from '../../components/Loading/Loader';
import FloatingButton from '../../components/Common/FloatingButton';
import ConnectionError from '../../components/Errors/ConnectionError';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';

import { isIOS } from '../../utils/isIOS';
import { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import { SelectOrgId } from '../../store/userSlice';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { SelectTotalSteps } from '../../store/flowSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SetNotificationName } from '../../store/notificationsSlice';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import {
  getLocationById,
  getWebAppLocation,
} from '../../api/rest/getWebAppLocation';
import {
  ContentWrapper,
  LocationName,
} from '../../primitives/styles/QRcode/QRcodeStyles';
import {
  SelectLocationName,
  SelectLocationId,
} from '../../store/locationSlice';

interface QRContainerProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
  path: string;
  theme: any;
}

const QRcodeContainer: React.FC<QRContainerProps> = ({
  currentStep,
  flowName,
  nextPath,
  theme,
}) => {
  const dispatch = useDispatch();

  const orgId = useSelector(SelectOrgId);
  const locationId = useSelector(SelectLocationId);
  const totalSteps = useSelector(SelectTotalSteps);
  const locationName = useSelector(SelectLocationName);

  const [loading, setLoading] = useState(true);
  const [qrImageSrc, setQrImageSrc] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [locationUrl, setLocationUrl] = useState(null);
  const [connectionError, setConnectionError] = useState(false);
  const [canvasIsRendered, setCanvasIsRendered] = useState(false);
  const [logoImageIsLoaded, setLogoImageIsLoaded] = useState(false);

  const open = '485e8b0a-35f3-4076-b627-9e9c8bfb06eb';
  const download = '1affcc4f-07ef-4df3-b051-c35e7a47330f';

  /*
    useInView returns a ref that is passed to the element we want to 
    track, and an inView:boolean  state that is set to true whenever 
    our element is visible.
  */

  useEffect(() => {
    isIOS() ? setButtonText(open) : setButtonText(download);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orgId && locationId) {

          getLocationById(locationId, orgId)
            .then((location) => {
              const { isWebappEnabled } = location
              getWebAppLocation(locationId, isWebappEnabled)
                .then((data) => {
                  setLocationUrl(data);
                  handleSetLogoImageIsLoaded()
                })
                .catch((err) => setConnectionError(true));
            })
            .catch((err) => setConnectionError(true))
    }
  }, [orgId, locationId]);

  useEffect(() => {
    if (isIOS()) {
      const transformQrCodeCanvasToImage = () => {
        const canvas = document.getElementById(
          'qr-code-location-canvas'
        ) as HTMLCanvasElement;
        if (canvas) {
          const imgURL = canvas.toDataURL();
          setQrImageSrc(imgURL);
        }
      };
      transformQrCodeCanvasToImage();
    }
  }, [canvasIsRendered]);

  useEffect(() => {
    if (logoImageIsLoaded) {
      const interval = setInterval(() => {
        const canvas = document.getElementById(
          'qr-code-location-canvas'
        ) as HTMLCanvasElement;
        if (canvas) {
          setCanvasIsRendered(true);
          clearInterval(interval);
        }
      }, 800);
    }
  }, [logoImageIsLoaded]);

  useEffect(() => {
    return clearTimeout(undefined);
  });

  useEffect(() => {
    const isLoading =
      !connectionError && (locationUrl === null);
    setLoading(isLoading);
  }, [connectionError, locationUrl]);

  const handleSetLogoImageIsLoaded = () => {
    setLogoImageIsLoaded(true);
  };

  const handleCloseConnectionError = () => {
    setConnectionError(false);
    nextPath();
  };

  const downloadQRcode = () => {
    const fileName = `${locationName}.png`;
    const link = document.createElement('a');
    const canvas = document.getElementById(
      'qr-code-location-canvas'
    ) as HTMLCanvasElement;
    canvas.toBlob((blob) => {
      if(blob) {
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    });
    dispatch(SetNotificationName({notificationName: flowName}));
    nextPath();
  };

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container style={{ paddingTop: 10 * theme.ratio }}>
      <BreadcrumbsHeader currentStep={currentStep} totalSteps={totalSteps} />
      <Wrapper>
        <ContentWrapper>
          <LocationName>{locationName}</LocationName>
          <QRcode
            imgSrc={qrImageSrc}
            value={locationUrl || ''}
          />
          {<CopyURL value={locationUrl || ''} /> }
        </ContentWrapper>
        {!isIOS() && (
          <FloatingButton
            onClick={downloadQRcode}
            buttonText={buttonText}
            isDisabled={false}
            position={'fixed'}
            variant={'contained'}
            buttonDefaultText={'DOWNLOAD'}
          />
        )}
      </Wrapper>
      {connectionError && (
        <ConnectionError onClose={handleCloseConnectionError} />
      )}
    </Container>
  );
};

export default withAuthenticationRequired(withTheme(QRcodeContainer), {
  onRedirecting: () => <Loading />,
});
