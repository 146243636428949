import WizardContainer from './WizardContainer';
// import RipplesContainer from '../Ripples/RipplesContainer';
import ChannelContainer from '../Channel/ChannelContainer';
import CreateRippleContainer from '../Create/CreateRippleContainer';
import PublishContainer from '../PublishChannels/PublishChannelsContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';
const CreateWizardContainer: React.FC = () => {
  const initialSteps:Readonly<WizardSteps[]> = [
    {
      name: 'location',
      next: 'edit',
      component: SelectLocationContainer,
    },
    {
      name: 'edit',
      next: 'channel',
      component: CreateRippleContainer,
    },
    {
      name: 'channel',
      next: 'publish',
      component: ChannelContainer,
    },
    {
      name: 'publish',
      next: '/',
      component: PublishContainer,
    },
  ];
  return <WizardContainer initialSteps={initialSteps} flowName="create" />;
};

export default CreateWizardContainer;
