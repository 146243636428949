import * as React from 'react';
import Wizard from '../../components/Wizard/Wizard';
import { SubWizardWrapper as Wrapper } from '../../primitives/styles/Home/HomeStyle';
import { generateAppRoutes } from '../../constants/Routes';
import { FrameWizardWrapper as WizardWrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import {
  CreateIcon,
  FramesIcon,
} from '../../primitives/media/Icons';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';
import Phrase from '../../components/Phrase/Phrase';
import { MessageTextWide } from '../../primitives/styles/Common/CommonStyle';
import { SelectOrgServiceLevel } from '../../store/userSlice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


interface WizardInterface {
  name: string;
  title: string;
  Icon: React.FC<any>;
  defaultTitle: string;
}

const routes = generateAppRoutes()
const FrameWizardPicker: React.FC = () => {

  const orgServiceLevel: number | null = useSelector(SelectOrgServiceLevel);
  const history = useHistory();


  if (orgServiceLevel !== null && orgServiceLevel <= 1) {
    history.push(routes.PUBLISH_FRAME);
  }


  const wizards: Array<WizardInterface> = [
    { name: routes.PUBLISH_FRAME,   title: 'ddf75c2a-78e9-4fdc-9352-be90103e0270',  defaultTitle: 'PUBLISH FRAME',   Icon: FramesIcon   },
    { name: routes.CREATE_FRAME,   title: '48d53a01-84c5-4686-a01f-a8a1558135d7',  defaultTitle: 'CREATE FRAME',   Icon: CreateIcon   },
  ];

  return (
    <Wrapper>
      <BreadcrumbsHeader currentStep={0} totalSteps={0}></BreadcrumbsHeader>

      <MessageTextWide>
        <Phrase translationKey='f7e25a2b-d0cc-4546-8137-e4f4ba09c083' defaultText='By using frames, you can customize webapp content'></Phrase>
      </MessageTextWide>

      <WizardWrapper>
        {wizards.map((wiz, index) => (
          <Wizard
            key={`${wiz.title}-${index}`}
            name={wiz.name}
            title={wiz.title}
            Icon={wiz.Icon}
            defaultTitle={wiz.defaultTitle}
            Wide={true}
          />
        ))}
      </WizardWrapper>
    </Wrapper>
  );
};

export default FrameWizardPicker;
