import { useEffect, useState } from 'react';
import BusinessType from './pages/BusinessType';
import { Answer, ContentSurveyStep } from '../../../primitives/types/ContentSurevyStep';
import { Container } from '../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import NewLoading from '../../../components/Loading/Loader';
import getContentSurvey from '../../../api/rest/getContentSurvey';
import DesignPrefernces from './pages/DesingPreferences';
import CulturalInfluence from './pages/CulturalInfluence';
import { Redirect } from 'react-router-dom';
import setContentSurvey from '../../../api/rest/setContentSurvey';
import GA from '../../../utils/analytics';

interface ContentPreferencesProps {
  theme: any;
  onNext: () => Promise<void>;
  organizationId?: string
  onClose: () => void;
  locationId?: string;
  isContentFlow: boolean;
  onConnectionError: () => void;
  setHasError: (message: string) => void;
}

enum ContentPreferencesSteps {
    BUSINESS_TYPE = 1,
    DESIGN_PREFERENCES = 2,
    CULTURAL_INFLUENCE = 3
}

const ContentPreferences: React.FC<ContentPreferencesProps> = ({
  theme,
  onNext,
  onClose,
  locationId,
  organizationId,
  setHasError,
  onConnectionError,
  isContentFlow
}) => {
    const [currentStep, setCurrentStep] = useState<ContentPreferencesSteps>(isContentFlow ? ContentPreferencesSteps.DESIGN_PREFERENCES : ContentPreferencesSteps.BUSINESS_TYPE);
    const [isLoading, setIsLoading] = useState(true);
    const [surveySteps, setSurveySteps] = useState<ContentSurveyStep[]>([]);

    useEffect(() => {
        getSurvey()
    }, [locationId])

    const getSurvey = async () => {
        if (locationId) {
            try {
                const response = await getContentSurvey(locationId, organizationId);
                if (response.data) {
                    setSurveySteps(sortSurveySteps(response.data));    
                }
            } catch (error: any) {
                if(error.message === 'Network Error') {
                    onConnectionError()
                  } else {
                    setHasError(error.message)
                  }
            } finally {
                setIsLoading(false)
            }
        }
    }

    const sortSurveySteps = (surveySteps: ContentSurveyStep[]) => {
        const firstQuestion = surveySteps.find(step => step.isRoot);
        let currentQuestion = {...firstQuestion}
        if (!firstQuestion) return [];

        const sortedArray = [firstQuestion]
        while (currentQuestion?.nextStep) {
            const nextQuestion = surveySteps.find(step => step._id === currentQuestion?.nextStep);
            if (nextQuestion) sortedArray.push(nextQuestion);
            currentQuestion = {...nextQuestion};
        }

        return sortedArray;
    }

    const handleBusinessTypeClick = (place: Answer) => {
        setSurveySteps(surveySteps => {
            const updatedSurveySteps = [...surveySteps]
                updatedSurveySteps[ContentPreferencesSteps.BUSINESS_TYPE - 1].selectedAnswer = [place];
            return updatedSurveySteps
        })
        setCurrentStep(ContentPreferencesSteps.DESIGN_PREFERENCES)
    }

    const handleDesignPreferencesClick = (preference: Answer, isSelected: boolean) => {
            setSurveySteps(surveySteps => {
                const updatedSurveySteps = [...surveySteps];
                const currentStep = updatedSurveySteps[ContentPreferencesSteps.DESIGN_PREFERENCES - 1];

                if (!isSelected) {
                currentStep.selectedAnswer = [...currentStep.selectedAnswer.filter(pref => pref._id !== preference._id)];
                } else {
                    currentStep.selectedAnswer = [...currentStep.selectedAnswer, preference];    
                }
                return updatedSurveySteps
            })
    }

    const handleCulturleInfluenceClick = (culturalInfluence: Answer, isSelected: boolean) => {
        setSurveySteps(surveySteps => {
            const updatedSurveySteps = [...surveySteps];
            const currentStep = updatedSurveySteps[ContentPreferencesSteps.CULTURAL_INFLUENCE - 1];

            if (!isSelected) {
            currentStep.selectedAnswer = [...currentStep.selectedAnswer.filter(pref => pref._id !== culturalInfluence._id)];
            } else {
                currentStep.selectedAnswer = [...currentStep.selectedAnswer, culturalInfluence];    
            }
            return updatedSurveySteps
        })
    }

    const handleDesignPreferncesNext = () => {
        setCurrentStep(ContentPreferencesSteps.CULTURAL_INFLUENCE)
    }

    const handleSubmit = async () => {
        if (surveySteps && locationId && organizationId) {
        const channelsIds = getSelectedChannels();
        const surveyReponse = surveySteps.map(step => ({
            contentSurveyStepId: step._id,
            answerIds: step.selectedAnswer?.map(pref => pref._id)
        }))

        try {
            setIsLoading(true)
            await onNext();
            await setContentSurvey({locationId, selectedChannels: channelsIds, surveyResponse: surveyReponse, organizationId})
            sendGoogleAnalyticsEvents();
        } catch (error) {
            console.log({error})
        } finally {
            setIsLoading(false)
        }
    }
    }

    const getSelectedChannels = () => {
        return surveySteps.flatMap(step => step.selectedAnswer?.flatMap(answer => answer.channels))
    }

    const sendGoogleAnalyticsEvents = () => {
        GA.event({
            category: 'Onboarding',
            action: 'Onboarding_select_business',
            label: surveySteps[ContentPreferencesSteps.BUSINESS_TYPE - 1].selectedAnswer[0].title.defaultText
        })
        surveySteps[ContentPreferencesSteps.DESIGN_PREFERENCES - 1].selectedAnswer.forEach(designPref => {
            GA.event({
                category: 'Onboarding',
                action: `${isContentFlow ? 'Manage_content' : 'Onboarding'}_select_category`,
                label: designPref.title.defaultText
              });
        })

        surveySteps[ContentPreferencesSteps.CULTURAL_INFLUENCE - 1].selectedAnswer.forEach(culturalPref => {
            GA.event({
                category: 'Onboarding',
                action: `${isContentFlow ? 'Manage_content' : 'Onboarding'}_select_country`,
                label: culturalPref.title.defaultText
              });
        })
    }

    if (isLoading || !surveySteps) {
        return (
          <Container>
            <NewLoading />
          </Container>
        );
    }

    switch (currentStep) {
        case ContentPreferencesSteps.BUSINESS_TYPE:
            return <BusinessType 
            theme={theme}
            currentStep={ContentPreferencesSteps.BUSINESS_TYPE} 
            totalSteps={3} 
            onClose={onClose} 
            onSelect={handleBusinessTypeClick}
            question={surveySteps[ContentPreferencesSteps.BUSINESS_TYPE - 1]}
            />

        case ContentPreferencesSteps.DESIGN_PREFERENCES:
            return <DesignPrefernces 
            theme={theme}
            currentStep={ContentPreferencesSteps.DESIGN_PREFERENCES} 
            totalSteps={3} 
            onClose={onClose} 
            onBack={() => setCurrentStep(ContentPreferencesSteps.BUSINESS_TYPE)}
            onSelect={handleDesignPreferencesClick}
            onNext={handleDesignPreferncesNext}
            question={surveySteps[ContentPreferencesSteps.DESIGN_PREFERENCES - 1]}
            selectedPlaceType={surveySteps[ContentPreferencesSteps.BUSINESS_TYPE - 1].selectedAnswer[0]}
            />

            case ContentPreferencesSteps.CULTURAL_INFLUENCE:
                return <CulturalInfluence 
                theme={theme}
                currentStep={ContentPreferencesSteps.CULTURAL_INFLUENCE} 
                totalSteps={3} 
                onClose={onClose} 
                onBack={() => setCurrentStep(ContentPreferencesSteps.DESIGN_PREFERENCES)}
                onSelect={handleCulturleInfluenceClick}
                onNext={handleSubmit}
                question={surveySteps[ContentPreferencesSteps.CULTURAL_INFLUENCE - 1]}
                />

        default:
            return <Redirect to={'/portal'}/>
    }
}

export default ContentPreferences;