import config from '../config/config';

export enum Environment {
  DEVELOPMENT = 'development',
  STAGING = 'test',
  PRODUCTION = 'production',
}

const getConfigByEnv = () =>{
  switch (process.env.REACT_APP_ENV) {
    case Environment.DEVELOPMENT: {
      return config.DEVELOPMENT;
    }
    case Environment.STAGING: {
      return config.STAGING;
    }
    case Environment.PRODUCTION: {
      return config.PRODUCTION;
    }
    default:
      return config.DEVELOPMENT;
  }
}
export default getConfigByEnv;
