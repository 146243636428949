import TabsMenu from './TabsMenu';
import TabPanel from './TabPanel';

import { union } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RippleInfo } from '../../../primitives/types/Ripples';
import { SelectTranslations } from '../../../store/translationSlice';
import {
  RippleInfoTag,
  RippleInfoDetail,
  RippleInfoDetailKey,
  RippleInfoDetailValue,
  RippleInfoTagsWrapper,
  RippleInfoDetailValueScroll,
  RippleInfoModalDetailsWrapper,
} from '../../../primitives/styles/Ripples/RipplesStyle';

interface RippleOwnDetailsProps {
  rippleInfo: RippleInfo[];
}

type Section = { key: string; value: string }[];

const RippleOwnDetails: React.FC<RippleOwnDetailsProps> = ({ rippleInfo }) => {
  const info = rippleInfo[0];
  const [value, setValue] = useState(0);
  const switchTab = (newValue: number) => {
    setValue(newValue);
  };

  const translations = useSelector(SelectTranslations);

  const NO = translations['06dfd870-c1b0-4366-ad02-afb46abbcd8d'] || 'No';
  const YES = translations['46030eba-3e78-4a24-a4f7-7765484db952'] || 'Yes';
  const TAGS = translations['2c0de27a-5bc5-4da0-84c7-c89bdcd29d05'] || 'Tags';
  const FRAME = translations['860d5919-31d7-4ee0-9f4a-e735311b92f4'] || 'Frame';
  const GENERAL = translations['27e8d778-b4ea-4820-a49e-724be3a498b3'] || 'GENERAL';
  const CHANNELS = translations['d986c8d4-5225-4e14-adce-faf28de1c165'] || 'Channels';
  const LOCATIONS = translations['f090e9f2-4c5a-47fd-b63e-36e1719874aa'] || 'Locations';
  const PERSONALIZED = translations['8877be0f-fd71-41f2-8e79-782a0fcb27df'] || 'Personalized';

  const section: Section = [
    { key: FRAME, value: info.isFrame ? YES : NO },
    { key: PERSONALIZED, value: info.isTemplate ? YES : NO },
  ];
  return (
    <RippleInfoModalDetailsWrapper>
      <TabsMenu
        value={value}
        switchTab={switchTab}
        tabs={[ GENERAL, LOCATIONS, CHANNELS, TAGS ]}
      />
      <TabPanel value={value} index={0}>
        <RippleInfoDetailValueScroll>
        {section.map(({ key, value }) => (
          <RippleInfoDetail key={key}>
            <RippleInfoDetailKey>{key}:</RippleInfoDetailKey>
            <RippleInfoDetailValue>{value}</RippleInfoDetailValue>
          </RippleInfoDetail>
        ))}
        </RippleInfoDetailValueScroll>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RippleInfoDetailValueScroll>
          {union(info.locations).map((loc, index) => (
            <RippleInfoDetailValue key={`${index}-${loc}`}>
              {loc}
            </RippleInfoDetailValue>
          ))}
        </RippleInfoDetailValueScroll>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RippleInfoDetailValueScroll>
          {union(info.channels).map((ch, index) => (
            <RippleInfoDetailValue key={`${index}-${ch}`}>
              {ch}
            </RippleInfoDetailValue>
          ))}
        </RippleInfoDetailValueScroll>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <RippleInfoTagsWrapper>
          {info.tags.map((tag, index) => (
            <RippleInfoTag key={`${index}-${tag}`}>{tag}</RippleInfoTag>
          ))}
        </RippleInfoTagsWrapper>
      </TabPanel>
    </RippleInfoModalDetailsWrapper>
  );
};


export default RippleOwnDetails;
