import styled from 'styled-components';
import { global } from '../Global/global';

export const SelectOrganizationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
  overflow-y: hidden;
  top: calc(0px * ${(props) => props.theme.ratio});
  padding-top: calc(${(props) => props.theme.deviceHeight}px * 0.05);
  padding-bottom: calc(${(props) => props.theme.deviceHeight}px * 0.05);
  left: 0;
  width: 100vw;
  height: calc(${(props) => props.theme.deviceHeight}px * 0.9);
  background: ${(props) => props.theme.transparent.black};
  ${global.userSelectNone}
`;

export const SelectOrganizationModalContent = styled.div.attrs(
  (props: { isKeyboardOpen: boolean }) => ({
    isKeyboardOpen: props.isKeyboardOpen,
  })
)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-width: 90vw;
  max-width: 90vw;
  min-height: calc(250px * ${(props) => props.theme.ratio});
  max-height: calc(700px * ${(props) => props.theme.ratio});
  border-radius: calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${(props) =>
    props.isKeyboardOpen
      ? `calc(50px * ${props.theme.ratio})`
      : `calc(50px * ${props.theme.ratio})`};
  z-index: 10;
  ${global.userSelectNone}
`;

export const SelectOrganizationModalHeader = styled.div`
  display: flex;
  min-height: calc(60px * ${(props) => props.theme.ratio});
  max-height: calc(60px * ${(props) => props.theme.ratio});
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.theme.palette.red};
  color: ${(props) => props.theme.palette.white};
  border-radius: calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio}) 0 0;
  padding: calc(20px * ${(props) => props.theme.ratio})
    calc(15px * ${(props) => props.theme.ratio});
  height: fit-content;
  ${global.userSelectNone}
`;

export const SelectOrganizationModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${global.userSelectNone}
`;

export const SelectOrganizationModalTitle = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: bold;
  font-size: calc(22px * ${(props) => props.theme.ratio});
  line-height: 120.56%;
  text-align: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.01em;
  margin-bottom: calc(5px * ${(props) => props.theme.ratio});
  padding-top: calc(11px * ${(props) => props.theme.ratio});
  padding-bottom: calc(11px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const SelectOrganizationSearchWrapper = styled.div`
  height: fit-content;
  // max-width: calc(293px * ${(props) => props.theme.ratio});
  padding: calc(7.5px * ${(props) => props.theme.ratio})
    calc(30px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const SelectOrganizationList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: calc(10px * ${(props) => props.theme.ratio})
    calc(36px * ${(props) => props.theme.ratio});
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  mix-height: calc(200px * ${(props) => props.theme.ratio});
  max-height: calc(400px * ${(props) => props.theme.ratio});
  padding-bottom: 60px;
  overflow-y: auto;
  ${global.userSelectNone}
`;

export const OrganizationOption = styled.span.attrs(
  (props: { isLastItem: boolean }) => ({
    isLastItem: props.isLastItem,
  })
)`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(18px * ${(props) => props.theme.ratio});
  line-height: calc(24px * ${(props) => props.theme.ratio});
  padding: calc(15px * ${(props) => props.theme.ratio}) 0;
  border-bottom: 1px solid ${(props) =>
    props.isLastItem ? ' rgba(0, 0, 0, 0)' : ' rgba(0, 0, 0, 0.1)'};
  display: flex;
  flex-direction: raw;
  justify-content: center;
  align-items: center;
  text-align: center;
  calc(293px * ${(props) => props.theme.ratio});
  word-break: normal;
  min-height: fit-content;
  width: 100%;
  padding-bottom: ${(props) => props.isLastItem ? '250px' : '15px'}
`;

export const SearchLabel = styled.span`
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  width: calc(100px * ${(props) => props.theme.ratio});
  height: calc(40px * ${(props) => props.theme.ratio});
  font-size: calc(20px * ${(props) => props.theme.ratio});
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
