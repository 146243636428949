import Phrase from '../../Phrase/Phrase';
import Loading from '../../Loading/Loader';
import { CloseIcon } from '../../../primitives/media/Icons';
import { CloseWrapper } from '../../../primitives/styles/Common/CommonStyle';
import {
  RippleInfoModalDetailsWrapper,
  RippleInfoModalLoaderWrapper,
  RippleModalWrapper,
  RippleInfoModalContent,
  RippleInfoModalHeader,
  RippleInfoModalClose,
  RippleInfoModalTitle,
} from '../../../primitives/styles/Ripples/RipplesStyle';

interface RippleInfoModalLoaderProps {
  closeModal: () => void;
}

const RippleInfoModalLoader: React.FC<RippleInfoModalLoaderProps> = ({
  closeModal,
}) => {
  return (
    <RippleModalWrapper>
      <RippleInfoModalContent>
        <RippleInfoModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={closeModal}>
              <CloseIcon />
            </CloseWrapper>
          </RippleInfoModalClose>
          <RippleInfoModalTitle>
            <Phrase
              translationKey={'a13a1e7c-c48f-4e3a-b4fc-37602cc9e28e'}
              defaultText={'Design info'}
            />
          </RippleInfoModalTitle>
          <RippleInfoModalLoaderWrapper>
            <Loading />
          </RippleInfoModalLoaderWrapper>
        </RippleInfoModalHeader>
        <RippleInfoModalDetailsWrapper></RippleInfoModalDetailsWrapper>
      </RippleInfoModalContent>
    </RippleModalWrapper>
  );
};

export default RippleInfoModalLoader;
