

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const styles = ( ratio = 1 ) => {
    return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiDialog-paperWidthSm': {
          minWidth: '100vw',
          position: 'fixed',
          bottom: 0,
          padding: 0,
          margin: 0,
        },
        '& .MuiPaper-rounded': {
          borderRadius: '25px 25px 0 0'
        }
      }
    })
  );
}

export default styles;