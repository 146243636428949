import Title from '../Title/Title';
import Phrase from '../Phrase/Phrase';
import Checkbox from '../Common/Checkbox';
import fontSize from '../../constants/FontSize';
import FloatingButton from '../Common/FloatingButton';
import AutocompleteSearch from '../Common/AutocompleteNew';
import rippleImages from '../../primitives/media/PublishRipples/rippleImages';

import { useEffect } from 'react';
import { WalkThroughArrow } from '../../primitives/media/Icons';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import {
  TextShadow,
  RipplesList,
  RippleImage,
  RippleWrapper,
  ArrowWrapper,
  HeadingWrapper,
  DetailsWrapper,
  RippleWalkThroughModalWrapper,
  RippleWalkThroughModalCircle,
  RippleWalkThroughModalText,
} from '../../primitives/styles/Ripples/RipplesStyle';

type RippleWalkThroughModalProps = {
  modalTitle: string;
  detailsTitle: string;
  closeModal: () => void;
  modalDefaultTitle: string;
};

/*
    this component renders the same page as the PublishRipples container, 
    but with static content and serves as a mask on top of the actual page.
    The reason for this is to get the exact same UI experience for any device 
    regardless of width and height
*/
const RippleWalkThroughModal: React.FC<RippleWalkThroughModalProps> = ({
  closeModal,
  modalTitle,
  detailsTitle,
  modalDefaultTitle
}) => {
  /*
    to prevent scrolling the original page and a bad UX,we set the body position to 'fixed'. 
    On component un-mount, we reset the position 
  */
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });

  return (
    <RippleWalkThroughModalWrapper>
      <Container style={{ background: 'none', overflowY: 'hidden' }}>
        <Wrapper>
          <HeadingWrapper>
            <Title
              type="flow"
              size={fontSize.MID_LARGE}
              text={modalTitle}
              defaultText={modalDefaultTitle}
            />
            <AutocompleteSearch
              options={[]}
              disabled={true}
              onChange={()=>{}}
              displayedProp={'tag'}
              id="org-tags-walkthrough"
              handleSelectOption={() => {}}
              defaultLabel={'Search by tags'}
              handleSetKeyboardOpen={() => {}}
              label={"ddcbdca4-f329-4a98-9491-1f75017319c8"}
            />
            <DetailsWrapper>
              {/* <DesignLibraryTitle>{t(detailsTitle)}</DesignLibraryTitle> */}
              <Checkbox
                onChange={() => {}}
                isChecked={false}
                isDisabled={false}
                label={'8c180522-c43e-4204-bbd1-755e4b6aa22b'}
                defaultLabel={'Select all'}
              />
            </DetailsWrapper>
          </HeadingWrapper>
          <RipplesList style={{ position: 'relative', overflowY: 'hidden' }}>
            {rippleImages.map((src) => (
              <RippleWrapper key={src}>
                <RippleImage key={src} src={src} />
              </RippleWrapper>
            ))}
            <RippleWalkThroughModalCircle />
            <RippleWalkThroughModalText>
              <Phrase
                translationKey={'1ca5ba60-9fff-4992-b70b-1fcf88bcf8c9'}
                defaultText={'Tap for select a Design Long-press for Design info'}
              />
            </RippleWalkThroughModalText>
            <TextShadow />
            <ArrowWrapper>
              <WalkThroughArrow />
            </ArrowWrapper>
          </RipplesList>
          <FloatingButton
            onClick={closeModal}
            isDisabled={false}
            buttonText={'14c7cd6c-8397-4f6d-9176-21f425e23833'}
            buttonDefaultText={'GOT IT'}
            variant={'contained'}
            position={'absolute'}
          />
        </Wrapper>
      </Container>
    </RippleWalkThroughModalWrapper>
  );
};

export default RippleWalkThroughModal;
