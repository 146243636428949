import BreadcrumbsHeader from '../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../components/Title/Title'

import { Container } from '../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../constants/Titles';
import { SubTitle, OnboardingContainer, LocationImage, Option } from '../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../components/Phrase/Phrase';
import LocationSrc from '../../../primitives/media/Onboarding/location.png'
import LocationButton from '../components/LocationButton';

interface SetLocationContainerProps {
  theme: any;
  onNext: () => void;
  onMoveOn: () => void;
}

const SetLocationContainer: React.FC<SetLocationContainerProps> = ({
  theme,
  onNext,
  onMoveOn
}) => {
  return (
    <Container>
      <BreadcrumbsHeader currentStep={0} totalSteps={0} shouldRenderBack={false} shouldRenderClose={false} shouldRenderRippesIcon={true}/>
      <OnboardingContainer>
      <Title type="main" text={TITLES.SET_LOCATION} defaultText={'SET LOCATION'} size={'large'} style={{paddingBottom: 0, paddingTop: 0, minHeight: '12%', height: 'fit-content', marginTop: '5%'}}/>
      <SubTitle style={{textAlign: 'center', height: '23%'}}>
        <Phrase defaultText={'Proceed easily to the next step - LOCATION, right here'} translationKey='0bf8b143-53f3-4d6d-bc76-50a8338704d2'/>
      </SubTitle>
      <div style={{height: '25%'}}>
      <LocationImage src={LocationSrc} alt=''/>
      </div>
      <LocationButton onClick={onNext} theme={theme} containerStyle={{height: '15%'}}>
        <Phrase defaultText={'NEXT'} translationKey={'b15c3820-3db5-4c29-8aea-fd6e94e22cc1'}/>
      </LocationButton>
      <Option onClick={onMoveOn}>
        <Phrase translationKey={'20fadc4a-fdba-4099-9ab9-23c870d0adfa'} defaultText={'I prefer continuing on the machine'}/>
      </Option>
      </OnboardingContainer>
    </Container>
  );
};

export default SetLocationContainer;