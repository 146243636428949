import Phrase from '../Phrase/Phrase';

import { useEffect, useState } from 'react';
import { Ripple } from '../../primitives/types/Ripples';
import {
  ThumbnailSupperWrapper,
  ThumbnailErrorLabel,
  ThumbnailOverflow,
  ThumbnailWrapper,
  ThumbnailLabel,
  Thumbnail,
} from '../../primitives/styles/Thumbnail/ThumbnailStyle';
interface ThumbnailWidgetProps {
  ripples: Ripple[];
}

const ThumbnailWidget: React.FC<ThumbnailWidgetProps> = ({ ripples }) => {
  const ripplesCount = ripples.length;
  const ripplesDisplayed = ripplesCount > 7 ? 6 : ripplesCount;
  const [hasInvalidImages, setHasInvalidImages] = useState(false);

  const isValidImageSize = (src: string) => {
    const img = document.createElement('img');
    img.src = src;
    if (img) {
      img.onload = () => {
        if (img.width !== img.height) {
          setHasInvalidImages(true);
          img.remove();
        }
      };
    }
  };

  useEffect(() => {
    if (Array.isArray(ripples)) {
      ripples.forEach((ripple) => {
        isValidImageSize(ripple.image);
      });
    }
  }, [ripples]);

  return (
    <>
      {Array.isArray(ripples) && (
        <ThumbnailSupperWrapper>
          <ThumbnailWrapper>
            {ripples.slice(0, ripplesDisplayed).map((ripple) => (
              <Thumbnail key={ripple.key} src={ripple.image} />
            ))}
            {ripplesCount > ripplesDisplayed && (
              <ThumbnailOverflow>...</ThumbnailOverflow>
            )}
            <ThumbnailLabel>
              <Phrase
                translationKey={'781ae39d-d5f7-483a-a1bf-909c18eef88c'}
                defaultText={`${ripplesCount} designs`}
                vars={{ X: String(ripplesCount) }}
              />
            </ThumbnailLabel>
          </ThumbnailWrapper>
          {hasInvalidImages && (
            <ThumbnailErrorLabel>
              <Phrase
                translationKey={'0a451292-81b1-4916-adae-dbd47e01bc6e'}
                defaultText={`SOME OF THE FILES ARE NOT SQUARE, THEY WILL BE RESIZED TO BE SQUARE`}
                vars={{ X: String(ripplesCount) }}
              />
            </ThumbnailErrorLabel>
          )}
        </ThumbnailSupperWrapper>
      )}
    </>
  );
};

export default ThumbnailWidget;
