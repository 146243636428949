import {
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
    root: {
        alignSelf: 'flex-start',
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      // '& .Mui'
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
  })(TextField);

interface LocationTextFieldProps {
    label: string;
    value?: string | number;
    onChange?: (e: any) => void;
    style?: Object;
    id?: string;
    name?: string;
    onBlur?: (e: any) => void;
    error?: boolean;
    errorText?: string;
    readonly?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const LocationTextField: React.FC<LocationTextFieldProps> = ({label, value, onChange, style, id , name , onBlur, error, errorText, readonly = false, disabled = false, onClick}) => {
    return <CssTextField 
    id={id}
    name={name}
    label={label} 
    inputProps={{onBlur, style: {color: 'white', }}} 
    InputLabelProps={{style: {color: 'white'}}} 
    InputProps={{readOnly: readonly}}
    value={value}
    onChange={onChange}
    style={style}
    error={error}
    helperText={error ? errorText : undefined}
    disabled={disabled}
    onClick={onClick}
    />
};

export default LocationTextField;