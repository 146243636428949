
import Phrase from '../Phrase/Phrase';

import { WarningIcon } from '../../primitives/media/Icons';
import {
  RippleInfoDetail,
  RippleWarningModalHeader,
} from '../../primitives/styles/Ripples/RipplesStyle';

import {
  ErrorWarningMsg,
  ErrorModalWrapper,
  ErrorModalContent,
  ErrorModalColumnTitle,
  DesignLimitErrorDetailsWrapper,
} from '../../primitives/styles/Common/CommonStyle'
import LocationButton from '../../containers/Onboarding/components/LocationButton';

interface ErrorProps {
    onClose: () => void;
    theme?: any
}

const NoPermissionsError: React.FC<ErrorProps> = ({onClose, theme}) => {
  return (
    <ErrorModalWrapper>
      <ErrorModalContent>
        <RippleWarningModalHeader>
          <ErrorModalColumnTitle>
            <WarningIcon />
            <Phrase
              translationKey={'df15e368-77f4-49b5-bb41-c88a24943dbf'}
              defaultText={'No permissions'}
            />
          </ErrorModalColumnTitle>
        </RippleWarningModalHeader>
        <DesignLimitErrorDetailsWrapper>
          <RippleInfoDetail>
            <ErrorWarningMsg style={{textAlign: 'left'}}>
              <Phrase
                translationKey={'2d94eb2a-4e47-49e5-88ec-de2377adeb05'}
                defaultText={`You do not have permission to access the mobile portal`}
              />
            </ErrorWarningMsg>
            <ErrorWarningMsg style={{textAlign: 'left'}}>
                <Phrase
                  translationKey={'bcbdacdd-db0c-4910-a544-03fdf97df3e8'}
                  defaultText={'Please contact your organization administrator or support'}
                />
            </ErrorWarningMsg>
          </RippleInfoDetail>
        </DesignLimitErrorDetailsWrapper>
        <LocationButton onClick={onClose} style={{marginTop: `calc(15px * ${theme.ratio})`, marginBottom: `calc(15px * ${theme.ratio})`}}>
            <Phrase defaultText='GOT IT' translationKey='14c7cd6c-8397-4f6d-9176-21f425e23833'/>
        </LocationButton>
      </ErrorModalContent>
    </ErrorModalWrapper>
  );
};

export default NoPermissionsError;
