import styled from 'styled-components';

export const Container = styled.div.attrs(
  (props: { isModalOpen: boolean, isNotScrollable: boolean }) => ({
    isModalOpen: props.isModalOpen,
    isNotScrollable: props.isNotScrollable
  })
)`
  background: ${(props) => props.theme.palette.black};
  overflow-y: hidden;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: ${(props) => props.theme.deviceHeight}px;
  width: 100vw;
  height: 100%;
  will-change: scroll-position;
`;
 