import Title from '../Title/Title';
import Phrase from '../Phrase/Phrase';
import titles from '../../constants/Titles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './headerBarStyles';
import {
  DrawerHeader,
  HelpMenuList,
  HelpListItem,
  DrawerMenuTitle,
  LogoIconHeader,
  HelpMenuTitleContainer,
  HelpContentContainer,
  HelpTopicIconContainer,
  HelpTopicTitle,
} from '../../primitives/styles/HeaderBar/HeaderBarStyles';
import {
  CreateIcon,
  // UploadIcon,
  RemoveIcon,
  PublishIcon,
  FramesIcon,
  QrCodeIcon,
  ChannelsIcon,
  RippleLogoIcon,
} from '../../primitives/media/Icons';

interface TopicsInterface {
  title: string;
  content: string;
  Icon: React.FC<any>;
  defaultTitle: string;
  defaultContent: string;
}

const topics: Array<TopicsInterface> = [
  {
    //CREATE
    title: 'f9cfcbe3-26a2-4a16-97b3-503aea2d9465',
    defaultTitle: 'CREATE',
    Icon: CreateIcon,
    content: '1eec89bc-8852-4c34-9fb2-f7e3db4fd65a',
    defaultContent:
      'Take a photo or select one from your gallery and publish it to Ripple Maker and customers’ mobile phones.',
  },
  {
    //DELETE
    title: '219a55a8-6201-4fda-81ed-df7215848308',
    defaultTitle: 'DELETE',
    Icon: RemoveIcon,
    content: 'c2833bd6-ab36-4972-81f5-a7bd503b6595',
    defaultContent:
      'Select designs from your channels and delete them from Ripple Maker and customers’ mobile phones.',
  },
  {
    //PUBLISH
    title: 'aaa7bd0f-1f5e-4b4f-a125-cd599a91f46d',
    defaultTitle: 'PUBLISH',
    Icon: PublishIcon,
    content: '767e8c6d-d385-46e9-8f5a-a1c6ffd9a20f',
    defaultContent:
      'Select designs from Ripples library and publish them to Ripple Maker and customers’ mobile phones.',
  },
  {
    //FRAMES
    title: '1452763c-175c-431c-a358-e8baa694eb02',
    defaultTitle: 'FRAMES',
    Icon: FramesIcon,
    content: '68441b8b-70ff-49a4-af06-2a1f0d375f65',
    defaultContent:
      'Choose frames from Ripples library, or create your own exclusively for your organization’s WebApp.',
  },
  {
    // CHANNELS
    title: 'd986c8d4-5225-4e14-adce-faf28de1c165',
    defaultTitle: 'CHANNELS',
    Icon: ChannelsIcon,
    content: '6f04e795-fd33-426c-860c-b140472a9842',
    defaultContent:
      "Manage your channels, add or remove channels, and publish them to your RMs and guests' mobile",
  },
  {
    // QR CODE
    title: '41789581-651d-49e7-8766-4945378801b0',
    defaultTitle: 'QR CODE',
    Icon: QrCodeIcon,
    content: '570a792a-f8d2-4eac-81e6-209abc48442e',
    defaultContent:
      'Download QR code or copy URL to share Ripples web app with your customers.',
  },
];

interface MenuProps {
  isOpen: boolean;
  handleClose: () => void;
}
const HomepageHelpMenu = (props: MenuProps): JSX.Element => {
  const classes = useStyles();
  const { isOpen, handleClose } = props;

  return (
    <Drawer
      className={classes.drawerHelp}
      variant="persistent"
      anchor="right"
      open={isOpen}
      classes={{
        paper: classes.drawerHelpPaper,
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <DrawerMenuTitle>
          <Phrase 
            translationKey={'cff7d310-2c1f-4666-b937-8345d979a59c'}
            defaultText={'Help'}
          />
        </DrawerMenuTitle>
        <LogoIconHeader>
          <RippleLogoIcon />
        </LogoIconHeader>
      </DrawerHeader>
      <HelpMenuList>
        <HelpMenuTitleContainer>
          <Title type="main" text={titles.HELP} defaultText={'what can you do here'} size={'extra_large'} />
        </HelpMenuTitleContainer>
        {topics.map((topic, i) => {
          const { Icon } = topic;
          return (
            <HelpListItem
              className={classes.helpMenuListItem}
              key={topic.title + i}
            >
              <HelpTopicIconContainer>
                <Icon />
                <HelpTopicTitle>
                  <Phrase 
                    translationKey={topic.title}
                    defaultText={topic.defaultTitle}
                  />  
                </HelpTopicTitle>
              </HelpTopicIconContainer>
              <HelpContentContainer>
                <Phrase 
                  translationKey={topic.content}
                  defaultText={topic.defaultContent}
                />   
              </HelpContentContainer>
            </HelpListItem>
          );
        })}
      </HelpMenuList>
    </Drawer>
  );
};

export default HomepageHelpMenu;
