import Phrase from '../Phrase/Phrase';

import { NotFoundIcon } from '../../primitives/media/Icons';
import {
  NoDataMessageWrapper,
  NoDataText,
  NoDataSubText,
  // NoDataSubTextLink,
} from '../../primitives/styles/Common/CommonStyle';

interface NoDataMsgProps {
  text: string;
  subtext?: string;
  defaultText: string;
  defaultSubtext?: string
  useIcon: boolean;
  isSubtextLink?: boolean;
  color?: string
}
const NoDataMessage: React.FC<NoDataMsgProps> = ({
  text,
  subtext,
  useIcon,
  defaultText,
  defaultSubtext,
  isSubtextLink,
  color,
}) => {
  return (
    <NoDataMessageWrapper>
      {useIcon && <NotFoundIcon />}
      <NoDataText color={color}>
        <Phrase translationKey={text} defaultText={defaultText} />
      </NoDataText>
      {subtext && defaultSubtext && (
        <NoDataSubText color={color}>
          <Phrase translationKey={subtext} defaultText={defaultSubtext} />
        </NoDataSubText>
      )}
    </NoDataMessageWrapper>
  );
};

export default NoDataMessage;
