import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTranslations } from '../api/rest/translations';
import { SetTranslations } from '../store/translationSlice';


const TranslationsProvider = ({ children }: { children: JSX.Element }) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const getAndSetTranslations = async () => {
        const translations = await getTranslations();
        const payload = translations ? translations : {};
        dispatch(SetTranslations(payload));
      }
      getAndSetTranslations();
      //eslint-disable-next-line
    }, []);
  

  
    return children;
  };

  export default TranslationsProvider