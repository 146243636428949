import styled from 'styled-components';

export const PlacesContainer = styled.div`
    display: flex;
    margin-top: calc(0.05px * ${(props) => props.theme.deviceHeight});
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    div:nth-child(2n) {
        margin-left: calc(0.01px * ${(props) => props.theme.deviceHeight});
    }
    div:nth-child(2n + 1) {
        margin-right: calc(0.01px * ${(props) => props.theme.deviceHeight});
    }
    div:not(:nth-last-child(-n+2)) {
        margin-bottom: calc(0.01px * ${(props) => props.theme.deviceHeight});
    }
    div:nth-child(n+3) {
        margin-top: calc(0.01px * ${(props) => props.theme.deviceHeight});
    }
    `;

export const PlaceRow = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: calc(15px * ${(props) => props.theme.ratio});    
    `
export const Place = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2C2E33;
    border-radius: 10px;
    border: 1px solid gray;
    width: calc(0.2px * ${(props) => props.theme.deviceHeight});
    height: calc(0.2px * ${(props) => props.theme.deviceHeight});
    align-items: center;
    color: white;
    font-weight: 500;
    white-space: pre-line;
    text-align: center;
    font-size: calc(0.018px * ${(props) => props.theme.deviceHeight});
    letter-spacing: 1px;
`;

export const PlaceImage = styled.img`
width: calc(65px * ${(props) => props.theme.ratio});;
height: calc(65px * ${(props) => props.theme.ratio});;
`;

export const PlaceTypeContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
color: rgba(255, 255, 255, 0.7);
`;

export const PlaceTypeNameContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`;

export const PlaceTypeSeperator = styled.div`
width: 50%;
height: 1px;
border-bottom: 1px solid #E86161;
`;

export const PlaceNameContainer = styled.div`
margin: 0px 15px;
flex-shrink: 0;
`