import Phrase from '../../../../../components/Phrase/Phrase';
import { DesignOption, DesignOptionsContainer, Seperator, StyledSelectedIcon } from '../../../../../primitives/styles/Onboarding/DesignPreferences';
import { Answer } from '../../../../../primitives/types/ContentSurevyStep';
import SelectedIcon from '../../../../../primitives/media/Onboarding/SelectedIcon.svg';

interface DesignOptionsProps {
  desingOptions: Answer[],
  selectedDesingOptions?: Answer[],
  onClick: (design: Answer, isSelected: boolean) => void;
}

const DesignOptions: React.FC<DesignOptionsProps> = ({
    desingOptions,
    selectedDesingOptions = [],
  onClick
}) => {
  return (
    <DesignOptionsContainer>
      {desingOptions.map((designOption) => {
        const isSelected = selectedDesingOptions.find((option: Answer) => option._id ===  designOption._id);
        return <DesignOption key={designOption._id} onClick={() => onClick(designOption, !isSelected)} isSelected={!!isSelected}>
              <Phrase translationKey={designOption.title.translationKey} defaultText={designOption.title.defaultText}/>
              {isSelected &&  <StyledSelectedIcon src={SelectedIcon} alt=''/>}
        </DesignOption>
      })}
      <Seperator/>
    </DesignOptionsContainer>
  );
};

export default DesignOptions;