import Phrase from '../Phrase/Phrase';
import { useState, useEffect } from 'react';
import {
  TopShades,
  BottomShades,
  CalendarWrapper,
  CalendarBody,
  CalendarMonths,
  CalendarDays,
  CalendarYears,
  CalendarValueItem,
  CalendarValueContent,
} from '../../primitives/styles/DatePicker/DatePickerStyles';

interface CalendarProps {
  toDate: string;
  fromDate: string;
  months: string[];
  validYears: number[];
  daysInMonth: number;
  selectedDay: number;
  selectedYear: number;
  selectedMonth: number;
  selectDay: (day: number) => void;
  selectYear: (year: number) => void;
  selectMonth: (month: number) => void;
}
const Calendar: React.FC<CalendarProps> = ({
  months,
  toDate,
  fromDate,
  validYears,
  daysInMonth,
  selectedDay,
  selectedMonth,
  selectedYear,
  selectDay,
  selectYear,
  selectMonth,
}) => {
  const [dayInit, setDayInit] = useState(false);
  const [yearInit, setYearInit] = useState(false);
  const [monthInit, setMonthInit] = useState(false);
  // const translations = useSelector(SelectTranslations);
  const defaultMonthNames: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const initializeObserver = (rootElement: Element, entries: any) => {
    let options = {
      root: rootElement,
      rootMargin: '-100px 0px -100px 0px',
      threshold: 1,
    };

    const setSelection = (entry: any) => {
      const selectionType = entry.target.classList[3].split('-')[2];
      const value = Number(entry.target.id);
      switch (selectionType) {
        case 'month':
          selectMonth(value);
          break;
        case 'day':
          selectDay(value);
          break;
        case 'year':
          selectYear(value);
          break;
        default:
          return;
      }
    };

    function handleIntersection(entries: any) {
      entries.map((entry: any) => {
        if (entry.isIntersecting) {
          setSelection(entry);
        } else {
        }
        return null;
      });
    }

    const observer = new IntersectionObserver(handleIntersection, options);
    entries.forEach((entry: any) => observer.observe(entry));
  };

  const getYears = () => {
    let yearLimit = null;
    const currentYear = new Date().getFullYear();
    const initYears: number[] = [];
    for (let i = 0; i < 5; i++) {
      initYears.push(currentYear + i);
    }

    let renderedYears = initYears;

    if (validYears[0] > currentYear) {
      renderedYears = validYears;
    }

    if (toDate) {
      yearLimit = new Date(toDate).getFullYear();
      let lastIndex = renderedYears.length - 1;
      let limitIndex = renderedYears.indexOf(yearLimit);
      renderedYears.splice(limitIndex + 1, lastIndex);
    }

    return renderedYears.map((year) => (
      <CalendarValueItem key={year}>
        <CalendarValueContent
          id={year.toString()}
          isSelected={selectedYear === year}
          isFade={selectedYear <= year - 2 || selectedYear >= year + 2}
          className={'date-picker-value date-picker-year'}
        >
          {year}
        </CalendarValueContent>
      </CalendarValueItem>
    ));
  };

  const getMonths = () => {
    let start = 0;
    let end = 11;
    const now = Date.now();
    const renderedMonths: string[] = [];
    let currentMonth = new Date(now).getMonth();
    let currentYear = new Date(now).getFullYear();

    if (toDate) {
      const dateLimit = new Date(toDate);
      const limitYear = dateLimit.getFullYear();
      if (limitYear === selectedYear) {
        end = dateLimit.getMonth();
      }
    }

    if (fromDate) {
      const dateLimit = new Date(fromDate);
      currentMonth = dateLimit.getMonth();
      currentYear = dateLimit.getFullYear();
    }

    if (selectedYear === currentYear) {
      start = currentMonth;
    }

    for (let i = start; i <= end; i++) {
      renderedMonths.push(months[i]);
    }

    return renderedMonths.map((month, index) => (
      <CalendarValueItem key={month} value={index}>
        <CalendarValueContent
          id={(index + start).toString()}
          isSelected={selectedMonth === index + start}
          isFade={
            selectedMonth <= index + start - 3 ||
            selectedMonth >= index + start + 3
          }
          className={'date-picker-value date-picker-month'}
        >
          <Phrase
            translationKey={month}
            defaultText={defaultMonthNames[index + start]}
          />
        </CalendarValueContent>
      </CalendarValueItem>
    ));
  };

  const getDays = () => {
    let start = 1;
    let end = daysInMonth;
    const now = Date.now();
    const renderedDays: number[] = [];
    let currentMonth = new Date(now).getMonth();
    let currentYear = new Date(now).getFullYear();
    let currentDay = new Date(now).getDate();

    if (toDate) {
      const dateLimit = new Date(toDate);
      let yearLimit = dateLimit.getFullYear();
      let monthLimit = dateLimit.getMonth();
      if (yearLimit === selectedYear && monthLimit === selectedMonth) {
        end = dateLimit.getDate();
      }
    }

    if (fromDate) {
      const dateLimit = new Date(fromDate);
      currentMonth = dateLimit.getMonth();
      currentYear = dateLimit.getFullYear();
      currentDay = dateLimit.getDate();
    }
    if (currentYear === selectedYear && currentMonth === selectedMonth) {
      start = currentDay;
    }

    for (let i = start; i <= end; i++) {
      renderedDays.push(i);
    }

    return renderedDays.map((day) => (
      <CalendarValueItem key={'day-' + day}>
        <CalendarValueContent
          id={day.toString()}
          isSelected={selectedDay === day}
          isFade={selectedDay <= day - 3 || selectedDay >= day + 3}
          className={'date-picker-value date-picker-day'}
        >
          {day}
        </CalendarValueContent>
      </CalendarValueItem>
    ));
  };

  // initialize selected year
  useEffect(() => {
    const getScroll = (scrollIndex: number) => {
      return (scrollIndex * 343) / 7;
    };
    const initializeSelectedDate = () => {
      const years = document.querySelectorAll('.date-picker-year');
      const yearsSelection = document.querySelector('#date-picker-years');

      let scrollIndex = 0;

      if (years) {
        years.forEach((m, i) => {
          if (m.id === selectedYear.toString()) {
            scrollIndex = i;
          }
        });
      }

      if (yearsSelection) {
        const yearScroll = getScroll(scrollIndex);
        yearsSelection.scrollTop = yearScroll;
        setYearInit(true);
      }
    };
    initializeSelectedDate();
    //eslint-disable-next-line
  }, []);

  // initialize selected month
  useEffect(() => {
    const getScroll = (scrollIndex: number) => {
      return (scrollIndex * 343) / 7;
    };
    const initializeSelectedMonth = () => {
      const months = document.querySelectorAll('.date-picker-month');
      const monthSelection = document.querySelector('#date-picker-months');

      let scrollIndex = 0;

      if (months) {
        months.forEach((m, i) => {
          if (m.id === selectedMonth.toString()) {
            scrollIndex = i;
          }
        });
      }

      if (monthSelection && months) {
        const monthScroll = getScroll(scrollIndex);
        monthSelection.scrollTop = monthScroll;
        setMonthInit(true);
      } else {
        initializeSelectedMonth();
      }
    };
    initializeSelectedMonth();

    //eslint-disable-next-line
  }, [selectedYear]);

  // initialize selected days
  useEffect(() => {
    const getScroll = (scrollIndex: number) => {
      return (scrollIndex * 343) / 7;
    };

    const initializeSelectedDate = () => {
      const days = document.querySelectorAll('.date-picker-day');
      const daysSelection = document.querySelector('#date-picker-days');

      let scrollIndex = 0;

      if (days) {
        days.forEach((d, i) => {
          if (d.id === selectedDay.toString()) {
            scrollIndex = i;
          }
        });
      }

      if (daysSelection && days) {
        const dayScroll = getScroll(scrollIndex);
        daysSelection.scrollTop = dayScroll;
        setDayInit(true);
      }
    };
    initializeSelectedDate();
    //eslint-disable-next-line
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    const observeEntries = () => {
      const rootElement = document.querySelector('#date-picker-body');
      const entries = document.querySelectorAll('.date-picker-value');
      if (rootElement && entries) {
        initializeObserver(rootElement, entries);
      } else {
        observeEntries();
      }
    };
    const observerTimeout = setTimeout(observeEntries, 500);
    return () => {
      clearTimeout(observerTimeout);
    };
    //eslint-disable-next-line
  }, [
    selectedYear,
    selectedMonth,
    selectedDay,
    daysInMonth,
    dayInit,
    monthInit,
    yearInit,
  ]);

  return (
    <CalendarWrapper>
      <CalendarBody id={'date-picker-body'}>
        <TopShades />
        <CalendarMonths id={'date-picker-months'}>
          {selectedYear && getMonths()}
        </CalendarMonths>
        <CalendarDays id={'date-picker-days'}>
          {selectedMonth + 1 && selectedYear && getDays()}
        </CalendarDays>
        <CalendarYears id={'date-picker-years'}>{getYears()}</CalendarYears>
        <BottomShades />
      </CalendarBody>
    </CalendarWrapper>
  );
};

export default Calendar;
