import Routes from './containers/Routes/Routes';
import Auth0TokenProvider from './providers/Auth0TokenProvider';
import { History } from 'history';
import { initializeGA } from './utils/analytics';
import { ConnectedRouter } from 'connected-react-router';

type Props = {
  history: History;
};

const App = ({ history }: Props): JSX.Element => {
  initializeGA();
  return (
      <ConnectedRouter history={history} noInitialPop>
          <Auth0TokenProvider>
              <Routes />
          </Auth0TokenProvider>
      </ConnectedRouter>
  );
};

export default App;
