import Button from '../../Common/Button';
import Phrase from '../../Phrase/Phrase';
import Loading from '../../Loading/Loader';

import { useEffect } from 'react';
import { CloseIcon } from '../../../primitives/media/Icons';
import { WarningIcon } from '../../../primitives/media/Icons';
import { CloseWrapper, LoaderGeneralWrapper } from '../../../primitives/styles/Common/CommonStyle';
import {
  RippleWarningMsg,
  RippleInfoDetail,
  RippleModalWrapper,
  RippleInfoModalTitle,
  RippleInfoModalClose,
  RippleInfoModalContent,
  RippleWarningModalHeader,
  RippleWarningButtonsWrapper,
  RippleWarningModalDetailsWrapper,
} from '../../../primitives/styles/Ripples/RipplesStyle';

interface WarningModalProps {
  closeModal: () => void;
  onSubmit: () => void;
  inProgress: boolean;
  count: number;
}
const WarningModal: React.FC<WarningModalProps> = ({
  count,
  onSubmit,
  closeModal,
  inProgress
}) => {
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });

  return (
    <RippleModalWrapper>
      <RippleInfoModalContent>
        <RippleWarningModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={closeModal}> 
              <CloseIcon />
            </CloseWrapper>
          </RippleInfoModalClose>
          <RippleInfoModalTitle>
            <WarningIcon />
            <Phrase
              translationKey={'f2ff41c8-a814-452b-b8da-776cb93be2f6'}
              defaultText={'Heads up'}
            />
          </RippleInfoModalTitle>
        </RippleWarningModalHeader>
        <RippleWarningModalDetailsWrapper>
          {
            inProgress && 
            <LoaderGeneralWrapper>
              <Loading/>
            </LoaderGeneralWrapper>
          }
          { 
            !inProgress &&
            <RippleInfoDetail>
              <RippleWarningMsg>
                <Phrase
                  translationKey={'3592dd6e-1ac4-4f02-92cf-a6990aefaf70'}
                  defaultText={
                    'Selected channels will deleted.'
                  }
                />
              </RippleWarningMsg>
              <RippleWarningMsg>
                <Phrase
                  translationKey={'a117be62-a661-48f2-acf5-d5ebc49f7e56'}
                  vars={{ X: String(count) }}
                  defaultText={
                    `Are you sure you want to remove ${count} channels?`
                  }
                />
              </RippleWarningMsg>
            </RippleInfoDetail>
          }
          { 
            !inProgress && 
              <RippleWarningButtonsWrapper>
                <Button
                  onClick={closeModal}
                  isDisabled={false}
                  variant={'outlined'}
                  buttonText={'a9660b02-9248-4bc5-a5db-c7ebc53589e5'} //cancel
                  buttonDefaultText={'CANCEL'}
                />
                <Button
                  onClick={onSubmit}
                  isDisabled={false}
                  variant={'contained'}
                  buttonText={'219a55a8-6201-4fda-81ed-df7215848308'} //delete
                  buttonDefaultText={'DELETE'}
                />
              </RippleWarningButtonsWrapper>
          }
        </RippleWarningModalDetailsWrapper>
      </RippleInfoModalContent>
    </RippleModalWrapper>
  );
};

export default WarningModal;
