import WizardContainer from './WizardContainer';
import RipplesContainer from '../Ripples/RipplesContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';
import AssignFrames from '../Frames/AssignFrames';


const PublishFrameWizardContainer: React.FC = () => {
  const initialSteps: WizardSteps[] = [
    {
      name: 'ripples',
      next: 'location',
      component: RipplesContainer,
    },
    {
      name: 'location',
      next: 'publish',
      component: SelectLocationContainer,
    },
    {

      name: 'publish',
      next: '/',
      component: AssignFrames,
    },
  ];
  
  return <WizardContainer initialSteps={initialSteps} flowName="publishFrames" />;
};

export default PublishFrameWizardContainer;
