export const GET_PUBLISHED_CHANNELS = `
  query(
    $orgId: String!
    $locationId: String!
    $canAssign: Boolean!
    $day: DateTime!
    $withCampaign: Boolean!
  ) {
    viewer {
      organizationScope(id: $orgId) {
        channels {
          edges {
            node {
              id
              name
              icon
              organizationId
              numberOfRipples
              ripples{
                id
                rippleImage {
                  id
                  thumbnailUrl
                }
              }
            }
          }
        }
        sharedChannels {
          edges {
            node {
              id
              name
              icon
              numberOfRipples
              ripples{
                id
                rippleImage {
                  id
                  thumbnailUrl
                }
              }
            }
          }
        }
        location(id: $locationId) @include(if: $canAssign){
          id
          name
          apps {
            channels(withCampaign: $withCampaign) {
              channel {
                id
                name
                icon
                organizationId
                numberOfRipples
                ripplesOn(day: $day) {
                  id
                  rippleImage {
                    id
                    thumbnailUrl
                  }
                }
              }
              showOnMobile
            }
          }
        }
      }
    }
  }
`;
