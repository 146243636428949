export const GET_INHERIDTED_FRAMES = `
  query findInheritedFramesByTags(
    $orgId: String!
    $tags: [String!]
    $canViewOnlyOwn: Boolean!
    $pageSize: Int!
    $lastPageCursor: String = ""
  ) {
    viewer {
      organizationScope(id: $orgId) {
        inheritedFrames(onlyMine: $canViewOnlyOwn, tags: $tags, first: $pageSize, after: $lastPageCursor) {
          totalCount
          edges {
            node {
              id
              rippleImage {
                id
                thumbnailUrl
              }
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;
