
export const PUBLISH_CHANNELS =`
  mutation publishChannels(
    $organization: String!
    $ids: [String]!
    $rippleMakerLocations: [String]!
    $channelsOrder: String!
    $showOnMobile: Boolean
  ) {
    publishChannels(
      input: {
        clientMutationId: "1"
        organization: $organization
        ids: $ids
        rippleMakerLocations: $rippleMakerLocations
        channelsOrder: $channelsOrder
        showOnMobile: $showOnMobile
      }
    ) {
      clientMutationId
      clientWarningMessage
    }
  }
`;
