import Phrase from '../../../../../components/Phrase/Phrase';
import { Place, PlaceImage, PlacesContainer } from '../../../../../primitives/styles/Onboarding/Places';
import { Answer } from '../../../../../primitives/types/ContentSurevyStep';

interface PlaceOptionsProps {
  places: Answer[],
  onClick: (place: Answer) => void;
}

const PlacesOptions: React.FC<PlaceOptionsProps> = ({
  places,
  onClick
}) => {
  return (
    <PlacesContainer>
          {places.map((place: Answer) => {
            return <Place key={place._id} onClick={() => onClick(place)}>
              <PlaceImage src={place.icon} alt=''/>
              <Phrase translationKey={place.title.translationKey} defaultText={place.title.defaultText}/>
            </Place>
          })}
    </PlacesContainer>
  );
};

export default PlacesOptions;