import flowReducer from './store/flowSlice'
import viewerReducer from './store/userSlice'
import channelReducer from './store/channelSlice';
import ripplesReducer  from './store/ripplesSlice';
import viewModeReducer from './store/viewModeSlice';
import locationReducer from './store/locationSlice';
import translationSlice from './store/translationSlice';
import notificationsSlice from './store/notificationsSlice';
import manageChannelSlice from './store/manageChannelSlice';

import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// eslint-disable-next-line import/no-cycle

export default function createRootReducer(history: History) {
  return combineReducers({
    flow: flowReducer,
    user: viewerReducer,
    ripples: ripplesReducer,
    channel: channelReducer,
    viewMode: viewModeReducer,
    location: locationReducer,
    router: connectRouter(history),
    translations: translationSlice,
    notifications: notificationsSlice,
    manageChannels: manageChannelSlice,
  });
}
