
import Phrase from '../../Phrase/Phrase';
import RippleOwnDetails from './RippleOwnDetails';
import RippleInfoModalLoader from './RippleInfoModalLoader';
import RippleInheritedDetails from './RippleInheritedDetails';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import { CloseIcon } from '../../../primitives/media/Icons';
import { RippleInfo } from '../../../primitives/types/Ripples';
import { CloseWrapper } from '../../../primitives/styles/Common/CommonStyle';
import {
  RippleModalWrapper,
  RippleInfoModalClose,
  RippleInfoModalImage,
  RippleInfoModalTitle,
  RippleInfoModalHeader,
  RippleInfoModalContent,
} from '../../../primitives/styles/Ripples/RipplesStyle';

interface RippleInfoModalProps {
  isOwnRipple: boolean;
  rippleInfo: RippleInfo[];
  closeModal: () => void;
}

const RippleInfoModal: React.FC<RippleInfoModalProps> = ({
  isOwnRipple,
  rippleInfo,
  closeModal,
}) => {
  const info = rippleInfo[0];

  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });



  if (isEmpty(rippleInfo)) {
    return (
      <RippleInfoModalLoader closeModal={closeModal}/>
    );
  }

  return (
    <RippleModalWrapper>
      <RippleInfoModalContent>
        <RippleInfoModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={closeModal}>
              <CloseIcon/>
            </CloseWrapper>
          </RippleInfoModalClose>
          <RippleInfoModalTitle>
            <Phrase
              translationKey={'a13a1e7c-c48f-4e3a-b4fc-37602cc9e28e'}
              defaultText={'Design info'}
            />
          </RippleInfoModalTitle>
          <RippleInfoModalImage src={info.rippleImage.thumbnailUrl} />
        </RippleInfoModalHeader>
        {
          isOwnRipple ? (
            <RippleOwnDetails rippleInfo={rippleInfo}/>
          ) : (
            <RippleInheritedDetails rippleInfo={rippleInfo}/>
          )
        }
        
      </RippleInfoModalContent>
    </RippleModalWrapper>
  );
};

export default RippleInfoModal;
