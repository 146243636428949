import copy from 'copy-to-clipboard';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectTranslations } from '../../store/translationSlice';
import { CopyURL as CopyUrl } from '../../primitives/styles/QRcode/QRcodeStyles';

interface CopyURLProps {
  value: string;
}

const CopyURL: React.FC<CopyURLProps> = ({ value }) => {

  const translations = useSelector(SelectTranslations);
  const copyURL = translations['c90a3c9d-d12c-4088-b374-a8224cf9b302'] || 'Copy URL';
  const linkCopiedMsg = translations['648c9e39-95fe-4677-a6d0-1d6a2c36d1bd'] || 'Link copied to clipboard';

  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipBoard = async () => {
    try {
      copy(value, { format: 'text/plain' })
      setCopySuccess(linkCopiedMsg);
    } catch (err) {
      if( typeof err === 'string') {
        setCopySuccess(err);
      }
    }
  };

  return (
    <CopyUrl
      data-clipboard-action="copy"
      data-clipboard-text="{browserShareText.hideText}"
      onClick={copyToClipBoard}
      isLink={!copySuccess}
    >
      {copySuccess ? copySuccess : copyURL}
    </CopyUrl>
  );
};

export default CopyURL;
