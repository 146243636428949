import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loader';
import NoDataMessage from '../../components/Common/NoDataMessage';
import FloatingButton from '../../components/Common/FloatingButton';
import ConnectionError from '../../components/Errors/ConnectionError';
import ManageChannelTabs from '../../components/Channel/ManageChannelTabs';
import ManageChannelsList from '../../components/Channel/ManageChannelsList';
import WarningModal from '../../components/Channel/WarningModal/WarningModal';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';
import ChannelInfoModal from '../../components/Channel/InfoModal/ChannelInfoModal';
import CampaignRunningAlert from '../../components/CampaignRunningAlert/CampaignRunningAlert';

import { get } from 'lodash';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { gqlClient } from '../../utils/gqlClient';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { generateAppRoutes } from '../../constants/Routes';
import { SelectLocationId } from '../../store/locationSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { unpublishChannels } from '../../services/ripplesService';
import { SelectOrgId, SelectCanAssign } from '../../store/userSlice';
import { SetNotificationName } from '../../store/notificationsSlice';
import { PublishDisableAll } from '../../primitives/styles/Publish/Publish';
import { SelectTotalSteps } from '../../store/flowSlice';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { GET_PUBLISHED_CHANNELS } from '../../api/graphQl/query/getPublishedChannels';
import { publishChannelsToSelectedApps } from '../../services/ripplesService';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import {
  Channel as ChannelType,
  UnpublishChannels,
} from '../../primitives/types/Channel';
import getCampaignByLocationId from '../../api/rest/getCampaignByLocationId';
import PublishButtons from './PublishButtons';
import { EDIT_APP_CHANNELS } from '../../api/graphQl/mutation/editAppChannels';

const MY_CHANNELS_PATH = 'viewer.organizationScope.channels.edges';
const LOCATION_APPS_PATH = 'viewer.organizationScope.location.apps';
const SHARED_CHANNELS_PATH = 'viewer.organizationScope.sharedChannels.edges';

type ActionType = 'add' | 'remove' | 'markMobile';
interface ManageChannelsContainerProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
  path: string;
}

const ManageChannelsContainer: React.FC<ManageChannelsContainerProps> = ({
  currentStep,
  nextPath,
  path,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routes = generateAppRoutes();
  const orgId: string = useSelector(SelectOrgId);
  const canAssign: boolean = useSelector(SelectCanAssign);
  const locationId: string = useSelector(SelectLocationId);

  const initialChannel: any = {}
  const initialChannels: ChannelType[] = [];
  const initialSelectedChannels: string[] = [];

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('RM');
  const [inProgress, setInProgress] = useState(false);
  const [publishError, setPublishError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const [openChannelInfoModal, setOpenChannelInfoModal] = useState(false)
  const [isAddChannelSelected, setIsAddChannelSelected] = useState(false);
  const [currentLongPressedChannel, setCurrentLongPressedChannel] = useState(initialChannel)

  const [myChannels, setMyChannels] = useState(initialChannels);
  const [sharedChannels, setSharedChannels] = useState(initialChannels);
  const [appChannels, setAppChannels] = useState(
    initialChannels
  );

  const [mobileChannels, setMobileChannels] = useState(
    initialChannels
  );

  const [selectedChannels, setSelectedChannels] = useState(
    initialSelectedChannels
  );
  const [selectedChannelsToPublish, setSelectedChannelsToPublish] = useState(
    initialSelectedChannels
  );

  const totalSteps = useSelector(SelectTotalSteps);

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleCampaign = (campaign: any) => {
    if (!campaign || !campaign._doc) return setIsAlertOpen(false);

    setIsAlertOpen(campaign._doc.overwriteContent)
  }

  useEffect(() => {
    if (locationId && orgId) {
      getCampaignByLocationId(locationId, orgId)
        .then(({ data }) => data.data)
        .then(campaign => handleCampaign(campaign))
        .catch(() => {
          setConnectionError(true);
        });
    }
  }, [locationId, orgId])

  useEffect(() => {
    if (canAssign && locationId) {
      const getChannels = (): Promise<any> =>
        gqlClient.mutate(GET_PUBLISHED_CHANNELS, {
          orgId,
          canAssign,
          locationId,
          day: getStartOfDayFormatted(),
          withCampaign: false
        });

      getChannels()
        .then(({ data }) => {
          return {
            data,
            apps: get(data, LOCATION_APPS_PATH),
            myChannelsNodes: get(data, MY_CHANNELS_PATH),
            sharedChannelsNodes: get(data, SHARED_CHANNELS_PATH),
          };
        })
        .then(({ data, apps, myChannelsNodes, sharedChannelsNodes }) => {
          const { channels } = apps;

          const formattedChannels = [...channels.map(({ channel, showOnMobile }: { channel: any, showOnMobile: boolean }) => ({ ...channel, showOnMobile }))];
          setAppChannels(formattedChannels);
          setMobileChannels(formattedChannels)
          setMyChannels(
            myChannelsNodes.map((n: any) => ({ ...n.node }))
          );
          setSharedChannels(
            sharedChannelsNodes.map((n: any) => ({ ...n.node }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setConnectionError(true);
        });
    }
    // eslint-disable-next-line
  }, [canAssign, locationId]);

  const getStartOfDayFormatted = () => {
    let now = new Date();
    return now.toISOString();
  }

  const handlePublishError = () => {
    setPublishError(true);
    setIsPublishInProgress(false);
  };

  const isMobileTab = currentTab === 'Mobile'

  const handlePublishChannelsToApps = async (channelsOrder: string) => {
    try {
      if (!navigator.onLine) {
        handlePublishError();
        return;
      }
      setIsPublishInProgress(true);
      const flowName: FlowName = 'publishChannels';
      const channels = [...selectedChannelsToPublish];
      const result = await publishChannelsToSelectedApps(
        {
          organization: orgId,
          channels,
          locationId,
          channelsOrder
        }
      );
      const clientWarningMessage = result.data.publishChannels.clientWarningMessage
      if (result) {
        dispatch(SetNotificationName({ notificationName: flowName, clientWarningMessage }));
        nextPath();
      } else {
        handlePublishError();
      }
    } catch (err) {
      handlePublishError();
    }
  };

  const onConnectionErrorClose = () => {
    setConnectionError(false);
    setPublishError(false)
  };

  const handleTabSelected = (tab: string) => {
    setCurrentTab(tab);
  };

  const handleClickOnChannel = (id: string) => {
    if (isMobileTab) return;

    return isAddChannelSelected
      ? selectChannelsToPublish(id)
      : selectChannelsToRemove(id);
  };

  const handleAlertClick = () => {
    setIsAlertOpen(false)
  };

  const selectChannelsToRemove = (id: string) => {
    switch (currentTab) {
      case 'RM':
        selectOrUnselectRM(id);
        break;
      case 'Mobile':
        break;
      default:
        return;
    }
  };

  const selectChannelsToPublish = (id: string) => {
    if (selectedChannelsToPublish.includes(id)) {
      const newState = selectedChannelsToPublish.filter((ch) => ch !== id);
      setSelectedChannelsToPublish(newState);
    } else {
      setSelectedChannelsToPublish([...selectedChannelsToPublish, id]);
    }
  };


  const selectOrUnselectRM = (id: string) => {
    if (selectedChannels.includes(id)) {
      const newState = selectedChannels.filter((ch) => ch !== id);
      setSelectedChannels(newState);
    } else {
      setSelectedChannels([...selectedChannels, id]);
    }
  };


  const renderChannelsByTab = (): ChannelType[] => {
    switch (currentTab) {
      case 'MyChannels':
        return myChannels;
      case 'Mobile':
        return mobileChannels;
      case 'SharedChannels':
        return sharedChannels;
      case 'RM':
        return appChannels;
      default:
        return [];
    }
  };

  const renderSelectedChannelsByTab = (): string[] => {
    switch (currentTab) {
      case 'RM':
        return selectedChannels;
      case 'Mobile':
        return mobileChannels.filter(({ showOnMobile }: ChannelType) => !!showOnMobile).map(({ id }: ChannelType) => id);
      case 'MyChannels':
        return selectedChannelsToPublish;
      case 'SharedChannels':
        return selectedChannelsToPublish;
      default:
        return [];
    }
  };

  const getActionType = (): ActionType => {
    let actionType: ActionType = 'add';
    switch (currentTab) {
      case 'RM':
        actionType = selectedChannels.length ? 'remove' : 'add';
        break;
      case 'Mobile':
        actionType = selectedChannels.length ? 'remove' : 'add';
        break;
      default:
        return actionType;
    };

    return actionType;
  };

  const getChannelsCountToRemove = (): number => {
    switch (currentTab) {
      case 'RM':
        return selectedChannels.length;
      case 'Mobile':
        return selectedChannels.length;
      default:
        return 0;
    };
  };

  const onAddAction = () => {
    setCurrentTab('MyChannels');
    setIsAddChannelSelected(true);
  };

  const onRemoveAction = () => {
    setOpenWarningModal(true);
  };

  const onCancelRemoveAction = () => {
    setOpenWarningModal(false);
  };

  const getCurrentPageTitle = () => {
    return isAddChannelSelected
      ? titles.SELECT_CHANNELS
      : titles.MANAGE_CHANNELS;
  };

  const removeChannelsFromLocationApp = async () => {
    setInProgress(true);

    let updateRippleMakerApp: UnpublishChannels = {
      organization: orgId,
      locationId,
      channels: selectedChannels
    };

    try {
      await unpublishChannels(updateRippleMakerApp)
      setInProgress(false);
      dispatch(SetNotificationName({ notificationName: 'unpublishChannels' }));
      history.push(routes.HOME);
    } catch (err) {
      setInProgress(false);
      setConnectionError(true);
    }
  };

  const onLongPress = (channel: ChannelType) => {
    if (channel.numberOfRipples) {
      setCurrentLongPressedChannel(channel)
      setOpenChannelInfoModal(true)
    }
  }

  const closeInfoModal = () => {
    setOpenChannelInfoModal(false)
    setCurrentLongPressedChannel({})
  }

  const handlePublishMobile = async () => {
    try {
      setIsPublishInProgress(true)
      await gqlClient.mutate(EDIT_APP_CHANNELS, {
        organization: orgId,
        locationId,
        channels: mobileChannels.map(({ id, showOnMobile }: ChannelType) => ({ id, showOnMobile })),
      })
      dispatch(SetNotificationName({ notificationName: 'publishChannels' }));
      nextPath();
    } catch (error) {
      handlePublishError()
    } finally {
      setIsPublishInProgress(false)
    }
  }

  const handleMarkMobile = (channelId: string) => {
    setMobileChannels(mobileChannels => {
      return mobileChannels.map(({ id, showOnMobile, ...channel }: ChannelType) => ({
        ...channel,
        id,
        showOnMobile: id === channelId ? !showOnMobile : showOnMobile
      }))
    })
  }

  if (!canAssign) {
    return (
      <Container>
        <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} />
        <NoDataMessage
          useIcon={false}
          isSubtextLink={true}
          text={'08988c8f-8f4b-4413-8a48-e3496f4a4b40'}
          defaultText={'You don’t have permission to perform this action'}
        // You don’t have permission to perform this action
        />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} />
        <Loading />
      </Container>
    );
  }

  return (
    <Container isNotScrollable={true}>
      <BreadcrumbsHeader
        totalSteps={totalSteps}
        currentStep={currentStep}
        isPublishInProgress={isPublishInProgress}
      />
      {isAlertOpen && <CampaignRunningAlert onClick={handleAlertClick} />}
      <Wrapper>
        {isPublishInProgress && <PublishDisableAll />}
        <Title
          type="flow"
          size={fontSize.MID_LARGE}
          text={getCurrentPageTitle()}
          defaultText={'Manage channels'}
        />
        <ManageChannelTabs
          currentTab={currentTab}
          handleTabSelected={handleTabSelected}
          isAddChannelSelected={isAddChannelSelected}
        />
        <ManageChannelsList
          organizationId={orgId}
          onAddAction={onAddAction}
          onLongPress={onLongPress}
          actionType={getActionType()}
          onRemoveAction={onRemoveAction}
          channels={renderChannelsByTab()}
          isAddChannelSelected={isAddChannelSelected}
          handleClickOnChannel={handleClickOnChannel}
          selectedChannels={renderSelectedChannelsByTab()}
          isMobile={isMobileTab}
          onMarkMobile={handleMarkMobile}
        />
      </Wrapper>
      {(connectionError || publishError) && <ConnectionError onClose={onConnectionErrorClose} />}
      {openWarningModal && (
        <WarningModal
          inProgress={inProgress}
          closeModal={onCancelRemoveAction}
          count={getChannelsCountToRemove()}
          onSubmit={removeChannelsFromLocationApp}
        />
      )}
      {selectedChannelsToPublish.length > 0 && (
        <PublishButtons onClick={handlePublishChannelsToApps} inProgress={isPublishInProgress} />
      )}
      {openChannelInfoModal && (
        <ChannelInfoModal
          closeModal={closeInfoModal}
          channel={currentLongPressedChannel}
        />
      )}
      {!!isMobileTab && mobileChannels.length > 0 && <FloatingButton
        position={'fixed'}
        variant={'contained'}
        onClick={handlePublishMobile}
        inProgress={isPublishInProgress}
        isDisabled={false}
        buttonText={'f2f05091-cf6b-4ce2-b00b-5216e1757052'}
        buttonDefaultText={'DONE'}

      />}
    </Container>
  );
};

export default withAuthenticationRequired(ManageChannelsContainer, {
  onRedirecting: () => <Loading />,
});
