import styled from 'styled-components';

export const LocationContentContainer = styled.div`
display: flex;
flex-direction: column;
width: 90%;`

export const AddressContainer = styled.div`
display: flex;
flex-direction: column;
height: 54%;
`
;

export const AddressRow = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin-bottom: calc( 30px * ${(props) => props.theme.ratio}); 
`;

export const NotFoundContainer = styled.div`
display:flex;
flex-wrap: wrap;
width: 85%;
justify-content: center;
font-weight:bold; 
letter-spacing: 0.4px;
margin-top: calc( 30px * ${(props) => props.theme.ratio}); 
margin-bottom: calc( 100px * ${(props) => props.theme.heightRatio}); 
`

export const ApplyButtonConatiner = styled.div`
position: absolute;
width: 100%;
bottom: calc( 35px * ${(props) => props.theme.ratio}); 
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
height: calc( 120px * ${(props) => props.theme.ratio});
background-color: rgba(255,255,255,0.8)
`