import styled from 'styled-components';
import { global } from '../Global/global';

const lg_grey = 'linear-gradient(0deg, #383c46, #383c46)';
const lg_red =
  'linear-gradient(0deg, #E86161, #E86161), linear-gradient(0deg, #383C46, #383C46)';

export const LocationsList = styled.div`
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  width: fit-content;
  max-height: calc(300px * ${(props) => props.theme.ratio});
  padding-bottom: 100%;
  overflow-y: scroll;
  ${global.userSelectNone}
`;

export const Location = styled.div.attrs((props: { isTouched: boolean }) => ({
  isTouched: props.isTouched,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => (props.isTouched ? lg_red : lg_grey)};
  border-radius: calc(5px * ${(props) => props.theme.ratio});
  padding-left: calc(9px * ${(props) => props.theme.ratio});
  padding-bottom: calc(5px * ${(props) => props.theme.ratio});
  padding-top: calc(4px * ${(props) => props.theme.ratio});
  margin-bottom: calc(16px * ${(props) => props.theme.ratio});
  min-width: calc(312px * ${(props) => props.theme.ratio});
  max-width: calc(312px * ${(props) => props.theme.ratio});
  min-height: max-content;
  ${global.userSelectNone};
`;

export const LocationName = styled.span.attrs(
  (props: { isTouched: boolean }) => ({
    isTouched: props.isTouched,
  })
)`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 600;
  font-size: calc(21px * ${(props) => props.theme.ratio});
  color: ${(props) => props.theme.palette.white};
  padding-left: calc(9px * ${(props) => props.theme.ratio});
  margin-top: calc(8px * ${(props) => props.theme.ratio});
  min-height: calc(21px * ${(props) => props.theme.ratio});
  line-height: calc(22px * ${(props) => props.theme.ratio});
  border-left: calc(2px * ${(props) => props.theme.ratio}) solid
    ${(props) =>
      props.isTouched ? props.theme.palette.grey : props.theme.palette.red};
`;

export const LocationAddress = styled.span`

  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(15px * ${(props) => props.theme.ratio});
  color: ${(props) => props.theme.palette.white};
  padding: calc(9px * ${(props) => props.theme.ratio})
    calc(12px * ${(props) => props.theme.ratio})
    calc(9px * ${(props) => props.theme.ratio})
    calc(12px * ${(props) => props.theme.ratio});
  line-height: 120.56%;
`;

export const Machines = styled.span.attrs(
  (props: { hasSingleMachine: boolean }) => ({
    hasSingleMachine: props.hasSingleMachine,
  })
)`
  font-family: ${(props) =>
    props.hasSingleMachine
      ? props.theme.fontFamily.roboto
      : props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: calc(15px * ${(props) => props.theme.ratio});
  color: ${(props) => props.theme.palette.white};
  padding-left: calc(12px * ${(props) => props.theme.ratio});
  line-height: 120.56%;
  text-decoration-line: ${(props) =>
    props.hasSingleMachine ? 'none' : 'underline'};
`;

export const RippleMakersModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100vw;
  height:${(props) => props.theme.deviceHeight}px;
  background: ${(props) => props.theme.transparent.black};
  ${global.userSelectNone}
`;

export const RippleMakersList = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: calc(293px * ${(props) => props.theme.ratio});
  max-width: calc(293px * ${(props) => props.theme.ratio});
  // min-height: calc(356px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleMakersListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.palette.red};
  color: ${(props) => props.theme.palette.white};
  border-radius: calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio}) 0 0;
  padding: calc(15px * ${(props) => props.theme.ratio});
  position: relative;
  z-index: 2;
  ${global.userSelectNone}
`;

export const RippleMakersListClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${global.userSelectNone}
`;

export const RippleMakersListTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // word-break: break-all;
  min-width: calc(273px * ${(props) => props.theme.ratio});
  max-width: calc(273px * ${(props) => props.theme.ratio});
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: bold;
  font-size: calc(30px * ${(props) => props.theme.ratio});
  line-height: 120.56%;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: calc(5px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleMakersListContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(36px * ${(props) => props.theme.ratio})
    calc(30px * ${(props) => props.theme.ratio});
  background: #e1e1e1;
  border-radius: 0 0 calc(5px * ${(props) => props.theme.ratio})
    calc(5px * ${(props) => props.theme.ratio});
  // overflow-y: auto;
  min-height: calc(248px * ${(props) => props.theme.ratio});
  max-height: calc(248px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleMakersListItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: calc(10px * ${(props) => props.theme.ratio});
  padding-top: calc(113px * ${(props) => props.theme.ratio});
  padding-bottom: calc(93px * ${(props) => props.theme.ratio});
  margin-top calc(-80px * ${(props) => props.theme.ratio});
  overflow-y: auto;
  min-height: calc(195px * ${(props) => props.theme.ratio});
  max-height: calc(195px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const RippleMakerListItem = styled.div`
  font-family: ${(props) => props.theme.fontFamily.roboto};
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.black};
  padding: calc(7px * ${(props) => props.theme.ratio});
  width: 100%;
  font-weight: 390;
  font-size: calc(16px * ${(props) => props.theme.ratio});
  line-height: 120.56%;
  border-radius: calc(9px * ${(props) => props.theme.ratio});
  text-align: center;
  margin-bottom: calc(16px * ${(props) => props.theme.ratio});
  letter-spacing: calc(1px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;


