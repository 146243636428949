import { FrameEditable } from "../../../primitives/types/FrameTemplate";
import { TextControlWrapper } from "../../../primitives/styles/Frames/FrameControlsStyle";
import Phrase from "../../../components/Phrase/Phrase";

interface TextControlProps {
    index: number;
    data: FrameEditable;
    updateControl: (index: number, control: FrameEditable) => void;
}

const TextControl: React.FC<TextControlProps> = ({index, data, updateControl}) => {
        
    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = {...data}
        if (newData.text) newData.text.text = e.target.value;
        updateControl(index, newData);
    }

    const onInputClick = () => {
        if (data.text && data.text.text === data.text.originalText) {
            const newData = {...data}
            if (newData.text) newData.text.text = "";
            updateControl(index, newData);
        }
    }

    const text = data.text ? data.text.text : "";

    return (
        <TextControlWrapper>
            <Phrase translationKey={data.title.translationKey as string} defaultText={data.title.text} />
            <input 
                value={text}
                onChange={handleTextChange} 
                onClick={onInputClick}
                style={{fontFamily: data.font}}
                className="allow-context hi" 
            ></input>
        </TextControlWrapper>
    );
}

export default TextControl;
