import styled from 'styled-components';
import { global } from '../Global/global'
export const NewChannel = styled.div`
  display: flex;
  padding-bottom: 22px;
  margin-top: 22px;
  align-items: center;
  min-width: calc(285px * ${(props) => props.theme.ratio});
  min-height: calc(59px * ${(props) => props.theme.ratio});
  max-width: calc(285px * ${(props) => props.theme.ratio});
  max-height: calc(59px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}

`;

export const NewChannelName = styled.input`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  background: ${(props) => props.theme.palette.black};
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 390;
  overflow: hidden;
  padding: 0px;
  font-size: calc(21px * ${(props) => props.theme.ratio});
  :disabled {
    background: ${(props) => props.theme.palette.black};
    border: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const EditButton = styled.span.attrs(
  (props: { isTouched: boolean }) => ({
    isTouched: props.isTouched,
  })
)`
  color: ${(props) =>
    props.isTouched ? props.theme.palette.white : props.theme.palette.red};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  text-decoration-line: underline;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  line-height: 112.56%;
  font-size: calc(18px * ${(props) => props.theme.ratio});
`;

export const NoChannelsMessage = styled.p`
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  color: ${(props) => props.theme.palette.white};
  line-height: 120.56%;
  font-style: normal;
  font-weight: 390;
  padding: 0;
  width: calc(314px * ${(props) => props.theme.ratio});
  font-size: calc(15px * ${(props) => props.theme.ratio});

`;
