import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import { useLongPress } from 'react-use';
import { createBadgeStyles } from './styles';
import { withTheme } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { RippleByTag } from '../../primitives/types/Ripples';
import {
  RippleWrapper,
  RippleImage,
  RippleSelected,
  RippleImagePlaceholder,
  RippleImageIntersectionTarget
} from '../../primitives/styles/Ripples/RipplesStyle';

interface RippleProps {
  removeFromSelectedRipplesList: (ripple: RippleByTag) => void;
  addToSelectedRipplesList: (ripple: RippleByTag) => void;
  onLongPress: (rippleId: string) => void;
  selectedRipplesList: RippleByTag[];
  ripple: RippleByTag;
  rippleRef?: React.Ref<any>;
  selectAll: boolean;
  theme: any;
}

const Ripple: React.FC<RippleProps> = ({
  removeFromSelectedRipplesList,
  addToSelectedRipplesList,
  selectedRipplesList,
  onLongPress,
  selectAll,
  ripple,
  theme,
  rippleRef,
}) => {
  const longPressOptions = {
    isPreventDefault: false,
    delay: 800,
  };

  const handleLongPress = () => {
    const rippleId = ripple.node.id;
    onLongPress(rippleId);
  };

  const longPressEvent = useLongPress(handleLongPress, longPressOptions);

  const isRippleSelected = selectedRipplesList.includes(ripple);

  const handleRipplesClicked = () => {
    if (!isRippleSelected) {
      addToSelectedRipplesList(ripple);
    } else {
      removeFromSelectedRipplesList(ripple);
    }
  };

    /*
    a good way to reduce memory usage and improve performance,
    (and also simulate lazy loading) is removing the ripple image when the ripple is not in the viewport scope.
    Below, we defined a simple empty <span/> element which is holding the ref.
    When the ref is out of the viewport scope we render a placeholder instead of the image.
    Please notice that we would rather keep the rendered element on the screen to avoid 
    unnecessary rerendering and keep the state and props that we already passed to it.
  */

  const [ref, inView] = useInView({
    threshold: 1,
  });

  const useStyles = createBadgeStyles(theme.ratio);
  const classes = useStyles();
  return (
    <>
      <RippleWrapper
        ref={rippleRef}
        {...longPressEvent}
        onClick={handleRipplesClicked}
      >
        {(isRippleSelected || selectAll) && <RippleSelected />}
        {inView ? (
          <RippleImage src={ripple.node.rippleImage.thumbnailUrl} />
        ) : (
          <RippleImagePlaceholder />
        )}

        {(isRippleSelected || selectAll) && (
          <Badge
            color="secondary"
            badgeContent=""
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{ root: classes.root, badge: classes.badge }}
          >
            <CheckIcon
              color="primary"
              style={{
                zIndex: 2,
                transform: `scale(${0.7})`,
                position: 'absolute',
                color: 'white',
                bottom: -12,
                left: -12,
              }}
            />
          </Badge>
        )}
      </RippleWrapper>
      <RippleImageIntersectionTarget ref={ref}/> 
    </>
  );
};

export default withTheme(Ripple);
