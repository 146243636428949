import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import { withTheme } from 'styled-components';
import { ChannelIcon } from '../../primitives/media/Icons';
import { Channel as ChannelType } from '../../primitives/types/Channel';
import { Channel as Wrapper, ChannelInfo, ChannelName, Designs } from '../../primitives/styles/Channel/ChannelStyle';

interface ChannelProps {
  theme: any;
  channel: ChannelType;
  handleSelectChannel: Function;
}

const Channel: React.FC<ChannelProps> = ({ theme, channel, handleSelectChannel }) => {

  const [isTouched, setIsTouched] = React.useState(false);

  const handleOnTouchStart = () => {
    setIsTouched(true);
  };

  const handleOnTouchEnd = () => {
    setIsTouched(false);
  };

  const handleOnClick = () => {
    handleSelectChannel(channel)
  }

  return (
    <Wrapper
      onTouchEnd={handleOnTouchEnd}
      onTouchStart={handleOnTouchStart}
      onClick={handleOnClick}
    >
      <ChannelIcon
        color={isTouched ? theme.palette.red : null}
      />
      <ChannelInfo>
          <ChannelName isTouched={isTouched}>{channel.name}</ChannelName>
          <Designs>
            <Phrase
              translationKey={'781ae39d-d5f7-483a-a1bf-909c18eef88c'}
              defaultText={`${channel.numberOfRipples} designs`}
              vars={{X: String(channel.numberOfRipples)}}
            />
          </Designs>
      </ChannelInfo>
    </Wrapper>
  );
};


export default withTheme( Channel );
