import * as React from 'react';
import {
  Breadcrumbs as Wrapper,
  Step,
  StepDivider,
} from '../../primitives/styles/Breadcrumbs/BreadcrumbsStyles';

interface BreadcrumbsProps {
  totalSteps: number;
  currentStep: number;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  totalSteps,
  currentStep,
}) => {

  return (
    <Wrapper>
      {Array.from({length: totalSteps}).map((_, i) => {
        const step = i+1;
        return (
          <React.Fragment key={step}>
            <Step
              key={`step-${currentStep}`}
              isCurrentStep={currentStep === step}
              isAccomplished={step < currentStep}
            >
              {step}
            </Step>
            {step < totalSteps && (
              <StepDivider
                key={`step-divider-${currentStep}`}
                isAccomplished={step < currentStep}
              />
            )}
          </React.Fragment>
        )})}
    </Wrapper>
  );
};

export default Breadcrumbs;
