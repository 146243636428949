import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import getConfigByEnv from './utils/getConfigByEnv';
import { configuredStore, history } from './store';
import CookiesAreDisabled from './components/CookiesAreDisabled/CookiesAreDisabled';
import StyledThemeProvider from './providers/StyledThemeProvider';
import TranslationsProvider from './providers/TranslationsProvider';
import { Provider } from 'react-redux';

const store = configuredStore();

const WrappedApp = (): JSX.Element => {
    const isCookiesEnabled = navigator.cookieEnabled;        
    
    return <Provider store={store}>
        <StyledThemeProvider>
            <TranslationsProvider>
            {isCookiesEnabled ?
            <Auth0Provider {...getConfigByEnv().AUTH}>
                <App history={history} />
            </Auth0Provider> :
            <CookiesAreDisabled/>}          
            </TranslationsProvider>
        </StyledThemeProvider>
    </Provider> 
  
};

export default WrappedApp;
