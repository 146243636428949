import rpl1 from './rpl1.jpg'
import rpl2 from './rpl2.jpg'
import rpl3 from './rpl3.jpg'
import rpl4 from './rpl4.jpg'
import rpl5 from './rpl5.jpg'
import rpl6 from './rpl6.jpg'
import rpl7 from './rpl7.jpg'
import rpl8 from './rpl8.jpg'
import rpl9 from './rpl9.jpg'
import rpl10 from './rpl10.jpg'
import rpl11 from './rpl11.jpg'
import rpl12 from './rpl12.jpg'

const rippleImages: string[] = [
    rpl1, rpl2, rpl3, rpl4, rpl5, rpl6,
    rpl7, rpl8, rpl9, rpl10, rpl11, rpl12 
]

export default rippleImages;
