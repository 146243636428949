import axios from 'axios';
import getConfigByEnv from './getConfigByEnv';

const GRAPHQL_URL = getConfigByEnv().PORTAL.GRAPHQL_SERVER_URL;

export const gqlClient = {
  query:  (query: string, variables: any):Promise<any> => {
    const body = {
      query: query,
      variables: variables,
    };
    return axios.post(GRAPHQL_URL, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res: any) => res.data);
  },

  mutate: (mutation: string, variables: any, operationName?: any) => {
    const body = {
      query: mutation,
      variables: variables,
      operationName
    };
    return axios.post(GRAPHQL_URL, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res: any) => res.data);
  },
};
