import { ContentSurveyStep } from '../../primitives/types/ContentSurevyStep';
import getConfigByEnv from '../../utils/getConfigByEnv';
import axios, { AxiosResponse } from 'axios';

const getContentSurvey = (locationId: string, organizationId?: string): Promise<AxiosResponse<ContentSurveyStep[]>> => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/content/survey/${locationId}`;
    
      return axios.get(url).then(data => data);
}

export default getContentSurvey;
