import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'

import { useState } from 'react';
import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import Note from './components/Note';
import fontSize from '../../../../constants/FontSize';
import SelectModal from '../../../../components/SelectModal/SelectModal';
import LocationButton from '../../components/LocationButton';
import { Location } from '../../../../primitives/types/Location';
import NotFoundComponent from './components/NotFoundLocation';
import { ApplyButtonConatiner } from '../../../../primitives/styles/Onboarding/Location';

interface LocationContainerProps {
  locations: Location[];
  theme: any;
  onBack: () => void;
  onNext: () => void;
  onCreateLocation: () => void;
  onSelectLocation: ({locationId, organizationId, serial}: {locationId?: string, organizationId?: string, serial?: string}) => void;
  organizationId?: string
  serial?: string
}

const LocationContainer: React.FC<LocationContainerProps> = ({
  locations,
  theme,
  onBack,
  onNext,
  onCreateLocation,
  organizationId,
  serial,
  onSelectLocation
}) => {
  const [isSelectLocationOpen, setIsSelectLocationOpen] = useState(false);
  const [locationId, setLocationId] = useState<string>();
  const { ratio } = theme;

  const titlesStyle = {
      whiteSpace: 'pre-line', textAlign: 'left', textShadow: 'none'
  };

  const handleSelectLocation = (location: any) => {
    setLocationId(location.id)
  };

  const handleApply = async () => {
    try {
      await onSelectLocation({locationId, organizationId, serial})
      onNext();
    } catch (error) {
      console.log({error})
    }
  }

  return (
    <Container >
      <BreadcrumbsHeader currentStep={0} totalSteps={0} flow='location' onBack={onBack} shouldRenderClose={false}/>
      <OnboardingContainer>
      <Note defaultText={'The location helps us identify and\n manage your Ripple Maker'} translationKey={'cfc9a556-aea4-4766-86fb-8657df14d5dd'} style={{height: '20%', marginTop: '5%'}}/>
      <Title type="flow" text={TITLES.SET_RIPPLE_MAKER_LOCATION} defaultText={'Set your Ripple Maker\'s\n location'} size={fontSize.LARGE} style={titlesStyle} />
      <SubTitle style={{height: 'fit-content', minHeight: '30%'}}>
        <Phrase defaultText={'Check and select if it’s listed in the organization options OR create new'} translationKey={'27e1a669-9c14-49b0-951c-b5c5c60fc6f8'}/>
      </SubTitle>
      <LocationButton onClick={() => setIsSelectLocationOpen(true)} style={{width: `calc(300px * ${ratio})`}} disabled={false} containerStyle={{height: 'fit-content', minHeight: '25%'}}>
        <Phrase defaultText={'SELECT'} translationKey={'30af2761-596e-4533-bb6d-45d9c1eca468'}/>
      </LocationButton>
      </OnboardingContainer>
      {isSelectLocationOpen && (
        <SelectModal
          selectedOption={locationId}
          options={locations}
          shouldPreviewCloseButton={true}
          defaultTitle={'Select location'}
          onSelectOption={handleSelectLocation}
          title={'8f1e8b35-ac69-4002-ba7b-25bed0ee345d'}
          closeSelectModal={() => setIsSelectLocationOpen(false)}
          noOptionsTitle={locations.length > 0 ? '74cbfafd-78a6-4a9b-8743-70120dbd11c5' : '19e39749-2e01-4b62-9d58-057371800d98'}
          defaultNoOptionsTitle={locations.length > 0 ? 'None of your locations matched this search' : 'No active locations found'}
          renderNotFoundComponent={() => <NotFoundComponent onClick={onCreateLocation}/>}
          renderApplyButton={() => <ApplyButtonConatiner>
          <LocationButton onClick={handleApply} disabled={!locationId} theme={theme} style={{marginTop: 0}}>
            <Phrase defaultText='APPLY' translationKey='a265b1ea-14cf-43f4-a816-f2024100442f'/> 
          </LocationButton>
          </ApplyButtonConatiner> }
        />
      )}
    </Container>
  );
};

export default LocationContainer;
