import { FrameTemplate } from '../../primitives/types/FrameTemplate';
import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';


export const getFrameTemplates = async (organizationId: string): Promise<FrameTemplate[]> => {
  const url = `${getConfigByEnv().PORTAL.REST_API_URL
    }/framesTemplates/templates/${organizationId}`;

  return axios.get(url).then(data => data.data.data);
};
