import WizardContainer from './WizardContainer';
import ChannelContainer from '../Channel/ChannelContainer';
import PublishChannelsContainer from '../PublishChannels/PublishChannelsContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';

import { FlowName } from '../../primitives/types/Flow';
import { WizardSteps } from '../../primitives/types/WizardSteps';
import { useEffect } from 'react';

interface UploadPlaceholderProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
  path: string;
}


const UploadPlaceholder: React.FC<UploadPlaceholderProps> = ({
  nextPath,
  path
}) => {
  useEffect(() => {
    nextPath()
  // eslint-disable-next-line  
  },[])
  return (<div></div>)
}




const UploadWizardContainer: React.FC = () => {
  const initialSteps: WizardSteps[] = [
    {
      name: 'upload',
      next: 'location',
      component: UploadPlaceholder,
    },
    {
      name: 'location',
      next: 'channel',
      component: SelectLocationContainer,
    },
    {
      name: 'channel',
      next: 'publish',
      component: ChannelContainer,
    },
    {
      name: 'publish',
      next: '/',
      component: PublishChannelsContainer,
    },
  ];
  
  return <WizardContainer initialSteps={initialSteps} flowName="upload" />;

};

export default UploadWizardContainer;
