export const PUBLISH_FRAMES = `
  mutation publishFrames(
    $organization: String!
    $ids: [String]!
    $locationId: String!
    $channelsOrder: String!
  ) {
    publishFrames(
      input: {
        clientMutationId: "1"
        organization: $organization
        ids: $ids
        locationId: $locationId
        channelsOrder: $channelsOrder
      }
    ) {
      clientMutationId
      clientWarningMessage
    }
  }
`;
