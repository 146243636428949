import WizardContainer from './WizardContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';
import ManageChannelsContainer from '../Channel/ManageChannelsContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';
const ManageChannelsWizardContainer: React.FC = () => {
  const initialSteps:Readonly<WizardSteps[]> = [
    {
      name: 'location',
      next: 'manage',
      component: SelectLocationContainer,
    },
    {
      name: 'manage',
      next: '/',
      component: ManageChannelsContainer,
    },
  ];
  return <WizardContainer initialSteps={initialSteps} flowName="channels" />;
};

export default ManageChannelsWizardContainer;
