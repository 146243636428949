import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails, Organization } from '../primitives/types/User';

const checkPermissions = (permissions: string[]) => {
  return permissions.includes('channel:manage') 
    && permissions.includes('ripple:manage')
    && permissions.includes('location:view')
}

export const initState: UserDetails = {
  id: null,
  name: null,
  email: null,
  roles: null,
  permissions: [],
  canAssign: false,
  organizations: [],
  isLoggedIn: false,
  defaultScope: null,
  isAuthorized: false,
  organizationId: null,
  organizationName: null,
  organizationServiceLevel: null,
  orgId: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    SetIsAuthorized( state, action: PayloadAction<boolean>){
      state.isAuthorized = action.payload;
    },

    SetCurrentViewer( state, action: PayloadAction<UserDetails>) {
        const { isLoggedIn, id, name, email, permissions, defaultScope, organizations, orgId } = action.payload
        const defaultOrgId = organizations && organizations.length && organizations.length === 1
          ? organizations[0].id : null;
        const orgName = organizations && organizations.length === 1
          ? organizations[0].name : null;

          state.id = id;
          state.name = name;
          state.email = email;
          state.organizationId = defaultOrgId;
          state.isLoggedIn = isLoggedIn;
          state.organizationName = orgName;
          state.defaultScope = defaultScope;
          state.organizations = organizations;
          state.orgId = orgId;
          state.permissions = permissions;
          state.canAssign = checkPermissions(permissions)
    },
    SetOrgId( state, action: PayloadAction<string|null>) {
      const orgId = action.payload;
      state.organizationId = orgId;
    },
    SetOrgName( state, action: PayloadAction<string|null> ) {
      state.organizationName = action.payload
    },
    SetOrgServiceLevel( state, action: PayloadAction<number|null> ) {
      state.organizationServiceLevel = action.payload
    },
    SetErrorFetching(state, action: PayloadAction<string>) {
      state.hasErrorFetching = action.payload;
    },
    SetErrorConnection(state, action: PayloadAction<string>) {
      state.hasErrorFetching = action.payload;
    }
  },
});

export const SelectUserId = (state: any): string => state.user.id;
export const SelectUserName = (state: any): string => state.user.name;
export const SelectRoles = (state: any): string[] => state.user.roles;
export const SelectOrgId = (state: any): string => state.user.organizationId;
export const SelectCanAssign = (state: any): boolean => state.user.canAssign;
export const SelectOrgName = (state: any): string => state.user.organizationName;
export const SelectOrgServiceLevel = (state: any): number => state.user.organizationServiceLevel;
export const SelectIsAuthorized = (state: any): boolean => state.user.isAuthorized;
export const SelectOrganizations = (state: any): Organization[] => state.user.organizations;
export const SelectUser = (state: any): any => state.user;
export const SelectHasErrorFetching = (state: any): string | undefined => state.user.hasErrorFetching;
export const SelectHasErrorConnection = (state: any): string | undefined => state.user.hasErrorConnection;
export const { SetIsAuthorized, SetCurrentViewer, SetOrgId, SetOrgName, SetErrorConnection, SetErrorFetching, SetOrgServiceLevel } = userSlice.actions;

export default userSlice.reducer;
