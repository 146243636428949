import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

import { devices } from '../Devices/devices';
import { global } from '../Global/global';

const drawerHeaderWidth = '100%';

export const DrawerHeader = styled(Container)`
  && {
    min-height: calc(51px * ${(props) => props.theme.ratio});
    max-height: calc(51px * ${(props) => props.theme.ratio});
    width: ${drawerHeaderWidth};
    padding-right: calc(10px * ${(props) => props.theme.ratio});
    // padding-left: calc(5px * ${(props) => props.theme.ratio});
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    background: ${(props) => props.theme.palette.red};
    ${global.userSelectNone}
  }
`;

export const LogoIconHeader = styled(Container)`
  && {
    height: calc(35.46px * ${(props) => props.theme.ratio});
    width: calc(35.46px * ${(props) => props.theme.ratio});
    background: ${(props) => props.theme.palette.white};
    border-radius: 50%;
    margin: 0;
    padding: 0;
    ${global.userSelectNone}
  }
`;

export const MenuItemText = styled(Container)`
  && {
    width: 100%;
    min-height: calc(35.46px * ${(props) => props.theme.ratio}) !important;
    font-size: calc(16px * ${(props) => props.theme.ratio}) !important;
    padding-top: 12px;
    ${global.userSelectNone}
  }
`;

export const DrawerMenuTitle = styled(Box)`
  && {
    height: calc(29.5px * ${(props) => props.theme.ratio});
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(150px * ${(props) => props.theme.ratio});
    align-self: center;
    font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
    font-style: normal;
    font-weight: 390;
    font-size: calc(21px * ${(props) => props.theme.ratio});
    color: ${(props) => props.theme.palette.white};
    ${global.userSelectNone}
  }
`;

export const LogoIconContainer = styled(Container)`
  && {
    width: calc(56px * ${(props) => props.theme.ratio});
    height: calc(56px * ${(props) => props.theme.ratio});
    position: absolute;
    left: calc(50vw - calc(28px * ${(props) => props.theme.ratio}));
    top: calc(25px * ${(props) => props.theme.ratio});
    padding: 0;
    margin: 0;
    background: white;
    border-radius: 50%;
    ${global.userSelectNone}
  }
`;

export const ToolBar = styled(Toolbar)`
  && {
    min-height: calc(51px * ${(props) => props.theme.ratio});
    max-height: calc(51px * ${(props) => props.theme.ratio});
    background: ${(props) => props.theme.palette.red};
    display: flex;
    justify-content: space-between;
    ${global.userSelectNone}
  }
`;

export const HelpMenuList = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  padding-bottom: calc(150px * ${(props) => props.theme.ratio});
  background: ${(props) => props.theme.palette.black};
  min-height: fit-content;
  flex: 1 1;
  ${global.userSelectNone}
`;

export const HelpListItem = styled(Container)`
  && {
    display: flex;
    margin-right: calc(20px * ${(props) => props.theme.ratio});
    ${global.userSelectNone}
  }
`;

export const HelpMenuTitleContainer = styled(Container)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;//calc(300px * ${(props) => props.theme.ratio});
    margin-top: calc(25px * ${(props) => props.theme.ratio});
    margin-bottom: calc(25px * ${(props) => props.theme.ratio});
    @media ${devices.tablet.portrait} {
      margin-top: calc(30px * ${(props) => props.theme.ratio});
      margin-bottom: calc(30px * ${(props) => props.theme.ratio});
    }
    ${global.userSelectNone}
  }
`;

export const HelpContentContainer = styled(Container)`
  && {
    display: flex;
    align-items: flex-start;;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.palette.white};
    font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
    font-style: normal;
    font-weight: 300;
    font-size: calc(20px * ${(props) => props.theme.ratio});
    line-height: calc(23px * ${(props) => props.theme.ratio});
    min-height: calc(150px * ${(props) => props.theme.ratio});
    margin-left: 4vw;
    ${global.userSelectNone}
  }
`;

export const HelpTopicIconContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: calc(105px * ${(props) => props.theme.ratio}) !important;
    min-height: calc(105px * ${(props) => props.theme.ratio}) !important;
    max-width: calc(105px * ${(props) => props.theme.ratio}) !important;
    max-height: calc(105px * ${(props) => props.theme.ratio}) !important;
    background: ${(props) => props.theme.transparent.grey};
    border: 1px solid ${(props) => props.theme.palette.border};
    // box-sizing: border-box;
    border-radius: 10px;
    padding:0;
    ${global.userSelectNone}
  }

`;

export const HelpTopicTitle = styled(Container)`
  && {
    display: flex;
    align-items: center;.
    justify-content: center;
    color: ${(props) => props.theme.palette.white};
    text-transform: uppercase;
    font-size: calc(9px * ${(props) => props.theme.ratio});
    margin-top: calc(${(props) => props.theme.deviceHeight}px * 0.01);
    width: fit-content;
    white-space: nowrap;
    padding: 0;
    ${global.userSelectNone}
  }
`;
