import { Text as KonvaText } from 'react-konva';
import { FrameEditable } from '../../../primitives/types/FrameTemplate';

interface TextProps {
    data: FrameEditable;
}

const Text: React.FC<TextProps> = ({data}) => {

    if (!data) return (<></>);

    const text = data.text ? data.text.text : "";
    
    return (
        <KonvaText 
            text={text} 
            align="center"
            x={data.x}
            y={data.y}
            fill={data.color}
            fontSize={data.fontSize} 
            textBaseline="middle"
            width={data.width}
            fontFamily={data.font}
        />
    );
}

export default Text;
