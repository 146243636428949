import { Ripple } from '../primitives/types/Ripples';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RippleState } from '../primitives/types/RippleState'

export const initState: RippleState = {
  filesLimitError: false,
  hasImages: false,
  ripples: [],
};

const ripplesSlice = createSlice({
  name: 'ripples',
  initialState: initState,
  reducers: {
    SetRipples( state, action: PayloadAction<RippleState> ) {
      const { hasImages, ripples } = action.payload;
      state.hasImages = hasImages;
      state.ripples = ripples;
    },

    SetFilesLimitError( state, action: PayloadAction<boolean>) {
      
      state.filesLimitError = action.payload;
    },

    ResetRipplesState(state) {
      state.hasImages = initState.hasImages;
      state.ripples = initState.ripples;
    },

  },
});

export const { SetRipples, ResetRipplesState, SetFilesLimitError } = ripplesSlice.actions;

export const SelectRipples = (state: any): Array<Ripple> => state.ripples.ripples;
export const SelectFilesLimitError = (state: any): boolean => state.ripples.filesLimitError;

export default ripplesSlice.reducer;
