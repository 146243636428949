import { get } from 'lodash';
import fetch from '../utils/fetchWithTimeout';
import { gqlClient } from '../utils/gqlClient';
import { FlowName } from '../primitives/types/Flow';
import { Ripple } from '../primitives/types/Ripples';
import { CreateRipple } from '../primitives/types/CreateRipple';
import { UnpublishChannels } from '../primitives/types/Channel';
import { flowNames as flows, defaultRippleMetadata } from '../constants/Flow';
import { CREATE_RIPPLE, ASSIGN_RIPPLES, CREATE_UPLOAD_URL, PUBLISH_CHANNELS, UNPUBLISH_CHANNELS } from '../api/graphQl/mutation'
import { CHANNELS_ORDER } from '../containers/Channel/PublishButtons';

export type AppType = {
  name: 'rippleMakerApp' | 'mobileApp';
  isSurprise: boolean;
}

export type TimeLimitOptions = {
  timeLimit: boolean;
  startDate: string;
  endDate: string;
}

type PublishRipples = {
  timeLimitOptions: TimeLimitOptions;
  canApproveRipple: boolean;
  organization: string;
  flowName: FlowName;
  ripples: Ripple[];
  locationId: string;
  channelId: string;
  addToFront?: boolean;
  showOnMobile?: boolean
}

export type UploadedImage = {
  url: string;
  phash: string;
};

export type UploadedRipple = {
  id: string;
  image: string
}


/* in this function, the <ripples> parameter are actually images selected from the local device 
in the beginning of the UPLOAD flow, and stored in dev server. 
We call them ripples because we use them to create new ripples :-)  
*/
export const createBlobsFromLocalBlobURL = (ripples: Array<Ripple>) => {
  const urls = ripples.map((ripple) => ripple.image);
  return Promise.all(
    urls.map(async (url) =>
      fetch(url).then((res) =>
        res.blob()).then((blob) => blob)))
};


export const createCloudinaryUploadUrl = (organization: string): Promise<{ params: any, url: string, errors?: any[] }> => {
  return new Promise((resolve, reject) => {
    gqlClient.mutate(CREATE_UPLOAD_URL, { removeOpacity: false, organization })
      .then(({ data, errors }) => {
        if (errors) { return reject(errors) }
        else { return data }
      })
      .then(({ createUploadUrl }) => {
        const { params, url }: { params: any, url: string } = createUploadUrl
        return resolve({ params, url })
      }).catch(err => reject(err))
  })
}


const createRipple = ({
  url,
  fingerprint,
  organization,
  languageCode,
  dayOfTheWeek,
  timeOfTheDay,
  isTemplate,
  isShareable,
  isFrame,
  tags,
  canApproveRipple,
  isMacaroonFrame
}: CreateRipple) => {
  return gqlClient.mutate(
    CREATE_RIPPLE, {
    url,
    fingerprint,
    organization,
    languageCode,
    dayOfTheWeek,
    timeOfTheDay,
    isTemplate,
    isShareable,
    isFrame,
    tags,
    canApproveRipple,
    isMacaroonFrame
  })
};


export const uploadFilesToCloudinary = (blobs: Blob[], params: any, url: string) => {
  const bodies: FormData[] = [];

  blobs.forEach((blob: Blob) => {
    let formData = new FormData();
    for (let key in params) { formData.set(key, params[key]) }
    formData.set('file', blob);
    bodies.push(formData);
  });

  return Promise.all(
    bodies.map(async (body) => {
      return fetch(url, { method: 'POST', body })
        .then((res) => res.json())
        .then(({ secure_url, phash }: { secure_url: string, phash: string }) => (
          {
            url: secure_url,
            phash,
          }
        ));
    })
  );
};


const uploadRipples = (
  organization: string,
  canApproveRipple: boolean,
  uploadedImages: UploadedImage[],
  isFrame: boolean,
  isMacaroonFrame: boolean
): Promise<UploadedRipple[]> => {
  const RIPPLE_ID = 'createRipple.ripple.id';
  const RIPPLE_IMAGE = 'createRipple.ripple.rippleImage.thumbnailUrl';

  return Promise.all(
    uploadedImages.map(async ({ url, phash: fingerprint }) => {
      return createRipple({
        url,
        fingerprint,
        organization,
        canApproveRipple,
        ...defaultRippleMetadata,
        isFrame,
        isMacaroonFrame
      }).then(({ data }) => ({
        id: get(data, RIPPLE_ID),
        image: get(data, RIPPLE_IMAGE)
      }))
        .then(({ id, image }: { id: string, image: string }) => ({ id, image }))
        .catch(err => Promise.reject(err));
    })
  );
};


const validateTimeLimit = (timeLimitOptions: TimeLimitOptions): {
  validStartDate: string, validEndDate: string
} => {

  let validEndDate: Date;
  let validStartDate: Date;

  let { startDate, endDate } = timeLimitOptions;

  validStartDate = new Date(startDate);
  validStartDate.setDate(validStartDate.getDate() + 1);

  validEndDate = new Date(endDate);
  validEndDate.setDate(validEndDate.getDate() + 1);

  return {
    validStartDate: validStartDate.toISOString(),
    validEndDate: validEndDate.toISOString()
  }


}

const getStartOfDayFormatted = () => {
  let now = new Date();
  now.setHours(0, 0, 0, 0);
  return now.toISOString();
}

const assignRipplesToChannel = (
  channelId: string,
  organization: string,
  uploadRipples: UploadedRipple[],
  timeLimitOptions: TimeLimitOptions,
  addToFront: boolean = false): Promise<{ clientMutationId: string, clientWarningMessage: string }> => {

  const { timeLimit } = timeLimitOptions;
  let startDate: string;
  let endDate: string;

  try {

    if (!timeLimit) {
      const now = Date.now();
      const fiveYears = 5 /*years*/ * 365 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000; // in milliseconds
      startDate = getStartOfDayFormatted();
      endDate = new Date(now + fiveYears).toISOString();
    } else {
      const { validStartDate, validEndDate } = validateTimeLimit(timeLimitOptions);
      startDate = validStartDate
      endDate = validEndDate
    }

    const rippleIds: string[] = uploadRipples.map(({ id }) => id)

    return new Promise((resolve, reject) => {
      gqlClient.mutate(ASSIGN_RIPPLES, {
        organization,
        ids: rippleIds,
        channelId,
        startDate,
        endDate,
        addToFront,
      }).then(({ data }) => data)
        .then(({ assignRipples }) => assignRipples)
        .then(({ clientMutationId, clientWarningMessage }: { clientMutationId: string, clientWarningMessage: string }) => {
          return resolve({ clientMutationId, clientWarningMessage })
        }
        )
    })

  } catch (err) {
    throw err
  }
}

export const publishChannelsToSelectedApps = (
  {
    organization,
    channels,
    locationId,
    channelsOrder,
    showOnMobile
  }:
    {
      organization: string;
      channels: string | string[];
      locationId: string;
      channelsOrder?: string;
      showOnMobile?: boolean
    }

) => {
  let ids = [channels];
  if (Array.isArray(channels)) {
    ids = channels
  }
  return gqlClient.mutate(PUBLISH_CHANNELS, {
    ids,
    organization,
    virtualLocations: [],
    rippleMakerLocations: [locationId],
    channelsOrder: channelsOrder || CHANNELS_ORDER.LAST,
    showOnMobile: showOnMobile
  })
}


export const unpublishChannels = ({ organization, locationId, channels }: UnpublishChannels): Promise<any> => {
  return new Promise((resolve, reject) => {
    gqlClient.mutate(UNPUBLISH_CHANNELS, {
      channelIds: channels,
      locationIds: [locationId],
      organization,
    }).then(({ data }) => data)
      .then(({ unpublishChannels }) => resolve(unpublishChannels))
      .catch(err => {
        reject(err)
      })
  })
}


// TODO: Publish to begining
export const publishRipples = async ({ timeLimitOptions, canApproveRipple, organization, flowName, ripples, locationId, channelId, addToFront, showOnMobile }: PublishRipples):
  Promise<{ success: boolean, clientWarningMessage?: string, error?: any }> => {

  if (flowName === flows.UPLOAD_FLOW || flowName === flows.CREATE_RIPPLE || flowName === flows.CREATE_FRAME) {

    try {
      const { params, url } = await createCloudinaryUploadUrl(organization);

      const blobs: Blob[] = await createBlobsFromLocalBlobURL(ripples);

      const uploadedImages: UploadedImage[] = await uploadFilesToCloudinary(blobs, JSON.parse(params), url);


      let isFrame = false;
      if (flowName === flows.CREATE_FRAME) isFrame = true;
      const uploadedRipples: UploadedRipple[] = await uploadRipples(organization, canApproveRipple, uploadedImages, isFrame, ripples.filter(ripple => ripple.isMacaroonFrame).length > 0);

      await assignRipplesToChannel(channelId, organization, uploadedRipples, timeLimitOptions, addToFront);

      await publishChannelsToSelectedApps({ organization, channels: channelId, locationId, showOnMobile });

      return { success: true };

    } catch (err: any) {
      if (err && err[0].message.includes('73c731fa-066b-4525-ab6d-ff07e5667773')) {
        const errorTitle = '8489e112-f273-456e-9364-7da245b21a82';
        const errorContent = 'f1261ad7-0bb0-455c-b279-b2531a66eda7';
        return { success: false, error: { errorTitle, errorContent } };
      }
      return { success: false };
    }


    // I know we should indicate that the flow is complete. Will take care of it next step;
  } else if (flowName === flows.PUBLISH_FLOW) {

    try {

      let clientWarningMessage;

      const { clientWarningMessage: _clientWarningMessage } = await assignRipplesToChannel(channelId, organization, ripples, timeLimitOptions);

      const publishChannelsRes = await publishChannelsToSelectedApps({ organization, channels: channelId, locationId, showOnMobile });

      if (_clientWarningMessage) {
        clientWarningMessage = _clientWarningMessage;
      }
      else {
        clientWarningMessage = publishChannelsRes.data.publishChannels.clientWarningMessage
      }

      return { success: true, clientWarningMessage };

    } catch (err) {

      return { success: false };
    }


  } else if (flowName === flows.PUBLISH_FRAME) {

    try {

      const { clientWarningMessage } = await assignRipplesToChannel(channelId, organization, ripples, timeLimitOptions, addToFront);

      await publishChannelsToSelectedApps({ organization, channels: channelId, locationId });

      return { success: true, clientWarningMessage };

    } catch (err) {

      return { success: false };
    }

  }

  else {
    return { success: false };
  }
};
