export const ASSIGN_RIPPLES =`
  mutation assignRipples(
    $organization: String!
    $ids: [String]!
    $channelId: String!
    $startDate: String!
    $endDate: String!
    $addToFront: Boolean!
  ) {
    assignRipples(
      input: {
        clientMutationId: "1"
        organization: $organization
        ids: $ids
        channelId: $channelId
        startDate: $startDate
        endDate: $endDate
        addToFront: $addToFront
      }
    ) {
      clientMutationId
      clientWarningMessage
    }
  }
`;
