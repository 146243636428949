import Box from '@material-ui/core/Box';
import { RippleTabPanel } from '../../../primitives/styles/Ripples/RipplesStyle';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, index, value}) => {
  return (
    <RippleTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`info-tab-panel-${index}`}
      aria-labelledby={`info-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </RippleTabPanel>
  );
}

export default TabPanel;