import Phrase from '../Phrase/Phrase'
import {
    DateSelectorUnderline,
    DateSelectorWrapper,
    DateSelectorLabel,
    DateSelectorValue,
    TriangleIndicator
} from '../../primitives/styles/DatePicker/DatePickerStyles'

interface DateSelectorProps {
    openCalendar: () => void;
    label: string;
    value: string;
    isOpen: boolean;
    defaultLabel: string;
}

const DateSelector: React.FC<DateSelectorProps> = ({ openCalendar, label, value, isOpen, defaultLabel }) => {
    return (
        <DateSelectorWrapper
            onClick={openCalendar}
        >
            <DateSelectorLabel isActive={isOpen}>
                <Phrase
                    translationKey={label}
                    defaultText={defaultLabel}
                />
            </DateSelectorLabel>
            <DateSelectorValue>{value}</DateSelectorValue>
            <DateSelectorUnderline isActive={isOpen} />
            {isOpen && <TriangleIndicator/>}
        </DateSelectorWrapper>
    )
}

export default DateSelector