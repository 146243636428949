import Phrase from '../Phrase/Phrase';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import FloatingButton from '../Common/FloatingButton';
import getConfigByEnv from '../../utils/getConfigByEnv';

import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useStyles } from './headerBarStyles';
import { RippleLogoIcon } from '../../primitives/media/Icons';
import { getDefaultWebAppLocation } from '../../api/rest/getWebAppLocation';
import { MenuItemText } from '../../primitives/styles/HeaderBar/HeaderBarStyles';

import { 
  SelectLocations,
  SelectLocationShortId,
  //SelectHasPrivateLocations,
} from '../../store/locationSlice';

import {
  DrawerHeader,
  LogoIconHeader,
  DrawerMenuTitle,
} from '../../primitives/styles/HeaderBar/HeaderBarStyles';


interface MenuProps {
  isOpen: boolean;
  orgName: string;
  hasSingleOrg: boolean;
  handleClose: () => void;
  openChooseOrg: () => void;
  handleOpenHelp: () => void;
  openGoToWebappModal: () => void;
  hideGoToWebapp?: boolean;
}
const HomepageMenu = (props: MenuProps): JSX.Element => {
  const classes = useStyles();
  const { logout } = useAuth0();
  const {
    isOpen,
    orgName,
    hasSingleOrg,
    handleClose,
    openChooseOrg,
    handleOpenHelp,
    openGoToWebappModal,
    hideGoToWebapp,
  } = props;

  const locations = useSelector(SelectLocations);
  const selectedLocationShortId = useSelector(SelectLocationShortId);
  //const hasPrivateLocations = useSelector(SelectHasPrivateLocations);

  const handleChooseOrg = () => {
    handleClose();
    openChooseOrg();
  };

  const openHelpMenu = () => {
    handleClose();
    handleOpenHelp();
  };

  const onExitPortalAndBackToWebApp = () => {
    const { WEBAPP_URL } = getConfigByEnv().PORTAL;
    if ( selectedLocationShortId ) {
      window.location.replace(`${WEBAPP_URL}/?code=${selectedLocationShortId}`);
    }
    else if (locations.length === 1) {
      const { shortId } = locations[0]
      window.location.replace(`${WEBAPP_URL}/?code=${shortId}`);
    }
    else {
      handleClose();
      openGoToWebappModal();
    }
  }

  const defaultWebappLocation = getDefaultWebAppLocation()

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpen}
      classes={{
        paper: isOpen ? classes.drawerPaperOpen : classes.drawerPaperClose,
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <DrawerMenuTitle>{orgName}</DrawerMenuTitle>
        <LogoIconHeader>
          <RippleLogoIcon />
        </LogoIconHeader>
      </DrawerHeader>
      <List className={classes.menuList}>
        {!hasSingleOrg && (
          <ListItem button>
            <MenuItemText onClick={handleChooseOrg}>
              <Phrase 
                translationKey={'95357955-4491-4342-9945-c9121580f1ad'}
                defaultText={'Select organization'}
              />
            </MenuItemText>
          </ListItem>
        )}
        {!hasSingleOrg && <Divider />}
        <ListItem button>
          <MenuItemText onClick={openHelpMenu}>
            <Phrase 
              translationKey={'cff7d310-2c1f-4666-b937-8345d979a59c'}
              defaultText={'Help'}
            />
          </MenuItemText>
        </ListItem>
        <Divider/>
        {!hideGoToWebapp && (
          <ListItem button>
          <MenuItemText onClick={onExitPortalAndBackToWebApp}>
            <Phrase 
              translationKey={'68757d8b-a7e4-4113-91f7-b06646cce032'}
              defaultText={'Go to Web App'}
            />
          </MenuItemText>
          </ListItem>
        )}

        {hasSingleOrg===true && <Divider />}
      </List>
      <FloatingButton
        isDisabled={false}
        variant={'outlined'}
        position={'fixed'}
        buttonText={'b69cd63c-ef0d-4a7f-8fa2-35033791ca3a'}
        buttonDefaultText={'LOG OUT'}
        onClick={() => logout({ returnTo: defaultWebappLocation })}
      />
    </Drawer>
  );
};

export default HomepageMenu;
