import * as React from 'react';
import { useEffect } from 'react';
import Phrase from '../Phrase/Phrase';
import { CloseIcon } from '../../primitives/media/Icons';
import { Location } from '../../primitives/types/Location';
import { CloseWrapper } from '../../primitives/styles/Common/CommonStyle';

import {
  Machines,
  RippleMakersList,
  RippleMakerListItem,
  RippleMakersListTitle,
  RippleMakersListClose,
  RippleMakersListHeader,
  RippleMakersListContent,
  RippleMakersModal as Modal,
  RippleMakersListItemsWrapper,
} from '../../primitives/styles/Location/Location';

interface RippleMakersProps {
  location: Location;
  isOpenModal: boolean;
  handleCloseModal: () => void;
}

const RippleMakersModal: React.FC<RippleMakersProps> = ({
  location,
  isOpenModal,
  handleCloseModal,
}) => {

  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });

  const { rippleMakers } = location;
  return (
    <>
      {isOpenModal && (
        <Modal>
          <RippleMakersList>
            <RippleMakersListHeader>
              <RippleMakersListClose>
                <CloseWrapper style={{ padding: 0 }} onClick={handleCloseModal}>
                  <CloseIcon/>
                </CloseWrapper>
              </RippleMakersListClose>
              <RippleMakersListTitle>{location.name}</RippleMakersListTitle>
              <Machines hasSingleMachine={false}>
                <Phrase
                  translationKey={'1e742613-2441-4472-be18-f0c2377ae300'}
                  defaultText={`${rippleMakers.length} Machines`}
                  vars={{X: String(rippleMakers.length)}}
                />
                
              </Machines>
            </RippleMakersListHeader>
            <RippleMakersListContent >
              <RippleMakersListItemsWrapper
                onBlur={(e) => e.currentTarget.focus()}
              >
                  {rippleMakers.map((rm) => (
                    <RippleMakerListItem 
                      key={rm.serial}
                      onTouchStart={(e) => e.currentTarget.parentElement?.focus()} 
                    >
                      {rm.serial}
                    </RippleMakerListItem>
                  ))}
              </RippleMakersListItemsWrapper>
            </RippleMakersListContent>
          </RippleMakersList>
        </Modal>
      )}
    </>
  );
};

export default RippleMakersModal;
