import styled from 'styled-components';

export const FrameControls = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #000;
  height: ${(props) => props.theme.deviceHeight}px;
  width: 100vw;
  border: none;  
  z-index: 100;
  color: #fff;
`;

export const FrameControlsHeader = styled.div`
  text-align: center;
  color: #E86161;
  font-size: calc(22px * ${(props) => props.theme.ratio});
  padding: calc(15px * ${(props) => props.theme.ratio}) 0;
  & #frame-controls-close {
    position: absolute;
    top: 0;
    right: 0;
  }
`;


export const TextControlWrapper = styled.div`
  margin: calc(25px * ${(props) => props.theme.ratio}) calc(30px * ${(props) => props.theme.ratio});
  color: #828282;
  font-size: calc(12px * ${(props) => props.theme.ratio});
  & input {
    border: none;
    border-bottom: 1px #828282 solid;
    display: block;
    width: calc(100% - 20px);
    background: transparent;
    color: #fff;
    font-size: calc(16px * ${(props) => props.theme.ratio});
    padding: 10px;
    &:focus-visible {
      outline: none;
    }
  }
  & .MuiInputBase-root {
    display: block;
    color: #fff;
    background: transparent;
    border: 1px #828282 solid;
    padding: 5px 10px;
    margin: 10px 0;
    & .MuiSelect-icon {
      color: #E86161;
    }
  }
  & .MuiInput-underline:after {
    border: none;
  }
  & .selectedImage {
    display: block;
    margin: 10px auto;
    border: 1px #E86161 solid;
    max-height: 80px;
    max-width: 200px;
    border-radius: 2px;
    padding: 2px;
    background-color: #fff;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
      linear-gradient(135deg, #ccc 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #ccc 75%),
      linear-gradient(135deg, transparent 75%, #ccc 75%);
    background-size: 12px 12px; /* Must be a square */
    background-position: 0 0, 6px 0, 6px -6px, 0px 6px; /* Must be half of one side of the square */
  }
  & .fileInputElement {
    display: none;
  }

  & .fileInputLabel {
    display: block;
    margin: 20px auto;
    text-align: center;
    width: calc(212px * ${(props) => props.theme.ratio});
    height: calc(48px * ${(props) => props.theme.ratio});
    border: 1px #828282 solid;
    border-radius: calc(25px * ${(props) => props.theme.ratio});
    font-family: Brandon Grotesque;
    font-size: calc(18px * ${(props) => props.theme.ratio});
    line-height: 2.3em;
  }
`;

export const FontSelectorPseudoSelect = styled.div`
  color: #fff;
  border: 1px #828282 solid;
  padding: 10px;
  font-size: calc(16px * ${(props) => props.theme.ratio});
  border-radius: 1px;
  margin-top: 10px;
  position: relative;
  &::after {
    content: "▼";
    position: absolute;
    top: 12px;
    right: 10px;
    color: #E86161;
    font-size: 0.9em;
  }
`;

export const TemplateSelectorWrapper = styled.div`
  margin: 0 auto;
  max-width: 90vw;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 40px; 

  & .template {
    display: inline-block;
    border-width: 2px;
    border-color: transparent;
    border-style: solid;
    border-radius: 15%;
    margin: 4px;
    opacity: 50%;
    & img {
      width: 80px;
      height: 80px;
      margin: 7px 6px 0px 6px;
      border-radius: 50px;
      background-color: #fff;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
        linear-gradient(135deg, #ccc 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #ccc 75%),
        linear-gradient(135deg, transparent 75%, #ccc 75%);
      background-size: 6px 6px; /* Must be a square */
      background-position: 0 0, 3px 0, 3px -3px, 0px 3px; /* Must be half of one side of the square */
    }
  }

  & .template.selected {
    border-color: #E86161;
    opacity: 100%;
  }
`;


export const FramesContainerWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: calc( 105.16px * ${(props) => props.theme.ratio});
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
`;

export const FramesContainerSpacer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;