import Phrase from '../Phrase/Phrase';

import { FontSize } from '../../primitives/types/FontSize';
import {
  MainTitle,
  SubTitle,
  FlowTitle,
  ErrorTitle,
} from '../../primitives/styles/Title/TitleStyle';

interface MainTitleProps {
  type: 'main' | 'sub' | 'flow' | 'error';
  size: FontSize;
  text: string;
  defaultText: string;
  style?: Object
}

const Title = (props: MainTitleProps): JSX.Element => {
  const { type, size, text, defaultText, style = {} } = props;

  switch (type) {
    case 'main':
      return (
        <MainTitle size={size} style={style}>
          <Phrase translationKey={text} defaultText={defaultText} />
        </MainTitle>
      );
    case 'sub':
      return (
        <SubTitle size={size} style={style}>
          <Phrase translationKey={text} defaultText={defaultText} />
        </SubTitle>
      );
    case 'flow':
      return (
        <FlowTitle size={size} style={style}>
          <Phrase translationKey={text} defaultText={defaultText} />
        </FlowTitle>
      );
    case 'error':
      return (
        <ErrorTitle size={size} style={style}>
          <Phrase translationKey={text} defaultText={defaultText} />
        </ErrorTitle>
      );
    default:
      return <></>;
  }
};
export default Title;
