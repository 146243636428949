import Phrase from '../../../../../components/Phrase/Phrase';
import { NoteContainer, NoteDivider } from '../../../../../primitives/styles/Onboarding/Onboarding';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface NoteProps {
  defaultText: string;
  translationKey: string;
  style?: Object
}

const Note: React.FC<NoteProps> = ({
  defaultText,
  translationKey,
  style
}) => {
  return (
    <NoteContainer style={style}>
      <InfoOutlinedIcon htmlColor='#e86161' />
      <NoteDivider/>
      <Phrase defaultText={defaultText} translationKey={translationKey}/>
    </NoteContainer>
  );
};

export default Note;