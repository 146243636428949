import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

const getOrganizationById = (organizationId: string) => {
  const url = `${
    getConfigByEnv().PORTAL.REST_API_URL
  }/organizations/byOrgId/${organizationId}`;

  return axios.get(url).then(data => data);

};

export default getOrganizationById;
