import { useState } from 'react';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined';
import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'

import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, Option, LocationNameContainer } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import Note from './components/Note';
import fontSize from '../../../../constants/FontSize';
import ContactSupport from './components/ContactSupport';
import LocationButton from '../../components/LocationButton';

interface LocationContainerProps {
  locationName: string;
  onOk: () => void;
  onBack: () => void;
  theme: any
  serial?: string
  supportDetails?: {
    phone?: string;
    email?: string;
    url?: string;
    label?: string;
  }
}

const LocationContainer: React.FC<LocationContainerProps> = ({
  locationName,
  onOk,
  onBack,
  theme,
  serial,
  supportDetails
}) => {
  const [isContactSupportOpen, setIsContactSupportOpen] = useState(false)
  const { ratio } = theme;

  const titlesStyle = {
    whiteSpace: 'pre-line', textAlign: 'left', textShadow: 'none', minHeight: '20%', height: 'fit-content'
  };

  return (
    <Container>
      <BreadcrumbsHeader currentStep={0} totalSteps={0} flow={'location'} onBack={onBack} shouldRenderClose={false} />
      <OnboardingContainer>
        <Note defaultText={'The location helps us identify and\n manage your Ripple Maker'} translationKey={'cfc9a556-aea4-4766-86fb-8657df14d5dd'} style={{ height: '20%', marginTop: '5%' }} />
        <Title type="flow" text={TITLES.LOCATION} defaultText={'Is this your Ripple Maker’s location?'} size={fontSize.LARGE} style={titlesStyle} />
        <LocationNameContainer>
          <LocationOnOutlined style={{ marginRight: `calc( 12px * ${ratio})` }} />
          {locationName}
        </LocationNameContainer>
        <LocationButton onClick={onOk} style={{ width: `calc(300px * ${ratio})` }} containerStyle={{ height: '15%' }}>
          <Phrase defaultText={'YES, THAT’S THE LOCATION'} translationKey={'23936f1f-d7f0-4d02-b005-17329bfd70b6'} />
        </LocationButton>
        <Option onClick={() => setIsContactSupportOpen(true)}>
          <Phrase translationKey={'8c670265-d531-4c86-9679-a775a6cd471a'} defaultText={'No, it\'s not'} />
        </Option>
      </OnboardingContainer>
      {isContactSupportOpen && <ContactSupport serial={serial} supportDetails={supportDetails} closeSupportModal={() => setIsContactSupportOpen(false)} />}
    </Container>
  );
};

export default LocationContainer;