import WizardContainer from './WizardContainer';
import RipplesContainer from '../Ripples/RipplesContainer';
import ChannelContainer from '../Channel/ChannelContainer';
import PublishContainer from '../PublishChannels/PublishChannelsContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';
const PublishWizardContainer: React.FC = () => {
  const initialSteps:Readonly<WizardSteps[]> = [
    {
      name: 'ripples',
      next: 'location',
      component: RipplesContainer,
    },
    {
      name: 'location',
      next: 'channel',
      component: SelectLocationContainer,
    },
    {
      name: 'channel',
      next: 'publish',
      component: ChannelContainer,
    },
    {
      name: 'publish',
      next: '/',
      component: PublishContainer,
    },
  ];
  return <WizardContainer initialSteps={initialSteps} flowName="publish" />;
};

export default PublishWizardContainer;
