import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position:'fixed',
        top: '100px',
        width: '100%',
        backgroundColor: '#e86161',
        zIndex: 13
    },
    text: {
        color: 'white',
        fontFamily: 'Brandon Grotesque',
        fontWeight: 390,
        lineHeight: '31px',
        fontStyle: 'normal',
        letterSpacing: '0.05em'
    },
    actions: {
        justifyContent: 'flex-end'
    },
    button: {
        color: 'white',
        fontSize: '16px',
        borderBottom: '1px solid white',
        borderRadius: 0,
        margin: '0 16px 10px 0',
        paddingBottom: 0
    },
    content: {
        paddingBottom: 0,
        marginLeft: '10px'
    }
}))

export default useStyles;