import Phrase from "../../../../../components/Phrase/Phrase"
import { NotFoundContainer } from "../../../../../primitives/styles/Onboarding/Location"

interface NotFoundProps {
    onClick: () => void;
  }

const NotFoundComponent: React.FC<NotFoundProps> = ({onClick}) => {
    return <NotFoundContainer onClick={onClick}>
      <Phrase translationKey='e6a0e7d8-6f47-4786-b036-43a38be5ac3e' defaultText='Not found it'/>
      {'\u00A0'}
      <span style={{textDecoration: 'underline'}}>
      <Phrase translationKey='6303e86c-06e1-4206-a1a9-3b02f2432ddb' defaultText='Create New'/>

      </span>
    </NotFoundContainer>
  }

export default NotFoundComponent