import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const createCheckboxStyles = (isDisabled: boolean, ratio = 1, size?: string) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        transform: `scale(${ratio})`,
        color: 'white',
        '& .MuiSvgIcon-root': {
          ...(size === 'medium' ? {
            width: 25,
            height: 25
          } : null)
        },
        '&$checked': {
          color: isDisabled ? 'grey' : '#E86161',
          '& .MuiIconButton-label': {
            position: 'relative',
            zIndex: 0,
          },
          '& .MuiIconButton-label:after': {
            content: '""',
            ...(size === 'medium' ? {
              height: 15,
              width: 15,
            } : {
              left: 4,
              top: 4,
              height: 15,
              width: 15,
            }),
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: -1,
          },
        },
      },
      checked: {},
      formLabelRoot: {
        marginRight: 0,
        ...(size === 'medium' ? {
          marginLeft: -8
        } : null)
      },
    })
  );
};

export const createButtonStyles = (isButtonTouched: boolean, ratio: number) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        minWidth: `calc(112px * ${ratio})`,
        borderRadius: `calc(25px * ${ratio})`,
        paddingTop: `calc(13px * ${ratio})`,
        paddingBottom: `calc(13px * ${ratio})`,
        paddingLeft: `calc(21px * ${ratio})`,
        paddingRight: `calc(21px * ${ratio})`,
        fontFamily: 'Brandon Grotesque',
        fontWeight: 600,
        alignSelf: 'center',
        fontSize: `calc(16px * ${ratio})`,
        zIndex: 10,
        "&:hover": {
          background: "#E86161 !important"
        },
        "&:focus": {
          background: "#E86161 !important"
        },
        '&:after': {
          transition: 'none'
      }
      },
      contained: {
        color: /*isButtonTouched ? '#E86161' :*/ '#FFFFFF',
        background: /*isButtonTouched ? '#FFFFFF' :*/ '#E86161',
        "&:hover": {
          background: "#E86161 !important"
        },
        "&:focus": {
          color: '#FFFFFF',
          background: "#E86161 !important"
        }
      },
      outlined: {
        color: /*isButtonTouched ? '#FFFFFF' :*/ '#E86161',
        background: /*isButtonTouched ? '#E86161' :*/ '#FFFFFF',
        border: '2px solid #E86161',
        "&:focus": {
          color: '#E86161',
          background: "#FFFFFF !important"
        },
      },
    })
  );
};

export const createFloatingButtonStyles = (
  isButtonTouched: boolean,
  ratio: number,
  position: 'fixed' | 'absolute' | 'sticky' | 'static' = 'fixed'
) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '&$buttonDisabled': {
          backgroundColor: '#E1E1E1 !important',
          color: '#E86161 !important',
        },
        width: `calc(212px * ${ratio})`,
        height: `calc(48px * ${ratio})`,
        borderRadius: `calc(25px * ${ratio})`,
        paddingTop: 13,
        paddingBottom: 13,
        position: position,
        fontFamily: 'Brandon Grotesque',
        fontWeight: 600,
        alignSelf: 'center',
        fontSize: `calc(16px * ${ratio})`,
        bottom: `calc(25px * ${ratio})`,
        zIndex: 99,
      },
      contained: {
        color: '#FFFFFF',
        background: '#E86161',
        boxShadow: '1px 65px 20px 70px rgba(0,0,0,0.6)',
        '&$buttonDisabled': {
          backgroundColor: '#E1E1E1 !important',
          color: '#FFFFFF !important',
        },
        '&:hover': {
          backgroundColor: '#E86161 !important',
      }
      },
      outlined: {
        color: '#E86161',
        background: '#FFFFFF',
        border: '2px solid #E86161',
        '&$buttonDisabled': {
          backgroundColor: '#FFFFFF !important',
          color: '#E1E1E1 !important',
        },
      },
      buttonDisabled: {},
    })
  );
};

export const createBadgeStyles = (ratio = 1) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        zIndex: 2,
        transform: `scale(${1.35 * ratio})`,
        position: 'absolute',
        bottom: `calc(23px * ${ratio})`,
        left: `calc(23px * ${ratio})`,
        width: 'auto',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      badge:{
        background: '#E86161',
      },
      checkedIcon: {
        zIndex: 2,
        transform: `scale(${0.7 * ratio})`,
        position: 'absolute',
        color: 'white',
        bottom: `calc(-12px * ${ratio})`,
        left: `calc(12px * ${ratio})`,
      },
    })
  );
};



