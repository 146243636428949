import styled, { keyframes } from 'styled-components';
import { global } from '../Global/global';

export const LoadingWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${global.userSelectNone}
`;
const rplLoader = keyframes`
0% {
    transform: scale(.2);
    opacity: 0
  }

  30% {
    opacity: 1
  }

  70% {
    opacity: .7
  }

  100% {
    transform: scale(1);
    opacity: 0
  }
 `;

const rplLogo = keyframes`
0% {
  transform: scale(1);
}

30% {
  transform: scale(.8);
}

100% {
  transform: scale(1);
  opacity: 0;
}
`;

export const LoaderLayersWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${global.userSelectNone}
`;
export const Logo = styled.div`
  position: absolute;
  width: calc(55.53px * ${(props) => props.theme.ratio});
  height: calc(55.53px * ${(props) => props.theme.ratio});
  border-radius: 50%;
  animation: ${rplLogo} 4s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  ${global.userSelectNone}
`;
export const FirstLayerRing = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(65px * ${(props) => props.theme.ratio});
  height: calc(65px * ${(props) => props.theme.ratio});
  border: 1px solid ${(props) => props.theme.palette.red};
  border-radius: 50%;
  animation: ${rplLoader} 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  ${global.userSelectNone}
`;

export const SecondLayerRing = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(80px * ${(props) => props.theme.ratio});
  height: calc(80px * ${(props) => props.theme.ratio});
  border: 1px solid ${(props) => props.theme.palette.red};
  border-radius: 50%;
  animation: ${rplLoader} 1.5s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  ${global.userSelectNone}
`;

export const ThirdLayerRing = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(95px * ${(props) => props.theme.ratio});
  height: calc(95px * ${(props) => props.theme.ratio});
  border: 1px solid ${(props) => props.theme.palette.red};
  border-radius: 50%;
  animation: ${rplLoader} 2s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  ${global.userSelectNone}
`;
