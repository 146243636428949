export const CREATE_LOCATION = `
mutation createRippleMakerLocation(
  $name: String!
  $address: String!
  $displayedName: String
  $displayedAddress: String
  $city: String!
  $organization: String!
  $zipCode: String
  $contactFirstName: String
  $contactLastName: String
  $contactEmail: String
  $contactPhoneNumber: String
  $country: String!
  $state: String
  $latitude: Float!
  $longitude: Float!
  $notes: String
  $isPrivate: Boolean
  $isForEvents: Boolean
  $isWebappEnabled: Boolean
  $logoUrl: String
) {
  createRippleMakerLocation(
    input: {
      clientMutationId: "1"
      name: $name
      address: $address
      displayedName: $displayedName
      displayedAddress: $displayedAddress
      city: $city
      organization: $organization
      zipCode: $zipCode
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactEmail: $contactEmail
      contactPhoneNumber: $contactPhoneNumber
      country: $country
      state: $state
      latitude: $latitude
      longitude: $longitude
      notes: $notes
      isPrivate: $isPrivate
      isForEvents: $isForEvents
      isWebappEnabled: $isWebappEnabled
      logoUrl: $logoUrl
    }
  ) {
    rippleMakerLocation {       
      id       
    }
  }
}
`;