
import { useHistory } from 'react-router';

declare global {
  interface Window { flow: any; }
}
window.flow = window.flow || {};

const HistoryListenWrapper = ({ children }: { children: JSX.Element }) => {

  const history = useHistory();
 
  history.listen( (location: any) => {
    window.history.pushState({}, '', `${window.location.pathname}`)
    window.scrollTo(0, 0);
  })

  const onLoad = () => {
    if (window.location.pathname !== '/portal' &&
      !window.location.search.includes('code') &&
      !window.location.pathname.includes('onboarding')) {
      history.replace('/portal', {});
    }
  };

  window.addEventListener('load', onLoad);

  window.addEventListener('contextmenu', function (event: any){
    // enable context menu when we would like it to work
    if (event.target.className && event.target.className.includes('allow-context')) {
      return false;
    } 
    event.preventDefault();
    event.stopPropagation();
    return false;
  });


  return ( children );
};


export default HistoryListenWrapper;