import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';
import { OnBoardingStates } from './setOnboardingState';

const getOnboardingState = async (serial: string): Promise<OnBoardingStates> => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/makers/machines/${serial}/onboarding`;
    
      const { data } = await axios.get(url)
      return data.data
}

export default getOnboardingState;