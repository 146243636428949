import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import SetLocationName from './SetLocationName';
import SetLocationAddress from './SetLocationAddress';

const UNITED_STATES_CODE = '223';

interface CreateLocationProps {
  theme: any;
  onNext: (values: any) => Promise<void>;
  organizationId?: string
  onBack: () => void;
  onClose: () => void;
}

export interface CreateLocationForm {
    name?: string;
    country?: string;
    state?: string;
    city?: string;
    postCode?: string;
    streetAddress?: string;
}

enum CreateLocationSteps {
    NAME = 1,
    ADDRESS = 2
}

const validate = (values: any) => {
    const errors: CreateLocationForm = {};
    if (!values.name) {
        errors.name = 'required'
    };

    if (!values.country) {
        errors.country = 'required'
    };

    if (values.country === UNITED_STATES_CODE && !values.state) {
        errors.state = 'required'
    };

    if (!values.city) {
        errors.city = 'required'
    };

    if (!values.streetAddress) {
        errors.streetAddress = 'required'
    };

    return errors;
}

const CreateLocation: React.FC<CreateLocationProps> = ({
  theme,
  onNext,
  onBack,
  onClose
}) => {
    const [currentStep, setCurrentStep] = useState<CreateLocationSteps>(CreateLocationSteps.NAME);
    const [longitude, setLongitude] = useState<number | undefined>();
    const [latitude, setLatitude] = useState<number | undefined>();

    const handleSubmit = async () => {
        try {
            await onNext({...formik.values, longitude, latitude});
        } catch (err) {
            console.log({err})
        }
    }
    
    const formik: FormikProps<CreateLocationForm> = useFormik<CreateLocationForm>({
        initialValues: {
            name: '',
            country: '',
            state: '',
            city: '',
            postCode: '',
            streetAddress: ''
        },
        validate,
        onSubmit: handleSubmit
    })

    switch (currentStep) {
        case CreateLocationSteps.NAME:
            return <SetLocationName onBack={onBack} currentStep={CreateLocationSteps.NAME} totalSteps={2} onNext={() => setCurrentStep(CreateLocationSteps.ADDRESS)} theme={theme} form={formik} onClose={onClose}/>

        case CreateLocationSteps.ADDRESS:
            return <SetLocationAddress setLongitude={setLongitude} setLatitude={setLatitude} onBack={() => setCurrentStep(CreateLocationSteps.NAME)} currentStep={CreateLocationSteps.ADDRESS} onCreateLocation={formik.handleSubmit} theme={theme} totalSteps={2} form={formik} onClose={onClose}/>
    }
}

export default CreateLocation;