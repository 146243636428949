import { get } from "lodash";
import { CREATE_FRAME } from "../api/graphQl/mutation";
import { defaultFrameMetadata } from "../constants/Flow";
import { CreateFrame } from "../primitives/types/CreateFrame";
import { gqlClient } from "../utils/gqlClient";
import { UploadedImage, UploadedRipple, createBlobsFromLocalBlobURL, createCloudinaryUploadUrl, uploadFilesToCloudinary } from "./ripplesService";
import { CHANNELS_ORDER } from "../containers/Channel/PublishButtons";
import { Ripple } from "../primitives/types/Ripples";
import { PUBLISH_FRAMES } from "../api/graphQl/mutation/publishFrames";

type PublishFrames = {
    organization: string;
    frames: Ripple[];
    locationId: string;
    channelsOrder: string;
    shouldPublish: boolean
}

export const createFrame = ({
    url,
    fingerprint,
    organization,
    languageCode,
    isShareable,
    tags,
}: CreateFrame) => {
    return gqlClient.mutate(
        CREATE_FRAME, {
        url,
        fingerprint,
        organization,
        languageCode,
        isShareable,
        tags,
    })
};

export const uploadFrames = (
    organization: string,
    uploadedImages: UploadedImage[],
): Promise<UploadedRipple[]> => {
    const FRAME_ID = 'createFrame.frame.id';
    const FRAME_IMAGE = 'createFrame.frame.rippleImage.thumbnailUrl';

    return Promise.all(
        uploadedImages.map(async ({ url, phash: fingerprint }) => {
            return createFrame({
                url,
                fingerprint,
                organization,
                ...defaultFrameMetadata
            }).then(({ data }) => ({
                id: get(data, FRAME_ID),
                image: get(data, FRAME_IMAGE)
            }))
                .then(({ id, image }: { id: string, image: string }) => ({ id, image }))
                .catch(err => Promise.reject(err));
        })
    );
};

export const publishFramesToSelectedApps = (
    {
        organization,
        frames,
        locationId,
        channelsOrder,
    }:
        {
            organization: string;
            frames: string | string[];
            locationId: string;
            channelsOrder: string;
        }

) => {
    let ids = [frames];
    if (Array.isArray(frames)) {
        ids = frames
    }
    return gqlClient.mutate(PUBLISH_FRAMES, {
        ids,
        organization,
        locationId,
        channelsOrder: channelsOrder || CHANNELS_ORDER.LAST,
    })
}

export const createAndPublishFrames = async ({ organization, frames, locationId, channelsOrder, shouldPublish }: PublishFrames):
    Promise<{ success: boolean, clientWarningMessage?: string, error?: any }> => {

    try {
        const { params, url } = await createCloudinaryUploadUrl(organization);

        const blobs: Blob[] = await createBlobsFromLocalBlobURL(frames);

        const uploadedImages: UploadedImage[] = await uploadFilesToCloudinary(blobs, JSON.parse(params), url);

        const uploadedFrames: UploadedRipple[] = await uploadFrames(organization, uploadedImages);

        if (shouldPublish) await publishFramesToSelectedApps({ organization, frames: uploadedFrames.map(({ id }) => id), locationId, channelsOrder });

        return { success: true };
    } catch (err: any) {
        if (err && err[0].message.includes('73c731fa-066b-4525-ab6d-ff07e5667773')) {
            const errorTitle = '8489e112-f273-456e-9364-7da245b21a82';
            const errorContent = 'f1261ad7-0bb0-455c-b279-b2531a66eda7';
            return { success: false, error: { errorTitle, errorContent } };
        }
        return { success: false };
    }
};
