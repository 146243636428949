import GA from '../../utils/analytics';
import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loader';
import Checkbox from '../../components/Common/Checkbox';
import RipplesList from '../../components/Ripple/RipplesList';
import FloatingButton from '../../components/Common/FloatingButton';
import ConnectionError from '../../components/Errors/ConnectionError';
import SelectedTags from '../../components/Ripple/SelectedTagsList';
import AutocompleteSearch from '../../components/Common/AutocompleteNew';
import getInheritedTagsByOrgId from '../../api/rest/getInheritedTagsByOrgId';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';
import RippleInfoModal from '../../components/Ripple/InfoModal/RippleInfoModal';
import RippleWalkThroughModal from '../../components/Ripple/RippleWalkThroughModal';

import { get, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { gqlClient } from '../../utils/gqlClient';
import { SelectOrgId } from '../../store/userSlice';
import { SetRipples } from '../../store/ripplesSlice';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { SelectCanAssign } from '../../store/userSlice';
import { getCookie, setCookie } from '../../utils/cookies';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { RippleState } from '../../primitives/types/RippleState';
import { SelectTotalSteps, SetStepForward } from '../../store/flowSlice';
import {
  Ripple,
  RippleInfo,
  RippleByTag,
} from '../../primitives/types/Ripples';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import { GET_INHERITED_RIPPLE_INFO } from '../../api/graphQl/query/getInheritedRippleInfo';
import { GET_INHERIDTED_RIPPLES } from '../../api/graphQl/query/findInheritedRipplesByTags';
import { GET_INHERITED_FRAME_INFO } from '../../api/graphQl/query/getInheritedFrameInfo';
import { GET_INHERIDTED_FRAMES } from '../../api/graphQl/query/findInheritedFramesByTags';
import {
  HeadingWrapper,
  DetailsWrapper,
} from '../../primitives/styles/Ripples/RipplesStyle';

const COOKIE_NAME = 'isPublishRipplesPageVisited';
const RIPPLES_INFO_PATH = 'viewer.organizationScope.inheritedRipple';
const FRAMES_INFO_PATH = 'viewer.organizationScope.inheritedFrame';
const RIPPLES_DATA_PATH = 'viewer.organizationScope.inheritedRipples';
const FRAMES_DATA_PATH = 'viewer.organizationScope.inheritedFrames';
const RIPPLES_PATH = 'viewer.organizationScope.inheritedRipples.edges';
const FRAMES_PATH = 'viewer.organizationScope.inheritedFrames.edges';

interface RipplesContainerProps {
  path: string;
  flowName: FlowName;
  currentStep: number;
  nextPath: () => void;
}
const RipplesContainer: React.FC<RipplesContainerProps> = ({
  path,
  flowName,
  nextPath,
  currentStep,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(SelectOrgId);
  const canAssign = useSelector(SelectCanAssign);
  const totalSteps = useSelector(SelectTotalSteps);
  const isFrame = flowName === 'publishFrames' ? true : false;
  // const keyboardIsOpen = useSelector(SelectDeviceKeyboardIsOpen);

  /*
    since these params are passed as props to child components
    which expect specific types, we cannot set them to null.
    therefore we initialize the state as an array of the desired type  
  */
  /*******************************************************************/
  const stringArray: string[] = [];
  const initRippleState: RippleByTag[] = [];
  const initRippleInfoState: RippleInfo[] = [];
  /******************************************************************/

  const [tags, setTags] = useState(stringArray);
  const [isLoading, setIsLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [orgTags, setOrgTags] = useState(stringArray);
  const [loadingMore, setLoadingMore] = useState(false);
  const [ripples, setRipples] = useState(initRippleState);
  const [lastPageCursor, setLastPageCursor] = useState('');
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const [ripplesTotalCount, setRipplesTotalCount] = useState(0);
  const [connectionError, setConnectionError] = useState(false);
  const [rippleInfo, setRippleInfo] = useState(initRippleInfoState);
  const [openRippleInfoModal, setOpenRippleInfoModal] = useState(false);
  const [openWalkThroughModal, setOpenWalkThroughModal] = useState(false);
  const [selectedRipplesList, setSelectedRipplesList] = useState(
    initRippleState
  );

  const handleCloseConnectionError = () => {
    setConnectionError(false);
  };

  const onConnectionError = () => {
    setConnectionError(true);
    setIsLoading(false);
  };

  const handleSetKeyboardOpen = (isOpen: boolean) => {
    setKeyboardIsOpen(isOpen);
  };

  /* 
    the submit function that fires when 'NEXT' button pressed
     creates and sets the ripplesReducer payload from the selected ripples
     and calls the next path
  */
  const submitRipplesSelection = () => {
    const ripples: Ripple[] = selectedRipplesList.map((rpl, index) => {
      const ripple: Ripple = {
        key: index,
        id: rpl.node.id,
        image: rpl.node.rippleImage.thumbnailUrl,
      };
      return ripple;
    });
    const payload: RippleState = {
      hasImages: true,
      ripples,
    };
    dispatch(SetRipples(payload));
    dispatch(SetStepForward({ currentStep, path }));
    nextPath();
  };

  /*
   the gets the info for the selected (long press) ripple 
   from the shared ripples library
  */
  const getRippleInfo = (rippleId: string) => {
    return gqlClient.query(
      isFrame ? GET_INHERITED_FRAME_INFO : GET_INHERITED_RIPPLE_INFO,
      {
        orgId,
        ...(isFrame ? { frameId: rippleId } : { rippleId }),
        canViewOnlyOwn: false,
      }
    );
  };

  /*
    this function is fired once a long press on ripple is detected
    it will first open the modal and then populate it with data
    meanwhile, a nice loader is rendered 
  */
  const handleDisplayRippleInfo = (rippleId: string) => {
    setOpenRippleInfoModal(true);
    getRippleInfo(rippleId)
      .then(({ data }) =>
        get(data, isFrame ? FRAMES_INFO_PATH : RIPPLES_INFO_PATH)
      )
      .then((rippleInfo: RippleInfo) => setRippleInfo([rippleInfo]))
      .catch(onConnectionError);
  };

  // the ripple info modal close handler
  const handleCloseRippleInfoModal = () => {
    setOpenRippleInfoModal(false);
    setRippleInfo([]);
  };

  // add ripple to list when selected
  const addToSelectedRipplesList = (ripple: RippleByTag) => {
    setSelectedRipplesList((selectedRipples) => [...selectedRipples, ripple]);
  };

  // remove the unselected ripple from the selected ripples list
  const removeFromSelectedRipplesList = (ripple: RippleByTag) => {
    const newRipplesState = selectedRipplesList.filter(
      (r) => r.node.id !== ripple.node.id
    );
    setSelectedRipplesList(newRipplesState);
  };

  // returns a promise to get the ripples from shared library
  const getRipplesByTags = (variables: any) => {
    return gqlClient.query(
      isFrame ? GET_INHERIDTED_FRAMES : GET_INHERIDTED_RIPPLES,
      variables
    );
  };

  // the implementation of the get ripple promise with updated variables
  const getAndSetRipples = () => {
    const variables = {
      isFrame,
      tags: tags,
      orgId: orgId,
      pageSize: 60,
      lastPageCursor: '',
      canViewOnlyOwn: false,
    };
    getRipplesByTags(variables)
      .then(({ data }) =>{
        return get(data, isFrame ? FRAMES_DATA_PATH : RIPPLES_DATA_PATH)
      })
      .then((ripplesData) => {
        const ripples: RippleByTag[] = ripplesData.edges;
        const totalCount: number = ripplesData.totalCount;
        setRipples(ripples);
        setRipplesTotalCount(totalCount);
        setIsLoading(false);
      })
      .catch((err: Error) => {
        if (err.message === 'Network Error') {
          onConnectionError();
        } else {
          setRipples([]);
          setRipplesTotalCount(0);
          setIsLoading(false);
        }
      });
  };

  // load more ripples when the target ripple is visible
  const loadMore = (cursor: string) => {
    setLastPageCursor(cursor);
  };

  // adds the selected tag to the list.
  const handleSelectTags = (
    selectedTag: { tag: string; count: number } | null
  ) => {
    if (selectedTag) {
      GA.event({
        category: 'Search',
        action: selectedTag.tag,
        label: navigator.language,
      });

      const { tag } = selectedTag;
      if (tags.indexOf(tag) === -1) {
        setTags((prevTags) => [...prevTags, tag]);
      }
    }
  };

  // removes specific tag from the list on click
  const handleRemoveTag = (selectedTag: string) => {
    const newState = tags.filter((tag) => tag !== selectedTag);
    setTags(newState);
  };

  /*
    the walk through modal will open if the user did not visit this page
    for at least 60 days. Once the 'GOT IT' button is pressed, we set
    the cookie for this page and close the modal
  */
  const closeWalkThroughModal = () => {
    const cookie = { [COOKIE_NAME]: true };
    const validThrough = 60; /*days*/
    setCookie(COOKIE_NAME, cookie, validThrough);
    setOpenWalkThroughModal(false);
  };

  /*
    on page load we first check if the page has been visited
    if not, we first render the walk Through modal.
    otherwise, we "refresh" the cookie to be valid for 60 days
  */
  useEffect(() => {
    const cookie = getCookie(COOKIE_NAME);
    const hasBeenVisited = cookie[COOKIE_NAME];
    if (!hasBeenVisited) {
      setOpenWalkThroughModal(true);
    } else {
      //extend the cookie
      const validThrough = 60; /*days*/
      setCookie(COOKIE_NAME, cookie, validThrough);
    }
  }, []);

  // initialize the ripple container
  useEffect(() => {
    if (canAssign) {
      setIsLoading(true);
      getAndSetRipples();
      getInheritedTagsByOrgId(orgId,isFrame)
        .then(({ data }) => {
          setOrgTags(data);
        })
        .catch((err: Error) => {
          if (err.message === 'Network Error') {
            onConnectionError();
          }
        });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  // refetch ripples if tags added or removed
  useEffect(() => {
    if (canAssign) {
      setIsLoading(true);
      getAndSetRipples();
    }
    // eslint-disable-next-line
  }, [tags]);

  /* 
    when the lastPageCursor is changed (the last ripple from the list is reached by scrolling down) 
    we run the getRipplesByTags query to fetch the next page
  */
  useEffect(() => {
    if (lastPageCursor) {
      const variables = {
        tags,
        orgId,
        isFrame,
        pageSize: 60,
        lastPageCursor,
        canViewOnlyOwn: false,
      };
      setLoadingMore(true);
      getRipplesByTags(variables)
        .then(({ data }) => get(data, isFrame ? FRAMES_PATH : RIPPLES_PATH))
        .then((ripples: RippleByTag[]) =>
          setRipples((prev) => {
            return prev.concat(ripples);
          })
        )
        .then(() => {
          setLoadingMore(false);
        });
    }
    // eslint-disable-next-line
  }, [lastPageCursor]);

  // handles the select / unselect all
  useEffect(() => {
    if (selectAll) {
      setSelectedRipplesList(ripples);
    } else {
      setSelectedRipplesList(initRippleState);
    }
    // eslint-disable-next-line
  }, [selectAll]);

  /* 
    if loading more ripples while 'Select all' is checked, make sure to add
    all ripples to the selected ripples list
  */
  useEffect(() => {
    if (selectAll) {
      setSelectedRipplesList(ripples);
    }
    // eslint-disable-next-line
  }, [ripples]);

  const isModalOpen = openWalkThroughModal || openRippleInfoModal;
  const showFloatingButton = !isEmpty(selectedRipplesList) && !keyboardIsOpen;

  return (
    <>
      <Container id={'ripples-container'} isModalOpen={isModalOpen}>
        <BreadcrumbsHeader
          totalSteps={totalSteps}
          currentStep={currentStep}
          flow={flowName as string}
        />
        <Wrapper>
          <HeadingWrapper>
            <Title
              type="flow"
              size={fontSize.MID_LARGE}
              text={isFrame ? titles.SELECT_FRAME : titles.SELECT_DESIGN}
              defaultText={isFrame ? 'Publish frame' : 'Select design'}
            />
            <AutocompleteSearch
              id="org-tags"
              options={orgTags}
              displayedProp={'tag'}
              handleSelectOption={handleSelectTags}
              handleSetKeyboardOpen={handleSetKeyboardOpen}
              label={'ddcbdca4-f329-4a98-9491-1f75017319c8'} // Search by tags
              defaultLabel={'Search by tags'}
            />
            {!isEmpty(tags) && (
              <SelectedTags tags={tags} handleRemoveTag={handleRemoveTag} />
            )}
            <DetailsWrapper>
              {/* <DesignLibraryTitle>Design Library</DesignLibraryTitle> */}
              <Checkbox
                onChange={setSelectAll}
                isChecked={selectAll}
                isDisabled={false}
                label={'8c180522-c43e-4204-bbd1-755e4b6aa22b'} // Select all
                defaultLabel={'Select all'}
              />
            </DetailsWrapper>
          </HeadingWrapper>
          <RipplesList
            tags={tags}
            ripples={ripples}
            loadMore={loadMore}
            canAssign={canAssign}
            isLoading={isLoading}
            selectAll={selectAll}
            loadingMore={loadingMore}
            ripplesTotalCount={ripplesTotalCount}
            selectedRipplesList={selectedRipplesList}
            onLongPress={handleDisplayRippleInfo}
            addToSelectedRipplesList={addToSelectedRipplesList}
            removeFromSelectedRipplesList={removeFromSelectedRipplesList}
          />
          {showFloatingButton && (
            <FloatingButton
              onClick={submitRipplesSelection}
              isDisabled={false}
              variant={'contained'}
              position={'fixed'}
              buttonText={'b15c3820-3db5-4c29-8aea-fd6e94e22cc1'} // Next
              buttonDefaultText={'NEXT'}
            />
          )}
          {openRippleInfoModal && (
            <RippleInfoModal
              isOwnRipple={false}
              rippleInfo={rippleInfo}
              closeModal={handleCloseRippleInfoModal}
            />
          )}
          {openWalkThroughModal && (
            <RippleWalkThroughModal
              modalTitle={titles.SELECT_DESIGN}
              closeModal={closeWalkThroughModal}
              detailsTitle={'34348702-2d3f-4f4b-82b5-0328c2d47971'}
              modalDefaultTitle={'Browse Ripples library'}
            />
          )}
          {connectionError && (
            <ConnectionError onClose={handleCloseConnectionError} />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default withAuthenticationRequired(RipplesContainer, {
  onRedirecting: () => <Loading />,
});
