import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { SetIsAuthorized } from '../store/userSlice';

import axios from 'axios';

const Auth0TokenProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [token, setToken] = useState('');

  const setUserAuthorizationToken = async () => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axios.defaults.timeout = 1000 * 60 * 1.5;
        dispatch(SetIsAuthorized(true));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (isAuthenticated)
      getAccessTokenSilently().then((token) => setToken(token));
    //eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    setUserAuthorizationToken();
    //eslint-disable-next-line
  }, [token]);

  return children;
};

export default Auth0TokenProvider;
