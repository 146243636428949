import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

const getCampaignByLocationId = (locationId: string, organizationId: string) => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/campaigns/${organizationId}/location/${locationId}`;
    
      return axios.get(url).then(data => data);
}

export default getCampaignByLocationId;
