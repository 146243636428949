import LocationLandingPage from '../../Onboarding/Location/LocationLandingPage';
import qs from 'querystring';

import Location from '../../Onboarding/Location/Location';
import { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import CreateOrganization from '../../Onboarding/Organization/CreateOrganization';
import CreateLocation from '../../Onboarding/Location/CreateLocation';
import { createLocation } from '../../../services/locationsService';
import BeforeContinuing from '../../Onboarding/Content/pages/BeforeContinuing';
import { useTheme } from 'styled-components';
import { Machine } from '../../../primitives/types/Machine';
import { useDispatch, useSelector } from 'react-redux';
import { SelectUser, SetCurrentViewer } from '../../../store/userSlice';
import getMachineBySerial from '../../../api/rest/getMachineBySerial';
import { Container } from '../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import NewLoading from '../../../components/Loading/Loader';
import shipMachine from '../../../api/rest/shipMachine';
import getOnboardingState from '../../../api/rest/getOnboardingState';
import setMachineBySerial, { OnBoardingStates } from '../../../api/rest/setOnboardingState';
import LetsMoveOn from '../../Onboarding/Content/pages/MoveOn';
import { gqlClient } from '../../../utils/gqlClient';
import { GET_LOGIN_DETAILS } from '../../../api/graphQl/query/getLoginDetails';
import { UserDetails } from '../../../primitives/types/User';
import { Organization } from '../../../primitives/types/Organization';
import ConnectionError from '../../../components/Errors/ConnectionError';
import OnboardingError from '../../../components/Errors/OnboardingError';
import ContentPreferences from '../../Onboarding/Content/ContentPreferences';
import getOrganizationById from '../../../api/rest/getOrganizationByOldOrgId';
import getConfigByEnv from '../../../utils/getConfigByEnv';
import createOrgForUser from '../../../api/rest/createOrgForUser';
import GA from '../../../utils/analytics';

export enum ONBOARDING_PAGES {
    LANDING_PAGE,
    LOCATION,
    CREATE_LOCATION,
    BEFORE_COUNTINUE,
    YOUR_PACE,
    MOVE_ON,
    CONTENT_PREFERENCS,
    CREATE_ORGANIZATION
}

type TokenData = {
    sub: string;
    name: string
    serial?: string;
    roles?: string[]
}

const OnboardingContainer: React.FC = () => {
    const location = useLocation();

    const urlParams: any = qs.parse(location.search.substring(1)) || {};

    const [page, setPage] = useState<ONBOARDING_PAGES>(urlParams.isContent ? ONBOARDING_PAGES.BEFORE_COUNTINUE : ONBOARDING_PAGES.LANDING_PAGE);
    const [machine, setMachine] = useState<Machine>();
    const [organization, setOrganization] = useState<Organization>();
    const [isLoading, setIsLoading] = useState(true);
    const [onboardingState, setOnboardingState] = useState<OnBoardingStates>();
    const [hasError, setHasError] = useState('');
    const [connectionError, setConnectionError] = useState(false);
    const [locationId, setLocationId] = useState<string>();

    const user = useSelector(SelectUser);
    const dispatch = useDispatch();
    const [isOnboardingAuthorized, setIsOnboardingAuthorized] = useState<boolean | undefined>();
    const [tokenData, setTokenData] = useState<TokenData>();

    const history = useHistory();

    const theme = useTheme();

    const { logout } = useAuth0();

    const onboardingStateInterval = useRef<number>();

    const getUserDetails = () => gqlClient.query(GET_LOGIN_DETAILS, {});

    const checkAuthorized = async (refetch?: boolean) => {
        if (refetch || (user.isAuthorized && !user.id)) {
            try {
                const { data: { viewer } } = await getUserDetails();
                const { isLoggedIn, me: userDetails } = viewer;
                const user: UserDetails = { isLoggedIn, ...userDetails };
                dispatch(SetCurrentViewer(user));
            } catch (err: any) {
                if (err.message === 'Network Error') {
                  onConnectionError();
                } else {
                  setHasError(err.message);
                }
                throw err;
            }
        }
    }

    const onConnectionError = () => {
        setIsLoading(false);
        setConnectionError(true);
    }

    useEffect(() => {
        setIsLoading(false);
    }, [isOnboardingAuthorized]);

    const initializeOnboarding = async () => {
        if (!user.id) return;

        if (urlParams && urlParams.org) {
            const dataPart = JSON.parse(atob(urlParams.org.split('.')[1]));
            setTokenData(dataPart);
            try {
                /*
                    If the token contains an organization that is a partner and the user
                    doesn't have an organization, redirect to the organization creation page.
                */
                if (dataPart.isPartner === 'true' && !user.orgId) {
                    setPage(ONBOARDING_PAGES.CREATE_ORGANIZATION);
                    setIsOnboardingAuthorized(true);
                } else if (user.permissions.includes('organization:manage') && user.organizations.some((org: any) => org.id === dataPart.sub)) {
                    await getMachine(dataPart.serial, dataPart.sub);
                    await getOrgnization(dataPart.sub);
                    setIsOnboardingAuthorized(true);
                } else if (dataPart.isPartner === 'true' && (await initializeOnboardingForPartner(dataPart))) {
                    setIsOnboardingAuthorized(true);
                } else if (dataPart.isPartner === 'true' && user.orgId) {
                    history.replace('/portal', {});
                    setIsOnboardingAuthorized(false);
                } else {
                    setIsOnboardingAuthorized(false);
                }
            } catch {
                setIsOnboardingAuthorized(false);
            }
        } else {
            history.replace('/portal', {});
        }
    }

    /*
     The function receives the token (dataPart) and checks if the
     user organization has a relationship with the dataPart.sub (orgId)
     If it exists, do shipMachine and then retrieve the machine details.
     If not exist, return false
    */
    const initializeOnboardingForPartner = async (dataPart: { serial?: string, sub?: string }) => {
        const userOrganization: Organization = (await getOrganizationById(user.orgId)).data.data;

        if (user.permissions.includes('organization:manage') && userOrganization.relationships.ancestors.includes(dataPart.sub || '')) {
            try {
                await shipMachine({ serial: dataPart.serial, organizationId: user.orgId, selectedOrganization: user.orgId, partnerOrganizationId: dataPart.sub });
            } catch {}
            await getMachine(dataPart.serial, user.orgId);
            setOrganization(userOrganization);
            return true;
        }
        return false;
    }

    useEffect(() => {
        checkAuthorized().catch(console.error);
    }, [user.id, user.isAuthorized]);

    useEffect(() => {
        initializeOnboarding().catch(console.error);
    }, [user.id, user.orgId]);

    const getMachine = async (serial?: string, orgId?: string) => {
        if (serial && orgId) {
            try {
                const response = await getMachineBySerial(serial);
                setMachine(response.data.data);
            } catch (error: any) {
                if (error.message === 'Network Error') {
                    onConnectionError()
                } else {
                    setHasError(error.message)
                }
            }
        }
    }

    const getOrgnization = async (orgId?: string) => {
        if (orgId) {
            try {
                setIsLoading(true);
                const response = await getOrganizationById(orgId);
                setOrganization(response.data.data);
            } catch (error: any) {
                if (error.message === 'Network Error') {
                    onConnectionError()
                } else {
                    setHasError(error.message)
                }
            }
        }
    }

    useEffect(() => {
        if (urlParams.isContent) return;
        if (machine?.id) {
            if (machine.onboardingState === OnBoardingStates.MACHINE_LOCATION || machine.onboardingState === OnBoardingStates.MACHINE_LICENSE) {
                setPage(ONBOARDING_PAGES.MOVE_ON)
            }
        }
    }, [machine?.id])

    useEffect(() => {
        if (urlParams.isContent) return;

        if (machine?.serial && !onboardingStateInterval.current) {
            onboardingStateInterval.current = window.setInterval(async () => {
                const onboardingState = await getOnboardingState(machine?.serial)
                setOnboardingState(onboardingState)
            }, 5000);

            return () => clearInterval(onboardingStateInterval.current)
        }
    }, [machine?.serial])

    useEffect(() => {
        if (urlParams.isContent) return;

        if (onboardingState === OnBoardingStates.MACHINE_LICENSE || onboardingState === OnBoardingStates.MACHINE_LOCATION) {
            setPage(ONBOARDING_PAGES.MOVE_ON);
            clearInterval(onboardingStateInterval.current)
        }
    }, [onboardingState])

    useEffect(() => {
        if (urlParams.isContent) return;

        if (machine?.serial && machine.onboardingState !== OnBoardingStates.MACHINE_LOCATION && machine.onboardingState !== OnBoardingStates.MACHINE_LICENSE) {
            setMachineBySerial(machine?.serial, OnBoardingStates.MOBILE_PORTAL_LOCATION, machine?.accountData?.organizationId || machine?.shipToOrg);
        }
    }, [machine?.serial])

    const handleCreateLocation = async (values: any) => {
        try {
            if (machine?.serial && machine.shipToOrg) {
                setIsLoading(true)
                const response = await createLocation({ ...values, organization: machine.shipToOrg });
                await shipMachine({ locationId: response.data.createRippleMakerLocation.rippleMakerLocation.id, serial: machine?.serial, organizationId: machine.shipToOrg })
                setLocationId(response.data.createRippleMakerLocation.rippleMakerLocation.id)
                await getMachine(machine.serial, user.orgId)
                handleLocationConfirm();
                GA.event({
                    category: 'Onboarding',
                    action: 'Onboarding_Set_location_from_MP',
                });
            }
        } catch (error: any) {
            if (error.message === 'Network Error') {
                onConnectionError()
            } else {
                setHasError(error.message)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectLocation = async ({ locationId, organizationId, serial }: { locationId?: string, organizationId?: string, serial?: string }) => {
        try {
            await shipMachine({ locationId, serial, organizationId })
            setLocationId(locationId);
            GA.event({
                category: 'Onboarding',
                action: 'Onboarding_Set_location_from_MP',
            });
        } catch (error: any) {
            if (error.message === 'Network Error') {
                onConnectionError()
            } else {
                setHasError(error.message)
            }
        }
    }

    const handleMoveOn = async () => {
        if (urlParams.isContent) {
            setPage(ONBOARDING_PAGES.MOVE_ON);
            return;
        }

        try {
            if (machine?.serial) {
                await setMachineBySerial(machine?.serial, OnBoardingStates.MACHINE_LICENSE, machine?.accountData?.organizationId || machine?.shipToOrg);
                setPage(ONBOARDING_PAGES.MOVE_ON);
            }
        } catch (error: any) {
            if (error.message === 'Network Error') {
                onConnectionError()
            } else {
                setHasError(error.message)
            }
        }
    }

    const handleContinueToContent = async () => {
        if (urlParams.isContent) {
            setPage(ONBOARDING_PAGES.CONTENT_PREFERENCS);
            return;
        }

        try {
            if (machine?.serial) {
                await setMachineBySerial(machine?.serial, OnBoardingStates.MOBILE_PORTAL_CONTENT, machine?.accountData?.organizationId || machine?.shipToOrg);
                setPage(ONBOARDING_PAGES.CONTENT_PREFERENCS);
            }
        } catch (error: any) {
            if (error.message === 'Network Error') {
                onConnectionError()
            } else {
                setHasError(error.message)
            }
        }
    }

    const handleContinueOnMachine = async () => {
        try {
            if (machine?.serial) {
                await setMachineBySerial(machine?.serial, OnBoardingStates.MACHINE_LOCATION, machine?.accountData?.organizationId || machine?.shipToOrg);
                setPage(ONBOARDING_PAGES.MOVE_ON);
            }
        } catch (error: any) {
            if (error.message === 'Network Error') {
                onConnectionError()
            } else {
                setHasError(error.message)
            }
        }

    }

    const handleOnboardingErrorClick = () => {
        logout()
    };

    const handleLocationConfirm = async () => {
        if (organization?.relationships.contentOrgs.includes(getConfigByEnv().RIPPLES_ID)) {
            setPage(ONBOARDING_PAGES.BEFORE_COUNTINUE)
        } else {
            await handleMoveOn()
        }
    }

    const handleCreateOrganization = async ({ name }: { name: string }) => {
        try {
            setIsLoading(true);
            const dataPart = JSON.parse(atob(urlParams.org.split('.')[1]));
            await createOrgForUser({
                email: user.email,
                orgName: name,
                parentId: dataPart.sub
            });
            await checkAuthorized(true);
            setPage(urlParams.isContent ? ONBOARDING_PAGES.BEFORE_COUNTINUE : ONBOARDING_PAGES.LANDING_PAGE);
        } catch (error: any) {
            if (error.message === 'Network Error') {
                onConnectionError()
            } else {
                setHasError(error.message)
            }
        } finally {
            setIsLoading(false)
        }
    };

    if (isLoading || isOnboardingAuthorized == null) {
        return (
            <Container>
                <NewLoading />
            </Container>
        );
    }

    if (!isOnboardingAuthorized) {
        return <OnboardingError theme={theme} orgName={tokenData ? tokenData.name : ''} onClose={handleOnboardingErrorClick} isContentFlow={!!urlParams.isContent} />
    }

    if (hasError || connectionError) {
        return <ConnectionError error={hasError} onClose={handleOnboardingErrorClick} />
    }

    switch (page) {
        case ONBOARDING_PAGES.LANDING_PAGE:
            return <LocationLandingPage onMoveOn={handleContinueOnMachine} onNext={() => setPage(ONBOARDING_PAGES.LOCATION)} theme={theme} />
        case ONBOARDING_PAGES.LOCATION:
            return <Location
                theme={theme}
                onLocationConfirm={handleLocationConfirm}
                onCreateLocation={() => setPage(ONBOARDING_PAGES.CREATE_LOCATION)}
                locationName={machine?.accountData?.locationName}
                locationId={machine?.accountData?.locationId}
                organizationId={machine?.accountData?.organizationId || machine?.shipToOrg}
                serial={machine?.serial}
                onBack={() => setPage(ONBOARDING_PAGES.LANDING_PAGE)}
                onSelectLocation={handleSelectLocation}
                supportDetails={machine?.supportDetails}
            />
        case ONBOARDING_PAGES.CREATE_LOCATION:
            return <CreateLocation theme={theme} onNext={handleCreateLocation} organizationId={machine?.accountData?.organizationId} onBack={() => setPage(ONBOARDING_PAGES.LANDING_PAGE)} onClose={() => setPage(ONBOARDING_PAGES.LANDING_PAGE)} />
        case ONBOARDING_PAGES.BEFORE_COUNTINUE:
            return <BeforeContinuing theme={theme} onNext={handleContinueToContent} onMoveOn={handleMoveOn} isContentFlow={!!urlParams.isContent} />
        case ONBOARDING_PAGES.CONTENT_PREFERENCS:
            return <ContentPreferences locationId={locationId || machine?.accountData?.locationId} onClose={() => setPage(ONBOARDING_PAGES.BEFORE_COUNTINUE)} theme={theme} organizationId={machine?.accountData?.organizationId || machine?.shipToOrg} onNext={handleMoveOn} onConnectionError={onConnectionError} setHasError={setHasError} isContentFlow={!!urlParams.isContent} />
        case ONBOARDING_PAGES.MOVE_ON:
            return <LetsMoveOn theme={theme} isContentFlow={!!urlParams.isContent} />
        case ONBOARDING_PAGES.CREATE_ORGANIZATION:
            return <CreateOrganization onNext={handleCreateOrganization} />
        default:
            return <Redirect to={'/portal'} />
    }
};

export default withAuthenticationRequired(OnboardingContainer, {
    onRedirecting: () => <NewLoading />,
    loginOptions: {
        org: window.location.search && qs.parse(window.location.search.substring(1)).org ? qs.parse(window.location.search.substring(1)).org : undefined,
        isPartner: window.location.search && qs.parse(window.location.search.substring(1)).isPartner ? qs.parse(window.location.search.substring(1)).isPartner : undefined
    }
});