export const CREATE_RIPPLE = `
  mutation createRipple(
    $url: String!
    $fingerprint: String!
    $organization: String!
    $languageCode: String!
    $dayOfTheWeek: [String]!
    $timeOfTheDay: [String]!
    $isTemplate: Boolean!
    $isFrame: Boolean!
    $tags: [String]
    $canApproveRipple: Boolean!
    $isMacaroonFrame: Boolean!
  ) {
    createRipple(
      input: {
        clientMutationId: "1"
        url: $url
        fingerprint: $fingerprint
        organization: $organization
        languageCode: $languageCode
        dayOfTheWeek: $dayOfTheWeek
        timeOfTheDay: $timeOfTheDay
        isTemplate: $isTemplate
        isFrame: $isFrame
        tags: $tags
        canApproveRipple: $canApproveRipple
        isMacaroonFrame: $isMacaroonFrame
      }
    ) {
      clientMutationId
      ripple {
        id
        rippleImage {
          thumbnailUrl
        }
      }
    }
  }
`;
