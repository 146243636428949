import TabsMenu from './TabsMenu';
import TabPanel from './TabPanel';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RippleInfo } from '../../../primitives/types/Ripples';
import { SelectTranslations } from '../../../store/translationSlice';
import {
  RippleInfoTag,
  RippleInfoDetail,
  RippleInfoDetailKey,
  RippleInfoDetailValue,
  RippleInfoTagsWrapper,
  RippleInfoDetailValueScroll,
  RippleInfoModalDetailsWrapper,
  RippleInfoModalDetailsSection,
} from '../../../primitives/styles/Ripples/RipplesStyle';

interface RippleInheritedDetailsProps {
  rippleInfo: RippleInfo[];
}

type Section = { key: string; value: string }[];

const RippleInheritedDetails: React.FC<RippleInheritedDetailsProps> = ({
  rippleInfo,
}) => {
  const info = rippleInfo[0];
  const [value, setValue] = useState(0);
  const switchTab = (newValue: number) => {
    setValue(newValue);
  };

  const translations = useSelector(SelectTranslations);

  const NO = translations['06dfd870-c1b0-4366-ad02-afb46abbcd8d'] || 'No';
  const YES = translations['46030eba-3e78-4a24-a4f7-7765484db952'] || 'Yes';
  const TAGS = translations['2c0de27a-5bc5-4da0-84c7-c89bdcd29d05'] || 'Tags';
  const CREATOR = translations['f5cef0e8-96eb-4092-9175-4182cef0b6be'] || 'Creator';
  const GENERAL = translations['27e8d778-b4ea-4820-a49e-724be3a498b3'] || 'GENERAL';
  const PERSONALIZED = translations['8877be0f-fd71-41f2-8e79-782a0fcb27df'] || 'Personalized';
  const CREATION_DATE = translations['56a1d0a9-6bef-4872-8f9f-afdcad6ef8e9'] || 'Creation date';

  const section1: Section = [
    { key: PERSONALIZED, value: info.isTemplate ? YES : NO },
  ];

  const section2: Section = [{ key: CREATOR, value: info.createdBy ? info.createdBy.name : 'N/A' }];
  const section3: Section = [ {
      key: CREATION_DATE,
      value: new Date(info.createdAt).toLocaleDateString(),
    }];
  
  const sections: Section[] = [section1, section2, section3];



  return (
    <RippleInfoModalDetailsWrapper>
      <TabsMenu value={value} switchTab={switchTab} tabs={[GENERAL, TAGS]} />
      <TabPanel value={value} index={0}>
        <RippleInfoDetailValueScroll>
          {sections.map((section: Section, index) => (
            <RippleInfoModalDetailsSection key={`section-${index}`}>
              {section.map(({ key, value }) => (
                <RippleInfoDetail key={key}>
                  <RippleInfoDetailKey>{key}:</RippleInfoDetailKey>
                  <RippleInfoDetailValue>{value}</RippleInfoDetailValue>
                </RippleInfoDetail>
              ))}
            </RippleInfoModalDetailsSection>
          ))}
        </RippleInfoDetailValueScroll>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RippleInfoTagsWrapper>
          {info.tags.map((tag, index) => (
            <RippleInfoTag key={`${index}-${tag}`}>{tag}</RippleInfoTag>
          ))}
        </RippleInfoTagsWrapper>
      </TabPanel>
    </RippleInfoModalDetailsWrapper>
  );
};

export default RippleInheritedDetails;
