import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Translation } from '../primitives/types/Translation';

interface TranslationState {
  translation: Translation ;
}

export const initState: TranslationState = {
  translation: {}
};

const translationsSlice = createSlice({
  name: 'translations',
  initialState: initState,
  reducers: {
    SetTranslations( state, action: PayloadAction<Translation>) {
      state.translation = action.payload
    },

  },
});

export const { SetTranslations } = translationsSlice.actions;

export const SelectTranslations = (state: any): Translation => state.translations.translation;


export default translationsSlice.reducer;
