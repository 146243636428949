import React, { useState } from "react";
import { PublishButtonsContainer, createPublishButtonStyles } from "./styles";
import { DefaultTheme, useTheme } from "styled-components";
import { Button, CircularProgress } from "@material-ui/core";
import Phrase from "../../components/Phrase/Phrase";

export const CHANNELS_ORDER = {
    FIRST: 'first',
    LAST: 'last'
}

const PublishButtons = ({inProgress, onClick}: {inProgress?: boolean, onClick: (channelsOrder: string) => Promise<void>}) => {
    const theme: DefaultTheme & {ratio?: number} = useTheme();

    const [selectedButton, setSelectedButton] = useState('');

    const usePrimaryButtonStyles = createPublishButtonStyles(
        true,
        theme.ratio,
      );

    const primatyButtonClasses = usePrimaryButtonStyles();

    const useSecondaryButtonStyles = createPublishButtonStyles(
        false,
        theme.ratio,
      );

    const secondaryButtonClasses = useSecondaryButtonStyles();

    const handleFirstClick = () => {
        setSelectedButton(CHANNELS_ORDER.FIRST);
        onClick(CHANNELS_ORDER.FIRST);
    }

    const handleLastClick = () => {
        setSelectedButton(CHANNELS_ORDER.LAST);
        onClick(CHANNELS_ORDER.LAST);
    }

    return <PublishButtonsContainer ratio={theme.ratio}>
        <Button classes={{root: primatyButtonClasses.root}} onClick={handleFirstClick}>
            {inProgress && selectedButton === CHANNELS_ORDER.FIRST ? (
            <CircularProgress 
              style={{ color: 'white' }} 
              size={27 * (theme.ratio || 1)} 
            />) : (
              <Phrase
                translationKey={'cbd5b0fa-6a77-4088-af30-86b0b0c110bf'}
                defaultText={'PUBLISH AS FIRST'}
              />
            )}
        </Button>
        <Button classes={{root: secondaryButtonClasses.root}} onClick={handleLastClick} style={{margin: 'auto 0'}}>
            {inProgress && selectedButton === CHANNELS_ORDER.LAST ? (
            <CircularProgress 
              style={{ color: '#e86161' }} 
              size={27 * (theme.ratio || 1)} 
            />) : (
              <Phrase
                translationKey={'5bbecd50-9eca-41d3-b112-677514e19994'}
                defaultText={'PUBLISH AS LAST'}
              />
            )}
        </Button>
    </PublishButtonsContainer>
}

export default PublishButtons;