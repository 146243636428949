import { FloatingLocationButtonContainer } from '../../../primitives/styles/Onboarding/Onboarding';
import LocationButton from './LocationButton';

interface FloatingLocationButtonProps {
  theme?: any;
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

const FloatingLocationButton: React.FC<FloatingLocationButtonProps> = ({
  theme = {},
  children,
  onClick,
  disabled = false
}) => {
  const buttonStyle = {
    marginTop: '0'
  }

  return (
    <FloatingLocationButtonContainer>
    <LocationButton disabled={disabled} style={buttonStyle} onClick={onClick} theme={theme}>
        {children}
    </LocationButton>

    </FloatingLocationButtonContainer>
  );
};

export default FloatingLocationButton;