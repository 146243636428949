import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
  Wizard as WizardContainer,
  Title,
  DisabledMask,
} from '../../primitives/styles/Wizard/WizardStyle';
import Phrase from '../Phrase/Phrase';

interface WizardProps {
  theme: any;
  name: string;
  title: string;
  Icon?: React.FC<any>;
  defaultTitle: string;
  Wide?: boolean;
  onClick?: () => void;
  style?: Object
  titleStyle?: Object
  disabled?: boolean
}

const Wizard: React.FC<WizardProps> = ({ theme, Icon, title, defaultTitle, name, Wide, onClick, disabled = false, style = {}, titleStyle = {} }) => {
  const [isTouched, setIsTouched] = React.useState(false);
  const history = useHistory();

  const handleOnTouchStart = () => {
    setIsTouched(true);
  };

  const handleOnTouchEnd = () => {
    setIsTouched(false);
  };

  const onNavigate = () => {
    history.push(name)
  }

  return (
    <WizardContainer
      isTouched={isTouched}
      Wide={Wide}
      onTouchStart={handleOnTouchStart}
      onTouchEnd={handleOnTouchEnd}
      onClick={!disabled ? (onClick || onNavigate) : undefined}
      style={{...style}}
    >
      {disabled && <DisabledMask/>}
        {Icon && <Icon color={isTouched ? theme.palette.white : theme.palette.red} />}
        <Title style={{...titleStyle}}>
          <Phrase 
            translationKey={title}
            defaultText={defaultTitle}
          />
        </Title>

    </WizardContainer>
  );
};

export default withTheme(Wizard);
