import GA from '../../utils/analytics';
import { useHistory } from 'react-router';
import { SelectOrgName } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { generateAppRoutes } from '../../constants/Routes';
import { CloseIcon, BackIcon } from '../../primitives/media/Icons';
import { SelectPathHistory, SetStepBackward } from '../../store/flowSlice';
import { ControlWrapper, CloseWrapper } from '../../primitives/styles/Common/CommonStyle';
import {
  ResetChannelState,
  ResetFlowState,
  ResetLocationState,
  ResetRipplesState,
} from '../../store/cleanup';

interface BackProps {
  currentStep: number;
  onBack?: () => void;
  shouldDisplay?: boolean;
}
export const Back: React.FC<BackProps> = ({
  currentStep,
  onBack,
  shouldDisplay = true
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pathHistory = useSelector(SelectPathHistory);

  const handleBack = () => {
    const [path] = pathHistory.slice(-1);
    if (typeof path === 'string') {
      history.push(path);
      dispatch(SetStepBackward(currentStep));
    }
  };

  return (
    <ControlWrapper id={'portal-mobile-back-button'} onClick={shouldDisplay ? onBack || handleBack : undefined}>
      {shouldDisplay && <BackIcon/>}
    </ControlWrapper>
  );
};


interface CloseProps {
  flowName: string;
  shouldDisplay?: boolean;
  onClose?: () => void;
}
export const Close: React.FC<CloseProps> = ({flowName, shouldDisplay = true, onClose}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { HOME } = generateAppRoutes();
  const orgName = useSelector(SelectOrgName);

  const cleanupActions = [
    ResetChannelState,
    ResetFlowState,
    ResetLocationState,
    ResetRipplesState,
  ];

  const handleCleanup = () => {

    GA.event({
      category: 'Flow',
      action: flowName,
      label: orgName,
      value: 0
    });

    cleanupActions.forEach( action => {
      dispatch(action())    
    });
    history.push(HOME)
  }
  return (
    <CloseWrapper onClick={shouldDisplay ? onClose || handleCleanup : undefined}>
       {shouldDisplay && <CloseIcon/>}
    </CloseWrapper>
  );
};
