import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

interface SurveyResponse {
    contentSurveyStepId: string
    answerIds: string[]
}

interface UpsertSurveyContentParams {
    locationId: string,
    selectedChannels: string[],
    surveyResponse: SurveyResponse[],
    organizationId: string
  }

const setContentSurvey = ({locationId, selectedChannels, surveyResponse, organizationId}: UpsertSurveyContentParams) => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/content/survey/${locationId}`;
    
      return axios.post(url, {selectedChannels, surveyResponse, organizationId}).then(data => data);
}

export default setContentSurvey;
