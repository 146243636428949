import styled from 'styled-components';

export const MainTitle = styled.span.attrs((props: { size: string }) => ({
  size: props.size,
}))`
  width: 100vw;
  font-family: ${(props) => props.theme.fontFamily.brandonPrintedTwo};
  font-style: normal;
  font-weight: 350;
  text-align: center;
  text-transform: uppercase;
  padding-top: calc(7.5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(7.5px * ${(props) => props.theme.ratio});
  // word-break: keep-all;
  font-size: calc(
    ${(props) => props.theme.fontSize[props.size]} *
      ${(props) => props.theme.ratio}
  );
  color: ${(props) => props.theme.palette.white};
`;

export const SubTitle = styled.span.attrs((props: { size: string }) => ({
  size: props.size,
}))`
  width: 100vw;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.red};
  text-shadow: 0px 4.91667px 49.1667px #ffffff;
  border-radius: 30.7292px;
  padding-top: calc(7.5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(7.5px * ${(props) => props.theme.ratio});
  font-size: calc(
    ${(props) => props.theme.fontSize[props.size]} *
      ${(props) => props.theme.ratio}
  );
`;

export const FlowTitle = styled.span.attrs((props: { size: string }) => ({
  size: props.size,
}))`
  width: fit-content;
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  font-style: normal;
  font-weight: 390;
  font-size: 20px;
  text-align: center;
  color: ${(props) => props.theme.palette.red};
  text-shadow: 0px 4.91667px 49.1667px #ffffff;
  border-radius: 30.7292px;
  padding-top: calc(3px * ${(props) => props.theme.ratio});
  padding-bottom: calc(3px * ${(props) => props.theme.ratio});
  font-size: calc(
    ${(props) => props.theme.fontSize[props.size]} *
      ${(props) => props.theme.ratio}
  );
`;

export const ErrorTitle = styled.span.attrs((props: { size: string }) => ({
  size: props.size,
}))`
  width: 100vw;
  font-family: ${(props) => props.theme.fontFamily.brandonPrintedTwo};
  font-style: normal;
  font-weight: 350;
  text-align: center;
  text-transform: uppercase;
  padding-top: calc(7.5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(7.5px * ${(props) => props.theme.ratio});
  font-size: calc(
    ${(props) => props.theme.fontSize[props.size]} *
      ${(props) => props.theme.ratio}
  );
  color: ${(props) => props.theme.palette.white};
`;
