import styled from 'styled-components';
import { global } from '../Global/global';
/*the calc(132px * ... is used to calculate the exact width of the wizard container to contain 
exactly 2 centered wizadrs */
export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: calc(150px * 2.25 * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const Wizard = styled.div.attrs((props: { isTouched: boolean, Wide: boolean, style?: Object }) => ({
  isTouched: props.isTouched,
  Wide: props.Wide,
  style: props.style
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isTouched ? props.theme.palette.red : props.theme.transparent.grey};
  border: 1px solid ${(props) => props.theme.palette.border};
  box-sizing: border-box;
  border-radius: calc(10px * ${(props) => props.theme.ratio});
  padding: 0;
  margin: calc(9px * ${(props) => props.theme.ratio});
  z-index: 999;
  position: relative;
  width: ${(props) =>
    props.Wide? `calc(220px * ${props.theme.ratio})` : `calc(150px * ${props.theme.ratio})`
  };
  height: ${(props) =>
    props.Wide? `calc(110px * ${props.theme.ratio})` : `calc(150px * ${props.theme.ratio})`
  };
  -width: calc(150px * ${(props) => props.theme.ratio});
  -height: calc(150px * ${(props) => props.theme.ratio});
  ${global.userSelectNone};
`;

export const DisabledMask = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2C2E33B3;
  position: absolute;
  border-radius: calc(10px * ${(props) => props.theme.ratio});
`


export const Title = styled.span.attrs((props: { style?: Object }) => ({
  style: props.style
}))`
  width: fit-content;
  font-family: Brandon Grotesque;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.white};
  border-radius: 25px;
  margin-top: calc(16px * ${(props) => props.theme.ratio});
  font-size: calc(12px * ${(props) => props.theme.ratio});
`;

export const Upload = styled.input`
  display: block;
  //opacity: 0;
  position: fixed;
  left: 100vw;
  top: ${(props) => props.theme.deviceHeight}px;
`;
