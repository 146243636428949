import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';
const defaultURL = getConfigByEnv().PORTAL.WEBAPP_URL;

export const getWebAppLocation = (
  locationId: string,
  isWebappEnabled: boolean
) => {
  const url = `${
    getConfigByEnv().PORTAL.REST_API_URL
  }/webapp/locations/url/${locationId}`;


  if (isWebappEnabled) {
    return axios
      .get(url)
      .then(({ data }) => data)
      .then(({ data }) => data);
  } else {
    return Promise.resolve(defaultURL);
  }
};

export const getDefaultWebAppLocation = () => defaultURL;

export const getLocationById = (locationId: string, orgId: string) => {
  const url = `${getConfigByEnv().PORTAL.REST_API_URL}/` + 
  `locations/getLocationById/${locationId}/` +
  `organizationId/${orgId}`;
  return axios
    .get(url)
    .then(({ data }) => data)
    .then(({ data }) => data);
};
