export const GET_INHERITED_FRAME_INFO = `
query getFrameInfo(
    $orgId: String!
    $frameId: String!
    $canViewOnlyOwn: Boolean!
  ) {
    viewer {
      organizationScope(id: $orgId) {
        inheritedFrame(id: $frameId, onlyMine: $canViewOnlyOwn) {
          id
          languageCode
          rippleImage {
            id
            thumbnailUrl
          }
          createdAt
          createdBy {
            id
            name
          }
          isShareable
          tags
        }
      }
    }
  }
`;
