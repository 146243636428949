import Home from '../Home/Home';
// import routes from '../../constants/Routes';
import OrientationWrapper from '../../wrappers/OrientationWrapper';
import HistoryListenWrapper from '../../wrappers/HistoryListenWrapper';
import PublishFrameWizardContainer from '../WizardSteps/PublishFrameWizardContainer';
import CreateFrameWizardContainer from '../WizardSteps/CreateFrameWizardContainer';
import FrameWizardPicker from '../Wizard/FrameWizardPicker';
import QRcodeWizardContainer from '../WizardSteps/QRcodeWizardContainer';
import UploadWizardContainer from '../WizardSteps/UploadWizardContainer';
import RemoveWizardContainer from '../WizardSteps/RemoveWizardContainer';
import CreateWizardContainer from '../WizardSteps/CreateWizardContainer';
import PublishWizardContainer from '../WizardSteps/PublishWizardContainer';
import ManageChannelsWizardContainer from '../WizardSteps/ManageChannelsWizardContainer';

import { generateAppRoutes } from '../../constants/Routes';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import OnboardingContainer from '../WizardSteps/Onboarding';

const routes = generateAppRoutes();

const Routes: React.FC = () => {


  return (
    <Router>
      <HistoryListenWrapper>
          <OrientationWrapper>
            <Switch>
              <Route path={routes.HOME} exact component={Home} />
              <Route path={routes.PUBLISH_FRAME} component={PublishFrameWizardContainer} />
              <Route path={routes.CREATE_FRAME} component={CreateFrameWizardContainer} />
              <Route path={routes.FRAME} component={FrameWizardPicker} />
              <Route path={routes.UPLOAD} component={UploadWizardContainer} />
              <Route path={routes.CREATE} component={CreateWizardContainer} />
              <Route path={routes.DELETE} component={RemoveWizardContainer} />
              <Route path={routes.QR_CODE} component={QRcodeWizardContainer} />
              <Route path={routes.PUBLISH} component={PublishWizardContainer} />
              <Route path={routes.CHANNELS} component={ManageChannelsWizardContainer} />
              <Route path={routes.ONBOARDING} component={OnboardingContainer} />
              {/* ... more routes to come here ... */}
            </Switch>
          </OrientationWrapper>
      </HistoryListenWrapper>
    </Router>
  );
};

export default Routes;
