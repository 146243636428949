import * as React from 'react';
import Phrase from '../../../components/Phrase/Phrase';
import { CloseIcon } from '../../../primitives/media/Icons';
import {
  OrganizationOption,
  SelectOrganizationList,
  SelectOrganizationModalClose,
  SelectOrganizationModalTitle,
  SelectOrganizationModalHeader,
  SelectOrganizationModalContent,
  SelectOrganizationModalWrapper,
} from '../../../primitives/styles/SelectOrganization/SelectOrganizationStyle';

interface SelectFontModalProps {
  fonts: string[];
  closeSelectFontModal: () => void;
  selectFont: (name: string) => void;
}

const SelectFontModal: React.FC<SelectFontModalProps> = ({
  fonts,
  closeSelectFontModal,
  selectFont,
}) => {
  

  return (
    <SelectOrganizationModalWrapper>
      <SelectOrganizationModalContent>
        <SelectOrganizationModalHeader>
            <SelectOrganizationModalClose
              onClick={closeSelectFontModal}
            >
              <CloseIcon />
            </SelectOrganizationModalClose>
          <SelectOrganizationModalTitle>
            <Phrase
              translationKey={'cc28ee62-77a5-4189-bf16-8f4013f9d6d2'}
              defaultText={'Select Font'}
            />
          </SelectOrganizationModalTitle>
        </SelectOrganizationModalHeader>
        <SelectOrganizationList>
          {fonts.map((font, index) => (
            <OrganizationOption
              key={font}
              onClick={() => selectFont(font)}
              isLastItem={index === fonts.length - 1}
            >
              <span style={{fontFamily: font, color: "#000"}}>{font}</span>
            </OrganizationOption>
          ))}
        </SelectOrganizationList>
      </SelectOrganizationModalContent>
    </SelectOrganizationModalWrapper>
  );
};

export default SelectFontModal;
