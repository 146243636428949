export const EDIT_APP_CHANNELS = `
  mutation editAppChannels(
    $organization: String!
    $locationId: String!
    $channels: [AppChannelInput]!
  ) {
    editAppChannels(
      input: {
        clientMutationId: "1"
        organization: $organization
        locationId: $locationId
        channels: $channels
      }
    ) {
      clientMutationId
    }
  }
`;