import { FontSize } from "../primitives/types/FontSize"

const EXTRA_LARGE: FontSize = 'extra_large'
const LARGE: FontSize = 'large'
const MID_LARGE: FontSize = 'mid_large'
const MEDIUM: FontSize = 'medium'
const SMALL: FontSize = 'small'

const fontSize = {
    EXTRA_LARGE,
    LARGE,
    MID_LARGE,
    MEDIUM,
    SMALL
}

export default fontSize;