import styled from "styled-components";

export const ContactSupportContent = styled.div`
  display: flex;
  flex-direction: column;
  padding 0 20px;
`;

export const ContactSupportSubTitle = styled.div`
width: 70%
font-family: Brandon Grotesque;
font-size: 18px;
font-weight: 390;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #000000;`;

export const SubTitle = styled.div`
font-family: Brandon Grotesque;
font-size: 18px;
font-weight: 390;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-top: calc( 15px * ${(props) => props.theme.ratio});
`

export const ChannelsContianer = styled.div`
diplsay: flex;
flex-direction: column;
margin-top: calc( 40px * ${(props) => props.theme.ratio});`

export const Channel = styled.div`
display: flex;
align-items: center;
margin-bottom: calc( 30px * ${(props) => props.theme.ratio});
`

export const ChannelIcon = styled.img`
margin-right: calc( 10px * ${(props) => props.theme.ratio});
width: 30px;
height: 30px;
`

export const UnderlineText = styled.span`
border-bottom: 1px solid;
`

export const SerialNumber = styled.div`
font-family: Brandon Grotesque;
font-size: 16px;
font-weight: 390;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
white-space:pre-line;
opacity: 0.6;
letter-spacing: 0.5px;
`