import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import SearchOptions from './SearchOptions';
import { useState } from 'react';
import { CloseIcon } from '../../primitives/media/Icons';
import {
  SelectModalOption,
  SelectModalList,
  SelectModalClose,
  SelectModalTitle,
  SelectModalHeader,
  SelectModalContent,
  SelectModalWrapper,
  SelectModalOptionContainer,
} from '../../primitives/styles/SelectModal/SelectModal';
import NoDataMessage from '../Common/NoDataMessage';

interface SelectModalProps {
  title: string;
  options: any[];
  defaultTitle: string;
  closeSelectModal: () => void;
  shouldPreviewCloseButton: boolean;
  onSelectOption: (params: any) => void;
  selectedOption?: string;
  noOptionsTitle?: string;
  defaultNoOptionsTitle?: string;
  renderNotFoundComponent?: () =>  JSX.Element;
  renderApplyButton?: () =>  JSX.Element;
}

const SelectModal: React.FC<SelectModalProps> = ({
  title,
  options,
  defaultTitle,
  onSelectOption,
  closeSelectModal,
  shouldPreviewCloseButton,
  noOptionsTitle = '',
  defaultNoOptionsTitle = '',
  renderNotFoundComponent,
  renderApplyButton,
  selectedOption
}) => {
  const [renderedOptions, setRenderedOptions] = useState(options);
  const [isKeyboardOpen, setIsKeyboardIsOpen] = useState(false);

  const supportIOS = (searchTerm: string) => {
    return searchTerm
      .replace("’","'")
      .replace("‘","'")
      .replace("”", '"')
      .replace("“", '"')
      .replace(/[\u2026]/g, '...')
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"')
      .replace(/[\u2013\u2014]/g, '-')
  }
  const handleSearchOptions = (input: string) => {
    const searchTerm = supportIOS(input.trim().toLocaleLowerCase());
    const filteredOptions = options.filter((opt) => {
      return opt.name.toLocaleLowerCase().includes(searchTerm);
    });
    setRenderedOptions(filteredOptions);
  };

  const handleKeyboardIsOpen = (isOpen: boolean) => {
    setIsKeyboardIsOpen(isOpen);
  };

  const handleSelectOption = (option: any | null) => {
    if (option) {
      onSelectOption(option);
      handleKeyboardIsOpen(false);
    }
  };

  const handleSearchBlur = () => {
    if(isKeyboardOpen){
      handleKeyboardIsOpen(false)
    }
  }

  const showSearchBar = options.length >= 1;

  const sortedOptions = renderedOptions
    .map((a) => a)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <SelectModalWrapper onClick={handleSearchBlur}>
      <SelectModalContent isKeyboardOpen={isKeyboardOpen}>
        <SelectModalHeader
          onClick={() => handleKeyboardIsOpen(false)}
        >
          {shouldPreviewCloseButton && (
            <SelectModalClose
              onClick={closeSelectModal}
            >
              <CloseIcon />
            </SelectModalClose>
          )}
          <SelectModalTitle>
            <Phrase
              translationKey={title}
              defaultText={defaultTitle}
            />
          </SelectModalTitle>
        </SelectModalHeader>
        {showSearchBar && (
          <SearchOptions
            handleKeyboardIsOpen={handleKeyboardIsOpen}
            handleSearch={handleSearchOptions}
          />
        )}
        {sortedOptions.length > 0 ? 
        <SelectModalList onClick={() => handleKeyboardIsOpen(false)}>
          {sortedOptions.map((option, index) => (
            <SelectModalOptionContainer key={option.id} isSelected={selectedOption === option.id}>
            <SelectModalOption
              key={option.id}
              onClick={() => handleSelectOption(option)}
              isLastItem={index === renderedOptions.length - 1 && !renderNotFoundComponent}
            >
              {option.name}
            </SelectModalOption>
            </SelectModalOptionContainer>
          ))}
          {renderNotFoundComponent && renderNotFoundComponent()}
          </SelectModalList>
          :         
          <NoDataMessage
          useIcon={false}
          isSubtextLink={true}
          defaultText={defaultNoOptionsTitle}
          text={noOptionsTitle}
          color={'black'}
          />
        }
        {renderApplyButton && renderApplyButton()}
      </SelectModalContent>
    </SelectModalWrapper>
  );
};

export default SelectModal;
