import styled from 'styled-components';
import { global } from '../Global/global';

export const BreadcrumbsHeader = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  min-width: 100%;
  color: white;
  display: flex;
  justify-content: space-around;
  background: ${(props) => props.theme.palette.red};
  max-height: calc(92px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  min-height: calc(57px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const Breadcrumbs = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: calc(21px * ${(props) => props.theme.ratio});
  min-height: calc(21px * ${(props) => props.theme.ratio});
  padding-top: calc(10px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  width: fit-content;
  ${global.userSelectNone}
`;

export const Step = styled.div.attrs(
  (props: { isCurrentStep: boolean; isAccomplished: boolean }) => ({
    isCurrentStep: props.isCurrentStep,
    isAccomplished: props.isAccomplished,
  })
)`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) =>
    props.isAccomplished
      ? props.theme.palette.white
      : props.theme.transparent.white};

  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};

  color ${(props) =>
    props.isCurrentStep
      ? props.theme.palette.red
      : props.isAccomplished
      ? props.theme.palette.white
      : props.theme.transparent.white};

  background: ${(props) =>
    props.isCurrentStep ? props.theme.palette.white : props.theme.palette.red};

  font-size: calc(15px * ${(props) => props.theme.ratio});
  min-width: calc(21px * ${(props) => props.theme.ratio});
  max-width: calc(21px * ${(props) => props.theme.ratio});
  min-height: calc(21px * ${(props) => props.theme.ratio});
  max-height: calc(21px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}
`;

export const StepDivider = styled.span.attrs(
  (props: { isAccomplished: boolean }) => ({
    isAccomplished: props.isAccomplished,
  })
)`
  width: calc(43.5px * ${(props) => props.theme.ratio});
  height: 0;
  border: 1px ${(props) => (props.isAccomplished ? 'solid' : 'dashed')}
    ${(props) =>
      props.isAccomplished
        ? props.theme.palette.white
        : props.theme.transparent.white};
  ${global.userSelectNone}
`;

export const BreadcrumbsTitle = styled.span`
  width: fit-content;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: calc(19px * ${(props) => props.theme.ratio});
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  text-shadow: 0px 4.91667px 49.1667px ${(props) => props.theme.palette.white};
  ${global.userSelectNone}
`;


export const TransparentFixedClose = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
`;
