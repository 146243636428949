import * as React from 'react';

import { Close } from '../Common/ControlButtons';
import { useRouteMatch } from 'react-router-dom';
import {
  TransparentFixedClose
} from '../../primitives/styles/Breadcrumbs/BreadcrumbsStyles';

interface BreadcrumbsProps {
  isPublishInProgress?: boolean;
  currentStep: number;
  totalSteps: number;
}

const TransparentBreadcrumbs: React.FC<BreadcrumbsProps> = ({
  isPublishInProgress,
  currentStep,
}) => {

  const { path } = useRouteMatch();
  const flowName = path.split('/')[1];


  return (
    <>
        <TransparentFixedClose>
            {!isPublishInProgress && <Close flowName={flowName} />}
        </TransparentFixedClose>
    </>
  );
};

export default TransparentBreadcrumbs;
