import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import Phrase from '../Phrase/Phrase';

import { useLongPress } from 'react-use';
import { withTheme } from 'styled-components';
import { createBadgeStyles } from '../Common/styles';
import { Channel as ChannelType } from '../../primitives/types/Channel';
import { ManageChannelIcon, ShareIcon } from '../../primitives/media/Icons';
import {
  Designs,
  ManageChannelName,
  ManageChannelDetails,
  ManageChannelWrapper,
  ManageChannelSharedIconWrapper,
  ManageChannelMobileCheckbox,
} from '../../primitives/styles/Channel/ChannelStyle';
import Checkbox from '../Common/Checkbox';

interface ManageChannelProps {
  theme: any;
  isShared: boolean;
  isSelected: boolean;
  channel: ChannelType;
  isMobile?: boolean
  handleClickOnChannel: (id: string) => void;
  onLongPress: (channel: ChannelType) => void;
  onMarkMobile?: (channelId: string) => void;
}

const ManageChannel: React.FC<ManageChannelProps> = ({
  theme,
  channel,
  isShared,
  isSelected,
  isMobile = false,
  onLongPress,
  handleClickOnChannel,
  onMarkMobile
}) => {
  const useStyles = createBadgeStyles(theme.ratio);
  const classes = useStyles();

  const longPressOptions = {
    isPreventDefault: false,
    delay: 800,
  };

  const handleLongPress = () => {
    onLongPress(channel);
  };

  const longPressEvent = useLongPress(handleLongPress, longPressOptions);

  return (
    <ManageChannelWrapper
      key={channel.id}
      {...longPressEvent}
      onClick={() => handleClickOnChannel(channel.id)}
    >
      {
        isShared &&
        <ManageChannelSharedIconWrapper>
          <ShareIcon />
        </ManageChannelSharedIconWrapper>
      }
      {
        isMobile &&
        <ManageChannelMobileCheckbox>
          <Checkbox isChecked={!!channel.showOnMobile} isDisabled={false} label='' defaultLabel='' onChange={() => onMarkMobile && onMarkMobile(channel.id)} style={{zIndex: 100000}}/>
        </ManageChannelMobileCheckbox>
      }

      <ManageChannelIcon isSelected={isSelected} />
      <ManageChannelDetails>
        <ManageChannelName>{channel.name}</ManageChannelName>
        <Designs>
          <Phrase
            translationKey={'781ae39d-d5f7-483a-a1bf-909c18eef88c'}
            defaultText={`${channel.numberOfRipples}  designs`}
            vars={{ X: String(channel.numberOfRipples) }}
          />
        </Designs>
      </ManageChannelDetails>
      {isSelected && !isMobile &&(
        <Badge
          color="secondary"
          badgeContent=""
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ root: classes.root, badge: classes.badge }}
        >
          <CheckIcon
            color="primary"
            style={{
              zIndex: 2,
              transform: `scale(${0.7})`,
              position: 'absolute',
              color: 'white',
              bottom: -12,
              left: -12,
            }}
          />
        </Badge>
      )}
    </ManageChannelWrapper>
  );
};

export default withTheme(ManageChannel);
