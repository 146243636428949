import styled from 'styled-components';

export const DesignOptionsContainer = styled.div`
display: flex;
flex-wrap: wrap;
margin-top: 10px;
overflow-y: scroll;
padding 5% 5%;
flex-grow: 0;
div:nth-child(n) {
  margin-left: calc(0.01px * ${(props) => props.theme.deviceHeight});
  margin-right: calc(0.01px * ${(props) => props.theme.deviceHeight});
  margin-bottom: calc(0.01px * ${(props) => props.theme.deviceHeight});
  margin-top: calc(0.01px * ${(props) => props.theme.deviceHeight});
}
`

export const DesignOption = styled.div.attrs(
    (props: { isSelected: boolean }) => ({
        isSelected: props.isSelected,
    })
  )`
display: flex;
justify-content: center;
align-items: center;
background-color: #2C2E33;
color: #FFFFFF;
border: 1px solid;
border-color: ${(props) => props.isSelected ? '#E86161' : 'rgba(255, 255, 255, 0.3)'} ;
border-radius: 12px;
padding: 0 20px;
height: 65px;
flex-shrink: 0;
flex-grow: 1;
position: relative;
font-size: calc( 0.022px * ${(props) => props.theme.deviceHeight});
`

export const Seperator = styled.div`
height: calc( 100px * ${(props) => props.theme.heightRatio});
width: 100%;
`;

export const StyledSelectedIcon = styled.img`
position: absolute;
left: -10px;
bottom: -10px;
width: 25px;
height: 25px;
`

export const CountryIcon = styled.img`
width: 30px;
height: 30px;
margin-right: calc( 10px * ${(props) => props.theme.ratio});
`;

export const SkipButton = styled.button`
border: none;
text-decoration: underline;
color: white;
background-color: transparent;
margin-top: calc( 20px * ${(props) => props.theme.heightRatio});
font-size: 16px;
`