import { Translation } from '../../primitives/types/Translation';
import { config } from '../../config/config';

import axios from 'axios';

export const getTranslations = async (): Promise<Translation | null> => {
  try {
    let lang = navigator.language || 'en';
    if (lang.includes('-')) {
      lang = lang.split('-')[0];
    }

    const { url, projectId, version, namespace } = config.TRANSLATIONS;
    const locizeUrl = `${url}/${projectId}/${version}/${lang}/${namespace}`;
    const response = await axios.get(locizeUrl);
    const translations: Translation = response.data;

    if (!translations || Object.keys(translations).length === 0) {
      return null;
    } else {
      return translations;
    }
  } catch (err) {
    return null;
  }
};
