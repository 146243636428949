import Phrase from '../../../../../components/Phrase/Phrase';
import { CountryIcon, DesignOption, DesignOptionsContainer, StyledSelectedIcon  } from '../../../../../primitives/styles/Onboarding/DesignPreferences';
import { Answer } from '../../../../../primitives/types/ContentSurevyStep';
import SelectedIcon from '../../../../../primitives/media/Onboarding/SelectedIcon.svg';

interface CultureOptionsProps {
  cultureOptions: Answer[],
  selectedCultureOptions?: Answer[],
  onClick: (design: Answer, isSelected: boolean) => void;
}

const CultureOptions: React.FC<CultureOptionsProps> = ({
    cultureOptions,
    selectedCultureOptions = [],
  onClick
}) => {
  return (
    <DesignOptionsContainer>
      {cultureOptions.map((cultureOption) => {
        const isSelected = selectedCultureOptions.find((option: Answer) => option._id ===  cultureOption._id);
        return <DesignOption key={cultureOption._id} onClick={() => onClick(cultureOption, !isSelected)} isSelected={!!isSelected} style={{height: '60px', padding: '0 15px'}}>
                <CountryIcon src={cultureOption.icon} alt=''/>
              <Phrase translationKey={cultureOption.title.translationKey} defaultText={cultureOption.title.defaultText}/>
              {isSelected &&  <StyledSelectedIcon src={SelectedIcon} alt=''/>}
        </DesignOption>
      })}
    </DesignOptionsContainer>
  );
};

export default CultureOptions;