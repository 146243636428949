import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useState } from 'react';
import { withTheme } from 'styled-components';
import { createFloatingButtonStyles } from './styles';

interface PublishProps {
  theme: any;
  buttonText: string;
  isDisabled: boolean;
  inProgress?: boolean;
  onClick: () => void;
  buttonDefaultText: string;
  position: 'fixed' | 'absolute' | 'sticky';
  variant: 'contained' | 'outlined';
}
const FloatingButton: React.FC<PublishProps> = ({
  theme,
  onClick,
  variant,
  position,
  isDisabled,
  buttonText,
  inProgress,
  buttonDefaultText,
}) => {
  const [isButtonTouched, setIsButtonTouched] = useState(false);

  const handleOnButtonTouchEnd = () => setIsButtonTouched(false);

  const handleOnButtonTouchStart = () => setIsButtonTouched(true);

  const { ratio } = theme;

  const useStyles = createFloatingButtonStyles(
    isButtonTouched,
    ratio,
    position
  );
  const classes = useStyles();

  return (
    <Button
      id={'floating-button'}
      onTouchStart={handleOnButtonTouchStart}
      onTouchEnd={handleOnButtonTouchEnd}
      classes={{
        root: classes.root,
        contained: classes.contained,
        outlined: classes.outlined,
        disabled: classes.buttonDisabled,
      }}
      disabled={isDisabled}
      variant={variant}
      onClick={onClick}
    >
      {inProgress ? (
        <CircularProgress 
          style={{ color: 'white' }} 
          size={27 * ratio} 
        />) : (
          <Phrase 
            translationKey={buttonText}
            defaultText={buttonDefaultText}
          />
        )}
    </Button>
  );
};

export default withTheme(FloatingButton);
