import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import FloatingButton from '../Common/FloatingButton';

import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { NewChannelIcon } from '../../primitives/media/Icons';
import { SelectTranslations } from '../../store/translationSlice';
import { SelectDeviceKeyboardIsOpen } from '../../store/viewModeSlice';
import { Channel as ChannelType } from '../../primitives/types/Channel';
import { ChannelInfo } from '../../primitives/styles/Channel/ChannelStyle';
import {
  EditButton,
  NewChannelName,
  NoChannelsMessage,
  NewChannel as NewChannelWrapper,
} from '../../primitives/styles/Channel/NewChannelStyle';

const NEW_CHANNEL = 'createChannel.channel';

interface NewChannelProps {
  inProgress: boolean;
  handleSelectChannel: Function;
  createNewChannel: (channelName: string) => Promise<any>;
}

const NewChannel: React.FC<NewChannelProps> = ({
  handleSelectChannel,
  createNewChannel,
  inProgress
}) => {
  const channelNameRef = useRef<HTMLInputElement>(null);
  const translations = useSelector(SelectTranslations)

  const defaultChannelName = translations["f023337f-16ff-4373-a5d1-3630d85350c9"] || "My New Channel"
  const channelNamePlaceholder = translations["aad989d1-485e-48fc-9dde-37c5e0a19046"] || "Channel Name"

  const [isEditTouched, seIsEditTouched] = useState(false);
  const [channelName, setChannelName] = useState(defaultChannelName);
  const keyboardIsOpen = useSelector(SelectDeviceKeyboardIsOpen);

  /*
    This hack is needed for IOS devices to focus and edit the channel name 
    when the Edit button is clicked. Although it works properly on android devices
    iPhone developers are too smart, and want us to be smart and invent coding 
    each time we have a simple behavior that is widely covered 
  */
  useEffect(() => {
    const input = channelNameRef.current;
    if (input) {
      input.addEventListener('click', () => {
        input.focus();
      });
    }
    return () => {
      if (input) {
        input.removeEventListener('click', () => {
          input.focus();
        });
      }
    };
  });

  const handleNameChange = (e: any) => {
    if (channelName === defaultChannelName) {
      setChannelName('');
    }
    const userInput: string = e.target.value;
    if (userInput.length < 21) {
      setChannelName(() => userInput);
    }
  };

  const enableEdit = () => {
    if (channelName === defaultChannelName) {
      setChannelName('');
    }
    channelNameRef.current?.click();
  };

  const handleOnEditTouchEnd = () => seIsEditTouched(false);

  const handleOnEditTouchStart = () => seIsEditTouched(true);

  const handleAssign = () =>
    createNewChannel(channelName)
      .then(({ data }) => get(data, NEW_CHANNEL))
      .then((channel: ChannelType) => handleSelectChannel(channel));

  return (
    <>
      <NoChannelsMessage>
        <Phrase
          translationKey={'794ec9b4-c09d-4e07-a556-bcdc5d50b928'}
          defaultText={`There are no channels yet in this location.

          For your convenience, we've created one you can use. You can edit its name. This channel will be published to Ripple Maker`}
        />
      </NoChannelsMessage>

      <NewChannelWrapper>
        <NewChannelIcon />
        <ChannelInfo>
          <NewChannelName
            value={channelName}
            ref={channelNameRef}
            onFocus={enableEdit}
            onChange={handleNameChange}
            placeholder={channelNamePlaceholder}
          />
          <EditButton
            onClick={enableEdit}
            isTouched={isEditTouched}
            onTouchEnd={handleOnEditTouchEnd}
            onTouchStart={handleOnEditTouchStart}
          >
            <Phrase
              translationKey={'bef42de2-4b51-4344-918b-11f2fe3d57b4'}
              defaultText={'Edit'}
            />
          </EditButton>
        </ChannelInfo>
      </NewChannelWrapper>

      {!keyboardIsOpen && (
        <FloatingButton
          position={'fixed'}
          variant={'contained'}
          onClick={handleAssign}
          inProgress={inProgress}
          isDisabled={isEmpty(channelName) || inProgress}
          buttonText={'10c5f9c8-b0e9-49b9-bd05-64ef50615060'}
          buttonDefaultText={'ASSIGN'}
        ></FloatingButton>
      )}
    </>
  );
};

export default NewChannel;
