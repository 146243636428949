import Loading from '../../components/Loading/Loader';
import getConfigByEnv from '../../utils/getConfigByEnv';
import TransparentBreadcrumbs from '../../components/Breadcrumbs/TransparentBreadcrumbs';

import { uniqueId } from 'lodash';
import { useState, useEffect } from 'react';
import { SetRipples } from '../../store/ripplesSlice';
import { FlowName } from '../../primitives/types/Flow';
import { useDispatch, useSelector } from 'react-redux';
import { Ripple } from '../../primitives/types/Ripples';
import { SelectTotalSteps } from '../../store/flowSlice';
import { SelectLocationId } from '../../store/locationSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PublishDisableAll } from '../../primitives/styles/Publish/Publish';
import { WebappEmbed, WebappEmbedLoader } from '../../primitives/styles/Create/CreateRippleStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';

interface CreateRippleContainerProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
}

const CreateRippleContainer: React.FC<CreateRippleContainerProps> = ({
  currentStep,
  nextPath,
}) => {
  const dispatch = useDispatch();
  const locationId = useSelector(SelectLocationId);
  const [isEditTextOpen, setIsEditTextOpen] = useState(false);
  const [isCreateInProgress, setIsCreateInProgress] = useState(false);
  const [webappEmbedIsLoaded, setWebappEmbedIsLoaded] = useState(false);

  const { WEBAPP_TEMPLATE_ID, WEBAPP_URL } = getConfigByEnv().PORTAL;

  const totalSteps = useSelector(SelectTotalSteps);

  const handleImageReceived = (image: string, isMacaroon?: boolean) => {
    setIsCreateInProgress(true);
    const id = uniqueId()
    fetch(image)
    .then((res: any) => res.blob())
    .then( (blob: Blob) => {
        let url = URL.createObjectURL(blob);
        const ripples: Ripple[] = [ { key: 0, image:url, id, isMacaroonFrame: !!isMacaroon} ]
        const payload = { hasImages: true, ripples };
        dispatch(SetRipples(payload));
        nextPath()
    })
  }

  const getLocalStorage = (key: string) => {
    const itemStr = window.localStorage.getItem(key)
    let shouldDisplay = false;
  
    if (!itemStr) {
      shouldDisplay = true;
    } else {
      const item = JSON.parse(itemStr)
      const now = new Date()
    
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        shouldDisplay = true;
      }
    }
    const webappIframe = document.getElementById('webapp_iframe') as HTMLIFrameElement;
    if (webappIframe && webappIframe.contentWindow) {
      webappIframe.contentWindow.postMessage({shouldDisplay}, '*')
    }
  }
  
  const setLocalStorageWithExpiry = (key: string, value: any, ttl: number) => {
    const now = new Date()
    const item = {
      value,
      expiry: now.getTime() + ttl * 24 * 60 * 60 * 1000,
    }
    window.localStorage.setItem(key, JSON.stringify(item))
  }

  const handleWebappEmbedPopstate = () => {
    const backButton = document.getElementById('portal-mobile-back-button') as HTMLButtonElement;
    if(backButton) {
      backButton.click();
    }
  }

  const webappDataHandler = (e: any) => {
    try {
      const data = JSON.parse(e.data);
      switch(data.type) {
          case 'image':
              const image: string = data.imageBase64;
              handleImageReceived(image, data.isMacaroon);
              break;
          case 'document':
              setWebappEmbedIsLoaded(true);
              break;
          case 'popstate':
              handleWebappEmbedPopstate();
              break;
          case 'edit_text_open':
              setIsEditTextOpen(true);
              break;
          case 'edit_text_closed':
              setIsEditTextOpen(false);
              break;
          case 'get_local_storage':
            getLocalStorage(data.key);
            break;
          case 'set_local_storage':
            setLocalStorageWithExpiry(data.key, data.value, data.ttl);
            break;
          default: 
              return;
      }    
    } catch(e){}
  }

  useEffect(() => {
    window.addEventListener('message',webappDataHandler)
    return () => window.removeEventListener('message',webappDataHandler)
    //eslint-disable-next-line
  },[])

  return (
    <Container isModalOpen={false}>
        <TransparentBreadcrumbs
            totalSteps={totalSteps}
            currentStep={currentStep}
            isPublishInProgress={isCreateInProgress || isEditTextOpen}
        />
        {isCreateInProgress && <PublishDisableAll />}
        {!webappEmbedIsLoaded && (
            <WebappEmbedLoader>
                <Loading/>
            </WebappEmbedLoader>
         )
        }
        
        <WebappEmbed
          id='webapp_iframe'
          title='webapp' 
          src={`${WEBAPP_URL}/iframe?iFrame=true&locationId=${locationId}&templateId=${WEBAPP_TEMPLATE_ID}`}/>
    </Container>
  );
};

export default withAuthenticationRequired(CreateRippleContainer, {
  onRedirecting: () => <Loading />,
});
