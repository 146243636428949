import DogSrc from '../../../../../primitives/media/Onboarding/ripples/dog.jpg'
import NatureSrc from '../../../../../primitives/media/Onboarding/ripples/nature 1.jpg'
import PatternSrc from '../../../../../primitives/media/Onboarding/ripples/pattern.jpg'
import SaxophoneSrc from '../../../../../primitives/media/Onboarding/ripples/saxophone.jpg'
import SunSrc from '../../../../../primitives/media/Onboarding/ripples/sun.jpg'
import TextSrc from '../../../../../primitives/media/Onboarding/ripples/text.jpg'

import './runningRipples.css'

const RunningRipples = () => {
    return <div className='running-ripples-container'>
        <img src={DogSrc} className='running-ripple' alt=''/>
        <img src={NatureSrc} className='running-ripple second-ripple' alt=''/>
        <img src={PatternSrc} className='running-ripple third-ripple' alt=''/>
        <img src={SaxophoneSrc} className='running-ripple fourth-ripple' alt=''/>
        <img src={SunSrc} className='running-ripple fifth-ripple' alt=''/>
        <img src={TextSrc} className='running-ripple sixth-ripple' alt=''/>
    </div>
};

export default RunningRipples;