import * as React from 'react';

import Phrase from '../Phrase/Phrase';
import GA from '../../utils/analytics';
import createUseStyles from './styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import QR from '../../primitives/media/SuccessNotificationIcons/qr.png';
import Upload from '../../primitives/media/SuccessNotificationIcons/upload.png';
import Remove from '../../primitives/media/SuccessNotificationIcons/remove.png';
import Publish from '../../primitives/media/SuccessNotificationIcons/publish.png';
import Channel from '../../primitives/media/SuccessNotificationIcons/channel.png';
import Warning from '../../primitives/media/SuccessNotificationIcons/warning.png';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SelectOrgName } from '../../store/userSlice';
import { TransitionProps } from '@material-ui/core/transitions';

import {
  NotificationContentWrapper,
  SuccessMessage,
} from '../../primitives/styles/SuccessNotification/SuccessNotification';

type Notification = {
  name: string;
  icon: string;
  width: string;
  message: string;
  defaultMessage: string;
};

const successNotifications: Notification[] = [
  {
    name: 'create',
    icon: Upload,
    message: 'a5dd4825-5d3b-4a53-90ec-1cd0b1557a75',
    defaultMessage: 'YOUR NEW DESIGN WAS CREATED SUCCESSFULLY!',
    width: '40%',
  },
  {
    name: 'upload',
    icon: Upload,
    message: 'e98a5d8a-b068-4a1a-8744-9031952d90e5',
    defaultMessage: 'NEW DESIGNS WERE UPLOADED SUCCESSFULLY!',
    width: '40%',
  },
  {
    name: 'publish',
    icon: Publish,
    message: 'd2308c50-6bc3-4c65-8ad1-02f1a14758b9',
    defaultMessage: 'SELECTED DESIGNS WERE PUBLISHED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'frames',
    icon: Publish,
    message: 'd02e6994-44ce-482e-a05a-d00c0a889954',
    defaultMessage: 'SELECTED FRAMES WERE PUBLISHED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'createFrame',
    icon: Publish,
    message: 'd02e6994-44ce-482e-a05a-d00c0a889954',
    defaultMessage: 'SELECTED FRAMES WERE PUBLISHED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'publishFrames',
    icon: Publish,
    message: 'd02e6994-44ce-482e-a05a-d00c0a889954',
    defaultMessage: 'SELECTED FRAMES WERE PUBLISHED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'delete',
    icon: Remove,
    message: 'fc4b6bad-d9d7-481d-8ced-86ad64d47c51',
    defaultMessage: 'SELECTED DESIGNS WERE DELETED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'qrcode',
    icon: QR,
    message: '6b88149a-5cc2-48a0-8e48-25f9e976a0f1',
    defaultMessage: 'QR CODE WAS DOWNLOADED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'publishChannels',
    icon: Channel,
    message: '465ba0fa-0d0f-4418-98d2-d107f57dd504',
    defaultMessage: 'SELECTED CHANNELS PUBLISHED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'unpublishChannels',
    icon: Channel,
    message: 'ee442381-9295-411f-a00d-39d1ddcdf9fc',
    defaultMessage: 'SELECTED CHANNELS REMOVED SUCCESSFULLY!',
    width: '30%',
  },
  {
    name: 'mobileChannels',
    icon: Channel,
    message: '9677c7a5-bd99-46a6-b0c7-f3f4b5ace009',
    defaultMessage: 'MOBILE CHANNELS SELECTED SUCCESSFULLY!',
    width: '30%'
  }
];

interface NotificationProps {
  notification: string;
  clientWarningMessage?: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessNotification: React.FC<NotificationProps> = ({ notification, clientWarningMessage }) => {

  const successNotification = successNotifications.filter((n) => n.name === notification)[0];

  const [open, setOpen] = useState(true);
  const [openWarning, setOpenWarning] = useState(false);

  const orgName = useSelector(SelectOrgName);
  const useStyles = createUseStyles();
  const classes = useStyles();

  const getActionName = () => {
    if( successNotification.name.includes('Channels') ) {
      return 'channels';
    } else {
      return successNotification.name;
    }
  }


  useEffect(() => {
    let closeWarningNotification: any = null;
    if(!open && clientWarningMessage) {
      setOpenWarning(true);
      closeWarningNotification = setTimeout(() => {
        setOpenWarning(false)
      }, 2200);
    }
    return () => {
      if(closeWarningNotification) {
        clearTimeout(closeWarningNotification)
      }
    }
    //eslint-disable-next-line
  }, [open]);


  useEffect(() => {
    const closeNotificationTimeout = setTimeout(() => {
      setOpen(false);
    }, 3000);
    return () => {
      clearTimeout(closeNotificationTimeout);
      GA.event({
        category: 'Flow',
        action: getActionName(),
        label: orgName,
        value: 1
      });
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {clientWarningMessage && (
          <Dialog
            keepMounted
            open={openWarning}
            classes={{ root: classes.root }}
            TransitionComponent={Transition}
            aria-labelledby="notification-dialog-slide-title"
            aria-describedby="notification-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText id="notification-dialog-slide-description">
                <NotificationContentWrapper>
                  <img
                    alt={''}
                    height={'auto'}
                    width={'40%'}
                    src={Warning}
                  />
                </NotificationContentWrapper>
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="notification-dialog-slide-description">
                <NotificationContentWrapper>
                  <SuccessMessage>
                    <Phrase 
                      translationKey={'c3a767e8-47ce-4a19-9f0b-600306b30623'}
                      defaultText={clientWarningMessage}
                    />
                  </SuccessMessage>
                </NotificationContentWrapper>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      <Dialog
        open={open}
        keepMounted
        classes={{ root: classes.root }}
        TransitionComponent={Transition}
        aria-labelledby="notification-dialog-slide-title"
        aria-describedby="notification-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="notification-dialog-slide-description">
            <NotificationContentWrapper>
              <img
                alt={''}
                height={'auto'}
                width={successNotification.width}
                src={successNotification.icon}
              />
            </NotificationContentWrapper>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="notification-dialog-slide-description">
            <NotificationContentWrapper>
              <SuccessMessage>
                <Phrase 
                  translationKey={successNotification.message}
                  defaultText={successNotification.defaultMessage}
                />
              </SuccessMessage>
            </NotificationContentWrapper>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SuccessNotification;
