import { useEffect, useRef } from 'react';

export const useScrollDetector = () => {

  const touchStart = useRef(0);
  const isScrolling = useRef(false);
  
  const setTouchStart = (start: number) => {
      touchStart.current = start;
  }

  const setIsScrolling = (val: boolean) => {
      isScrolling.current = val;
  } 

  const onTouchStart = (e: TouchEvent) => {
    if (isScrolling.current) {
      let start = e.touches[0].clientY;
      setTouchStart(start);
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    let distance = e.touches[0].clientY;
    if (distance !== touchStart.current) {
        setIsScrolling(true);
    }
  };

  const onTouchEnd = (e: TouchEvent) => {
    setIsScrolling(false);
  };

  useEffect(() => {
    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };
    //eslint-disable-next-line
  }, []);

  return (cb: (param: any) => void, param: any) =>
    !isScrolling.current ? cb(param) : null;
};
