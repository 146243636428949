import { FlowName } from "../primitives/types/Flow";
import { FrameMetadata, RippleMetadata } from "../primitives/types/Metadata";

const UPLOAD_FLOW: FlowName = 'upload';
const PUBLISH_FLOW: FlowName = 'publish';
const REMOVE_FLOW: FlowName = 'delete';
const PUBLISH_FRAME: FlowName = 'publishFrames';
const CREATE_FRAME: FlowName = 'createFrame';
const QR_CODE_FLOW: FlowName = 'qrcode';
const CREATE_RIPPLE: FlowName = 'create';


export const flowNames = {
    UPLOAD_FLOW,
    PUBLISH_FLOW,
    REMOVE_FLOW,
    PUBLISH_FRAME,
    CREATE_FRAME,
    QR_CODE_FLOW,
    CREATE_RIPPLE
}

export const defaultRippleMetadata: RippleMetadata = {
    dayOfTheWeek: [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ],
    timeOfTheDay: ["MORNING", "NOON", "EVENING"],
    languageCode: "N/A",
    tags: [],
    isPersonalized: false,
    isShareable: true,
    isTemplate: false,
    isFrame: false,
  };

  export const defaultFrameMetadata: FrameMetadata = {
    languageCode: "N/A",
    tags: [],
    isShareable: true,
  };

export const CAN_ASSIGN = 'contentManager';