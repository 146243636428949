import Channel from './Channel/channel.png';
import RM from './PublishRipples/rmIcon.png';
import Channels from './Channel/channels.png';
import RMTab from './PublishRipples/RMTab.png';
import Vector from './PublishRipples/Vector.png';
import QR from './SuccessNotificationIcons/qr.png';
import EditPen from './PublishRipples/EditPen.png';
import Mobile from './PublishRipples/mobileIcon.png';
import MobileTab from './PublishRipples/mobileTab.png';
import AutomaticTab from './PublishRipples/automaticTab.png';
import SurpriseMeTab from './PublishRipples/surpriseMeTab.png';

import { withTheme } from 'styled-components';

interface IconProps {
  theme?: any;
  color?: string;
  ratio?: number;
  isSelected?: boolean;
}

export const NotFoundIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(43px * ${props.theme.ratio})`}
      height={`calc(43px * ${props.theme.ratio})`}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.314 25.3142C20.6538 29.9744 13.0981 29.9744 8.43793 25.3142C3.77773 20.654 3.77773 13.0983 8.43793 8.43805C13.0981 3.77785 20.6538 3.77785 25.314 8.43805C29.9742 13.0983 29.9742 20.654 25.314 25.3142ZM25.314 25.3142L33.7521 33.7522"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <line
        x1="12.4337"
        y1="12"
        x2="21.5873"
        y2="21.1536"
        stroke="#E86161"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="13.6952"
        y2="-0.75"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 22.373 12)"
        stroke="#E86161"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const BackIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(22px * ${props.theme.ratio})`}
      height={`calc(21px * ${props.theme.ratio})`}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2"
        y1="11.001"
        x2="21"
        y2="11.001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.1924 2.30859L1.7071 10.7939L10.1924 19.2792"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const CloseIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(38px * ${props.theme.ratio})`}
      height={`calc(21px * ${props.theme.ratio})`}
      viewBox="0 0 38 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4907 2.30859L19.0054 10.7939L27.4907 19.2792"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4853 19.2793L18.9706 10.794L10.4853 2.30873"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const CreateIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(24.5px * ${props.theme.ratio})`}
      height={`calc(24.5px * ${props.theme.ratio})`}
      viewBox="0 0 31 31"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1581 6.20569L24.7317 1.7635C23.7173 0.7455 21.9652 0.7455 20.9508 1.7635C20.2131 2.50387 3.89057 18.8844 3.5217 19.1621C3.42949 19.2546 3.33727 19.4397 3.24505 19.6248L1.03183 28.7868C0.8474 29.5272 1.49292 30.0825 2.23066 29.9899C2.69175 29.8974 10.8991 27.8614 11.3602 27.7688C11.5446 27.7688 11.7291 27.6763 11.8213 27.4912C12.1901 27.121 28.4204 10.833 29.2504 10.0001C30.2648 8.88951 30.2647 7.22369 29.1581 6.20569ZM5.82714 27.121L3.89057 25.1775L4.72053 21.7534L9.23918 26.2881L5.82714 27.121ZM3.33727 27.6763L3.42949 27.3061L3.70614 27.5837L3.33727 27.6763ZM11.0835 25.4552L5.55049 19.9024L19.8442 5.55787L25.3772 11.1106L11.0835 25.4552ZM27.8671 8.61188L26.7605 9.72242L21.2274 4.16969L22.3341 3.05914C22.6107 2.7815 23.164 2.7815 23.4407 3.05914L27.8671 7.50133C28.1438 7.87151 28.1438 8.33424 27.8671 8.61188Z"
        fill={props.color || '#E86161'}
        stroke={props.color || '#E86161'}
        strokeWidth="0.25"
      />
    </svg>
  );
});

export const UploadIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(23px * ${props.theme.ratio})`}
      height={`calc(26px * ${props.theme.ratio})`}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0005 24.3169H1.00195"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9994 8.49949L11.4999 1L4.00043 8.49949"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5002 1V18.9988"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const RemoveIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(30px * ${props.theme.ratio})`}
      height={`calc(29px * ${props.theme.ratio})`}
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66968 4.06958L4.56169 25.6561C4.59886 26.9855 5.75105 28.0001 7.16341 28.0001H21.8445C23.2569 28.0001 24.4091 26.9506 24.4462 25.6561L25.3382 4.06958"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.66968 4.06958L4.56169 25.6561C4.59886 26.9855 5.75105 28.0001 7.16341 28.0001H21.8445C23.2569 28.0001 24.4091 26.9506 24.4462 25.6561L25.3382 4.06958"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.5622 3.47406V2.14459C18.5622 1.44486 17.9676 0.850098 17.1871 0.850098H11.6491C10.8686 0.850098 10.2739 1.44486 10.2739 2.14459V3.47406"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.5622 3.47406V2.14459C18.5622 1.44486 17.9676 0.850098 17.1871 0.850098H11.6491C10.8686 0.850098 10.2739 1.44486 10.2739 2.14459V3.47406"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.53876 10.3894L9.76176 21.0952M14.4821 10.3894V21.0952M19.4253 10.3894L19.2023 21.0952"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.53876 10.3894L9.76176 21.0952M14.4821 10.3894V21.0952M19.4253 10.3894L19.2023 21.0952"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="3.76904"
        x2="28.6016"
        y2="3.76904"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.75"
        y1="3.76904"
        x2="28.6016"
        y2="3.76904"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const PublishIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(43px * ${props.theme.ratio})`}
      height={`calc(36px * ${props.theme.ratio})`}
      viewBox="0 0 43 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8945 27.2427C39.2116 26.0367 41.7669 23.1169 42.1141 19.4633C42.6409 13.9216 38.3121 9.25932 32.8977 9.25905C30.8815 4.40981 26.1137 1 20.5499 1C13.4763 1 7.70003 6.51275 7.2206 13.4879C3.48024 14.0046 0.645387 17.3672 1.03601 21.347C1.33115 24.354 3.56071 26.7097 6.36838 27.5292"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10.8423"
        y="20.6842"
        width="20.5788"
        height="14.3157"
        rx="1"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="26.9466"
        cy="25.158"
        r="1.78946"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7365 34.1052L16.3918 27.5102C16.7588 26.9902 17.5118 26.9409 17.9435 27.4086L21.8285 31.6173C22.1897 32.0086 22.7943 32.0482 23.2035 31.7072L24.4559 30.6635C24.8532 30.3324 25.4374 30.3589 25.8031 30.7247L30.0784 34.9999"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const FramesIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(43px * ${props.theme.ratio})`}
      height={`calc(36px * ${props.theme.ratio})`}
      viewBox="0 0 43 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8945 27.2427C39.2116 26.0367 41.7669 23.1169 42.1141 19.4633C42.6409 13.9216 38.3121 9.25932 32.8977 9.25905C30.8815 4.40981 26.1137 1 20.5499 1C13.4763 1 7.70003 6.51275 7.2206 13.4879C3.48024 14.0046 0.645387 17.3672 1.03601 21.347C1.33115 24.354 3.56071 26.7097 6.36838 27.5292"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3149 23.3685V22.6185C14.9007 22.6185 14.5649 22.9543 14.5649 23.3685H15.3149ZM27.8411 23.3685H28.5911C28.5911 22.9543 28.2553 22.6185 27.8411 22.6185V23.3685ZM27.8411 32.3158V33.0658C28.2553 33.0658 28.5911 32.7301 28.5911 32.3158H27.8411ZM15.3149 32.3158H14.5649C14.5649 32.7301 14.9007 33.0658 15.3149 33.0658V32.3158ZM25.1569 22.6185C24.7427 22.6185 24.4069 22.9543 24.4069 23.3685C24.4069 23.7827 24.7427 24.1185 25.1569 24.1185V22.6185ZM27.0911 26.0527C27.0911 26.4669 27.4269 26.8027 27.8411 26.8027C28.2553 26.8027 28.5911 26.4669 28.5911 26.0527H27.0911ZM28.5911 29.6316C28.5911 29.2174 28.2553 28.8816 27.8411 28.8816C27.4269 28.8816 27.0911 29.2174 27.0911 29.6316H28.5911ZM25.1569 31.5658C24.7427 31.5658 24.4069 31.9016 24.4069 32.3158C24.4069 32.7301 24.7427 33.0658 25.1569 33.0658V31.5658ZM17.9991 33.0658C18.4133 33.0658 18.7491 32.7301 18.7491 32.3158C18.7491 31.9016 18.4133 31.5658 17.9991 31.5658V33.0658ZM16.0649 29.6316C16.0649 29.2174 15.7291 28.8816 15.3149 28.8816C14.9007 28.8816 14.5649 29.2174 14.5649 29.6316H16.0649ZM14.5649 26.0527C14.5649 26.4669 14.9007 26.8027 15.3149 26.8027C15.7291 26.8027 16.0649 26.4669 16.0649 26.0527H14.5649ZM17.9991 24.1185C18.4133 24.1185 18.7491 23.7827 18.7491 23.3685C18.7491 22.9543 18.4133 22.6185 17.9991 22.6185V24.1185ZM25.1569 24.1185H27.8411V22.6185H25.1569V24.1185ZM27.0911 23.3685V26.0527H28.5911V23.3685H27.0911ZM27.0911 29.6316V32.3158H28.5911V29.6316H27.0911ZM27.8411 31.5658H25.1569V33.0658H27.8411V31.5658ZM17.9991 31.5658H15.3149V33.0658H17.9991V31.5658ZM16.0649 32.3158V29.6316H14.5649V32.3158H16.0649ZM16.0649 26.0527V23.3685H14.5649V26.0527H16.0649ZM15.3149 24.1185H17.9991V22.6185H15.3149V24.1185Z"
        fill={props.color || '#E86161'}
      />
      <rect
        x="12.6315"
        y="20.6842"
        width="17.8946"
        height="14.3157"
        rx="1"
        stroke={props.color || '#E86161'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const QrCodeIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt="gr"
      src={QR}
      style={{
        width: `calc(46px * ${props.theme.ratio})`,
        height: `calc(46px * ${props.theme.ratio})`,
      }}
    />
  );
});

export const ChannelsIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt="gr"
      src={Channels}
      style={{
        width: `calc(41.16px * ${props.theme.ratio})`,
        height: `calc(36.52px * ${props.theme.ratio})`,
      }}
    />
  );
});

export const ChannelIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={Channel}
      style={{ width: `calc(42px * ${props.theme.ratio})` }}
    />
  );
});

export const NewChannelIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={Channel}
      style={{ width: `calc(68px * ${props.theme.ratio})` }}
    />
  );
});

export const RightArrowIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={Vector}
      style={{ width: `calc(12px * ${props.theme.ratio})` }}
    />
  );
});

export const EditPenIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={EditPen}
      style={{ width: `calc(17px * ${props.theme.ratio})` }}
    />
  );
});

export const MobileIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={Mobile}
      style={{ height: 'auto', width: `calc(31px * ${props.theme.ratio})` }}
    />
  );
});

export const RMIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={RM}
      style={{ height: 'auto', width: `calc(35px * ${props.theme.ratio})` }}
    />
  );
});

export const MobileTabIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={MobileTab}
      style={{
        height: `calc(40px * ${props.theme.ratio})`,
        width: `calc(40px * ${props.theme.ratio})`,
      }}
    />
  );
});

export const RMTabIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={RMTab}
      style={{
        height: `calc(40px * ${props.theme.ratio})`,
        width: `calc(40px * ${props.theme.ratio})`,
      }}
    />
  );
});

export const SurpriseMeTabIcon: React.FC<any> = withTheme(
  (props: IconProps) => {
    return (
      <img
        alt=""
        src={SurpriseMeTab}
        style={{
          height: `calc(40px * ${props.theme.ratio})`,
          width: `calc(40px * ${props.theme.ratio})`,
        }}
      />
    );
  }
);

export const AutomaticTabIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <img
      alt=""
      src={AutomaticTab}
      style={{
        height: `calc(40px * ${props.theme.ratio})`,
        width: `calc(40px * ${props.theme.ratio})`,
      }}
    />
  );
});

export const RippleLogoIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="28.1485" cy="27.7276" rx="27.764" ry="27.7279" fill="none" />
      <path
        d="M33.7746 33.0602C33.6256 34.0027 33.3772 34.8459 32.9799 35.6892C31.4898 38.9135 28.2117 41.1456 24.4369 41.1456C21.4568 41.1456 18.7747 39.7567 17.0363 37.5741C15.7946 35.9372 14.9999 33.9034 14.9999 31.7209L14.9999 23.7346C14.9999 23.2386 15.0495 22.7425 15.0992 22.2465C15.0992 22.0977 15.1489 21.9489 15.1489 21.8497C15.2979 21.2544 15.4966 20.6592 15.7449 20.1135C16.2912 18.7742 17.1853 17.5837 18.278 16.6412C19.619 15.4507 21.3078 14.6571 23.1455 14.4091L23.1952 14.4091C23.6422 14.3594 24.0395 14.3098 24.4369 14.3098L32.4335 14.3098C37.6487 14.3098 41.8706 18.5262 41.8706 23.7346C41.8706 28.447 38.3441 32.4153 33.7746 33.0602ZM32.4335 12.3257L24.4369 12.3257C23.9402 12.3257 23.3939 12.3753 22.8475 12.4249C18.0793 13.1193 14.2052 16.7404 13.2615 21.4528C13.2118 21.6016 13.2118 21.8001 13.1621 21.9489C13.0628 22.5441 13.0131 23.1394 13.0131 23.7346L13.0131 31.7209C13.0131 38.0206 18.129 43.1298 24.4369 43.1298C29.6024 43.1298 34.1223 39.6079 35.4633 34.7467C40.3308 33.4074 43.8573 28.8934 43.8573 23.7346C43.8573 17.4349 38.7414 12.3257 32.4335 12.3257Z"
        fill="#E86161"
      />
      <path
        d="M33.5263 29.0919C33.1786 29.1415 32.7812 29.1911 32.4336 29.1911L29.9005 29.1911L29.9005 31.7209C29.9005 33.5562 28.9568 35.2428 27.5164 36.1852L27.4667 36.2349C27.417 36.2845 27.3177 36.3341 27.268 36.3837C27.1687 36.4333 27.119 36.4829 27.0197 36.5325C26.97 36.5325 26.9203 36.5821 26.8707 36.5821C26.7713 36.6317 26.6223 36.6813 26.523 36.7309C26.4237 36.7805 26.2746 36.8301 26.1753 36.8797C26.1256 36.8797 26.076 36.9293 26.0263 36.9293C25.9766 36.9293 25.927 36.9789 25.8773 36.9789C25.4303 37.0781 24.9336 37.1773 24.4369 37.1773C24.0396 37.1773 23.6919 37.1277 23.3442 37.0781C20.8608 36.5821 18.9734 34.3499 18.9734 31.7209L18.9734 23.7346C18.9734 23.437 18.9734 23.1394 19.0727 22.8418C19.0727 22.7922 19.0727 22.6929 19.1224 22.6433C19.5694 20.4112 21.4071 18.675 23.7415 18.3278C23.9402 18.2782 24.1886 18.2782 24.4369 18.2782L32.4336 18.2782C35.4633 18.2782 37.8971 20.7088 37.8971 23.7346C37.8971 26.3636 36.0097 28.5462 33.5263 29.0919ZM32.4336 16.294L24.4369 16.294C24.0892 16.294 23.7912 16.294 23.3939 16.3932C20.3144 16.7901 17.7813 19.1711 17.1356 22.2465C17.1356 22.3457 17.086 22.4449 17.086 22.5441C17.0363 22.941 16.9866 23.3378 16.9866 23.7346L16.9866 31.7209C16.9866 35.838 20.3144 39.1615 24.4369 39.1615C28.5594 39.1615 31.8872 35.838 31.8872 31.7209L31.8872 31.1752L32.4336 31.1752C36.556 31.1752 39.8838 27.8518 39.8838 23.7346C39.8838 19.6175 36.5064 16.294 32.4336 16.294Z"
        fill="#E86161"
      />
    </svg>
  );
});

export const LandscapeErrorIcon: React.FC<any> = withTheme(
  (props: IconProps) => {
    return (
      <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M82 31.8974C82 18.5354 71.168 7.70337 57.806 7.70337"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.9311 4.28646L56.4597 7.02566L62.4265 13.6336"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.5 80L86.5 48C86.5 44.4101 83.5899 41.5 80 41.5L79.5 41.5L79.5 86.5L80 86.5C83.5898 86.5 86.5 83.5899 86.5 80ZM78 86.5L78 41.5L16.5 41.5L16.5 86.5L78 86.5ZM78 40L79.5 40L80 40C84.4183 40 88 43.5817 88 48L88 80C88 84.4183 84.4183 88 80 88L79.5 88L78 88L16.5 88L15 88L8 88C3.58172 88 -1.94158e-06 84.4183 -1.74846e-06 80L-3.49691e-07 48C-1.56562e-07 43.5817 3.58173 40 8 40L78 40ZM8 41.5L15 41.5L15 86.5L8 86.5C4.41015 86.5 1.5 83.5898 1.5 80L1.5 48C1.5 44.4101 4.41015 41.5 8 41.5ZM82 70L82 58L83.5 58L83.5 70L82 70ZM8.5 67.5C6.84315 67.5 5.5 66.1569 5.5 64.5C5.5 62.8431 6.84315 61.5 8.5 61.5C10.1569 61.5 11.5 62.8431 11.5 64.5C11.5 66.1569 10.1569 67.5 8.5 67.5ZM8.5 69C6.01472 69 4 66.9853 4 64.5C4 62.0147 6.01472 60 8.5 60C10.9853 60 13 62.0147 13 64.5C13 66.9853 10.9853 69 8.5 69Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 1.5H8C4.41015 1.5 1.5 4.41015 1.5 8V8.5H46.5V8C46.5 4.41015 43.5899 1.5 40 1.5ZM46.5 10H1.5V71.5H46.5V10ZM0 10V8.5V8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V8.5V10L48 71.5V73L48 80C48 84.4183 44.4183 88 40 88H8C3.58172 88 0 84.4183 0 80V10ZM1.5 80V73H46.5V80C46.5 83.5899 43.5899 86.5 40 86.5H8C4.41015 86.5 1.5 83.5899 1.5 80ZM30 6H18V4.5H30V6ZM27.5 79.5C27.5 81.1569 26.1569 82.5 24.5 82.5C22.8431 82.5 21.5 81.1569 21.5 79.5C21.5 77.8431 22.8431 76.5 24.5 76.5C26.1569 76.5 27.5 77.8431 27.5 79.5ZM29 79.5C29 81.9853 26.9853 84 24.5 84C22.0147 84 20 81.9853 20 79.5C20 77.0147 22.0147 75 24.5 75C26.9853 75 29 77.0147 29 79.5Z"
          fill="white"
        />
      </svg>
    );
  }
);

export const SearchIcon = (): JSX.Element => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8922 13.6309C10.0585 16.4646 5.46428 16.4646 2.63062 13.6309C-0.203035 10.7973 -0.203035 6.20298 2.63062 3.36932C5.46428 0.535662 10.0585 0.535662 12.8922 3.36932C15.7259 6.20298 15.7259 10.7973 12.8922 13.6309ZM12.8922 13.6309L18.023 18.7617"
        stroke="white"
        strokeWidth="0.907004"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const WalkThroughArrow: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(35px * ${props.theme.ratio})`}
      height={`calc(56px * ${props.theme.ratio})`}
      viewBox="0 0 35 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.35812 50.6559C7.7634 33.3002 23.1593 11.8005 33.0045 5.19784"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2602 7.61995L33.167 4.21087L31.0446 20.0695"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const WarningIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(36px * ${props.theme.ratio})`}
      height={`calc(36px * ${props.theme.ratio})`}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4766 3.79238C17.1537 2.6197 18.8463 2.6197 19.5234 3.79239L31.0658 23.7845C31.7429 24.9572 30.8966 26.4231 29.5425 26.4231H6.45752C5.10342 26.4231 4.25711 24.9572 4.93416 23.7845L16.4766 3.79238Z"
        fill="white"
        stroke="white"
        strokeWidth="1.15385"
      />
      <path
        d="M16.4764 20.9718C16.4764 20.5269 16.6204 20.1632 16.9084 19.8806C17.2018 19.592 17.5658 19.4478 18.0005 19.4478C18.4352 19.4478 18.7965 19.592 19.0845 19.8806C19.3779 20.1632 19.5246 20.5269 19.5246 20.9718C19.5246 21.4167 19.3779 21.7835 19.0845 22.072C18.7965 22.3546 18.4352 22.4959 18.0005 22.4959C17.5658 22.4959 17.2018 22.3546 16.9084 22.072C16.6204 21.7835 16.4764 21.4167 16.4764 20.9718Z"
        fill="#E86161"
      />
      <path
        d="M16.5922 10.5197L17.1099 17.319C17.1454 17.7847 17.5336 18.1444 18.0006 18.1444C18.4676 18.1444 18.8558 17.7847 18.8913 17.319L19.409 10.5197C19.4714 9.69972 18.8229 9 18.0006 9C17.1783 9 16.5298 9.69972 16.5922 10.5197Z"
        fill="#E86161"
      />
    </svg>
  );
});

export const WarningIconInverted: React.FC<any> = withTheme(
  (props: IconProps) => {
    return (
      <svg
        width={`calc(36px * ${props.theme.ratio})`}
        height={`calc(36px * ${props.theme.ratio})`}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4766 3.79238C17.1537 2.6197 18.8463 2.6197 19.5234 3.79239L31.0658 23.7845C31.7429 24.9572 30.8966 26.4231 29.5425 26.4231H6.45752C5.10342 26.4231 4.25711 24.9572 4.93416 23.7845L16.4766 3.79238Z"
          fill="#E86161"
          stroke="#E86161"
          strokeWidth="1.15385"
        />
        <path
          d="M16.4764 20.9718C16.4764 20.5269 16.6204 20.1632 16.9084 19.8806C17.2018 19.592 17.5658 19.4478 18.0005 19.4478C18.4352 19.4478 18.7965 19.592 19.0845 19.8806C19.3779 20.1632 19.5246 20.5269 19.5246 20.9718C19.5246 21.4167 19.3779 21.7835 19.0845 22.072C18.7965 22.3546 18.4352 22.4959 18.0005 22.4959C17.5658 22.4959 17.2018 22.3546 16.9084 22.072C16.6204 21.7835 16.4764 21.4167 16.4764 20.9718Z"
          fill="white"
        />
        <path
          d="M16.5922 10.5197L17.1099 17.319C17.1454 17.7847 17.5336 18.1444 18.0006 18.1444C18.4676 18.1444 18.8558 17.7847 18.8913 17.319L19.409 10.5197C19.4714 9.69972 18.8229 9 18.0006 9C17.1783 9 16.5298 9.69972 16.5922 10.5197Z"
          fill="white"
        />
      </svg>
    );
  }
);

export const ManageChannelIcon: React.FC<any> = withTheme(
  (props: IconProps) => {
    return (
      <svg
        width={`calc(147px * ${props.theme.ratio})`}
        height={`calc(132px * ${props.theme.ratio})`}
        viewBox="0 0 147 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M146.43 127.123V22.5331C146.43 19.9574 144.342 17.8694 141.766 17.8694H73.7739L60.6424 1.72129C59.7568 0.632237 58.4278 0 57.0241 0H46.3881H4.66365C2.08799 0 0 2.08798 0 4.66365V127.123C0 129.699 2.08798 131.787 4.66364 131.787H141.766C144.342 131.787 146.43 129.699 146.43 127.123Z"
          fill="#383C46"
        />
        <path
          d="M73.386 18.1849L73.536 18.3694H73.7739H141.766C144.066 18.3694 145.93 20.2336 145.93 22.5331V127.123C145.93 129.423 144.066 131.287 141.766 131.287H4.66364C2.36412 131.287 0.5 129.423 0.5 127.123V4.66365C0.5 2.36412 2.36413 0.5 4.66365 0.5H46.3881H57.0241C58.2773 0.5 59.4638 1.06445 60.2545 2.03674L73.386 18.1849Z"
          stroke={props.isSelected ? '#E86161' : 'white'}
          strokeOpacity="1"
          strokeWidth={props.isSelected ? '4' : '1'}
        />
      </svg>
    );
  }
);

export const AddButton: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(48px * ${props.theme.ratio})`}
      height={`calc(48px * ${props.theme.ratio})`}
      viewBox="0 0 48 48"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#E86161" />
      <path
        d="M34.9986 23.3169H14"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4982 15V32.9988"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export const DeleteButton: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(48px * ${props.theme.ratio})`}
      height={`calc(48px * ${props.theme.ratio})`}
      viewBox="0 0 48 48"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#E86161" />
      <path
        d="M15.7506 16.4131L16.4192 32.5929C16.447 33.5894 17.3106 34.3498 18.3692 34.3498H29.3732C30.4318 34.3498 31.2954 33.5631 31.3233 32.5929L31.9919 16.4131"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.7506 16.4131L16.4192 32.5929C16.447 33.5894 17.3106 34.3498 18.3692 34.3498H29.3732C30.4318 34.3498 31.2954 33.5631 31.3233 32.5929L31.9919 16.4131"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.9131 15.9668V14.9703C26.9131 14.4458 26.4673 14 25.8823 14H21.7314C21.1464 14 20.7007 14.4458 20.7007 14.9703V15.9668"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.9131 15.9668V14.9703C26.9131 14.4458 26.4673 14 25.8823 14H21.7314C21.1464 14 20.7007 14.4458 20.7007 14.9703V15.9668"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.1496 21.1499L20.3168 29.1742M23.8548 21.1499V29.1742M27.5599 21.1499L27.3928 29.1743"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.1496 21.1499L20.3168 29.1742M23.8548 21.1499V29.1742M27.5599 21.1499L27.3928 29.1743"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="13.75"
        y1="16"
        x2="34.25"
        y2="16"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="13.75"
        y1="16"
        x2="34.25"
        y2="16"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
});

export const ShareIcon: React.FC<any> = withTheme((props: IconProps) => {
  return (
    <svg
      width={`calc(18px * ${props.theme.ratio})`}
      height={`calc(18px * ${props.theme.ratio})`}
      viewBox="0 0 38 38"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.34666 38C11.4041 38 14.6933 35.1726 14.6933 31.6848C14.6933 28.197 11.4041 25.3696 7.34666 25.3696C3.28921 25.3696 0 28.197 0 31.6848C0 35.1726 3.28921 38 7.34666 38Z"
        fill="white"
      />
      <path
        d="M7.4733 12.6304C11.5307 12.6304 14.82 9.80297 14.82 6.31519C14.82 2.82741 11.5307 0 7.4733 0C3.41585 0 0.126636 2.82741 0.126636 6.31519C0.126636 9.80297 3.41585 12.6304 7.4733 12.6304Z"
        fill="white"
      />
      <path
        d="M22.9267 22.1031L13.8067 27.4384C14.44 28.0917 14.9467 28.8539 15.2 29.7249L24.5733 24.2808C23.94 23.6275 23.3067 22.8653 22.9267 22.1031Z"
        fill="white"
      />
      <path
        d="M30.6533 25.9141C34.7108 25.9141 38 23.0867 38 19.5989C38 16.1111 34.7108 13.2837 30.6533 13.2837C26.5959 13.2837 23.3067 16.1111 23.3067 19.5989C23.3067 23.0867 26.5959 25.9141 30.6533 25.9141Z"
        fill="white"
      />
      <path
        d="M30.02 18.2923L29.64 18.0745V20.5788C29.8933 20.4699 30.2733 20.361 30.4 20.0344C30.9067 19.49 30.78 18.6189 30.02 18.2923Z"
        fill="white"
      />
      <path
        d="M24.4466 15.1347L14.8199 9.69054C14.3133 10.4527 13.8066 11.2149 12.9199 11.7593L22.7999 17.3123C23.1799 16.4413 23.8132 15.788 24.4466 15.1347Z"
        fill="white"
      />
    </svg>
  );
});
