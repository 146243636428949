export const GET_INHERIDTED_RIPPLES = `
  query findInheritedRipplesByTags(
    $orgId: String!
    $tags: [String!]
    $canViewOnlyOwn: Boolean!
    $isFrame: Boolean
    $pageSize: Int!
    $lastPageCursor: String = ""
  ) {
    viewer {
      organizationScope(id: $orgId) {
        inheritedRipples(onlyMine: $canViewOnlyOwn, tags: $tags, isFrame: $isFrame, first: $pageSize, after: $lastPageCursor) {
          totalCount
          edges {
            node {
              id
              rippleImage {
                id
                thumbnailUrl
              }
              isApproved
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;
