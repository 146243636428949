import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loader';
import LocationsList from '../../components/Location/LocationsList';
import ThumbnailWidget from '../../components/ThumbNails/ThumbnailWidget';
import RippleMakersModal from '../../components/Location/RippleMakersModal';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';

import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { SelectRipples } from '../../store/ripplesSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SelectTotalSteps, SetStepForward, } from '../../store/flowSlice';
import { Location as LocationType } from '../../primitives/types/Location';
import { SetSelectedLocation, SelectLocations, } from '../../store/locationSlice';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';

interface SelectLocationContainerProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
  path: string;
}
const SelectLocationContainer: React.FC<SelectLocationContainerProps> = ({
  currentStep,
  nextPath,
  flowName,
  path
}) => {

  const dispatch = useDispatch();
  const ripples = useSelector(SelectRipples);
  const locations = useSelector(SelectLocations);
  const totalSteps = useSelector(SelectTotalSteps);
  
  const initLocationForModal: LocationType[] = [];
  const [openModal, setOpenModal] = useState(false);
  const [locationForModal, setLocationForModal] = useState(initLocationForModal)
  
  useEffect(() => {
    if (locations.length === 1) {
      dispatch(SetSelectedLocation(locations[0]));
      nextPath();
    }
    // eslint-disable-next-line
  }, [locations]);

  const handleSelectLocation = (location: LocationType) => {
    dispatch(SetSelectedLocation(location));
    dispatch(SetStepForward({ currentStep, path }));
    nextPath();
  };

  const handleOpenModal = ( location: LocationType ) => {
    setLocationForModal([location])
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setLocationForModal([])
    setOpenModal(false);
  }

  return (
    <>
      <Container>
        <BreadcrumbsHeader
          totalSteps={totalSteps}
          currentStep={currentStep}
          flow={flowName as string}
        />
        <Wrapper>
          {!isEmpty(ripples) && (
            <ThumbnailWidget ripples={ripples} />
          )}
          <Title
            type="flow"
            size={fontSize.MID_LARGE}
            text={titles.SELECT_LOCATION}
            defaultText={'Select location'}
          />
          <LocationsList
            locations={locations}
            handleOpenModal={handleOpenModal}
            handleSelectLocation={handleSelectLocation}
          />
        </Wrapper>
      </Container>
      {locationForModal.length && (
          <RippleMakersModal isOpenModal={openModal} handleCloseModal={handleCloseModal} location={locationForModal[0]}/>
        )}
    </>
  );
};

export default withAuthenticationRequired(SelectLocationContainer, {
  onRedirecting: () => <Loading />,
});