export const CREATE_FRAME = `
  mutation createFrame(
    $url: String!
    $fingerprint: String!
    $organization: String!
    $languageCode: String!
    $tags: [String]
  ) {
    createFrame(
      input: {
        clientMutationId: "1"
        url: $url
        fingerprint: $fingerprint
        organization: $organization
        languageCode: $languageCode
        tags: $tags
      }
    ) {
      clientMutationId
      frame {
        id
        rippleImage {
          thumbnailUrl
        }
      }
    }
  }
`;
