export const GET_RIPPLE_INFO = `
  query getRippleInfo(
    $orgId: String!
    $rippleId: String!
    $canViewOnlyOwn: Boolean!
  ) {
    viewer {
      organizationScope(id: $orgId) {
        organization(id: $orgId) {
          code
          name
        }
        ripple(id: $rippleId, onlyMine: $canViewOnlyOwn) {
          id
          languageCode
          rippleImage {
            id
            thumbnailUrl
          }
          createdAt
          createdBy {
            id
            name
          }
          organization {
            code
            name
          }
          dayOfTheWeek
          timeOfTheDay
          isApproved
          approvedBy {
            id
            name
          }
          isTemplate
          isShareable
          isFrame
          tags
        }
      }
    }
  }
`;