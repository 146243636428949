import * as React from 'react';
import Phrase from '../Phrase/Phrase';
import { Machines } from '../../primitives/styles/Location/Location';
import { RippleMaker } from '../../primitives/types/Location';


interface RippleMakersProps {
  id: string;
  rippleMakers: RippleMaker[];
  handleOpenModal: () => void;
}

const RippleMakers: React.FC<RippleMakersProps> = ({ id, rippleMakers, handleOpenModal }) => {

  const numberOfMakers: number = rippleMakers && rippleMakers.length;
  const hasSingleMachine: boolean = numberOfMakers === 1;
  const serialNumber: string | null =
    numberOfMakers === 1 ? rippleMakers[0].serial : null;

  const handleOnClick = (e: any) => {
    if (hasSingleMachine) return;
    handleOpenModal();
    e.stopPropagation()
  };

  return (
    <>
      {!!numberOfMakers && (
        <Machines
          onClick={handleOnClick}
          hasSingleMachine={hasSingleMachine}
          id={hasSingleMachine ? `${id}-single` : id}
        >
          {hasSingleMachine ? serialNumber : (
            <Phrase
              translationKey={'1e742613-2441-4472-be18-f0c2377ae300'}
              defaultText={`${numberOfMakers} Machines`}
              vars={{X: String(numberOfMakers)}}
            />
          )}
        </Machines>
      )}
    </>
  );
};

export default RippleMakers;
