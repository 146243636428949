export const GET_COUNTRIES = `
  query {
    viewer {
      countries {
        id
        name
      }
      states {
        id
        name
        state
      }
    }
  }
`;