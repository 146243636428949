

type Routes = {
    HOME: string,
    FRAME: string,
    CREATE: string,
    CREATE_FRAME: string,
    PUBLISH_FRAME: string,
    UPLOAD: string,
    DELETE: string,
    PUBLISH: string,
    QR_CODE: string,
    CHANNELS: string,
    ONBOARDING: string
}
const BASE_PATH = '/portal';

const HOME = '';
const CREATE = '/create';
const FRAME = '/frames';
const CREATE_FRAME = '/frames/create';
const PUBLISH_FRAME = '/frames/publish';
const UPLOAD = '/upload';
const DELETE = '/delete';
const QR_CODE = '/qrcode';
const PUBLISH = '/publish';
const CHANNELS = '/channels'
const ONBOARDING = '/onboarding'

const routes: Routes = {
    HOME,
    FRAME,
    PUBLISH_FRAME,
    CREATE_FRAME,
    CREATE,
    UPLOAD,
    DELETE,
    PUBLISH,
    QR_CODE,
    CHANNELS,
    ONBOARDING
}

export const generateAppRoutes = () => {
    
    const appRoutes: Routes = Object.create(routes);

    Object.keys(routes).forEach( (route) => {
        appRoutes[route as keyof Routes] = `${BASE_PATH}${routes[route as keyof Routes]}`
    })

    return appRoutes;

}

export default routes
