import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface FlowState {
  totalSteps: number;
  currentStep: number;
  pathHistory: string[]
}

export const initState: FlowState = {
  totalSteps: 0,
  currentStep: 1,
  pathHistory: ['/portal']
};

const flowSlice = createSlice({
  name: 'flow',
  initialState: initState,
  reducers: {
    SetTotalSteps( state, action: PayloadAction<number>) {
      state.totalSteps = action.payload
    },

    SetCurrentStep( state, action: PayloadAction<number>) {
      state.currentStep = action.payload
    },

    SetStepForward( state, action: PayloadAction<{currentStep:number, path: string}> ) {
        let { currentStep, path } = action.payload;
        state.currentStep = ++currentStep;
        state.pathHistory.push(path);
    },

    SetStepBackward( state, action: PayloadAction<number> ) {
        state.currentStep = --action.payload;
        state.pathHistory.pop()
    },

    ResetFlowState(state) {
      state.totalSteps = initState.totalSteps;
      state.currentStep = initState.currentStep;
      state.pathHistory = initState.pathHistory;
    },

  },
});

export const { SetCurrentStep, SetTotalSteps, SetStepForward, SetStepBackward, ResetFlowState } = flowSlice.actions;

export const SelectTotalSteps = (state: any): number => state.flow.totalSteps;

export const SelectCurrentStep = (state: any): number => state.flow.currentStep;

export const SelectPathHistory = (state: any): string[] => state.flow.pathHistory

export default flowSlice.reducer;
