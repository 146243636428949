import { withTheme } from "styled-components"
import { Tag } from "../../primitives/styles/Ripples/RipplesStyle"

interface SelectedTagsProps {
    theme: any;
    tag: string;
    handleRemoveTag: (tag: string) => void;
}
const RippleTag: React.FC<SelectedTagsProps> = ({tag, theme, handleRemoveTag}) => {

    return (                
    <Tag onClick={() => handleRemoveTag(tag)}>
      <span style={{marginRight: 10 * theme.ratio}}>{tag}</span>
      <span>x</span>
    </Tag>
    )
}

export default withTheme(RippleTag)