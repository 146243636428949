import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { SelectRatio, SelectDeviceHeight, SelectHeightRatio } from '../store/viewModeSlice';
const StyledThemeProvider = ({ children }: { children: JSX.Element }) => {
  const ratio = useSelector(SelectRatio)
  const heightRatio = useSelector(SelectHeightRatio);
  const deviceHeight = useSelector(SelectDeviceHeight)
  const theme = {
    ratio:ratio,
    heightRatio,
    deviceHeight: deviceHeight,
    palette: {
      ratio: ratio,
      white: '#FFFFFF',
      black: '#131418',
      lightBlack: '#20242F',
      grey: '#383C46',
      red: '#E86161',
      border: 'rgba(255, 255, 255, 0.5)'
    },
    transparent: {
      red: 'rgba(240, 96, 96, 0.3)',
      grey: 'rgba(44, 46, 51, 0.85)',
      black: 'rgba(0, 0, 0, 0.8)'
    },
    fontFamily:{
      brandonGrotesque: 'Brandon Grotesque',
      brandonPrintedTwo: 'Brandon Printed Two',
      roboto: 'Roboto'
    },
    fontSize:{
      extra_large:'40px',
      large: '36px',
      mid_large: '32px',
      medium:'30px',
      small: '22px',
      success: '20px'
    }
  };
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledThemeProvider;
