import WizardContainer from './WizardContainer';
import RemoveRipplesContainer from '../RemoveRipples/RemoveRipplesContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';



const RemoveWizardContainer: React.FC = () => {
  const initialSteps: WizardSteps[] = [
    {
      name: 'delete',
      next: '/',
      component: RemoveRipplesContainer,
    }
  ];
  return <WizardContainer initialSteps={initialSteps} flowName="delete" />;

};

export default RemoveWizardContainer;
