
import Channel from './Channel';
import { Channel as ChannelType } from '../../primitives/types/Channel'
import { ChannelsList as Wrapper } from '../../primitives/styles/Channel/ChannelStyle';

interface ChannelsListProps {
  channels: Array<ChannelType>
  handleSelectChannel: Function;
}

const ChannelsList: React.FC<ChannelsListProps> = ({ channels, handleSelectChannel }) => {
  return (
    <Wrapper>
        { 
            Array.isArray(channels) && 
            channels.map( (channel: ChannelType) => (
                <Channel 
                  key={channel.id} 
                  channel={channel} 
                  handleSelectChannel={handleSelectChannel} 
                />
            )) 
        }
    </Wrapper>
  );
};


export default ChannelsList;
