import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';

const getTagsByOrgId = (orgId: string) => {

  const url = `${
    getConfigByEnv().PORTAL.REST_API_URL
  }/tags/${orgId}`;

  return axios.get(url).then(data => data);

};

export default getTagsByOrgId;
