import styled, { keyframes } from 'styled-components';
import { devices } from '../Devices/devices';

const notificationAnimation = keyframes`
 0% { height: 0px; }
 5% { height: 30vh; opacity: 1 }
 90% { height: 30vh; opacity: 1 }
 100% { height: 0px; opacity: 0}
 `


export const SuccessNotificationWrapper = styled.span`
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.palette.white};
  height: 0;
  opacity: 0;
  min-width: 100vw;
  border-radius: 30px 30px 0 0;
  animation-name: ${notificationAnimation};
  animation-duration: 4s;
  animation-iteration-count: 1;
`;

export const NotificationContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SuccessMessage = styled.span`
  width: 100%;
  height: 40%;
  z-index: 2;
  font-family: ${(props) => props.theme.fontFamily.brandonPrintedTwo};
  font-style: normal;
  font-weight: 350;
  text-align: center;
  text-transform: uppercase;
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.red};
  @media ${devices.mobile.portrait} {
    font-size: calc(19px * 1);
  }
  @media ${devices.tablet.portrait} {
    font-size: calc(19px * 1.5);
  }
`;
