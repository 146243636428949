import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const createBadgeStyles = (ratio = 1) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        zIndex: 2,
        transform: `scale(${1.35 * ratio})`,
        position: 'absolute',
        bottom: `calc(23px * ${ratio})`,
        left: `calc(23px * ${ratio})`,
        width: 'auto',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      badge:{
        background: '#E86161',
      },
      checkedIcon: {
        zIndex: 2,
        transform: `scale(${0.7 * ratio})`,
        position: 'absolute',
        color: 'white',
        bottom: `calc(-12px * ${ratio})`,
        left: `calc(12px * ${ratio})`,
      },
    })
  );
};

