import Phrase from '../Phrase/Phrase';
import { withTheme } from 'styled-components';
import {
  TabsContainer,
  Tab,
} from '../../primitives/styles/Channel/LocationAppTab';

import {
  MobileTabIcon,
  RMTabIcon,
} from '../../primitives/media/Icons';

interface ManageChannelTabsProps {
  theme: any;
  currentTab: string;
  isAddChannelSelected: boolean;
  handleTabSelected: (type: string) => void;
}

const ManageChannelTabs: React.FC<ManageChannelTabsProps> = ({
  currentTab,
  handleTabSelected,
  isAddChannelSelected,
}) => {
  return (
    <TabsContainer>
      {isAddChannelSelected ? (
        <>
          <Tab
            isTouched={currentTab === 'MyChannels'}
            onClick={() => handleTabSelected('MyChannels')}
          >
            <Phrase
              defaultText={'My channels'}
              translationKey={'067bd393-b6ca-44c6-a855-728f2b117ab0'}
            />
          </Tab>
          <Tab
            isTouched={currentTab === 'SharedChannels'}
            onClick={() => handleTabSelected('SharedChannels')}
          >
            <Phrase
              defaultText={'Shared channels'}
              translationKey={'1ee79b10-81da-4977-841f-027f2e362e6f'}
            />
          </Tab>
        </>
      ) : (
        <>
          <Tab
            isTouched={currentTab === 'RM'}
            onClick={() => handleTabSelected('RM')}
          >
            <RMTabIcon />
          </Tab>
          <Tab
            isTouched={currentTab === 'Mobile'}
            onClick={() => handleTabSelected('Mobile')}
          >
            <MobileTabIcon />
          </Tab>
        </>
      )}
    </TabsContainer>
  );
};

export default withTheme(ManageChannelTabs);
