import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit';
import { createMemoryHistory  } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { ThunkAction } from 'redux-thunk';
import createRootReducer from './rootReducer';

export const history = createMemoryHistory()
const rootReducer = createRootReducer(history);
export type RootState = ReturnType<typeof rootReducer>;

const router = routerMiddleware(history);
const middleware = [...getDefaultMiddleware(), router];

export const configuredStore = (initialState?: RootState) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState: initialState,
    devTools: true
  });

  return store;
};
export type Store = ReturnType<typeof configuredStore>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
