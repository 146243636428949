import * as React from 'react';

import clsx from 'clsx';
import GA from '../../utils/analytics';
import HomepageMenu from './HomepageMenu';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import HomepageHelpMenu from './HomepageHelpMenu';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './headerBarStyles';
import { RippleLogoIcon } from '../../primitives/media/Icons';
import {
  ToolBar,
  LogoIconContainer,
} from '../../primitives/styles/HeaderBar/HeaderBarStyles';

interface HeaderBarProps {
  orgName: string;
  disabled: boolean;
  hasSingleOrg: boolean;
  openChooseOrg: () => void;
  openGoToWebappModal: () => void;
  hideGoToWebapp?: boolean;
}

const HomepageHeaderBar: React.FC<HeaderBarProps> = ({
  orgName,
  disabled,
  hasSingleOrg,
  openChooseOrg,
  openGoToWebappModal,
  hideGoToWebapp,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);

  const handleDrawerOpen = () => {

    GA.event({
      category: 'Header Bar',
      action: 'Open Menu'
    });

    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpenHelp = () => {

    GA.event({
      category: 'Header Bar',
      action: 'Open Help Menu'
    });

    setOpenHelp(true);
  };

  const handleDrawerCloseHelp = () => {
    setOpenHelp(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <ToolBar>
          <IconButton
            disabled={disabled}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon
              className={clsx(classes.menuButton, open && classes.hide)}
            />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open help"
            onClick={handleDrawerOpenHelp}
            edge={false}
            className={clsx(classes.menuButtonHelp, open && classes.hide)}
          >
            ?
          </IconButton>
          <LogoIconContainer>
            <RippleLogoIcon />
          </LogoIconContainer>
        </ToolBar>
      </AppBar>

      <HomepageMenu
        isOpen={open}
        orgName={orgName}
        hasSingleOrg={hasSingleOrg}
        openChooseOrg={openChooseOrg}
        handleClose={handleDrawerClose}
        handleOpenHelp={handleDrawerOpenHelp}
        openGoToWebappModal={openGoToWebappModal}
        hideGoToWebapp={hideGoToWebapp}
      />
      <HomepageHelpMenu isOpen={openHelp} handleClose={handleDrawerCloseHelp} />
    </div>
  );
};

export default HomepageHeaderBar;
