import styled from 'styled-components';
// import { devices } from '../Devices/devices';
import { global } from '../Global/global';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: space-between;
  width: contain;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  min-height: max-content;
  padding-top: calc(21px * ${(props) => props.theme.ratio});
  font-size: calc(21px * ${(props) => props.theme.ratio});
  width: calc(150px * 2.25 * ${(props) => props.theme.ratio});
  border-bottom: 1px solid ${(props) => props.theme.palette.grey};
  ${global.userSelectNone}
`;

export const Tab = styled.span.attrs((props: { isTouched: boolean }) => ({
  isTouched: props.isTouched,
}))`
  display: flex;
  flex: 1;
  flex-direction: raw;
  justify-content: space-around;
  width: contain;
  text-align: center;
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  padding-bottom: calc(5px * ${(props) => props.theme.ratio});
  padding-right: calc(10px * ${(props) => props.theme.ratio});
  border-bottom: 2px solid
    ${(props) =>
      props.isTouched ? props.theme.palette.red : props.theme.palette.black};
  ${global.userSelectNone}
`;

export const TabTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: raw;
  background: ${(props) => props.theme.palette.black};
  color: ${(props) => props.theme.palette.white};
  font-size: calc(21px * ${(props) => props.theme.ratio});
  font-family: ${(props) => props.theme.fontFamily.brandonGrotesque};
  padding-top: calc(5px * ${(props) => props.theme.ratio});
  padding-bottom: calc(10px * ${(props) => props.theme.ratio});
  margin-bottom: calc(10px * ${(props) => props.theme.ratio});
  padding-right: calc(10px * ${(props) => props.theme.ratio});
  ${global.userSelectNone}

`;
