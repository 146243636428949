import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SelectTranslations } from '../../store/translationSlice';
import useStyles from './styles';

type AlertProps = {
    onClick: () => void
}
  
const CampaignRunningAlert = ({onClick}: AlertProps) => {
    const translations = useSelector(SelectTranslations);
    
    const campaignRunningText: string = translations['d40a2aae-c330-400b-ac56-047b171e6e9d'] ||
    'Campaign is running at the selected location. The channels you publish will appear once the campaign ends.'

    const dismissText: string = translations['25904d0e-d4ce-4ea6-b78a-56d451a38246'] || 'DISMISS'
    
    const classes = useStyles();

    return (
        <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography className={classes.text}>
            {campaignRunningText}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
            <Button className={classes.button} onClick={onClick} >{dismissText}</Button>
        </CardActions>
      </Card>
    )
}

export default CampaignRunningAlert