import Loading from '../../components/Loading/Loader';
import DatePicker from '../../components/DatePicker/DatePicker';
import ConnectionError from '../../components/Errors/ConnectionError';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';
import CampaignRunningAlert from '../../components/CampaignRunningAlert/CampaignRunningAlert';

import { useState, useEffect } from 'react';
import { FlowName } from '../../primitives/types/Flow';
import { useDispatch, useSelector } from 'react-redux';
import { SelectRipples } from '../../store/ripplesSlice';
import { SelectChannel } from '../../store/channelSlice';
import { SelectTotalSteps } from '../../store/flowSlice';
import { SelectLocationId } from '../../store/locationSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { TimeLimitOptions } from '../../services/ripplesService';
import { SelectOrgId, SelectCanAssign } from '../../store/userSlice';
import { SetNotificationName } from '../../store/notificationsSlice';
import { publishRipples} from '../../services/ripplesService';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import getCampaignByLocationId from '../../api/rest/getCampaignByLocationId';
import { useTheme } from 'styled-components';

interface PublishChannelsContainerProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
}

const PublishChannelsContainer: React.FC<PublishChannelsContainerProps> = ({
  currentStep,
  nextPath,
  flowName,
}) => {
  const dispatch = useDispatch();
  const channel = useSelector(SelectChannel);
  const ripples = useSelector(SelectRipples);
  const locationId = useSelector(SelectLocationId);
  const organization: string = useSelector(SelectOrgId);
  const canApproveRipple: boolean = useSelector(SelectCanAssign);
  const totalSteps = useSelector(SelectTotalSteps);

  const initPublishError: any = false;
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [timeLimit, setTimeLimit] = useState(false);
  const [publishError, setPublishError] = useState(initPublishError);
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const theme: any = useTheme();

  const handleCampaign = (campaign: any) => {
    if (!campaign || !campaign._doc) return setIsAlertOpen(false);

    setIsAlertOpen(campaign._doc.overwriteContent)
  }

  useEffect(() => {
    if (locationId && organization) {
      getCampaignByLocationId(locationId, organization)
        .then(({ data }) => data.data)
        .then(campaign => handleCampaign(campaign))
        .catch(() => {
          setPublishError(true);
        });
    }
  }, [locationId, organization])


  useEffect(() => {
    const limit = !!startDate && !!endDate;
    setTimeLimit(limit);
    //eslint-disable-next-line
  }, [startDate, endDate]);



  const handleChangeTimeLimit = (limit: boolean) => {
    setTimeLimit(limit);

    if (!limit) {
      setStartDate('')
      setEndDate('')
    }
  };

  const handlePublishError = (error?: { errorTitle: string, errorContent: string }) => {
    if (error) {
      setPublishError(error)
    } else {
      setPublishError(true);
    }
    setIsPublishInProgress(false);
  };

  const handleCloseErrorModal = () => {
    if (typeof publishError === 'boolean') {
      setPublishError(false);
    } else {
      setPublishError(false);
      nextPath();
    }
  };

  const handleAlertClick = () => {
    setIsAlertOpen(false)
  };

  const handlePublishChannelWithRipples = async ({channelId, showOnMobile, from, to}: {channelId: string, showOnMobile?: boolean, from?: string, to?: string}) => {
    try {
      if (!navigator.onLine) {
        handlePublishError();
        return;
      }
      const timeLimitOptions: TimeLimitOptions = {
        timeLimit,
        startDate: from || '',
        endDate: to || '',
      };
      setIsPublishInProgress(true);
      const { success, clientWarningMessage, error } = await publishRipples({
        timeLimitOptions,
        canApproveRipple,
        organization,
        flowName,
        ripples,
        locationId,
        channelId,
        showOnMobile
      })

      if (success) {
        dispatch(SetNotificationName({
          clientWarningMessage,
          notificationName: flowName,
        }));
        nextPath();
      } else {
        handlePublishError(error);
      }
    } catch (err) {
      handlePublishError();
    }
    //TODO: Error handling
    /*currently, we don't have a proper error handling, retries or rollback. 
     Therefor we just assume that the process will complete successfully*/
  };

  const handleSetStartDate = (date: string) => {
    setStartDate(date);
  };

  const handleSetEndDate = (date: string) => {
    setEndDate(date);
  };

  return (
    <Container>
      <BreadcrumbsHeader
        totalSteps={totalSteps}
        currentStep={currentStep}
        isPublishInProgress={isPublishInProgress}
      />
      {isAlertOpen && <CampaignRunningAlert onClick={handleAlertClick} />}
      <Wrapper style={{ marginTop: `calc( 70.16px * ${theme.ratio})` }}>
        <DatePicker
          timeLimit={timeLimit}
          to={endDate}
          from={startDate}
          setTo={handleSetEndDate}
          setFrom={handleSetStartDate}
          onPublish={(timeLimit) => handlePublishChannelWithRipples({channelId: channel.id, from: timeLimit?.from, to: timeLimit?.to})}
          handleChangeTimeLimit={handleChangeTimeLimit}
          isPublishOnProgress={isPublishInProgress}
        />
      </Wrapper>
      {(publishError && typeof publishError === 'boolean') && <ConnectionError onClose={handleCloseErrorModal} />}
      {(publishError && typeof publishError === 'object') && (
        <ConnectionError
          onClose={handleCloseErrorModal}
          error={publishError.errorContent}
          errorTitle={publishError.errorTitle}
        />
      )}

    </Container>
  );
};

export default withAuthenticationRequired(PublishChannelsContainer, {
  onRedirecting: () => <Loading />,
});
