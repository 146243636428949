
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SetTotalSteps } from '../../store/flowSlice';
import { FlowName } from '../../primitives/types/Flow';
import { useDispatch, useSelector } from 'react-redux';
import { generateAppRoutes } from '../../constants/Routes';
import { Location } from '../../primitives/types/Location';
import { SelectLocations } from '../../store/locationSlice';
import { WizardSteps } from '../../primitives/types/WizardSteps';
import { Route, useRouteMatch, Redirect } from 'react-router-dom';


interface WizardContainerProps {
  initialSteps: Readonly<WizardSteps[]>
  flowName: FlowName
}
const WizardContainer: React.FC<WizardContainerProps> = ({
  initialSteps,
  flowName
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routes = generateAppRoutes();
  const locations = useSelector(SelectLocations);


  /*
    The generic container receives the initialSteps as props from the <FlowName>WizardContainer.
    It is required to perform some filtering on the data (see comment below) before the rendering. 
    Having that on mind, we use state as the only source of truth for rendering. 
  */
  const [firstStep, setFirstStep] = useState('')
  const [steps, setSteps] = useState(initialSteps)
  const [stepsReady, setStepsReady] = useState(false)


  /*
   This function is about preparing the flow steps for rendering.
   In case we have a single location (it is already selected in the homepage),
   the received initial steps will be filtered and exclude the location step as it should not be rendered.
   Having that, the total steps array are always equal to steps length.
   
   In case we indeed have a single location that is already selected, we must set the 'next' prop of the 
   first step object to be equal to the 'name' prop of the NEW next step (having the location step filtered out)
  */
  const prepareSteps = (locations: Location[]) => {
    const flowSteps = initialSteps;
    
    if (locations.length === 1){
      const _steps = flowSteps.filter((step) => {
        return step.name !== 'location'
      });

      dispatch(SetTotalSteps(_steps.length));
      if(_steps.length > 1){
        _steps[0].next = _steps[1].name
      }

      setSteps(_steps);
      setFirstStep(_steps[0].name)
      setStepsReady(true)
      
    } else {
      dispatch(SetTotalSteps(flowSteps.length));
      setFirstStep(flowSteps[0].name)
      setStepsReady(true)
    }
  }

  useEffect(() => {
    if(locations) {
      window.flow.isInProgress = true;
      prepareSteps(locations)
    }
    // eslint-disable-next-line
  }, [locations]);

  const { path } = useRouteMatch();

  const HandleNextStep = (nextPath: string) => {
    if (nextPath === '/') {
      history.push(routes.HOME);
    } else {
      history.push(`${path}/${nextPath}`);
    }
  };

  return (
    <>
      {stepsReady && steps.map(({ name, next, component: Step }, index) => {
        return (
          <Route
            key={`${path}/${name}`}
            exact
            path={`${path}/${name}`}
            render={() => (
              <Step
                currentStep={index + 1}
                flowName={flowName}
                path={`${path}/${name}`}
                nextPath={() => HandleNextStep(next)}
              />
            )}
          />
        );
      })}
      <Route exact path={path}>
        <Redirect from={path} to={`${path}/${firstStep}`} />
      </Route>
    </>
  );
};

export default WizardContainer;
