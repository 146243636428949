import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import Title from '../../../../components/Title/Title'
import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import TITLES from '../../../../constants/Titles';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import Phrase from '../../../../components/Phrase/Phrase';
import fontSize from '../../../../constants/FontSize';
import { LocationContentContainer } from '../../../../primitives/styles/Onboarding/Location';
import { Answer, ContentSurveyStep } from '../../../../primitives/types/ContentSurevyStep';
import PlaceType from './components/PlaceType';
import DesignOptions from './components/DesignOptions';
import FloatingLocationButton from '../../components/FloatingLocationButton';

interface DesignPreferencesProps {
  currentStep: number;
  totalSteps: number;
  question?: ContentSurveyStep;
  onSelect: (place: Answer, isSelected: boolean) => void;
  onNext: () => void;
  onClose: () => void;
  onBack: () => void;
  selectedPlaceType?: Answer;
  theme?: any
}

const DesignPrefernces: React.FC<DesignPreferencesProps> = ({
  currentStep,
  totalSteps,
  question,
  onSelect,
  onNext,
  onClose,
  theme = {},
  onBack,
  selectedPlaceType,
}) => {
  const { heightRatio } = theme;

  const titlesStyle = {
    whiteSpace: 'pre-line', textAlign: 'left', marginTop: `calc( 10px * ${heightRatio})`, textShadow: 'none' 
};

const subTitleStyle: Object = {
    textAlign: 'left',
    width: '100%',
    marginTop: `calc( 5px * ${heightRatio})`
}

const selectedDesignOptions = question?.selectedAnswer || [];

  return (
    <Container>
      <BreadcrumbsHeader currentStep={currentStep} totalSteps={totalSteps} onClose={onClose} onBack={onBack} flow={'aboutYourPlace'}/>
      <OnboardingContainer style={{overflowY: 'auto', width: '95%'}}>
        {question &&
      <LocationContentContainer style={{width: '100%', alignItems: 'center'}}>
      <LocationContentContainer style={{width: '90%', minHeight: 'fit-content'}}>
      <Title type="flow" text={TITLES.DESIGN_PREFERENCES} defaultText={'Design preferences'} size={fontSize.MID_LARGE} style={titlesStyle} />
      <SubTitle style={subTitleStyle}>
        <Phrase defaultText={question.subTitle.defaultText} translationKey={question.subTitle.translationKey}/>
      </SubTitle>
      <PlaceType place={selectedPlaceType}/>
      </LocationContentContainer>
      <DesignOptions desingOptions={question.answers} onClick={onSelect} selectedDesingOptions={selectedDesignOptions}/>
      </LocationContentContainer>
      }
      <FloatingLocationButton onClick={onNext} theme={theme} disabled={!selectedDesignOptions.length}>
          <Phrase defaultText={`Select (${selectedDesignOptions.length})`} translationKey='3369276b-1c04-4fa6-b75b-0edf74159513' vars={{ number: selectedDesignOptions.length.toString() }}/>
      </FloatingLocationButton>
      </OnboardingContainer>
    </Container>
  );
};

export default DesignPrefernces;