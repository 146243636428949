import getConfigByEnv from '../../utils/getConfigByEnv';
import axios from 'axios';
import qs from 'querystring'

const getAddressByCoordinates = ({longitude, latitude}:{longitude: number, latitude: number}) => {
    const url = `${
        getConfigByEnv().PORTAL.REST_API_URL
      }/locations/getAddress?${qs.stringify({longitude, latitude})}`;
    
      return axios.get(url).then(data => data);
}

export default getAddressByCoordinates;
