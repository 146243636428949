import { useEffect } from 'react';
import { CloseIcon } from '../../../primitives/media/Icons';
import { Channel as ChannelType } from '../../../primitives/types/Channel';
import { CloseWrapper } from '../../../primitives/styles/Common/CommonStyle';
import {
    RippleImage,
    InlineRippleWrapper,
    ScrollableRipplesList,
    ScrollableRipplesListWrapper
  } from '../../../primitives/styles/Ripples/RipplesStyle';

import {
  RippleModalWrapper,
  RippleInfoModalClose,
  RippleInfoModalHeader,
  RippleInfoModalTitle,
  RippleInfoModalContent,
} from '../../../primitives/styles/Ripples/RipplesStyle';


interface ChannelInfoModalProps {
  channel: ChannelType
  closeModal: () => void;
}

const ChannelInfoModal: React.FC<ChannelInfoModalProps> = ({
  channel,
  closeModal
}) => {

  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  },[]);

  const { ripplesOn } = channel;
  return (
    <RippleModalWrapper>
      <RippleInfoModalContent>
        <RippleInfoModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={closeModal}>
              <CloseIcon />
            </CloseWrapper>
          </RippleInfoModalClose>
          <RippleInfoModalTitle>
            {channel.name}
          </RippleInfoModalTitle>
        </RippleInfoModalHeader>
        <ScrollableRipplesListWrapper>
            <ScrollableRipplesList>
                {ripplesOn && ripplesOn.map( (ripple) => (
                    <InlineRippleWrapper key={ripple.id}>
                        <RippleImage src={ripple.rippleImage?.thumbnailUrl} />
                    </InlineRippleWrapper>
                ))}
                {/* empty wrappers for spacing and better scrolling experience */}
                <InlineRippleWrapper/>
                <InlineRippleWrapper/>
            </ScrollableRipplesList>
        </ScrollableRipplesListWrapper>
      </RippleInfoModalContent>
    </RippleModalWrapper>
  );
};

export default ChannelInfoModal;
