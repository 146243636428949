import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loader';
import NewChannel from '../../components/Channel/NewChannel';
import ChannelsList from '../../components/Channel/ChannelsList';
import NoDataMessage from '../../components/Common/NoDataMessage';
import ConnectionError from '../../components/Errors/ConnectionError';
import ThumbnailWidget from '../../components/ThumbNails/ThumbnailWidget';
import BreadcrumbsHeader from '../../components/Breadcrumbs/BreadcrubmbsHeader';

import { get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { gqlClient } from '../../utils/gqlClient';
import { SetChannel } from '../../store/channelSlice';
import { FlowName } from '../../primitives/types/Flow';
import { useSelector, useDispatch } from 'react-redux';
import { SelectRipples } from '../../store/ripplesSlice';
import { SelectLocationId } from '../../store/locationSlice';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SelectOrgId, SelectCanAssign } from '../../store/userSlice';
import { GET_CHANNELS } from '../../api/graphQl/query/getChannels';
import { Channel as ChannelType } from '../../primitives/types/Channel';
import { SelectTotalSteps, SetStepForward } from '../../store/flowSlice';
import { CREATE_CHANNEL } from '../../api/graphQl/mutation/createChannel';
import { Wrapper } from '../../primitives/styles/WizardSteps/WizardStepWrapperStyle';
import { Container } from '../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import { NoChannelsMessage } from '../../primitives/styles/Channel/NewChannelStyle';
import Phrase from '../../components/Phrase/Phrase';

const ORG_CHANNELS_PATH = 'viewer.organizationScope.channels.edges';
const NEW_CHANNEL_DEFAULT_ICON =
  'https://res.cloudinary.com/ripplemaker/image/upload/v1/static/channels/balloon_4x.png';

interface ChannelContainerProps {
  nextPath: () => void;
  currentStep: number;
  flowName: FlowName;
  path: string;
}

const ChannelContainer: React.FC<ChannelContainerProps> = ({
  currentStep,
  nextPath,
  flowName,
  path,
}) => {
  const dispatch = useDispatch();
  const ripples = useSelector(SelectRipples);
  const orgId: string = useSelector(SelectOrgId);
  const locationId: string = useSelector(SelectLocationId);
  const canAssign: boolean = useSelector(SelectCanAssign);

  const getAllLocations: boolean = false;
  const isMobileApp: boolean = canAssign;
  const isRippleMakerApp: boolean = canAssign;

  const [channels, setChannels] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newChannel, setNewChannel] = useState(true);
  const [connectionError, setConnectionError] = useState(false);
  const [channelsMetaData, setChannelsMetaData] = useState(null);
  const [createNewChannelInProgress, setCreateNewChannelInProgress] = useState(false)

  const totalSteps = useSelector(SelectTotalSteps);

  useEffect(() => {
    if (canAssign) {
      const getChannels = (): Promise<any> =>
        gqlClient.mutate(GET_CHANNELS, {
          orgId,
          canAssign,
          locationId,
          isMobileApp,
          getAllLocations,
          isRippleMakerApp,
        });

      getChannels()
        .then(({ data }) => {
          return {
            data,
            channels: get(data, ORG_CHANNELS_PATH).map((ch: any) => ch.node),
          };
        })
        .then(({ data, channels }) => {
          setChannels(channels);
          setChannelsMetaData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setNewChannel(false);
          setConnectionError(true);
        });
    }
    // eslint-disable-next-line
  }, [canAssign]);

  const createNewChannel = (channelName: string) => {
    setCreateNewChannelInProgress(true)
    return gqlClient.mutate(CREATE_CHANNEL, {
      name: channelName,
      organization: orgId,
      icon: NEW_CHANNEL_DEFAULT_ICON,
    });
  };

  const handleSelectChannel = (selectedChannel: ChannelType) => {
    dispatch(SetChannel({ channelsMetaData, selectedChannel }));
    dispatch(SetStepForward({ currentStep, path }));
    nextPath();
  };

  const onConnectionErrorClose = () => {
    setConnectionError(false);
  };

  if (!canAssign) {
    return (
      <Container>
        <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow={flowName as string} />
        <NoDataMessage
          useIcon={false}
          isSubtextLink={true}
          defaultText={'You don’t have permission to perform this action'}
          text={'08988c8f-8f4b-4413-8a48-e3496f4a4b40'}
        // You don’t have permission to perform this action
        />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow={flowName as string} />
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <BreadcrumbsHeader totalSteps={totalSteps} currentStep={currentStep} flow={flowName as string} />
      <Wrapper>
        <ThumbnailWidget ripples={ripples} />
        <Title
          type="flow"
          size={fontSize.MID_LARGE}
          text={titles.ASSIGN_TO_CHANNEL}
          defaultText={'Assign to channel'}
        />
        {Array.isArray(channels) && !isEmpty(channels) ? (
          <>
            <NoChannelsMessage>
              <Phrase
                translationKey={'d54af5d3-3f81-408b-bac2-56db9ffaee89'}
                defaultText={`Choose a channel for your designs This channel will be published to Ripple maker and displayed on mobile devices`}
              />
            </NoChannelsMessage>
            <ChannelsList
              channels={channels || []}
              handleSelectChannel={handleSelectChannel}
            />
          </>
        ) : (
          <>
            {newChannel && (
              <NewChannel
                createNewChannel={createNewChannel}
                inProgress={createNewChannelInProgress}
                handleSelectChannel={handleSelectChannel}
              />
            )}
          </>
        )}
      </Wrapper>
      {connectionError && (
        <ConnectionError onClose={onConnectionErrorClose} />
      )}
    </Container>
  );
};

export default withAuthenticationRequired(ChannelContainer, {
  onRedirecting: () => <Loading />,
});

//dummy