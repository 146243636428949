
import Phrase from '../Phrase/Phrase';

import { useEffect } from 'react';
import { CloseIcon } from '../../primitives/media/Icons';
import { WarningIcon } from '../../primitives/media/Icons';
import { CloseWrapper } from '../../primitives/styles/Common/CommonStyle';
import {
  RippleInfoDetail,
  RippleInfoModalClose,
  RippleWarningModalHeader,
} from '../../primitives/styles/Ripples/RipplesStyle';

import {
  ErrorWarningMsg,
  ErrorModalWrapper,
  ErrorModalContent,
  ErrorModalColumnTitle,
  DesignLimitErrorDetailsWrapper,
} from '../../primitives/styles/Common/CommonStyle'
import Button from '../Common/Button';

interface ErrorProps {
    error?: string;
    errorTitle?: string;
    onClose: () => void;
}

const NoFrameTemplatesError: React.FC<ErrorProps> = ({onClose, error, errorTitle}) => {
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  });


  return (
    <ErrorModalWrapper>
      <ErrorModalContent>
        <RippleWarningModalHeader>
          <RippleInfoModalClose>
            <CloseWrapper style={{ padding: 0 }} onClick={onClose}>
              <CloseIcon />
            </CloseWrapper>
          </RippleInfoModalClose>
          <ErrorModalColumnTitle>
            <WarningIcon />
            <Phrase
              translationKey={'48d53a01-84c5-4686-a01f-a8a1558135d7'}
              defaultText={'Create Frame'}
            />
          </ErrorModalColumnTitle>
        </RippleWarningModalHeader>
        <DesignLimitErrorDetailsWrapper>
          <RippleInfoDetail>
            <ErrorWarningMsg style={{marginBottom: "50px"}}>
              <Phrase
                translationKey={'78d7d6d4-29b3-475d-b4d8-5672f9d8c537'}
                defaultText={'Frame templates are only available to customers who have access to content from Ripples'}
              />
            </ErrorWarningMsg>

            <Button
              onClick={onClose}
              isDisabled={false}
              variant={'contained'}
              buttonText={'14c7cd6c-8397-4f6d-9176-21f425e23833'}
              buttonDefaultText={'GOT IT'}
            />
          </RippleInfoDetail>
        </DesignLimitErrorDetailsWrapper>
      </ErrorModalContent>
    </ErrorModalWrapper>
  );
};
export default NoFrameTemplatesError;
