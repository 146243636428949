import styled from 'styled-components';

export const Wrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: calc( 105.16px * ${(props) => props.theme.ratio});
  margin-left: 10px;
  margin-right: 10px;
`;


export const FrameWizardWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: calc( 20px * ${(props) => props.theme.ratio});
`;
