import { useSelector } from 'react-redux';
import { SelectTranslations } from '../../../../../store/translationSlice';
import { AddressContainer, AddressRow } from '../../../../../primitives/styles/Onboarding/Location';
import LocationTextField from './CreateLocationTextField';
import { FormikProps } from 'formik';
import { useState } from 'react';
import SelectModal from '../../../../../components/SelectModal/SelectModal';

const UNITED_STATES_CODE = '223';

interface AddressProps {
  form?: FormikProps<any>;
  countries?: any[];
  states?: any[]
}

const Address: React.FC<AddressProps> = ({
  form,
  countries = [], 
  states = []
}) => {
  const [isCountryModalOpen, setIsCountryModalOpen] = useState<boolean>(false)
  const [isStateModalOpen, setIsStateModalOpen] = useState<boolean>(false)

  const translations = useSelector(SelectTranslations);
  const countryLabel: string = translations['2b436e0a-ce50-4e22-9e24-3b5c715dd8a6'] || 'Country';
  const stateLabel: string = translations['90ecf8e4-361e-472e-8292-09d51dd2449b'] || 'State';
  const cityTownLabel: string = translations['b4bce275-8c50-436c-86d4-5802bacd5370'] || 'City / town';
  const postCodeLabel: string = translations['61a31356-6c87-4a86-be43-e372e883da76'] || 'Postcode / ZIP';
  const streetAddressLabel: string = translations['511c7e23-1e15-4cfb-a958-eaffc2d92e10'] || 'Street Address';
  const requiredText  = translations['a615fa81-f3f3-41d4-be4d-1684cff14737'] || 'This field is required';



  const handleSelectCountry = (country: any) => {
    form?.setFieldValue('country', country.id.toString())
    setIsCountryModalOpen(false)
  };

  const handleSelectState = (state: any) => {
    form?.setFieldValue('state', state.id.toString())
    setIsStateModalOpen(false)
  };

  const getCountryName = () => {
    if (countries.length > 0) {
      const country = countries.find(country => country.id.toString() === form?.values.country);
      if (country) return country.name
    }

    return '';
  }

  const getStateName = () => {
    if (states.length > 0) {
      const state = states.find(state => state.state.toString() === form?.values.state);
      if (state) return state.name
    }

    return '';
  }

  const onStateClick = () => {
    if (form?.values.country?.toString() === UNITED_STATES_CODE) {
      setIsStateModalOpen(true);
    }
  }

  return (
    <AddressContainer>
      <AddressRow>
        <LocationTextField 
        id={'country'} 
        name={'country'} 
        onBlur={form?.handleBlur} 
        value={getCountryName()} 
        error={!!form?.errors.country && !!form?.touched.country} 
        label={countryLabel} 
        style={{width: '45%'}}
        errorText={requiredText}
        readonly={true}
        onClick={() => setIsCountryModalOpen(true)}
        />
        <LocationTextField 
        label={stateLabel} 
        id={'state'} 
        name={'state'} 
        onBlur={form?.handleBlur} 
        value={getStateName()} 
        error={!!form?.errors.state} 
        style={{width: '45%'}}
        disabled={form?.values.country?.toString() !== UNITED_STATES_CODE}
        errorText={requiredText}
        readonly={true}
        onClick={onStateClick}
        />
      </AddressRow>
      <AddressRow>
      <LocationTextField label={cityTownLabel} id={'city'} name={'city'} onBlur={form?.handleBlur} onChange={form?.handleChange} value={form?.values.city} error={!!form?.errors.city && !!form?.touched.city} errorText={requiredText} style={{width: '45%'}}/>
      <LocationTextField label={postCodeLabel} id={'postCode'} name={'postCode'} onBlur={form?.handleBlur} onChange={form?.handleChange} value={form?.values.postCode} error={!!form?.errors.postCode && !!form?.touched.postCode} style={{width: '45%'}} errorText={requiredText}/>
      </AddressRow>
      <LocationTextField label={streetAddressLabel} id={'streetAddress'} name={'streetAddress'} onBlur={form?.handleBlur} onChange={form?.handleChange} value={form?.values.streetAddress} error={!!form?.errors.streetAddress && !!form?.touched.streetAddress} style={{width: '100%'}} errorText={requiredText}/>
      {isCountryModalOpen && (
        <SelectModal
          selectedOption={form?.values.country}
          options={countries}
          shouldPreviewCloseButton={true}
          defaultTitle={'Select country'}
          onSelectOption={handleSelectCountry}
          title={'8fd1c9f8-83fd-4c02-8163-985d9fd9b7e5'}
          closeSelectModal={() => setIsCountryModalOpen(false)}
          noOptionsTitle={'03ab60e3-6699-476d-887b-d8a49a5ef0f3'}
          defaultNoOptionsTitle={'No results found'}
        />
      )}

      {isStateModalOpen && (
        <SelectModal
          selectedOption={form?.values.country}
          options={states.map((state: any) => ({name: state.name, id: state.state.toString()}))}
          shouldPreviewCloseButton={true}
          defaultTitle={'Select state'}
          onSelectOption={handleSelectState}
          title={'a7b909e3-1630-4d43-9063-6bcac21edb75'}
          closeSelectModal={() => setIsStateModalOpen(false)}
          noOptionsTitle={'03ab60e3-6699-476d-887b-d8a49a5ef0f3'}
          defaultNoOptionsTitle={'No results found'}
        />
      )}
    </AddressContainer>
  );
};

export default Address;