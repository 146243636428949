import TitleRender from '../Title/Title';
import titles from '../../constants/Titles';
import fontSize from '../../constants/FontSize';


import { LandscapeError } from '../../primitives/styles/LandscapeError/LandscapeErrorStyle';
import { LandscapeErrorIcon } from '../../primitives/media/Icons';

const LandScapeErrorPage = (): JSX.Element => {
  return (
    <LandscapeError>
      <LandscapeErrorIcon />
      <TitleRender 
        type="error" 
        text={titles.LANDSCAPE_ERROR} 
        defaultText={'MOBILE PORTAL WORKS IN PORTRAIT MODE ONLY'} 
        size={fontSize.MEDIUM}
      />
    </LandscapeError>
  );
};


export default LandScapeErrorPage;