import QRCode from 'qrcode.react';

// import { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import {
  QRcodeWrapper,
  QRcodeImage,
} from '../../primitives/styles/QRcode/QRcodeStyles';

interface QRcodeProps {
  theme: any;
  value: string;
  imgSrc: string;
}

const QRcode: React.FC<QRcodeProps> = ({
  value,
  theme,
  imgSrc,
}) => {
  return (
    <>
      {imgSrc.length > 0 && (
        <QRcodeImage
          id={'qr-code-location-img'}
          alt={''}
          src={imgSrc}
        />
      )}

      {!imgSrc.length && (
        <QRcodeWrapper>
          <QRCode
            id="qr-code-location-canvas"
            value={value}
            size={150 * theme.ratio}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'Q'}
            includeMargin={false}
            renderAs={'canvas'}
            imageSettings={{
              src: '/favicon.ico',
              height: 28 * theme.ratio,
              width: 28 * theme.ratio,
              excavate: true,
            }}
          />
        </QRcodeWrapper>
      )}
    </>
  );
};

export default withTheme(QRcode);
