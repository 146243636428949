import { createStyles, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const PublishButtonsContainer = styled.div<{ratio?: number}>`
position: fixed;
bottom: 0;
left: 0;
right: 0;
height: calc(135px * ${props => props.ratio});
display: flex;
flex-direction: column;
align-items: center;
background: linear-gradient(rgba(19, 20, 24, 0.25), rgba(0, 0, 0, 1));
z-index: 99;
`
export const createPublishButtonStyles = (
    isPrimary: boolean,
    ratio?: number,
  ) => {
    return makeStyles(() =>
      createStyles({
        root: {
          width: `calc(280px * ${ratio})`,
          height: `calc(48px * ${ratio})`,
          borderRadius: `calc(25px * ${ratio})`,
          paddingTop: 13,
          paddingBottom: 13,
          fontFamily: 'Brandon Grotesque',
          fontWeight: 'bolder',
          fontSize: `calc(15px * ${ratio})`,
          letterSpacing: '1px',
          zIndex: 99,
          color: isPrimary ? '#ffffff' : '#e86161',
          backgroundColor: isPrimary ? '#e86161' : '#ffffff',
          '&.MuiButton-root:hover': {
            backgroundColor: isPrimary ? '#e86161' : '#ffffff',
          }
        },
      })
    );
  };