export const GET_CHANNELS = `
  query(
    $orgId: String!
    $locationId: String!
    $canAssign: Boolean!
    $getAllLocations: Boolean!
  ) {
    viewer {
      organizationScope(id: $orgId) {
        channels @include(if: $canAssign){
          edges {
            node {
              id
              name
              icon
              numberOfRipples
              ripplesOrder
            }
          }
        }
        locations(archived: false) @include(if: $getAllLocations) {
          edges {
            node {
              id
              name
              apps {
                channels {
                  channel {
                    id
                    name
                    icon
                    numberOfRipples
                  }
                  showOnMobile
                }
              }
            }
          }
        }
        location(id: $locationId) @skip(if: $getAllLocations) {
          id
          name
          apps {
            channels {
              channel {
                id
                name
                icon
                numberOfRipples
              }
              showOnMobile
            }
          }
        }
      }
    }
  }
`;
