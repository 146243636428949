import WizardContainer from './WizardContainer';
import QRcodeContainer from '../QRcode/QRcodeContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';


const QRcodeWizardContainer: React.FC = () => {
  const initialSteps: WizardSteps[] = [
    {
      name: 'location',
      next: 'qrcode',
      component: SelectLocationContainer,
    },
    {
      name: 'qrcode',
      next: '/',
      component: QRcodeContainer,
    },
  ];
  return <WizardContainer initialSteps={initialSteps} flowName="qrcode" />;
};

export default QRcodeWizardContainer;
