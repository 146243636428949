import RippleTag from "./RippleTag";
import { SelectedTagsWrapper } from "../../primitives/styles/Ripples/RipplesStyle";


interface SelectedTagsProps {
    tags: string[]
    handleRemoveTag: (tag: string) => void;
}


const SelectedTags: React.FC<SelectedTagsProps> = ({ tags,handleRemoveTag }) => {

    return (
        <SelectedTagsWrapper>
            {tags.map( tag => <RippleTag key={tag} handleRemoveTag={handleRemoveTag} tag={tag}/>)}
        </SelectedTagsWrapper>
    );
}

export default SelectedTags;