import WizardContainer from './WizardContainer';
import FramesContainer from '../Frames/FramesContainer';
import SelectLocationContainer from '../SelectLocation/SelectLocationContainer';

import { WizardSteps } from '../../primitives/types/WizardSteps';
import AssignFrames from '../Frames/AssignFrames';


const FrameWizardContainer: React.FC = () => {
  const initialSteps: WizardSteps[] = [
    {
      name: 'createFrame',
      next: 'location',
      component: FramesContainer,
    },
    {
      name: 'location',
      next: 'publish',
      component: SelectLocationContainer,
    },
    {
      name: 'publish',
      next: '/',
      component: AssignFrames,
    }
  ];
  
  return <WizardContainer initialSteps={initialSteps} flowName="createFrame" />;
};

export default FrameWizardContainer;
